import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  menu: {
    width: 280,
    maxWidth: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      width: '280px'
    }
  },
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%'
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1.5)
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  imageProfileMenu: {
    width: '100%',
    height: '100%',
    borderRadius: 7
  },
  buttonChangePassword: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  homeButton: {
    justifyContent: 'flex-start',
    marginLeft: -5,
    '& .text': {
      transform: 'translateY(1px)'
    },
    marginBottom: theme.spacing(2)
  }
}));

export { useStyles };
