import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestForm.sections.MaterialsSection.title',
    defaultMessage: 'Materiales'
  },
  totalCostLabel: {
    id: 'components.EventRequestForm.sections.MaterialsSection.totalCostLabel',
    defaultMessage: 'Costo total'
  },
  totalCostPlaceholder: {
    id:
      'components.EventRequestForm.sections.MaterialsSection.totalCostPlaceholder',
    defaultMessage: 'Ingrese el costo total'
  },
  quoteLabel: {
    id: 'components.EventRequestForm.sections.MaterialsSection.quoteLabel',
    defaultMessage: 'Adjuntar Cotización: '
  },
  quoteButtonLabel: {
    id:
      'components.EventRequestForm.sections.MaterialsSection.quoteButtonLabel',
    defaultMessage: 'Seleccionar archivo PDF'
  }
});
