import { useIntl } from 'react-intl';
import { messages } from '../HcpsTableMessages';

export const useTable = () => {
  const intl = useIntl();

  const tableHead = [
    { key: 'oneKeyId', label: 'ID', align: 'center' },
    {
      key: 'name',
      label: intl.formatMessage(messages.nameLabel),
      align: 'center'
    },
    {
      key: 'license',
      label: intl.formatMessage(messages.licenseLabel),
      align: 'center'
    },
    {
      key: 'specialty',
      label: intl.formatMessage(messages.specialtyLabel),
      align: 'center'
    },
    {
      key: 'therapys',
      label: intl.formatMessage(messages.therapysLabel),
      align: 'center'
    },
    {
      key: 'email',
      label: intl.formatMessage(messages.emailLabel),
      align: 'center'
    }
  ];

  return { tableHead };
};
