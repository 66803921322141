import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Grid, Button } from '@material-ui/core';
import { messages } from './EmployeesActionsMessages';
import { useStyles } from './EmployeesActionsStyles';
import { useHistory } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useDrawer } from 'components/Drawer';
import { LoginAsForm } from '../LoginAsForm';
import { useEmployees } from '../hooks/useContext';
import { downloadFile } from 'utils/files';
import { useLoader } from '@octopy/react-loader';

const EmployeesActions = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleExportEmployee } = useEmployees();
  const { handleShowLoader } = useLoader();
  const history = useHistory();
  const { handleOpenDrawer } = useDrawer();
  const handleChangeSession = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.logInAs),
      caption: intl.formatMessage(messages.descriptionDrawer),
      body: <LoginAsForm />
    });
  };

  const handleExport = async () => {
    handleShowLoader(true);
    const exportResponse = await handleExportEmployee();
    const format = {
      ...exportResponse,
      name: intl.formatMessage(messages.title),
      date: new Date()
    };

    downloadFile(format);
    handleShowLoader(false);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={4} className={classes.gridTitle}>
        <Typography className={classes.title} color="primary" variant="h5">
          {intl.formatMessage(messages.title)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} className={classes.gridButtons}>
        <Button
          size="small"
          className={classes.buttonSize}
          color="primary"
          onClick={handleChangeSession}
        >
          {intl.formatMessage(messages.buttonSession)}
        </Button>
        <Button
          endIcon={<InsertDriveFileIcon />}
          className={classes.buttonNewEmployee}
          onClick={() => handleExport()}
        >
          {intl.formatMessage(messages.export)}
        </Button>
        <Button
          onClick={() => history.push('/employees/new')}
          className={classes.buttonNewEmployee}
          color="primary"
          endIcon={<AddCircleIcon />}
        >
          {intl.formatMessage(messages.newEmployee)}
        </Button>
      </Grid>
    </Grid>
  );
};

EmployeesActions.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { EmployeesActions };
