import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.SpeakerTable.title',
    defaultMessage: 'Speaker'
  },
  name: {
    id: 'components.SpeakerTable.name',
    defaultMessage: 'Nombre'
  },
  type: {
    id: 'components.SpeakerTable.type',
    defaultMessage: 'Tipo'
  },
  franchises: {
    id: 'components.SpeakerTable.franchises',
    defaultMessage: 'Franquicias'
  },
  action: {
    id: 'components.SpeakerTable.action',
    defaultMessage: 'Acción'
  },
  edit: {
    id: 'components.SpeakerTable.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'components.SpeakerTable.delete',
    defaultMessage: 'Eliminar'
  },
  deleteTitle: {
    id: 'components.SpeakerTable.deleteTitle',
    defaultMessage: '¿Seguro que deseas eliminar este elemento?'
  },
  deleteBody: {
    id: 'components.SpeakerTable.deleteBody',
    defaultMessage: 'Si borras este elemento no podrás recuperarlo'
  },
  accept: {
    id: 'components.SpeakerTable.accept',
    defaultMessage: 'Aceptar'
  },
  cancel: {
    id: 'components.SpeakerTable.cancel',
    defaultMessage: 'cancelar'
  },
  cedula: {
    id: 'components.SpeakerAddDrawer.cedula',
    defaultMessage: 'Cédula profesional'
  },
  city: {
    id: 'components.SpeakerAddDrawer.city',
    defaultMessage: 'Ciudad de origen'
  },
  contractNumber: {
    id: 'components.SpeakerAddDrawer.contractNumber',
    defaultMessage: 'Número de contrato'
  },
  email: {
    id: 'components.SpeakerAddDrawer.email',
    defaultMessage: 'Correo electrónico'
  },
  fiscalAddress: {
    id: 'components.SpeakerAddDrawer.fiscalAddress',
    defaultMessage: 'Dirección fiscal'
  },
  hcpTier: {
    id: 'components.SpeakerAddDrawer.hcpTier',
    defaultMessage: 'HCP Tier'
  },
  rfc: {
    id: 'components.SpeakerAddDrawer.rfc',
    defaultMessage: 'RFC'
  },
  speciality: {
    id: 'components.SpeakerAddDrawer.speciality',
    defaultMessage: 'Especialidad'
  },
  curriculum: {
    id: 'components.SpeakerAddDrawer.curriculum',
    defaultMessage: 'Curriculum'
  },
  supplierNumber: {
    id: 'components.SpeakerAddDrawer.supplierNumber',
    defaultMessage: 'Número de proveedor'
  }
});
