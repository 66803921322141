import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  boxAreas: {
    display: 'flex',
    justifyContent: 'center'
  },
  areasText: {
    width: '250px'
  }
}));

export { useStyles };
