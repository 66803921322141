import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  hello: {
    id: 'components.ApproverEventRequestDetails.hello',
    defaultMessage: 'Hello {name}!!'
  },
  returnRequest: {
    id: 'components.ApproverEventRequestDetails.returnRequest',
    defaultMessage: 'Devolver solicitud'
  },
  rejectRequest: {
    id: 'components.ApproverEventRequestDetails.rejectRequest',
    defaultMessage: 'Rechazar solicitud'
  },
  btnCancel: {
    id: 'components.ApproverEventRequestDetails.btnCancel',
    defaultMessage: 'Cancelar'
  },
  imgUploadSignError: {
    id: 'components.ApproverEventRequestDetails.imgUploadSignError',
    defaultMessage: 'Error al subir la firma'
  }
});
