import { responseMapper } from 'utils/responseMapper';

const districtTemplate = {
  id: '_id',
  name: 'name',
  franchise: {
    id: 'franchise_id._id',
    name: 'franchise_id.name'
  },
  manager: {
    id: 'manager_id._id',
    identifier: 'manager_id.identifier',
    name: 'manager_id.name',
    imageUrl: 'manager_id.avatar'
  },
  representatives: [
    'employee_id',
    {
      id: '_id',
      identifier: 'identifier',
      name: 'name',
      imageUrl: 'avatar'
    }
  ]
};

export const mapDistrict = (district) => {
  const mappedDistrict = responseMapper({
    data: district,
    template: districtTemplate
  });

  return mappedDistrict;
};
