import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  listItem: {
    boxShadow: theme.customShadows.z3,
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    '&.touchable': {
      cursor: 'pointer',
      transition: theme.transitions.create(['background-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: hexToRgba(theme.palette.common.black, 0.065),
        boxShadow: theme.customShadows.z1
      }
    }
  },
  listItemText: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  primaryListItemText: {
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  secondaryListItemText: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      minWidth: 325,
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        minWidth: 210
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 125
      }
    }
  },
  moreIcon: {
    fontSize: 28
  }
}));

export { useStyles };
