import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    letterSpacing: 4,
    fontWeight: 'bold',
    lineHeight: 1,
    display: 'inline-block',
    transform: 'translateY(2px)'
  },
  addSpeakerButton: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  cardsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginLeft: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
    flexWrap: 'wrap'
  },
  disabledText: {
    color: theme.palette.text.disabled
  },
  disabledCards: {
    pointerEvents: 'none',
    opacity: 0.4,
    transition: theme.transitions.create('opacity')
  }
}));

export { useStyles };
