import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  addByOneKeyIdLabel: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.addByOneKeyIdLabel',
    defaultMessage: 'Agregar por OneKey ID'
  },
  addByNameLabel: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.addByNameLabel',
    defaultMessage: 'Agregar por nombre'
  },
  oneKeyIdLabel: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.oneKeyIdLabel',
    defaultMessage: 'OneKey ID'
  },
  oneKeyIdPlaceholder: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.oneKeyIdPlaceholder',
    defaultMessage: 'Ingrese la OneKey ID'
  },
  nameLabel: {
    id: 'components.EventRequestForm.sections.HcpsSection.AddHcpForm.nameLabel',
    defaultMessage: 'Nombre (s)'
  },
  namePlaceholder: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.namePlaceholder',
    defaultMessage: 'Ingrese el/los nombres del HCP'
  },
  paternalSurnameLabel: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.paternalSurnameLabel',
    defaultMessage: 'Apellido paterno'
  },
  paternalSurnamePlaceholder: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.paternalSurnamePlaceholder',
    defaultMessage: 'Ingrese el apellido paterno'
  },
  maternalSurnameLabel: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.maternalSurnameLabel',
    defaultMessage: 'Apellido materno'
  },
  maternalSurnamePlaceholder: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.maternalSurnamePlaceholder',
    defaultMessage: 'Ingrese el apellido materno'
  },
  accept: {
    id: 'components.DistrictForm.accept',
    defaultMessage: 'Aceptar'
  },
  emailLabel: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.emailLabel',
    defaultMessage: 'Email'
  },
  emailPlaceholder: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.emailPlaceholder',
    defaultMessage: 'email@example.com'
  }
});
