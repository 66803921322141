import { DistrictContext } from './DistrictContext';
import { useApi } from 'hooks';
import { useState } from 'react';
import { mapDistrict } from './helpers';

export const DistrictProvider = ({ children }) => {
  const [district, setDistrict] = useState();
  const [manager, setManager] = useState();
  const [representatives, setRepresentatives] = useState();

  const [getDistrict] = useApi({ endpoint: '/district/get', method: 'get' });
  const [addRepresentative] = useApi({
    endpoint: '/district/add-employee',
    method: 'put'
  });
  const [removeRepresentative] = useApi({
    endpoint: '/district/remove-employee',
    method: 'put'
  });

  const handleGetDistrict = async (districtId) => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getDistrict({ urlParams: districtId });

      if (status === 200) {
        const { manager, representatives, ...district } = mapDistrict(payload);

        setDistrict(district);
        setManager(manager);
        setRepresentatives(representatives);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRepresentative = async (districtId, values) => {
    try {
      const body = { employee_id: values.employeeId };

      const {
        headerResponse: { status }
      } = await addRepresentative({ body, urlParams: districtId });

      if (status === 200) {
        handleGetDistrict(districtId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveRepresentative = async (districtId, employeeId) => {
    try {
      const body = { employee_id: employeeId };

      const {
        headerResponse: { status }
      } = await removeRepresentative({ body, urlParams: districtId });

      if (status === 200) {
        handleGetDistrict(districtId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contextValue = {
    district,
    manager,
    representatives,
    handleGetDistrict,
    handleAddRepresentative,
    handleRemoveRepresentative
  };

  return (
    <DistrictContext.Provider value={contextValue}>
      {children}
    </DistrictContext.Provider>
  );
};
