import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    border: '1px solid #c9c9c9',
    position: 'relative',
    // border: '1px solid #d5d5d5',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #ababab',
      boxShadow: theme.shadows[24],
      '& .delete-button': {
        display: 'inherit'
      }
    }
  },
  fileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
    // fontWeight: 500
  },
  img: ({ backgroundImage }) => ({
    display: 'block',
    width: '100%',
    height: '200px',
    backgroundImage: `url('${backgroundImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }),
  previewIcon: {
    fontSize: 64
  },
  deleteButton: {
    display: 'none',
    position: 'absolute',
    right: '-24px',
    top: '-24px'
  },
  deleteIcon: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    '& .icon': {
      color: '#e62c3b'
    }
  },
  uploadStatusIcon: {
    position: 'absolute',
    right: '2px',
    bottom: '2px',
    height: '20px',
    padding: '0'
    // '& .icon': {
    //   color: theme.palette.success.main
    // }
  },
  success: {
    color: theme.palette.success.main
  }
}));

export { useStyles };
