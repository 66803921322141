import { useIntl } from 'react-intl';

import { Button, Typography, Box } from '@material-ui/core';

import { messages } from '../SpeakerTableMessages';

import { useModal } from '@octopy/react-modal';
import { useDrawer } from 'components/Drawer';

import { SpeakerAddDrawer } from '../../SpeakerAddDrawer';

import { useSpeakers } from 'components/Speakers/hooks/useSpeakers';

export const useTable = () => {
  const intl = useIntl();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleOpenDrawer } = useDrawer();
  const { handleUpdateSpeakers, handleDeleteSpeakers } = useSpeakers();

  const handleDeleteSpeaker = (deleted) => {
    handleOpenModal({
      title: intl.formatMessage(messages.deleteTitle),
      body: (
        <Box>
          <Typography>{intl.formatMessage(messages.deleteBody)}</Typography>
        </Box>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleDeleteSpeakers(deleted.id);
              handleCloseModal();
            }}
          >
            {intl.formatMessage(messages.accept)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleOpenEditSpeaker = (speaker) => {
    const initialValues = {
      addBy: 'external',
      curriculum: {
        url: speaker.curriculum,
        file: {
          name: 'Curriculum',
          type: 'application/pdf'
        }
      },
      employee: '',
      speaker: speaker.name,
      city: speaker.cityId,
      ...speaker
    };

    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.title),
      body: (
        <SpeakerAddDrawer
          initialValues={initialValues}
          onSubmit={(values) => handleUpdateSpeakers(values, speaker.id)}
          edit={true}
        />
      )
    });
  };

  const tableHead = [
    {
      key: 'name',
      label: intl.formatMessage(messages.name),
      align: 'center'
    },
    {
      key: 'type',
      label: intl.formatMessage(messages.type),
      align: 'center'
    },
    {
      key: 'franchises',
      label: intl.formatMessage(messages.franchises),
      align: 'center'
    },
    {
      key: 'cedula',
      label: intl.formatMessage(messages.cedula),
      align: 'center'
    },
    {
      key: 'cityName',
      label: intl.formatMessage(messages.city),
      align: 'center'
    },
    {
      key: 'contractNumber',
      label: intl.formatMessage(messages.contractNumber),
      align: 'center'
    },
    {
      key: 'email',
      label: intl.formatMessage(messages.email),
      align: 'center'
    },
    {
      key: 'fiscalAddress',
      label: intl.formatMessage(messages.fiscalAddress),
      align: 'center'
    },
    {
      key: 'hcpTier',
      label: intl.formatMessage(messages.hcpTier),
      align: 'center'
    },
    {
      key: 'rfc',
      label: intl.formatMessage(messages.rfc),
      align: 'center'
    },
    {
      key: 'speciality',
      label: intl.formatMessage(messages.speciality),
      align: 'center'
    },
    {
      key: 'supplierNumber',
      label: intl.formatMessage(messages.supplierNumber),
      align: 'center'
    },
    {
      key: 'curriculum',
      label: intl.formatMessage(messages.curriculum),
      align: 'center'
    },
    {
      key: 'action',
      label: intl.formatMessage(messages.action),
      align: 'end'
    }
  ];

  const actions = (item) => [
    {
      label: intl.formatMessage(messages.edit),
      onClick: () => handleOpenEditSpeaker(item),
      disabled: item?.type !== 'externo'
    },
    {
      label: intl.formatMessage(messages.delete),
      onClick: () => handleDeleteSpeaker(item),
      disabled: item?.franchises !== ''
    }
  ];

  return { tableHead, actions };
};
