import { responseMapper } from 'utils/responseMapper';

export const mapFranchiseCatalogue = (franchises) => {
  const mappedFranchises = franchises.map((franchise) => ({
    value: franchise._id,
    label: franchise.name
  }));

  return mappedFranchises;
};

export const mapDistrictCatalogue = (franchises) => {
  const mappedDistricts = franchises.reduce(
    (districts, franchise) => [
      ...districts,
      ...franchise.districts.map((district) => ({
        value: district._id,
        label: district.name,
        franchiseId: franchise._id
      }))
    ],
    []
  );

  return mappedDistricts;
};

export const mapEmployeeCatalogue = (employees) => {
  const mappedEmployees = employees.map((employee) => ({
    value: employee._id,
    label: employee.identifier
      ? `${employee.identifier} - ${employee.name}`
      : employee.name
  }));

  return mappedEmployees;
};

export const mapFranchisesEmployees = (franchisesEmployees) => {
  const mappedFranchisesEmployes = franchisesEmployees.map(
    (franchiseEmployee) => ({
      value: franchiseEmployee._id,
      label: franchiseEmployee.name,
      approvers: franchiseEmployee.employees.map((approver) => ({
        value: approver._id,
        label: approver.name
      }))
    })
  );

  return mappedFranchisesEmployes;
};

export const mapCitiesCatalogue = (catalogue) => {
  const mappedCatalogue = catalogue.map((city) => ({
    label: city.nombre,
    value: city._id
  }));

  return mappedCatalogue;
};

export const mapEmployeesL = (employees) => {
  const mappedEmployee = employees.map((employee) => ({
    value: employee._id,
    label: employee.name
  }));

  return mappedEmployee;
};

export const mapSchedulesCatalogue = (catalogue) => {
  const mappedCatalogue = catalogue.map((schedule) => ({
    name: schedule.name,
    id: schedule._id
  }));

  return mappedCatalogue;
};

const catalogueRequestTemplate = {
  logisticType: [
    'logistic_type',
    {
      value: '_id',
      label: 'name'
    }
  ],
  placeType: [
    'place_type',
    {
      value: '_id',
      label: 'name'
    }
  ],
  speakerReason: [
    'speaker_reason',
    {
      value: '_id',
      label: 'name'
    }
  ],
  talkType: [
    'talk_type',
    {
      value: '_id',
      label: 'name'
    }
  ],
  tip: [
    'tip',
    {
      value: '_id',
      label: 'name'
    }
  ]
};

export const mapCatalogueRequest = (catalogueRequest) => {
  const mappedFranchise = responseMapper({
    data: catalogueRequest,
    template: catalogueRequestTemplate
  });

  return mappedFranchise;
};
