import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CataloguesContext } from './CataloguesContext';
import { useApi } from 'hooks/useApi';
import {
  mapEmployeeCatalogue,
  mapFranchiseCatalogue,
  mapFranchisesEmployees,
  mapCitiesCatalogue,
  mapEmployeesL,
  mapSchedulesCatalogue,
  mapCatalogueRequest,
  mapDistrictCatalogue
} from './helpers';

const CataloguesProvider = ({ children }) => {
  const [allEmployees, setAllEmployees] = useState([]);
  const [emptyEmployees, setEmptyEmployees] = useState([]);
  const [employeesMedical, setEmployessMedical] = useState([]);
  const [franchises, setFranchises] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [employeesFranchises, setEmployeesFranchises] = useState([]);
  const [employeeL, setEmployeeL] = useState([]);
  const [schedules, setSchedules] = useState([]);

  const [logisticType, setLogisticType] = useState([]);
  const [placeType, setPlaceType] = useState([]);
  const [speakerReason, setSpeakerReason] = useState([]);
  const [talkType, setTalkType] = useState([]);
  const [tip, setTip] = useState([]);

  const [getAllEmployeeCatalogue] = useApi({
    endpoint: '/employee/list',
    method: 'get'
  });
  const [getEmptyEmployeeCatalogue] = useApi({
    endpoint: '/catalogue/empty-employee',
    method: 'get'
  });
  const [getFranchiseCatalogue] = useApi({
    endpoint: '/catalogue/franchise',
    method: 'get'
  });
  const [getDistrictCatalogue] = useApi({
    endpoint: '/catalogue/district',
    method: 'get'
  });
  const [getEmployesMedicalCatalogue] = useApi({
    endpoint: '/catalogue/medical-employee',
    method: 'get'
  });
  const [getFranchiseEmployee] = useApi({
    endpoint: '/catalogue/franchise-employee',
    method: 'get'
  });
  const [getCitiesCatalogue] = useApi({
    endpoint: '/catalogue/cities',
    method: 'get'
  });
  const [getSchedulesCatalogue] = useApi({
    endpoint: '/catalogue/agenda',
    method: 'get'
  });
  const [getEmployeList] = useApi({
    endpoint: '/catalogue/employee',
    method: 'get'
  });
  const [getCatalogueRequest] = useApi({
    endpoint: '/catalogue/request',
    method: 'get'
  });

  const handleGetAllEmployees = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getAllEmployeeCatalogue();

      if (status === 200) {
        const allEmployeeCatalogue = mapEmployeeCatalogue(payload);

        setAllEmployees(allEmployeeCatalogue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFranchises = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getFranchiseCatalogue();

      if (status === 200) {
        const franchises = mapFranchiseCatalogue(payload);

        setFranchises(franchises);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDistricts = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getDistrictCatalogue();

      if (status === 200) {
        const districts = mapDistrictCatalogue(payload);

        setDistricts(districts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEmptyEmployees = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getEmptyEmployeeCatalogue();

      if (status === 200) {
        const employeeCatalogue = mapEmployeeCatalogue(payload);

        setEmptyEmployees(employeeCatalogue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEmployeesMedical = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getEmployesMedicalCatalogue();

      if (status === 200) {
        const employeeMedicalCatalogue = mapEmployeeCatalogue(payload);

        setEmployessMedical(employeeMedicalCatalogue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFranchiseEmployee = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getFranchiseEmployee();

      if (status === 200) {
        const employesFranchises = mapFranchisesEmployees(payload);

        setEmployeesFranchises(employesFranchises);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCitiesCatalogue = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getCitiesCatalogue();

      if (status === 200) {
        return mapCitiesCatalogue(payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEmployeesList = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getEmployeList();

      if (status === 200) {
        const employeeList = mapEmployeesL(payload);

        setEmployeeL(employeeList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetSchedules = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getSchedulesCatalogue();

      if (status === 200) {
        const schedules = await mapSchedulesCatalogue(payload);

        setSchedules(schedules);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCatalogueRequest = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getCatalogueRequest();

      if (status === 200) {
        const {
          logisticType,
          placeType,
          speakerReason,
          talkType,
          tip
        } = mapCatalogueRequest(payload);

        setLogisticType(logisticType);
        setPlaceType(placeType);
        setSpeakerReason(speakerReason);
        setTalkType(talkType);
        setTip(tip);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      emptyEmployees,
      schedules,
      allEmployees,
      employeesMedical,
      franchises,
      districts,
      employeesFranchises,
      employeeL,
      logisticType,
      placeType,
      speakerReason,
      talkType,
      tip,
      handleGetDistricts,
      handleGetFranchises,
      handleGetEmptyEmployees,
      handleGetAllEmployees,
      handleGetEmployeesMedical,
      handleGetFranchiseEmployee,
      handleGetCitiesCatalogue,
      handleGetEmployeesList,
      handleGetSchedules,
      handleGetCatalogueRequest
    }),
    [
      emptyEmployees,
      schedules,
      allEmployees,
      employeesMedical,
      franchises,
      districts,
      employeesFranchises,
      employeeL,
      logisticType,
      placeType,
      speakerReason,
      talkType,
      tip
    ]
  );

  return (
    <CataloguesContext.Provider value={contextValue}>
      {children}
    </CataloguesContext.Provider>
  );
};

CataloguesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { CataloguesProvider };
