import { useApi } from 'hooks';
import { useState } from 'react';
import { FormatRepositoryContext } from './FormatRepositoryContext';
import { mapFormats } from './helpers';

export const FormatRepositoryProvider = ({ children }) => {
  const [formats, setFormats] = useState();
  const [filteredFormats, setFilteredFormats] = useState();

  const [getFormats] = useApi({ endpoint: '/format/list', method: 'get' });
  const [addFormat] = useApi({
    endpoint: '/format/create',
    method: 'post'
  });
  const [removeFormat] = useApi({
    endpoint: '/format/delete',
    method: 'delete'
  });

  const handleFilterFormats = async (search) => {
    if (formats.length > 0)
      setFilteredFormats(
        formats.filter((format) => format.name.includes(search))
      );
  };

  const handleGetFormats = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getFormats();

      if (status === 200) {
        setFormats(mapFormats(payload));
        setFilteredFormats(mapFormats(payload));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddFormat = async (values) => {
    try {
      const body = {
        name: values.name,
        file_url: values.document.url
      };

      const {
        headerResponse: { status }
      } = await addFormat({ body });

      if (status === 200) {
        handleGetFormats();

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveFormat = async (formatId) => {
    try {
      const {
        headerResponse: { status }
      } = await removeFormat({ urlParams: formatId });

      if (status === 200) {
        handleGetFormats();

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contextValue = {
    formats,
    filteredFormats,
    handleGetFormats,
    handleAddFormat,
    handleRemoveFormat,
    handleFilterFormats
  };

  return (
    <FormatRepositoryContext.Provider value={contextValue}>
      {children}
    </FormatRepositoryContext.Provider>
  );
};
