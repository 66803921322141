import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Container,
  List,
  Typography,
  TextField
} from '@material-ui/core';

import { Search as SearchIcon } from '@material-ui/icons';

import { messages } from './FormatRepositoryMessages';
import { useStyles } from './FormatRepositoryStyles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FormatListItem } from './FormatListItem';
import { useDrawer } from 'components/Drawer';
import { AddFormatForm } from './AddFormatForm';
import { useImageViewer } from 'components/ImageViewer/hooks/useImageViewer';
import { ImageViewer } from 'components/ImageViewer';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';
import { useFormatRepository } from './hooks/useFormatRepository';
import { NoData } from 'components/NoData';

const FormatRepository = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const imageViewer = useImageViewer();
  const {
    user: { role }
  } = useAuth();

  const {
    formats,
    filteredFormats,
    handleGetFormats,
    handleFilterFormats
  } = useFormatRepository();

  useEffect(() => {
    handleGetFormats();
  }, []);

  const handleOpenAddFormatForm = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.addFormatFormTitle),
      caption: intl.formatMessage(messages.addFormatFormCaption),
      body: <AddFormatForm />
    });
  };

  return (
    <Container className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography color="primary" variant="h5" className={classes.title}>
            {intl.formatMessage(messages.formatRepository)}
          </Typography>
        </div>
        {role === roles.admin && (
          <Button
            endIcon={<CloudUploadIcon />}
            color="primary"
            className={classes.uploadFormatButton}
            onClick={handleOpenAddFormatForm}
          >
            {intl.formatMessage(messages.uploadFormat)}
          </Button>
        )}
      </div>

      {formats?.length > 1 && (
        <TextField
          id="formats-search-box"
          className={classes.inputSearch}
          placeholder={intl.formatMessage(messages.searchTextLabel)}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <div style={{ marginRight: 12, marginTop: 4 }}>
                <SearchIcon color="disabled" />
              </div>
            )
          }}
          onChange={(e) => handleFilterFormats(e.target.value)}
        />
      )}

      <List>
        {filteredFormats ? (
          filteredFormats.length ? (
            filteredFormats.map((format) => (
              <FormatListItem
                key={format.id}
                format={format}
                imageViewer={imageViewer}
              />
            ))
          ) : (
            <NoData pt={5} />
          )
        ) : (
          <NoData pt={5} loading />
        )}
      </List>

      <ImageViewer {...imageViewer} />
    </Container>
  );
};

export { FormatRepository };
