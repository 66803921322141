import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../../../../EventRequestFormMessages';

export const useValidationSchema = () => {
  const intl = useIntl();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const invalidEmailErrorMessage = intl.formatMessage(
    messages.invalidEmailError
  );

  const validationSchema = yup.object({
    addBy: yup.string().oneOf(['one-key-id', 'name']).required(),
    oneKeyId: yup.string().when('addBy', {
      is: 'one-key-id',
      then: yup.string().required(requiredErrorMessage),
      otherwise: yup.string()
    }),
    name: yup.string().when('addBy', {
      is: 'name',
      then: yup.string().required(requiredErrorMessage),
      otherwise: yup.string()
    }),
    paternalSurname: yup.string().when('addBy', {
      is: 'name',
      then: yup.string(),
      otherwise: yup.string()
    }),
    maternalSurname: yup.string().when('addBy', {
      is: 'name',
      then: yup.string(),
      otherwise: yup.string()
    }),
    email: yup.string().when('addBy', {
      is: 'email',
      then: yup
        .string()
        .email(invalidEmailErrorMessage)
        .required(requiredErrorMessage),
      otherwise: yup.string()
    })
  });

  return validationSchema;
};
