import React from 'react';
import PropTypes from 'prop-types';

import { useIntl, FormattedMessage } from 'react-intl';

import { Typography, Button } from '@material-ui/core';

import { messages } from './AddFranchisesDrawerMessages';
import { useStyles } from './AddFranchisesDrawerStyles';

import { useForm } from './hooks/useForm';

import { renderAutocomplete, renderTextField } from '@octopy/react-form';

const AddFranchisesDrawer = ({ initialValues, onSubmit, edit, director }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { fields, formik } = useForm({ initialValues, onSubmit, director });

  return (
    <div>
      {renderTextField({
        formik,
        field: fields.franchisesName,
        inputProps: {}
      })}

      <Typography className={classes.approverSubtitle} variant="subtitle2">
        <FormattedMessage {...messages.addDirector} />
      </Typography>

      {renderAutocomplete({
        formik,
        field: fields.director,
        inputProps: {}
      })}

      <Typography className={classes.approverSubtitle} variant="subtitle2">
        <FormattedMessage {...messages.addMedical} />
      </Typography>

      {renderAutocomplete({
        formik,
        field: fields.medical,
        inputProps: {}
      })}

      <Button
        onClick={formik.handleSubmit}
        className={classes.submitButton}
        color="primary"
      >
        {edit
          ? intl.formatMessage(messages.save)
          : intl.formatMessage(messages.create)}
      </Button>
    </div>
  );
};

AddFranchisesDrawer.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  edit: PropTypes.bool
};

export { AddFranchisesDrawer };
