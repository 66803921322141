import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  franchise: {
    id: 'components.EventRequestCard.franchise',
    defaultMessage: 'Franquicia'
  },
  onAprobation: {
    id: 'components.EventRequestCard.onAprobation',
    defaultMessage: 'En aprobación'
  },
  director: {
    id: 'components.EventRequestCard.director',
    defaultMessage: 'Director'
  },
  medical: {
    id: 'components.EventRequestCard.medical',
    defaultMessage: 'Medical'
  },
  district: {
    id: 'components.EventRequestCard.district',
    defaultMessage: 'Distrito'
  },
  representative: {
    id: 'components.EventRequestCard.representative',
    defaultMessage: 'Representante'
  },
  manager: {
    id: 'components.EventRequestCard.manager',
    defaultMessage: 'Gerente'
  },
  approved: {
    id: 'components.EventRequestCard.approved',
    defaultMessage: 'Aprobada'
  },
  review: {
    id: 'components.EventRequestCard.review',
    defaultMessage: 'En revisión'
  },
  back: {
    id: 'components.EventRequestCard.back',
    defaultMessage: 'Devuelta'
  },
  close: {
    id: 'components.EventRequestCard.close',
    defaultMessage: 'Cerrada'
  },
  rejected: {
    id: 'components.EventRequestCard.rejected',
    defaultMessage: 'Rechazada'
  },
  entered: {
    id: 'components.EventRequestCard.entered',
    defaultMessage: 'Ingresada'
  },
  created: {
    id: 'components.EventRequestCard.created',
    defaultMessage: 'Creada'
  },
  archived: {
    id: 'components.EventRequestCard.archived',
    defaultMessage: 'Archivada'
  },
  closed: {
    id: 'components.EventRequestCard.closed',
    defaultMessage: 'Cerrada'
  },
  eventDate: {
    id: 'components.EventRequestCard.eventDate',
    defaultMessage: 'Fecha del evento'
  },
  movements: {
    id: 'components.EventRequestCard.movements',
    defaultMessage: 'Movimientos'
  },
  closeRequest: {
    id: 'components.EventRequestCard.closeRequest',
    defaultMessage: 'Cerrar solicitud'
  },
  movementsDrawer: {
    id: 'components.EventRequestCard.EventMovements.movementsDrawer',
    defaultMessage: 'Movimientos'
  },
  approvedFooter: {
    id: 'components.EventRequestCard.approvedFooter',
    defaultMessage: 'Se aprobó el'
  },
  closeFooter: {
    id: 'components.EventRequestCard.closeFooter',
    defaultMessage: 'Cerrada desde'
  },
  reviewFooter: {
    id: 'components.EventRequestCard.reviewFooter',
    defaultMessage: 'En revisión desde el'
  },
  onAprobationFooter: {
    id: 'components.EventRequestCard.onAprobationFooter',
    defaultMessage: 'En aprobación desde el'
  },
  backFooter: {
    id: 'components.EventRequestCard.backFooter',
    defaultMessage: 'Se devolvió'
  },
  rejectedFooter: {
    id: 'components.EventRequestCard.rejectedFooter',
    defaultMessage: 'Se rechazó'
  },
  enteredFooter: {
    id: 'components.EventRequestCard.enteredFooter',
    defaultMessage: 'Se inicio revisión el'
  },
  createdFooter: {
    id: 'components.EventRequestCard.createdFooter',
    defaultMessage: 'Se creó el'
  },
  archivedFooter: {
    id: 'components.EventRequestCard.archivedFooter',
    defaultMessage: 'Archivada desde'
  },
  closedFooter: {
    id: 'components.EventRequestCard.closedFooter',
    defaultMessage: 'Cerrada desde'
  },
  edit: {
    id: 'components.EventRequestCard.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'components.EventRequestCard.delete',
    defaultMessage: 'Eliminar'
  },
  cancel: {
    id: 'components.EventRequestCard.cancel',
    defaultMessage: 'Cancelar'
  },
  deleteModalTitle: {
    id: 'components.EventRequestCard.deleteModalTitle',
    defaultMessage: 'Eliminar evento'
  },
  deleteModalBody: {
    id: 'components.EventRequestCard.deleteModalBody',
    defaultMessage: '¿Estas seguro de querer eliminar este evento?'
  }
});
