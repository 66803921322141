import React from 'react';
import { Box, Card as MuiCard } from '@material-ui/core';
import { useStyles } from './CardStyles';

export const Card = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Box {...props}>
      <MuiCard className={classes.container}>{children}</MuiCard>
    </Box>
  );
};
