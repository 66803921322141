import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: ({ backgroundImage }) => ({
    minHeight: '100vh',
    backgroundImage: `url('${backgroundImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '& > .nav-container': {
      minHeight: 'calc(100vh - 40px)'
    }
  })
}));
