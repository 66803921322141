import { messages } from './EventMovementsMessages';

export const messageStatusTimeline = {
  aprobada: messages.approved,
  'en revisión': messages.review,
  'en aprobación': messages.onAprobation,
  devuelta: messages.back,
  rechazada: messages.rejected,
  ingresada: messages.entered,
  creada: messages.created,
  cerrada: messages.closed,
  archivada: messages.archived,
  undefined: messages.undefined
};
