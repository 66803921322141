import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { messages } from '../ConfigurationFormMessages';

export const useFields = (formik) => {
  const intl = useIntl();

  const commonBreakpoints = {
    xs: 12,
    sm: 10,
    md: 7,
    lg: 5
  };

  const fields = [
    {
      type: 'number',
      name: 'anticipationDays',
      value: formik.values.anticipationDays,
      error:
        formik.touched.anticipationDays && !!formik.errors.anticipationDays,
      helperText:
        formik.touched.anticipationDays && formik.errors.anticipationDays,
      onChange: formik.handleChange,
      label: intl.formatMessage(messages.anticipationDaysLabel),
      placeholder: intl.formatMessage(messages.anticipationDaysPlaceholder),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            {intl.formatMessage(messages.days)}
          </InputAdornment>
        )
      }
    },
    {
      type: 'number',
      name: 'facilityRentalCost',
      value: formik.values.facilityRentalCost,
      error:
        formik.touched.facilityRentalCost && !!formik.errors.facilityRentalCost,
      helperText:
        formik.touched.facilityRentalCost && formik.errors.facilityRentalCost,
      onChange: formik.handleChange,
      label: intl.formatMessage(messages.facilityRentalCostLabel),
      placeholder: intl.formatMessage(messages.facilityRentalCostPlaceholder),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: <InputAdornment position="start">$</InputAdornment>
      }
    },
    {
      type: 'number',
      name: 'minimumNumberOfGuestsForNovoEventAndNovoSupport',
      value: formik.values.minimumNumberOfGuestsForNovoEventAndNovoSupport,
      error:
        formik.touched.minimumNumberOfGuestsForNovoEventAndNovoSupport &&
        !!formik.errors.minimumNumberOfGuestsForNovoEventAndNovoSupport,
      helperText:
        formik.touched.minimumNumberOfGuestsForNovoEventAndNovoSupport &&
        formik.errors.minimumNumberOfGuestsForNovoEventAndNovoSupport,
      onChange: formik.handleChange,
      label: intl.formatMessage(
        messages.minimumNumberOfGuestsForNovoEventAndNovoSupportLabel
      ),
      placeholder: intl.formatMessage(
        messages.minimumNumberOfGuestsForNovoEventAndNovoSupportPlaceholder
      ),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            {intl.formatMessage(messages.guests)}
          </InputAdornment>
        )
      }
    },
    {
      type: 'number',
      name: 'materialsCost',
      value: formik.values.materialsCost,
      error: formik.touched.materialsCost && !!formik.errors.materialsCost,
      helperText: formik.touched.materialsCost && formik.errors.materialsCost,
      onChange: formik.handleChange,
      label: intl.formatMessage(messages.materialsCostLabel),
      placeholder: intl.formatMessage(messages.materialsCostPlaceholder),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: <InputAdornment position="start">$</InputAdornment>
      }
    },
    {
      type: 'number',
      name: 'maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities',
      value:
        formik.values.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities,
      error:
        formik.touched
          .maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities &&
        !!formik.errors.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities,
      helperText:
        formik.touched
          .maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities &&
        formik.errors.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities,
      onChange: formik.handleChange,
      label: intl.formatMessage(
        messages.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivitiesLabel
      ),
      placeholder: intl.formatMessage(
        messages.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivitiesPlaceholder
      ),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">Invitados</InputAdornment>
        )
      }
    },
    {
      type: 'number',
      name: 'maximumFoodCostPerPerson',
      value: formik.values.maximumFoodCostPerPerson,
      error:
        formik.touched.maximumFoodCostPerPerson &&
        !!formik.errors.maximumFoodCostPerPerson,
      helperText:
        formik.touched.maximumFoodCostPerPerson &&
        formik.errors.maximumFoodCostPerPerson,
      onChange: formik.handleChange,
      label: intl.formatMessage(messages.maximumFoodCostPerPersonLabel),
      placeholder: intl.formatMessage(
        messages.maximumFoodCostPerPersonPlaceholder
      ),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: <InputAdornment position="start">$</InputAdornment>
      }
    },
    // {
    //   type: 'number',
    //   name: 'tipPercent',
    //   value: formik.values.tipPercent,
    //   error: formik.touched.tipPercent && !!formik.errors.tipPercent,
    //   helperText: formik.touched.tipPercent && formik.errors.tipPercent,
    //   onChange: formik.handleChange,
    //   label: intl.formatMessage(messages.tipPercentLabel),
    //   placeholder: intl.formatMessage(messages.tipPercentPlaceholder),
    //   breakpoints: commonBreakpoints,
    //   InputProps: {
    //     startAdornment: <InputAdornment position="start">%</InputAdornment>
    //   }
    // },
    {
      type: 'number',
      name: 'firstCloseNotification',
      value: formik.values.firstCloseNotification,
      error:
        formik.touched.firstCloseNotification &&
        !!formik.errors.firstCloseNotification,
      helperText:
        formik.touched.firstCloseNotification &&
        formik.errors.firstCloseNotification,
      onChange: formik.handleChange,
      label: intl.formatMessage(messages.firstCloseNotification),
      placeholder: intl.formatMessage(
        messages.firstCloseNotificationPlaceholder
      ),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            {intl.formatMessage(messages.days)}
          </InputAdornment>
        )
      }
    },
    {
      type: 'number',
      name: 'secondCloseNotification',
      value: formik.values.secondCloseNotification,
      error:
        formik.touched.secondCloseNotification &&
        !!formik.errors.secondCloseNotification,
      helperText:
        formik.touched.secondCloseNotification &&
        formik.errors.secondCloseNotification,
      onChange: formik.handleChange,
      label: intl.formatMessage(messages.secondCloseNotification),
      placeholder: intl.formatMessage(
        messages.secondCloseNotificationPlaceholder
      ),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            {intl.formatMessage(messages.days)}
          </InputAdornment>
        )
      }
    },
    {
      type: 'number',
      name: 'thirdCloseNotification',
      value: formik.values.thirdCloseNotification,
      error:
        formik.touched.thirdCloseNotification &&
        !!formik.errors.thirdCloseNotification,
      helperText:
        formik.touched.thirdCloseNotification &&
        formik.errors.thirdCloseNotification,
      onChange: formik.handleChange,
      label: intl.formatMessage(messages.thirdCloseNotification),
      placeholder: intl.formatMessage(
        messages.thirdCloseNotificationplaceholder
      ),
      breakpoints: commonBreakpoints,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            {intl.formatMessage(messages.days)}
          </InputAdornment>
        )
      }
    }
  ];

  return fields;
};
