import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  formatRepository: {
    id: 'components.FormatRepository.formatRepository',
    defaultMessage: 'Repositorio de formatos'
  },
  uploadFormat: {
    id: 'components.FormatRepository.uploadFormat',
    defaultMessage: 'Cargar formato'
  },
  addFormatFormTitle: {
    id: 'components.Franchise.addFormatFormTitle',
    defaultMessage: 'Nuevo formato'
  },
  addFormatFormCaption: {
    id: 'components.Franchise.addFormatFormCaption',
    defaultMessage:
      'Completa los siguientes campos para cargar un nuevo formato'
  },
  searchTextLabel: {
    id: 'components.FormatRepository.searchTextLabel',
    defaultMessage: 'Buscar'
  },
  searchTextPlaceholder: {
    id: 'components.FormatRepository.searchTextPlaceholder',
    defaultMessage: 'Ingresa tu búsqueda'
  }
});
