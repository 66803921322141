import React from 'react';
import { useIntl } from 'react-intl';
import {
  Avatar,
  Button,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useStyles } from './FormatListItemStyles';
import { useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import MoreIcon from '@material-ui/icons/MoreVert';
import DownloadIcon from '@material-ui/icons/GetAppRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import { messages } from './FormatListItemMessages';
import FileIcon from '@material-ui/icons/InsertDriveFileRounded';
import PdfIcon from '@material-ui/icons/PictureAsPdfRounded';
import { downloadFile } from 'utils/files';
import { formatDate } from 'utils/dates';
import { usePdfViewer } from 'components/PdfViewer/hooks/usePdfViewer';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';
import { useFormatRepository } from '../hooks/useFormatRepository';

const imageTypes = ['png', 'jpg', 'jepg', 'svg'];

export const FormatListItem = ({ format, imageViewer }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleShowLoader } = useLoader();
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const pdfViewer = usePdfViewer();
  const {
    user: { role }
  } = useAuth();
  const { handleRemoveFormat } = useFormatRepository();

  const handleOpenMenu = (event) => setMenuAnchor(event.currentTarget);
  const handleCloseMenu = () => setMenuAnchor(null);

  const handleOpenDeleteModal = ({ name }) => {
    handleOpenModal({
      title: intl.formatMessage(messages.deleteModalTitle),
      body: (
        <>
          {intl.formatMessage(messages.deleteModalBody1)}{' '}
          <span className={classes.deleteModalImportantText}>{name}</span>{' '}
          {intl.formatMessage(messages.deleteModalBody2)}
        </>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleRemoveFormat(format.id);
            }}
          >
            {intl.formatMessage(messages.delete)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });

    handleCloseMenu();
  };

  const handleDownload = async () => {
    handleCloseMenu();

    handleShowLoader(true);

    await downloadFile(format);

    handleShowLoader(false);
  };

  return (
    <ListItem
      classes={{
        root: `${classes.listItem} ${
          ['pdf', ...imageTypes].includes(format.type) && 'touchable'
        }`
      }}
      onClick={
        imageTypes.includes(format.type)
          ? () => imageViewer.handleOpen(format)
          : format.type === 'pdf'
          ? () => pdfViewer.handleOpen(format)
          : undefined
      }
    >
      <ListItemAvatar>
        <Avatar
          src={imageTypes.includes(format.type) ? format.url : null}
          variant="circle"
        >
          {imageTypes.includes(format.type) ? null : format.type === 'pdf' ? (
            <PdfIcon />
          ) : (
            <FileIcon />
          )}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        classes={{
          root: classes.listItemText,
          primary: classes.primaryListItemText,
          secondary: classes.secondaryListItemText
        }}
        primary={format.name}
        secondary={formatDate(format.date, intl, { complete: true })}
      />

      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={handleOpenMenu}>
          <MoreIcon className={classes.moreIcon} />
        </IconButton>

        <Menu
          anchorEl={menuAnchor}
          keepMounted
          open={!!menuAnchor}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleDownload}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">
              {intl.formatMessage(messages.download)}
            </Typography>
          </MenuItem>

          {role === roles.admin && (
            <MenuItem onClick={handleOpenDeleteModal}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2">
                {intl.formatMessage(messages.delete)}
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
