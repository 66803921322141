import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    color: 'black'
  },
  history: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText
  },
  MuiTimelineRoot: {
    padding: 0
  },
  MuiTimelineSeparatorRoot: {
    backgroundColor: theme.palette.secondary.main
  }
}));

export { useStyles };
