import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Box } from '@material-ui/core';
import { useStyles } from './NewEmployeeStyles';
import { NewEmployeeActions } from './NewEmployeeActions';
import { EmployeeForm } from '../EmployeeForm';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { messages } from './NewEmployeeMessages';
import { useEmployees } from '../hooks/useContext';

const NewEmployee = ({ item }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { handleCreateEmployee } = useEmployees();
  const formikRef = useRef();

  const handleSubmit = async (values) => await handleCreateEmployee(values);

  return (
    <div className={classes.container}>
      <Container>
        <NewEmployeeActions />
        <EmployeeForm handleSubmit={handleSubmit} formikRef={formikRef} />
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={() => formikRef.current.submitForm()}
          >
            {intl.formatMessage(messages.createButton)}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

NewEmployee.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { NewEmployee };
