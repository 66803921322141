import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      width: '100%'
    }
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize
    }
  },
  searchTextField: {
    width: 282,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginTop: theme.spacing(2)
    }
  },
  button: {
    marginLeft: 'auto'
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -theme.spacing(2),
    marginBottom: theme.spacing(3)
  }
}));

export { useStyles };
