/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from './FilePickerStyles';
import { usePdfViewer } from 'components/PdfViewer';

const FilePicker = ({
  id,
  buttonLabel,
  value,
  onChange,
  error,
  disabled,
  type,
  ...props
}) => {
  const pdfViewer = usePdfViewer();
  const classes = useStyles({ error: !!error, disabled });

  const canViewPdf =
    !disabled &&
    type === 'application/pdf' &&
    !!value?.url &&
    !!value?.file?.type &&
    value?.file?.type === 'application/pdf';

  const handleOnChange = (e) => {
    try {
      if (!!e.target.files.length) {
        onChange({
          file: e.target.files[0],
          url: URL.createObjectURL(e.target.files[0])
        });
      }
    } catch {}
  };

  return (
    <div {...props}>
      <div className={classes.contentContainer}>
        <Button
          disabled={disabled}
          className={classes.button}
          component="label"
          htmlFor={`file-${id}`}
          color="primary"
        >
          {buttonLabel}
        </Button>
        <input
          disabled={disabled}
          onChange={handleOnChange}
          className={classes.fileInput}
          type="file"
          name={`file-${id}`}
          id={`file-${id}`}
          accept={type}
        />

        <Typography
          onClick={
            canViewPdf
              ? () =>
                  pdfViewer.handleOpen({
                    url: value?.url,
                    name: value?.file?.name
                  })
              : undefined
          }
          className={`${classes.name} ${canViewPdf && 'clickable'}`}
          variant="subtitle2"
        >
          {value?.file?.name}
        </Typography>
      </div>

      {!!error && (
        <Typography className={classes.error} variant="caption">
          {error}
        </Typography>
      )}
    </div>
  );
};

export { FilePicker };
