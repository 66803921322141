import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Form, messages as formMessages } from '@octopy/react-form';
import { messages } from './EmailFormMessages';
import { useStyles } from './EmailFormStyles';

const EmailForm = (props) => {
  const { onSubmit, data } = props;
  const intl = useIntl();
  const classes = useStyles();

  const getInitialValues = (initialValues) => ({
    email: '',
    ...initialValues
  });

  const getFields = () => [
    {
      id: 'hcp-update-email',
      type: 'text',
      name: 'email',
      label: intl.formatMessage(formMessages.emailLabel),
      placeholder: intl.formatMessage(formMessages.emailPlaceholder)
    }
  ];

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const invalidEmailError = intl.formatMessage(formMessages.invalidEmailError);

  const getValidationRules = () =>
    Yup.object({
      email: Yup.string().email(invalidEmailError).required(requiredFieldError)
    });

  const handleSubmit = async (formData) => {
    onSubmit && onSubmit(formData, data);
  };

  return (
    <div className={classes.container}>
      <Typography
        variant="body1"
        color="primary"
        component="h1"
        align="left"
        className={classes.title}
      >
        {intl.formatMessage(messages.title)}
      </Typography>

      <Typography variant="body2" align="left" className={classes.instructions}>
        {intl.formatMessage(messages.formInstructions)}
      </Typography>

      <Form
        // autoFocus
        initialValues={getInitialValues()}
        validationRules={getValidationRules()}
        fields={getFields()}
        buttonSubmitLabel={'Enviar'}
        handleSubmit={handleSubmit}
        withInputsBorder
        inputProps={{
          variant: 'outlined'
        }}
        buttonSubmitProps={{
          size: 'medium',
          color: 'primary',
          fullWidth: true
        }}
      />
    </div>
  );
};

EmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export { EmailForm };
