import React from 'react';
import {
  Drawer as MaterialDrawer,
  Box,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { useDrawer } from './hooks/useDrawer';
import { useStyles } from './DrawerStyles';

const Drawer = () => {
  const { drawerState, handleCloseDrawer } = useDrawer();

  const {
    show,
    title,
    caption,
    body,
    isDismissible,
    closeButton,
    configProps
  } = drawerState;
  const { anchor, color = 'default' } = configProps;

  const classes = useStyles({ color });

  const toggleDrawer = (event) => {
    if (
      !isDismissible &&
      ((event.type === 'keydown' && event.key === 'Escape') ||
        event.type === 'click')
    ) {
      return;
    }

    handleCloseDrawer();
  };

  const drawerClasses = { root: classes.root, paper: classes.container };

  return (
    <MaterialDrawer
      anchor={anchor}
      open={show}
      onClose={toggleDrawer}
      classes={drawerClasses}
    >
      <Box width={1}>
        <Box width={1} className={classes.headerContainer}>
          <Typography variant="h6" color="primary" className={classes.title}>
            {title}
          </Typography>

          <Typography variant="body2" className={classes.caption}>
            {caption}
          </Typography>

          {closeButton && (
            <IconButton
              edge="end"
              color="primary"
              className={classes.closeButton}
              onClick={handleCloseDrawer}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
        </Box>

        {body}
      </Box>
    </MaterialDrawer>
  );
};

export { Drawer };
