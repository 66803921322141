import { defineMessages } from 'react-intl';

// TODO: Check deleted dinamycal variables

export const messages = defineMessages({
  title: {
    id: 'components.MedicalEventRequests.title',
    defaultMessage: 'Mis solicitudes de eventos'
  },
  [`administradorPendingRequests`]: {
    id: `components.MedicalEventRequests.administradorpendingRequests`,
    defaultMessage: 'Solicitudes pendientes de su revisión'
  },
  [`administradorAllRequests`]: {
    id: `components.MedicalEventRequests.administradorAllRequests`,
    defaultMessage: 'Todas las solicitudes de eventos'
  },
  [`administradorAllRequestsBody`]: {
    id: `components.MedicalEventRequests.administradorAllRequestsBody`,
    defaultMessage: ' '
  },
  [`directorPendingRequests`]: {
    id: `components.MedicalEventRequests.directorpendingRequests`,
    defaultMessage: 'Solicitudes pendientes de su aprobación'
  },
  [`directorAllRequests`]: {
    id: `components.MedicalEventRequests.directorAllRequests`,
    defaultMessage: 'Solicitudes revisadas y aprobadas'
  },
  [`directorAllRequestsBody`]: {
    id: `components.MedicalEventRequests.directorAllRequestsBody`,
    defaultMessage:
      'Aquí podrás visualizar el estado en que se encuentran dentro del proceso de aprobación las solicitudes que requieran tu aprobación y ya fueron previamente revisadas y aprobadas por ti.'
  },
  [`gerentePendingRequests`]: {
    id: `components.MedicalEventRequests.gerentependingRequests`,
    defaultMessage: 'Solicitudes pendientes de su aprobación'
  },
  [`gerenteAllRequests`]: {
    id: `components.MedicalEventRequests.gerenteAllRequests`,
    defaultMessage: 'Todas las solicitudes de eventos'
  },
  [`gerenteAllRequestsBody`]: {
    id: `components.MedicalEventRequests.gerenteAllRequestsBody`,
    defaultMessage: ''
  },
  [`representantePendingRequests`]: {
    id: `components.MedicalEventRequests.representantependingRequests`,
    defaultMessage: 'Solicitudes pendientes de su revisión'
  },
  [`representanteAllRequests`]: {
    id: `components.MedicalEventRequests.representanteAllRequests`,
    defaultMessage: 'Solicitudes ingresadas anteriormente'
  },
  [`representanteAllRequestsBody`]: {
    id: `components.MedicalEventRequests.representanteAllRequestsBody`,
    defaultMessage:
      'Aquí podrás visualizar el estado en que se encuentran dentro del proceso de aprobación las solicitudes creadas por ti.'
  },
  [`revisorPendingRequests`]: {
    id: `components.MedicalEventRequests.revisorpendingRequests`,
    defaultMessage: 'Solicitudes pendientes de su revisión'
  },
  [`revisorAllRequests`]: {
    id: `components.MedicalEventRequests.revisorAllRequests`,
    defaultMessage: 'Solicitudes revisadas'
  },
  [`revisorAllRequestsBody`]: {
    id: `components.MedicalEventRequests.revisorAllRequestsBody`,
    defaultMessage:
      'Aquí podrás visualizar el estado en que se encuentran dentro del proceso de aprobación las solicitudes que requieran tu aprobación y ya fueron previamente revisadas y aprobadas por ti.'
  },
  medicalPendingRequests: {
    id: `components.MedicalEventRequests.medicalPendingRequests`,
    defaultMessage: 'Solicitudes pendientes de su revisión'
  },
  medicalAllRequests: {
    id: `components.MedicalEventRequests.medicalAllRequests`,
    defaultMessage: 'Todas las solicitudes de eventos'
  },
  medicalAllRequestsBody: {
    id: `components.MedicalEventRequests.medicalAllRequestsBody`,
    defaultMessage:
      'Aquí podrás visualizar el estado en que se encuentran dentro del proceso de aprobación las solicitudes en las que tengas participación.'
  },
  newRequest: {
    id: 'components.EventRequestForm.newRequest',
    defaultMessage: 'Nueva solicitud'
  }
});
