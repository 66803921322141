import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: 4,
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle1.fontSize
    }
  },
  description: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle2.fontSize
    }
  }
}));

export { useStyles };
