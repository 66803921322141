import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Card, Typography, IconButton, Button } from '@material-ui/core';

import EditIcon from '@material-ui/icons/EditRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import ApproveIcon from '@material-ui/icons/CheckCircle';

import { messages } from './ScheduleCardMessages';

import { useModal } from '@octopy/react-modal';
import { useStyles } from './ScheduleCardStyles';

import { useDrawer } from 'components/Drawer';

import { ScheduleDrawer } from '../ScheduleDrawer';

import { mapEditScheduleFormInitialValues } from '../helpers';
import { useFranchise } from 'components/Franchise/hooks/useFranchise';
import { useParams } from 'react-router-dom';
import { usePdfViewer } from 'components/PdfViewer';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';

const ScheduleCard = ({ schedule, medical }) => {
  const { franchiseId } = useParams();
  const {
    user: { role }
  } = useAuth();
  const intl = useIntl();
  const classes = useStyles({ schedule });
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleOpenDrawer } = useDrawer();
  const {
    handleRemoveSchedule,
    handleEditSchedule,
    handleApproveSchedule
  } = useFranchise();
  const pdfViewer = usePdfViewer();

  const handleOpenEditScheduleModal = (event) => {
    event.stopPropagation();

    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.editScheduleCard),
      caption: intl.formatMessage(messages.editScheduleCarSubTitle),
      body: (
        <ScheduleDrawer
          initialValues={mapEditScheduleFormInitialValues(schedule)}
          onSubmit={async (values) =>
            await handleEditSchedule(franchiseId, values)
          }
        />
      )
    });
  };

  const handleOpenDeleteScheduleModal = (event) => {
    event.stopPropagation();

    handleOpenModal({
      title: intl.formatMessage(messages.deleteScheduleTitle),
      body: (
        <Fragment>
          {intl.formatMessage(messages.deleteScheduleQuestion)}
        </Fragment>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleRemoveSchedule(franchiseId, schedule.id);
            }}
          >
            {intl.formatMessage(messages.deleteSchedule)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleOpenApproveScheduleModal = (event) => {
    event.stopPropagation();

    handleOpenModal({
      title: intl.formatMessage(messages.approveScheduleModalTitle),
      body: (
        <Fragment>
          {intl.formatMessage(messages.approveScheduleModalBody)}
        </Fragment>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleApproveSchedule(franchiseId, schedule.id);
            }}
          >
            {intl.formatMessage(messages.approve)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  return (
    <Card
      className={classes.container}
      onClick={
        !!schedule.url ? () => pdfViewer.handleOpen(schedule) : undefined
      }
    >
      {schedule.approved ? (
        <Typography variant="body2" className={`${classes.status} approved`}>
          {intl.formatMessage(messages.approved)}
        </Typography>
      ) : (
        <Typography variant="body2" className={`${classes.status} disapproved`}>
          {intl.formatMessage(messages.disapproved)}
        </Typography>
      )}
      <div className={classes.body}>
        <Typography
          component="div"
          variant="subtitile1"
          className={classes.bodyEllipsis}
        >
          {schedule.name}
        </Typography>
        <Typography
          className={classes.franchise}
          component="div"
          variant="caption"
          color="secondary"
        >
          {schedule.franchise.name}
        </Typography>
      </div>
      {!schedule.approved && role === roles.admin && (
        <div className={classes.footer}>
          <IconButton
            size="small"
            onClick={(event) =>
              handleOpenEditScheduleModal(event, schedule.name, schedule.url)
            }
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            edge="end"
            onClick={handleOpenDeleteScheduleModal}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )}

      {!schedule.approved && medical && (
        <div className={classes.footer}>
          <IconButton
            size="small"
            edge="end"
            onClick={handleOpenApproveScheduleModal}
          >
            <ApproveIcon />
          </IconButton>
        </div>
      )}
    </Card>
  );
};

ScheduleCard.propTypes = {
  schedule: PropTypes.array.isRequired
};

export { ScheduleCard };
