import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.FoodSection.title',
    defaultMessage: 'ALIMENTOS'
  },
  totalCost: {
    id: 'components.FoodSection.totalCost',
    defaultMessage: 'Costo total:'
  },
  costPerPerson: {
    id: 'components.FoodSection.costPerPerson',
    defaultMessage: 'Costo por persona: '
  },
  tip: {
    id: 'components.FoodSection.tip',
    defaultMessage: 'Propina:'
  }
});
