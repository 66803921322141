import { useDrawer } from 'components/Drawer';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { messages } from '../AddHcpFormMessages';
import { useValidationSchema } from './useValidationSchema';

const _initialValues = {
  addBy: 'one-key-id',
  name: '',
  paternalSurname: '',
  maternalSurname: '',
  email: ''
};

export const useForm = ({ initialValues = _initialValues, onSubmit }) => {
  const { handleCloseDrawer } = useDrawer();
  const intl = useIntl();

  const validationSchema = useValidationSchema();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const done = await onSubmit(values);

      done && handleCloseDrawer();
    }
  });

  const byOneKeyIdFields = [
    {
      type: 'text',
      name: 'oneKeyId',
      label: intl.formatMessage(messages.oneKeyIdLabel),
      placeholder: intl.formatMessage(messages.oneKeyIdPlaceholder),
      breakpoints: { xs: 12 }
    }
  ];

  const byNameFields = [
    {
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: intl.formatMessage(messages.namePlaceholder),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'paternalSurname',
      label: intl.formatMessage(messages.paternalSurnameLabel),
      placeholder: intl.formatMessage(messages.paternalSurnamePlaceholder),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'maternalSurname',
      label: intl.formatMessage(messages.maternalSurnameLabel),
      placeholder: intl.formatMessage(messages.maternalSurnamePlaceholder),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'email',
      label: intl.formatMessage(messages.emailLabel),
      placeholder: intl.formatMessage(messages.emailPlaceholder),
      breakpoints: { xs: 12 }
    }
  ];

  const fields = [
    {
      type: 'radioGroup',
      name: 'addBy',
      row: true,
      options: [
        {
          name: 'one-key-id',
          label: intl.formatMessage(messages.addByOneKeyIdLabel)
        },
        {
          name: 'name',
          label: intl.formatMessage(messages.addByNameLabel)
        }
      ],
      breakpoints: { xs: 12 }
    },
    ...(formik.values.addBy === 'one-key-id' ? byOneKeyIdFields : byNameFields)
  ];

  return { formik, fields };
};
