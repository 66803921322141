import { makeStyles } from '@material-ui/core/styles';
import { Images } from 'assets';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  container: ({ variant }) => ({
    height: 40,
    backgroundColor:
      variant === 'light'
        ? '#f3f3f3'
        : hexToRgba(theme.palette.common.black, 0.6),
    display: 'flex',
    alignItems: 'center'
  }),
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  octopy: ({ variant }) => ({
    marginLeft: theme.spacing(0.5),
    marginBottom: 2,
    height: 15,
    width: 54,
    maskImage: `url("${Images.nameLogo}")`,
    maskSize: '100%',
    backgroundColor:
      variant === 'light' ? theme.palette.text.secondary : '#BFBFBF'
  }),
  r: ({ variant }) => ({
    marginRight: theme.spacing(0.5),
    alignSelf: 'flex-start',
    lineHeight: 1,
    color: variant === 'light' ? theme.palette.text.secondary : '#BFBFBF',
    fontSize: 10
  }),
  text: ({ variant }) => ({
    lineHeight: 1,
    fontSize: 10,
    color:
      variant === 'light'
        ? hexToRgba(theme.palette.text.secondary, 0.4)
        : '#BFBFBF'
  })
}));

export { useStyles };
