import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestForm.sections.PlaceSection.title',
    defaultMessage: 'Lugar del evento'
  },
  stateLabel: {
    id: 'components.EventRequestForm.sections.PlaceSection.stateLabel',
    defaultMessage: 'Estado'
  },
  statePlaceholder: {
    id: 'components.EventRequestForm.sections.PlaceSection.statePlaceholder',
    defaultMessage: 'Seleccione el estado'
  },
  cityLabel: {
    id: 'components.EventRequestForm.sections.PlaceSection.cityLabel',
    defaultMessage: 'Ciudad'
  },
  cityPlaceholder: {
    id: 'components.EventRequestForm.sections.PlaceSection.cityPlaceholder',
    defaultMessage: 'Seleccione la ciudad'
  },
  typeLabel: {
    id: 'components.EventRequestForm.sections.PlaceSection.typeLabel',
    defaultMessage: 'Tipo de lugar'
  },
  typePlaceholder: {
    id: 'components.EventRequestForm.sections.PlaceSection.typePlaceholder',
    defaultMessage: 'Seleccione el tipo de lugar'
  },
  nameLabel: {
    id: 'components.EventRequestForm.sections.PlaceSection.nameLabel',
    defaultMessage: 'Nombre del lugar'
  },
  namePlaceholder: {
    id: 'components.EventRequestForm.sections.PlaceSection.namePlaceholder',
    defaultMessage: 'Ingrese el nombre del lugar'
  },
  costLabel: {
    id: 'components.EventRequestForm.sections.PlaceSection.costLabel',
    defaultMessage: 'Costo de las instalaciones'
  },
  costPlaceholder: {
    id: 'components.EventRequestForm.sections.PlaceSection.costPlaceholder',
    defaultMessage: 'Ingrese el costo de las instalaciones'
  },
  quoteLabel: {
    id: 'components.EventRequestForm.sections.PlaceSection.quoteLabel',
    defaultMessage: 'Adjuntar Cotización: '
  },
  facilityNonUseLabel: {
    id: 'components.EventRequestForm.sections.PlaceSection.facilityNonUseLabel',
    defaultMessage: 'Adjuntar carta de no uso de instalaciones: '
  },
  quoteButtonLabel: {
    id: 'components.EventRequestForm.sections.PlaceSection.quoteButtonLabel',
    defaultMessage: 'Seleccionar archivo PDF'
  }
});
