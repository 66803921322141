export const base64ToFile = async (base64) => {
  try {
    const url = base64;

    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], 'File name', { type: 'image/png' });

    return file;
  } catch (error) {}
};
