import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box } from '@material-ui/core';
import { messages } from './PetitionerSectionMessages';
import { useStyles } from './PetitionerSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const PetitionerSection = () => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    eventRequest: { petitioner }
  } = useEventRequestDetails();

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.titleCard)}
      </Typography>

      <Grid container spacing={1}>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
          className={classes.containerInfo}
        >
          <Typography variant="subtitle2" className={classes.informationLabel}>
            {intl.formatMessage(messages.id)}
          </Typography>
          <Typography>{petitioner.id}</Typography>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.containerInfo}
        >
          <Typography variant="subtitle2" className={classes.informationLabel}>
            {intl.formatMessage(messages.name)}
          </Typography>
          <Typography>{petitioner.name}</Typography>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.containerInfo}
        >
          <Typography variant="subtitle2" className={classes.informationLabel}>
            {intl.formatMessage(messages.email)}
          </Typography>
          <Typography>{petitioner.email}</Typography>
        </Grid>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
          className={classes.containerInfo}
        >
          <Typography variant="subtitle2" className={classes.informationLabel}>
            {intl.formatMessage(messages.franchise)}
          </Typography>
          <Typography>{petitioner.franchise}</Typography>
        </Grid>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
          className={classes.containerInfo}
        >
          <Typography variant="subtitle2" className={classes.informationLabel}>
            {intl.formatMessage(messages.district)}
          </Typography>
          <Typography>{petitioner.district}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

PetitionerSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { PetitionerSection };
