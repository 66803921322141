import { useLocation } from 'react-router-dom';

export const useInitialValues = () => {
  const location = useLocation();

  return (
    location.state?.values || {
      draft: false,
      requirements: {
        date: null,
        time: null,
        scheduleId: null,
        numberOfGuests: ''
      },
      speakersEnabled: true,
      speakers: [],
      place: {
        cityId: null,
        type: null,
        name: '',
        hasACost: true,
        cost: '',
        quote: {
          url: null,
          file: null
        }
      },
      foodEnabled: true,
      food: {
        costPerPerson: '',
        totalCost: '',
        tip: null,
        quote: {
          url: null,
          file: null
        }
      },
      materialsEnabled: true,
      materials: {
        totalCost: '',
        quote: {
          url: null,
          file: null
        }
      }
    }
  );
};
