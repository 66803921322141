import { useState } from 'react';
import { useLoader } from '@octopy/react-loader';
import { getBlobUrl } from 'utils/files';
import { PdfViewerContext } from './PdfViewerContext';

export const PdfViewerProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState(null);
  const [name, setName] = useState('');
  const { handleShowLoader } = useLoader();

  const handleOpen = async ({ url, name }) => {
    handleShowLoader(true);

    const blobUrl = await getBlobUrl(url);

    handleShowLoader(false);

    setUrl(blobUrl);
    setName(name);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const contextValue = {
    visible,
    url,
    name,
    handleOpen,
    handleClose
  };

  return (
    <PdfViewerContext.Provider value={contextValue}>
      {children}
    </PdfViewerContext.Provider>
  );
};
