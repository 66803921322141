import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { useStyles } from './EmployeesStyles';
import { EmployeesActions } from './EmployeesActions';
import { EmployeesTable } from './EmployeesTable';

const Employees = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container>
        <EmployeesActions />
      </Container>
      <EmployeesTable />
    </div>
  );
};

Employees.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Employees };
