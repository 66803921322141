import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import { messages } from './HcpsActionsMessages';
import { useStyles } from './HcpsActionsStyles';
import { useDrawer } from 'components/Drawer';
import { ImportHcpsForm } from '../ImportHcpsForm';
import { useLoader } from '@octopy/react-loader';
import { useHcps } from '../hooks/useHcps';
import { downloadFile } from 'utils/files';

const HcpsActions = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleExportList } = useHcps();
  const { handleShowLoader } = useLoader();
  const { handleOpenDrawer } = useDrawer();

  const handleButtonExport = async () => {
    handleShowLoader(true);

    const exportResponse = await handleExportList();
    const format = {
      ...exportResponse,
      name: 'HCPS',
      date: new Date()
    };

    downloadFile(format);

    handleShowLoader(false);
  };

  const handleImportHcpsDrawer = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.titleDrawer),
      caption: intl.formatMessage(messages.captionDrawer),
      body: <ImportHcpsForm />
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={4}>
        <Typography className={classes.title} variant="h5">
          {intl.formatMessage(messages.title)}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} className={classes.boxButtons}>
        <Button color="primary" onClick={handleImportHcpsDrawer}>
          {intl.formatMessage(messages.importButton)}
        </Button>
        <Button
          color="primary"
          className={classes.exportButton}
          onClick={handleButtonExport}
        >
          {intl.formatMessage(messages.exportButton)}
        </Button>
      </Grid>
    </Grid>
  );
};

HcpsActions.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { HcpsActions };
