import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: (index) => ({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.palette.primary.main,
    zIndex: 10000,
    padding: theme.spacing(1.5),
    transition: theme.transitions.create('opacity', { duration: 1000 }),
    opacity: index > 5 ? 0 : 1,
    pointerEvents: index > 4 ? 'none' : undefined
  }),
  backgroundImage: (index) => ({
    opacity: index > 2 ? 1 : 0,
    position: 'absolute',
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: theme.transitions.create('opacity', { duration: 1000 })
  }),
  container: () => ({
    transition: theme.transitions.create(['transform'], {
      duration: 1000
    }),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  appLogoContainer: (index) => ({
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['transform', 'opacity'], {
      duration: 1000
    }),
    transform: index > 2 ? 'scale(0.5)' : undefined,
    opacity: index > 0 ? 1 : 0,
    [theme.breakpoints.down('sm')]: {
      transform: index > 2 ? 'scale(0.5)' : 'scale(0.8)'
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(0.4)'
    }
  }),
  bar: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    height: 118,
    borderRadius: 2,
    width: 6,
    backgroundColor: theme.palette.secondary.main
  },
  welcome: (index) => ({
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    marginTop: -theme.spacing(2),
    transition: theme.transitions.create('opacity', { duration: 1000 }),
    opacity: index > 3 ? 1 : 0
  }),
  appName: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  appCaption: {
    color: theme.palette.primary.contrastText,
    letterSpacing: 6,
    marginTop: theme.spacing(1),
    whiteSpace: 'nowrap'
  }
}));

export { useStyles };
