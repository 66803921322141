import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { messages } from '../PlaceSectionMessages';
import { useStyles } from '../PlaceSectionStyles';
import { useCatalogues } from 'components/Catalogues';
import { InputAdornment } from '@material-ui/core';

export const useFields = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { placeType, handleGetCitiesCatalogue } = useCatalogues();
  const [citiesCatalogue, setCitiesCatalogue] = useState([]);
  const [placeTyeOptions, setPlaceTypeOptions] = useState([]);

  useEffect(() => {
    setPlaceTypeOptions(placeType);
  }, [placeType]);

  useEffect(() => {
    (async () => {
      const catalogueResponse = await handleGetCitiesCatalogue();

      if (catalogueResponse) {
        setCitiesCatalogue(catalogueResponse);
      }
    })();
  }, []);

  const commonBreakpoints = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3
  };

  return [
    {
      type: 'select',
      name: 'place.cityId',
      label: intl.formatMessage(messages.cityLabel),
      placeholder: intl.formatMessage(messages.cityPlaceholder),
      options: citiesCatalogue,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: commonBreakpoints
    },
    {
      type: 'select',
      name: 'place.type',
      label: intl.formatMessage(messages.typeLabel),
      placeholder: intl.formatMessage(messages.typePlaceholder),
      options: placeTyeOptions,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: commonBreakpoints
    },
    {
      type: 'text',
      name: 'place.name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: intl.formatMessage(messages.namePlaceholder),
      breakpoints: commonBreakpoints
    },
    {
      type: 'switch',
      name: 'place.hasACost',
      label: intl.formatMessage(messages.costLabel),
      breakpoints: commonBreakpoints,
      formControlLabelProps: {
        className: classes.switch
      }
    },
    {
      type: 'number',
      name: 'place.cost',
      label: intl.formatMessage(messages.costLabel),
      placeholder: intl.formatMessage(messages.costPlaceholder),
      breakpoints: commonBreakpoints,
      disabled: !formik.values.place.hasACost,
      inputProps: {
        InputProps: {
          endAdornment: <InputAdornment position="end">$</InputAdornment>
        }
      }
    },
    {
      type: 'application/pdf',
      name: 'place.quote',
      buttonLabel: intl.formatMessage(messages.quoteButtonLabel),
      label: formik.values.place.hasACost
        ? intl.formatMessage(messages.quoteLabel)
        : intl.formatMessage(messages.facilityNonUseLabel),
      value: formik.values.place.quote,
      onChange: (value) => formik.setFieldValue('place.quote', value),
      error:
        formik.touched.place?.quote?.file && formik.errors.place?.quote?.file,
      breakpoints: {
        xs: 12
      },
      id: 'place.quote.filePicker'
    }
  ];
};
