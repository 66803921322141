import React from 'react';
import { Navigation } from 'routes/Navigation';
import './App.scss';

// TODO: Add auth logic for login & register components
const App = () => (
  <>
    <Navigation />
  </>
);

export { App };
