import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(8)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2)
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      width: '100%'
    }
  },
  backButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    },
    transform: 'translateY(-1px)'
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize
    }
  },
  typeField: {
    width: 296,
    [theme.breakpoints.down('md')]: {
      width: 250
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto'
    }
  },
  submitButtonsContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(6)
    }
  },
  submitButton: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      width: '100%'
    }
  }
}));

export { useStyles };
