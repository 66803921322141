import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Typography, Box } from '@material-ui/core';
import { messages } from '../EmployeesTableMessages';
import { useStyles } from '../EmployeesTableStyles';
import { useHistory } from 'react-router-dom';
import { useModal } from '@octopy/react-modal';
import { TableFilters } from '../../TableFilters';
import { useEmployees } from 'components/Employees/hooks/useContext';

export const useTable = () => {
  const history = useHistory();
  const intl = useIntl();
  const { handleOpenModal, handleCloseModal } = useModal();
  const classes = useStyles();
  const filterFormikRef = useRef();
  const { handleDeleteEmployee } = useEmployees();

  const handleOpenFilterTableModal = () => {
    handleOpenModal({
      title: intl.formatMessage(messages.filter),
      body: <TableFilters formikRef={filterFormikRef} />,
      actionButtons: (
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={() => {
              handleCloseModal();
              filterFormikRef.current.handleSubmit();
            }}
            color="primary"
          >
            {intl.formatMessage(messages.filterButton)}
          </Button>
          <Button onClick={handleCloseModal} className={classes.cancelButton}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </Box>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleDeleteEmployeeModal = (deleted, employeeId) => {
    handleOpenModal({
      title: intl.formatMessage(messages.deleteElementMessage),
      body: (
        <Box>
          <Typography>
            {intl.formatMessage(messages.deleteSubtitleElementMessage)}
          </Typography>
        </Box>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleDeleteEmployee(deleted, employeeId);
              handleCloseModal();
            }}
          >
            {intl.formatMessage(messages.deleteButton)}
          </Button>
          <Button onClick={handleCloseModal} className={classes.cancelButton}>
            {intl.formatMessage(messages.cancelButton)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const tableHead = [
    { key: 'identifier', label: 'ID', align: 'center' },
    {
      key: 'name',
      label: intl.formatMessage(messages.employeeName),
      align: 'start'
    },
    {
      key: 'email',
      label: intl.formatMessage(messages.email),
      align: 'start'
    },
    { key: 'rfc', label: 'RFC', align: 'start' },
    { key: 'city', label: intl.formatMessage(messages.city), align: 'center' },
    {
      key: 'franchise',
      label: intl.formatMessage(messages.franchise),
      align: 'center'
    },
    { key: 'title', label: intl.formatMessage(messages.role), align: 'start' },
    {
      key: 'enabled',
      label: intl.formatMessage(messages.enabled),
      align: 'center'
    },
    {
      key: 'action',
      label: intl.formatMessage(messages.action),
      align: 'center'
    }
  ];

  const actions = (item) => [
    {
      label: intl.formatMessage(messages.actionEdit),
      onClick: async () => {
        history.push(`employees/edit/${item.id}`);
      }
    },
    {
      label: intl.formatMessage(messages.actionDelete),
      onClick: () => handleDeleteEmployeeModal(item.deleted, item.id)
    }
  ];

  const onSelectItems = (items) => {
    handleOpenModal({
      title: 'intl.formatMessage(messages.deleteMessage)',
      body: (
        <>
          <Typography>
            {intl.formatMessage(messages.deleteSubtitleMessage)}
          </Typography>
          <Box
            pt={2}
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Button color="primary">
              {intl.formatMessage(messages.deleteButton)}
            </Button>
            <Button className={classes.cancelButton}>
              {intl.formatMessage(messages.cancelButton)}
            </Button>
          </Box>
        </>
      )
    });
  };

  return { tableHead, actions, onSelectItems };
};
