import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleCard: {
    id: 'components.ObjectiveSection.titleCard',
    defaultMessage: 'OBJETIVO DE LA ACTIVIDAD'
  },
  objectiveDescription: {
    id: 'components.ObjectiveSection.objectiveDescription',
    defaultMessage: 'Objetivo:'
  }
});
