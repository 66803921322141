import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  openSideMenu: {
    id: 'components.Header.openSideMenu',
    defaultMessage: 'Abrir menu'
  },
  appName: {
    id: 'components.Header.appName',
    defaultMessage: 'Novo Eventos y Novo Apoyos'
  },
  subtitle: {
    id: 'components.Header.subtitle',
    defaultMessage: 'Plataforma de gestión'
  },
  returnToAdminProfile: {
    id: 'components.Header.returnToAdminProfile',
    defaultMessage: 'REGRESAR A PERFIL ADMINISTRADOR'
  }
});
