import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: 4,
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle1.fontSize
    }
  }
}));

export { useStyles };
