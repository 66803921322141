import { useCatalogues } from 'components/Catalogues';
import { useDrawer } from 'components/Drawer';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../AddRepresentativeFormMessages';

export const useForm = ({ onSubmit }) => {
  const { handleCloseDrawer } = useDrawer();
  const intl = useIntl();
  const catalogues = useCatalogues();

  useEffect(() => {
    catalogues.handleGetEmptyEmployees();
  }, []);

  const initialValues = {
    employeeId: ''
  };

  const validationSchema = yup.object({
    employeeId: yup
      .string()
      .required(intl.formatMessage(messages.requiredError))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const done = await onSubmit(values);

      done && handleCloseDrawer();
    }
  });

  const fields = {
    employeeId: {
      type: 'select',
      name: 'employeeId',
      label: intl.formatMessage(messages.employeeIdLabel),
      placeholder: intl.formatMessage(messages.employeeIdPlaceholder),
      options: catalogues.emptyEmployees,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value
    }
  };

  return { formik, fields };
};
