import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { messages } from './RequirementsSectionMessages';
import { useStyles } from './RequirementsSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

export const RequirementsSection = () => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    eventRequest: { type: eventType, requirements }
  } = useEventRequestDetails();

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.titleCard)}
      </Typography>
      <Grid container spacing={3}>
        {!!requirements.name && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            {eventType === 'pharmacy' ? (
              <Typography className={classes.informationLabel}>
                {intl.formatMessage(messages.pharmacyName)}
              </Typography>
            ) : (
              <Typography className={classes.informationLabel}>
                {intl.formatMessage(messages.nameLocation)}
              </Typography>
            )}

            <Typography>{requirements.name}</Typography>
          </Grid>
        )}
        {!!requirements.date && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.dateRequested)}
            </Typography>
            <Typography>{requirements.date}</Typography>
          </Grid>
        )}
        {!!requirements.hourEvent && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.hourEvent)}
            </Typography>
            <Typography>{requirements.hourEvent}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
