import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.customShadows.z2,
    width: 281,
    height: 143,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    cursor: 'pointer',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: hexToRgba(theme.palette.primary.main, 0.05)
    }
  },
  header: {
    height: '33%',
    display: 'flex',
    alignItems: 'flex-start'
  },
  name: {
    fontWeight: 'bold',
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontSize: 13,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineHeight: 1.25,
    textOverflow: 'ellipsis',
    marginRight: 50
  },
  activeSwitch: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(2)
  },
  body: {
    height: '34%',
    display: 'flex',
    alignItems: 'center'
  },
  managerName: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  representativeQuantity: {
    lineHeight: 1.25,
    fontWeight: 'bold',
    color: hexToRgba(theme.palette.text.primary, 0.2)
  },
  modalImportantText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}));

export { useStyles };
