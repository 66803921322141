import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexGrow: 1
  },
  title: {
    letterSpacing: 4,
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground
  },
  formDataContainer: {
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  formImageContainer: {
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    },
    padding: theme.spacing(3)
  },
  boxButton: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2)
    }
  }
}));

export { useStyles };
