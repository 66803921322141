import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EmployeesActions.title',
    defaultMessage: 'EMPLEADOS'
  },
  export: {
    id: 'components.EmployeesActions.export',
    defaultMessage: 'Exportar'
  },
  newEmployee: {
    id: 'components.EmployeesActions.newEmployee',
    defaultMessage: 'Nuevo empleado'
  },
  buttonSession: {
    id: 'components.EmployeesActions.buttonSession',
    defaultMessage: 'Iniciar sesión como otro usuario'
  },
  logInAs: {
    id: 'components.EmployeesActions.logInAs',
    defaultMessage: 'INICIAR SESIÓN COMO'
  },
  descriptionDrawer: {
    id: 'components.EmployeesActions.descriptionDrawer',
    defaultMessage:
      'Selecciona el nombre del empleado con el cual quieres acceder a la plataforma'
  }
});
