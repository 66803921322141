/* eslint-disable react/jsx-props-no-spreading */
import { get, map, reduce } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { messages } from './NavigationMessages';
import { useRoutes } from './routes';
import { metas } from './metas';
import './Navigation.scss';
import { useAuth } from 'components/Auth/hooks/useAuth';
import { Redirect } from 'react-router-dom';

function Navigation() {
  const { user } = useAuth();
  const { formatMessage } = useIntl();
  const role = user?.role || 'public';

  const routes = useRoutes();

  return (
    <Router>
      <Switch>
        {role !== 'public' &&
          map(
            reduce(
              routes.public,
              (paths, route) => [
                ...paths,
                ...(!!route.path ? [route.path] : [])
              ],
              []
            ),
            (path, index) => (
              <Route
                key={`public-route-${index}`}
                path={path}
                exact={true}
                render={() => <Redirect to="/" />}
              />
            )
          )}

        {map(routes[role], (route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <>
                <Helmet>
                  <title>{formatMessage(messages[route.titleMessage])}</title>
                  {get(metas, route.path, []).map((meta, index) => (
                    <meta key={`meta-${index}`} {...meta} />
                  ))}
                </Helmet>
                <route.layout>
                  <div className="nav-container fadeIn">
                    <route.component {...props} />
                  </div>
                </route.layout>
              </>
            )}
          />
        ))}
      </Switch>
    </Router>
  );
}

export { Navigation };
