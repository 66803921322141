import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.FranchiseSchedule.title',
    defaultMessage: 'Agendas'
  },
  newSchedule: {
    id: 'components.FranchiseSchedule.newSchedule',
    defaultMessage: 'Nueva agenda'
  },
  newScheduleTitle: {
    id: 'components.FranchiseSchedule.newScheduleTitle',
    defaultMessage: 'Nueva agenda'
  },
  scheduleDrawerSubTitle: {
    id: 'components.FranchiseSchedule.scheduleDrawerSubTitle',
    defaultMessage:
      'Completa los siguientes campos para cargar una nueva agenda'
  }
});
