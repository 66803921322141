import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { renderAutocomplete, renderTextField } from '@octopy/react-form';

import { Button } from '@material-ui/core';

import ExportIcon from '@material-ui/icons/InsertDriveFile';

import { useStyles } from './SepakerSearchStyles';

import { messages } from './SepakerSearchMessages';

import { useForm } from './hooks/useForm';

const SepakerSearch = ({ onSubmit, onExport, setValuesExport }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { formik, fields } = useForm({ onSubmit });

  useEffect(() => {
    setValuesExport(formik.values);
  }, [formik.values]);

  return (
    <div className={classes.container}>
      <div className={classes.actionsDatePickers}>
        {renderTextField({
          formik,
          field: fields.speakerName,
          inputProps: {}
        })}
        {renderAutocomplete({
          formik,
          field: fields.speakerType,
          inputProps: {}
        })}
        {renderAutocomplete({
          formik,
          field: fields.speakerFranchises,
          inputProps: {}
        })}
      </div>
      <Button
        onClick={formik.handleSubmit}
        className={classes.buttonSearch}
        size="large"
        color="primary"
      >
        {intl.formatMessage(messages.search)}
      </Button>

      <Button
        onClick={onExport}
        className={classes.buttonSearch}
        size="large"
        endIcon={<ExportIcon />}
      >
        {intl.formatMessage(messages.exportDoc)}
      </Button>
    </div>
  );
};

SepakerSearch.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { SepakerSearch };
