import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2)
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      width: '100%'
    }
  },
  backButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    },
    transform: 'translateY(-1px)'
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize
    }
  },
  newRepresentativeButton: {
    textTransform: 'uppercase',
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    minWidth: 210
  },
  subtitle: {
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  cardsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginLeft: -theme.spacing(3),
    flexWrap: 'wrap'
  },
  removeRepresentativeModalImportantText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}));

export { useStyles };
