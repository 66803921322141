import { useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { messages } from '../FoodSectionMessages';
import { useCatalogues } from 'components/Catalogues';

export const useFields = ({ formik }) => {
  const intl = useIntl();
  const [tipOptions, setTipOptions] = useState([]);

  const { tip } = useCatalogues();

  useEffect(() => {
    setTipOptions(tip);
  }, [tip]);

  const commonBreakpoints = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3
  };

  return [
    {
      type: 'number',
      name: 'food.totalCost',
      label: intl.formatMessage(messages.totalCostLabel),
      placeholder: intl.formatMessage(messages.totalCostPlaceholder),
      breakpoints: commonBreakpoints,
      disabled: !formik.values.foodEnabled,
      inputProps: {
        InputProps: {
          endAdornment: <InputAdornment position="end">$</InputAdornment>
        }
      }
    },
    {
      type: 'number',
      name: 'food.costPerPerson',
      label: intl.formatMessage(messages.costPerPersonLabel),
      placeholder: intl.formatMessage(messages.costPerPersonPlaceholder),
      breakpoints: commonBreakpoints,
      disabled: !formik.values.foodEnabled,
      inputProps: {
        InputProps: {
          endAdornment: <InputAdornment position="end">$</InputAdornment>
        }
      }
    },
    {
      type: 'select',
      name: 'food.tip',
      label: intl.formatMessage(messages.tipLabel),
      placeholder: intl.formatMessage(messages.tipPlaceholder),
      options: tipOptions,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: commonBreakpoints,
      disabled: !formik.values.foodEnabled
    },
    {
      type: 'application/pdf',
      name: 'food.quote',
      buttonLabel: intl.formatMessage(messages.quoteButtonLabel),
      label: intl.formatMessage(messages.quoteLabel),
      value: formik.values.food.quote,
      onChange: (value) => formik.setFieldValue('food.quote', value),
      error:
        formik.touched.food?.quote?.file && formik.errors.food?.quote?.file,
      breakpoints: {
        xs: 12
      },
      disabled: !formik.values.foodEnabled,
      id: 'food.quote.filePicker'
    }
  ];
};
