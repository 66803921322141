export const speakerType = [
  { value: 'external', label: 'Externo' },
  { value: 'internal', label: 'Interno' }
];

export const eventStatus = [
  { value: 'creada', label: 'Creada' },
  { value: 'ingresada', label: 'Ingresada' },
  { value: 'en revisión', label: 'En revisión' },
  { value: 'en aprobación', label: 'En aprobación' },
  { value: 'aprobada', label: 'Aprobada' },
  { value: 'devuelta', label: 'Devuelta' },
  { value: 'rechazada', label: 'Rechazada' },
  { value: 'archivada', label: 'Archivada' },
  { value: 'cerrada', label: 'Cerrada' }
];

export const eventTypes = [
  { value: 'Novo Evento', label: 'Novo Evento' },
  { value: 'Novo Apoyo', label: 'Novo Apoyo' },
  { value: 'Face to Face', label: 'Face to Face' },
  { value: 'Farmacias', label: 'Actividades de Farmacias' }
];
