import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    boxShadow: theme.customShadows.z3,
    padding: theme.spacing(3),
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    letterSpacing: 6,
    paddingBottom: theme.spacing(2)
  },
  informationLabel: {
    color: theme.palette.grey[600],
    paddingRight: theme.spacing(1)
  },
  containerInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  boxPdf: {
    boxShadow: theme.customShadows.z3,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    height: '190px'
  },
  embed: {
    width: '100%',
    height: '460px'
  },
  namePdf: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 500,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.removable': {
      marginRight: theme.spacing(2.5)
    }
  },
  icon: {
    width: '90px',
    height: '90px',
    color: 'red'
  }
}));

export { useStyles };
