import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  welcome: {
    id: 'components.Dashboard.welcome',
    defaultMessage: 'Bienvenido'
  },
  roleIndicator: {
    id: 'components.Dashboard.roleIndicator',
    defaultMessage: 'Haz iniciado sesión como {role}'
  },

  requestsOnCurrentWeek: {
    id: 'components.Dashboard.Summary.requestsOnCurrentWeek',
    defaultMessage: 'Solicitudes en ejecución esta semana'
  },
  requestsFinishedOnPastMonth: {
    id: 'components.Dashboard.Summary.requestsFinishedOnPastMonth',
    defaultMessage: 'Solicitudes finalizadas hace 30 días'
  }
});
