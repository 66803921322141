import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Avatar,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Badge,
  ButtonBase,
  Button,
  Box
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from './HeaderStyles';
import { useSideMenu } from 'components/SideMenu';
import { useIntl } from 'react-intl';
import { messages } from './HeaderMessages';
import { AccountPopover } from './AccountPopover';
import { useAccountPopover } from './AccountPopover/hooks/useAccountPopover';
import { useAuth } from 'components/Auth';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { getItem } from 'utils/persistentStorage';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

const Header = () => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const { setVisible, mini } = useSideMenu();
  const { user, returnToAdmin } = useAuth();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const accountPopover = useAccountPopover();
  const session = getItem('adminSession');
  const adminSession = _.get(session, 'token', false);

  const toolbarStyle = {
    [theme.direction === 'ltr' ? 'marginLeft' : 'marginRight']: mdUp
      ? theme.mixins.getSideMenu(mini).width
      : 0,
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.standard
    })
  };

  const handleReturnToAdmin = () => {
    returnToAdmin();
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <AppBar color="default" position="fixed">
        <Toolbar style={toolbarStyle}>
          <Hidden mdUp implementation="css">
            <Tooltip title={intl.formatMessage(messages.openSideMenu)}>
              <IconButton
                edge="start"
                aria-label="switch-sidemenu"
                color="secondary"
                onClick={() => setVisible((visible) => !visible)}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Hidden>

          <span className={classes.bar} />

          <div className={classes.appNameContainer}>
            <Typography variant="subtitle2">
              {intl.formatMessage(messages.appName)}
            </Typography>
            <Typography variant="caption">
              {intl.formatMessage(messages.subtitle)}
            </Typography>
          </div>

          <Box ml="auto" display="flex" alignItems="center">
            {adminSession ? (
              isMobile ? (
                <IconButton
                  className={classes.iconAdmin}
                  aria-label="return to admin user"
                  component="span"
                  onClick={handleReturnToAdmin}
                >
                  <SupervisorAccountIcon />
                </IconButton>
              ) : (
                <Button onClick={handleReturnToAdmin}>
                  {intl.formatMessage(messages.returnToAdminProfile)}
                </Button>
              )
            ) : null}

            <ButtonBase
              id="accountPopoverButton"
              onClick={accountPopover.handleOpen}
              className={classes.avatarWrapper}
            >
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                classes={{ badge: classes.avatarBadge }}
                color="secondary"
                variant="dot"
                invisible={false}
              >
                {!!user.image ? (
                  <Avatar
                    src={user.image}
                    variant="circular"
                    className={classes.avatar}
                    alt={user.name}
                  />
                ) : (
                  <Avatar className={classes.avatar}>{`${user.name[0]}${
                    user.name[user.name.search(' ') + 1]
                  }`}</Avatar>
                )}
              </Badge>
            </ButtonBase>

            <AccountPopover {...accountPopover} />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export { Header };
