import { useIntl } from 'react-intl';
import { messages } from '../ChangePasswordSectionMessages';
import * as yup from 'yup';
import { useAuth } from 'components/Auth/hooks/useAuth';

export const useForm = () => {
  const intl = useIntl();
  const auth = useAuth();
  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!¿@(.)$=%^/&¡*-]).{8,}$/;

  const inputProps = { fullWidth: true };
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const fields = [
    Object.assign(
      {
        name: 'currentPassword',
        label: intl.formatMessage(messages.currentPasswordLabel),
        type: 'password',
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        name: 'newPassword',
        label: intl.formatMessage(messages.newPasswordLabel),
        type: 'password',
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        name: 'confirmPassword',
        label: intl.formatMessage(messages.confirmPasswordLabel),
        type: 'password',
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const validationSchema = yup.object({
    currentPassword: yup
      .string()
      .required(intl.formatMessage(messages.requiredMessage)),
    newPassword: yup
      .string()
      .required(intl.formatMessage(messages.requiredMessage))
      .matches(passwordRegex, intl.formatMessage(messages.formatPassword)),
    confirmPassword: yup
      .string()
      .required(intl.formatMessage(messages.requiredMessage))
      .matches(
        initialValues.newPassword,
        intl.formatMessage(messages.formatPassword)
      )
  });

  const handleSubmit = ({ currentPassword, newPassword }) => {
    const status = auth.changePassword({
      old_password: currentPassword,
      new_password: newPassword
    });

    return status;
  };

  const buttonSubmitProps = {
    variant: 'contained',
    size: 'large',
    color: 'primary',
    fullWidth: false,
    labelColor: ''
  };

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    fields,
    buttonSubmitProps,
    inputProps
  };
};
