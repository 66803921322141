import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'felx',
    padding: theme.spacing(2)
  },
  approverSubtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5)
  },
  submitButtonPdf: {
    display: 'flex',
    marginTop: theme.spacing(5)
  }
}));

export { useStyles };
