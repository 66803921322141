import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Container, Typography } from '@material-ui/core';
import { messages } from './LoginMessages';
import { useStyles } from './LoginStyles';
import { renderTextField } from '@octopy/react-form';
import { useForm } from './hooks/useForm';
import { Images } from 'assets';
import { useModal } from '@octopy/react-modal';
import { ForgotPasswordModalContent } from './ForgotPasswordModalContent';

const Login = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { formik, fields, passwordInputs } = useForm();
  const { handleOpenModal, handleCloseModal } = useModal();
  const forgotPasswordFormikRef = useRef();

  const handleOpenForgotPasswordModal = () => {
    handleOpenModal({
      title: (
        <>
          <img
            src={Images.forgotPassword}
            alt=""
            className={classes.forgotPasswordModalImage}
          />
          {intl.formatMessage(messages.forgotPasswordModalTitle)}
        </>
      ),
      body: (
        <ForgotPasswordModalContent
          email={formik.values.email}
          formikRef={forgotPasswordFormikRef}
        />
      ),
      actionButtons: (
        <>
          <Button
            size="large"
            color="primary"
            onClick={() => forgotPasswordFormikRef.current.submitForm()}
          >
            {intl.formatMessage(messages.send)}
          </Button>
          <Button size="large" onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
        <img src={Images.logoWithName} alt="" className={classes.logo} />

        {renderTextField({
          formik,
          field: fields.email,
          passwordInputs
        })}
        {renderTextField({
          formik,
          field: fields.password,
          passwordInputs
        })}

        <Button
          onClick={formik.handleSubmit}
          size="large"
          className={classes.submitButton}
          color="primary"
        >
          {intl.formatMessage(messages.login)}
        </Button>
        <input type="submit" hidden />

        <Typography
          onClick={handleOpenForgotPasswordModal}
          color="primary"
          variant="body2"
          className={classes.forgotPasswordButton}
        >
          {intl.formatMessage(messages.forgotPassword)}
        </Typography>
      </form>
    </Container>
  );
};

export { Login };
