import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.RolSection.title',
    defaultMessage: 'ROL'
  },
  subtitleRol: {
    id: 'components.RolSection.subtitleRol',
    defaultMessage: 'Rol asignado:'
  }
});
