import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 281,
    boxShadow: theme.customShadows.z2,
    opacity: 1,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    cursor: 'pointer',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: hexToRgba(theme.palette.primary.main, 0.05)
    }
  },
  cardHeader: {
    paddingBottom: theme.spacing(1.5)
  },

  cardHeaderContent: {
    maxWidth: '100%',
    overflow: 'hidden'
  },
  cardHeaderTitle: {
    ...theme.typography.subtitle2,
    textTransform: 'uppercase',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cardActions: {
    display: 'flex'
  },
  franquiciaCss: {
    fontSize: 13,
    letterSpacing: 4,
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  directorCss: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  empleadosCss: {
    width: 92,
    height: 22,
    font: 'normal normal normal 14px/16px Roboto',
    letterSpacing: 0,
    color: '#BFBFBF',
    opacity: 1,
    padding: '0px 0px 0px 8px'
  },
  empleadosActions: {
    flexGrow: 1
  },
  btnFranchises: {
    width: 25,
    height: 25,
    background: 'transparent 0% 0% no-repeat padding-box',
    opacity: 1
  },
  cardContent: {
    flexGrow: 1,
    paddingTop: 0,
    paddingBottom: theme.spacing(1)
  }
}));

export { useStyles };
