import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.AddRepresentativeForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  employeeIdLabel: {
    id: 'components.AddRepresentativeForm.employeeIdLabel',
    defaultMessage: 'Nombre del empleado'
  },
  employeeIdPlaceholder: {
    id: 'components.AddRepresentativeForm.employeeIdPlaceholder',
    defaultMessage: 'Ingrese nombre del empleado'
  },
  employee: {
    id: 'components.AddemployeeForm.employee',
    defaultMessage: 'Empleado'
  },
  accept: {
    id: 'components.AddRepresentativeForm.accept',
    defaultMessage: 'Agregar'
  }
});
