import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { messages } from './SpeakersSectionMessages';
import { useStyles } from './SpeakersSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const SpeakersSection = () => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    eventRequest: { speakers }
  } = useEventRequestDetails();

  if (!speakers?.length) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>

      <Grid container spacing={2}>
        {speakers.map(
          (speaker) =>
            speaker.rol === 'externo' && (
              <Grid item xs={12} className={classes.containerInfo}>
                <Typography
                  variant="body2"
                  color="secondary"
                  className={classes.rolDescription}
                >
                  {intl.formatMessage(messages.speakerExtern)}
                </Typography>
                <Typography variant="body2">{speaker.name}</Typography>
              </Grid>
            )
        )}
      </Grid>

      <Grid container spacing={2}>
        {speakers.map(
          (speaker) =>
            speaker.rol === 'interno' && (
              <Grid item xs={12} className={classes.containerInfo}>
                <Typography
                  variant="body2"
                  color="secondary"
                  className={classes.rolDescription}
                >
                  {intl.formatMessage(messages.speakerIntern)}
                </Typography>
                <Typography variant="body2">{speaker.name}</Typography>
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
};

SpeakersSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { SpeakersSection };
