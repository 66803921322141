import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.AddFranchisesDrawer.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  mustBePdfError: {
    id: 'components.AddFranchisesDrawer.mustBePdfError',
    defaultMessage: 'El archivo debe ser de tipo PDF'
  },
  documentSchedule: {
    id: 'components.ScheduleDrawer.documentSchedule',
    defaultMessage: 'Documento'
  },
  documentButtonSchedule: {
    id: 'components.ScheduleDrawer.documentButtonSchedule',
    defaultMessage: 'Seleccionar archivo PDF'
  },
  saveSchedule: {
    id: 'components.ScheduleDrawer.saveSchedule',
    defaultMessage: 'Guardar'
  },
  anErrorHasOccured: {
    id: 'components.ScheduleDrawer.anErrorHasOccured',
    defaultMessage: 'Ha ocurrido un error'
  },
  fileUploadError: {
    id: 'components.ScheduleDrawer.fileUploadError',
    defaultMessage: 'Ha ocurrido un error al intentar subir el archivo'
  },
  accept: {
    id: 'components.ScheduleDrawer.accept',
    defaultMessage: 'Aceptar'
  }
});
