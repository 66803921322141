import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Container, Box } from '@material-ui/core';
import { messages } from './MyAccountMessages';
import { useStyles } from './MyAccountStyles';
import { MyAccountForm } from './MyAccountForm';

const MyAccount = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container>
        <Typography color="primary" variant="h5" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
        <MyAccountForm />
      </Container>
    </div>
  );
};

MyAccount.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { MyAccount };
