import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  employee: {
    id: 'components.LoginForm.employee',
    defaultMessage: 'Empleado'
  },
  requiredError: {
    id: 'components.LoginForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  employeeNameLabel: {
    id: 'components.LoginForm.employeeNameLabel',
    defaultMessage: 'Nombre del empleado'
  },
  loginButton: {
    id: 'components.LoginForm.loginButton',
    defaultMessage: 'iniciar sesión ahora'
  },
  adminPassword: {
    id: 'components.LoginForm.adminPassword',
    defaultMessage: 'Contraseña de administrador'
  }
});
