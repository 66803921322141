import { get } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, Typography } from '@material-ui/core';
import { useModal } from '@octopy/react-modal';
import { messages } from './HcpsSectionMessages';
import { useStyles } from './HcpsSectionStyles';
import { useApi } from 'hooks/useApi';
import { EmployeeCard } from 'components/EmployeeCard';
import { useDrawer } from 'components/Drawer';
import { AddHcpForm } from './AddHcpForm';
import { EmailForm } from './AddHcpForm/EmailForm';

export const HcpsSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer, handleCloseDrawer } = useDrawer();
  const { handleOpenModal, handleCloseModal } = useModal();
  const [idToUpdate, setIdToUpdate] = useState(0);

  const [createHCP] = useApi({
    endpoint: '/guest/create',
    method: 'post'
  });

  const [updateHCP] = useApi({
    endpoint: '/guest/update',
    method: 'put'
  });

  const [getIdByOneKey] = useApi({
    endpoint: '/guest/get',
    method: 'get'
  });

  const handleUpdateEmail = async ({ email }, hcp) => {
    try {
      const response = await updateHCP({
        urlParams: `${hcp.id}`,
        body: { email }
      });

      if (response.headerResponse.status === 200) {
        handleCloseModal();
        handleCloseDrawer();

        return formik.setFieldValue('hcps', [
          ...formik.values.hcps,
          { ...hcp, email }
        ]);
      }
    } catch (error) {
      //
    }
  };

  const updateHCPRequired = (hcp) => {
    handleOpenModal({
      configProps: {
        maxWidth: 'xs',
        className: classes.modal
      },
      body: (
        <Box>
          <EmailForm onSubmit={handleUpdateEmail} data={hcp} />
        </Box>
      )
    });
  };

  const handleGetHCPByOneKeyId = async (oneKeyId) => {
    const hcp = {
      id: null,
      name: '',
      email: ''
    };

    try {
      const response = await getIdByOneKey({ urlParams: oneKeyId });

      if (get(response, 'headerResponse.status') === 200) {
        hcp.id = get(response, 'payload._id');
        hcp.name = get(response, 'payload.name');
        hcp.email = get(response, 'payload.email');
      }

      return hcp;
    } catch (error) {
      console.log('[error] An error occurred while fetch HCP');
    }
  };

  const handleRegisterHCP = async ({ name, email }) => {
    const hcp = {
      id: null,
      name: '',
      email: ''
    };

    try {
      const response = await createHCP({ body: { name, email } });

      if (get(response, 'headerResponse.status') === 200) {
        hcp.id = get(response, 'payload._id');
        hcp.name = get(response, 'payload.name');
        hcp.email = get(response, 'payload.email');
      }

      return hcp;
    } catch (error) {
      console.log('[error] An error occurred while register HCP');
    }
  };

  const handleOpenAddHcpForm = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.addHcpFormTitle),
      caption: intl.formatMessage(messages.addHcpFormCaption),
      body: (
        <AddHcpForm
          onSubmit={async (values) => {
            const hcp = { ...values };

            const addByOneKey = values.addBy === 'one-key-id';

            const handler = addByOneKey
              ? handleGetHCPByOneKeyId
              : handleRegisterHCP;

            const params = addByOneKey
              ? values.oneKeyId
              : {
                  name: `${values.name} ${values.paternalSurname} ${values.maternalSurname}`,
                  email: values.email
                };

            const aditionalData = await handler(params);

            if (aditionalData.id) {
              hcp.id = aditionalData.id;
              hcp.name = aditionalData.name;
              hcp.email = aditionalData.email;

              // Update HCP flow
              if (!hcp.email && addByOneKey) {
                setIdToUpdate(hcp.id);
                updateHCPRequired(hcp);
              } else {
                return formik.setFieldValue('hcps', [
                  ...formik.values.hcps,
                  hcp
                ]);
              }
            }
          }}
        />
      )
    });
  };

  return (
    <>
      <Box className={classes.header}>
        <Typography variant="subtitle" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>

        <Button color="primary" onClick={handleOpenAddHcpForm}>
          {intl.formatMessage(messages.addHcp)}
        </Button>
      </Box>

      {formik.touched.hcps && !!formik.errors.hcps && (
        <Typography variant="body2" color="error">
          {typeof formik.errors.hcps === 'string'
            ? formik.errors.hcps
            : 'Hay un error en los datos'}
        </Typography>
      )}

      {!!formik.values.hcps.length && (
        <Box className={classes.cardsContainer}>
          {formik.values.hcps.map((hcp, index) => (
            <EmployeeCard
              key={`${index}-hcp`}
              {...hcp}
              role={hcp.addBy === 'one-key-id' ? hcp.oneKeyId : undefined}
              onRemoveClick={() =>
                formik.setFieldValue('hcps', [
                  ...formik.values.hcps.filter(
                    (_, currentIndex) => currentIndex !== index
                  )
                ])
              }
            />
          ))}
        </Box>
      )}
    </>
  );
};
