import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useStyles } from './EventRequestDetailsStyles';
import { EventRequestDetailsActions } from './EventRequestDetailsActions';
import { useEventRequestDetails } from './hooks/useEventRequestDetails';
import { ReviewerEventRequestDetails } from './byUserRole/ReviewerEventRequestDetails/ReviewerEventRequestDetails';
import { ApproverEventRequestDetails } from './byUserRole/ApproverEventRequestDetails';
import { ObserverEventRequestDetails } from './byUserRole/ObserverEventRequestDetails';
import { useAuth } from 'components/Auth';
import { useParams } from 'react-router-dom';
import { objectEventRequest } from './helpers';
import { eventTypes } from 'components/EventRequestForm/helpers';

export const EventRequestDetails = () => {
  const classes = useStyles();
  const { eventId } = useParams();

  const {
    handleGetEventRequest,
    userRole,
    eventRequest,
    handleGetEventById,
    clear
  } = useEventRequestDetails();

  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      const event = await handleGetEventById(eventId);

      if (!!event) {
        handleGetEventRequest(objectEventRequest(event, user));
      }
    })();

    return () => clear();
  }, []);

  return (
    <Container className={classes.container}>
      {!!eventRequest && <EventRequestDetailsActions />}

      {userRole === 'reviewer' && <ReviewerEventRequestDetails />}
      {userRole === 'approver' && <ApproverEventRequestDetails />}
      {userRole === 'observer' && <ObserverEventRequestDetails />}
    </Container>
  );
};
