import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { messages } from './DistrictFormMessages';
import { useStyles } from './DistrictFormStyles';
import { useForm } from './hooks/useForm';
import { renderAutocomplete, renderTextField } from '@octopy/react-form';

export const DistrictForm = ({ initialValues, onSubmit, manager }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { fields, formik } = useForm({ initialValues, onSubmit, manager });

  return (
    <div>
      {renderTextField({
        formik,
        field: fields.name,
        inputProps: {}
      })}

      <Typography className={classes.managementSubtitle} variant="subtitle2">
        {intl.formatMessage(messages.management)}
      </Typography>

      {renderAutocomplete({
        formik,
        field: fields.managerId,
        inputProps: {}
      })}

      <Button
        onClick={formik.handleSubmit}
        className={classes.submitButton}
        color="primary"
      >
        {intl.formatMessage(messages.accept)}
      </Button>
    </div>
  );
};
