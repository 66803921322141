/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { useStyles } from './ConfigurationFormStyles';
import { Field } from './Field';
import { useFormik } from 'formik';
import { useFields } from './hooks/useFields';
import { messages } from './ConfigurationFormMessages';
import { useValidationSchema } from './hooks/useValidationSchema';

const defaultInitialValues = {
  anticipationDays: null,
  facilityRentalCost: null,
  materialsCost: null,
  minimumNumberOfGuestsForNovoEventAndNovoSupport: null,
  maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities: null,
  maximumFoodCostPerPerson: null,
  firstCloseNotification: null,
  secondCloseNotification: null,
  thirdCloseNotification: null
};

export const ConfigurationForm = ({
  initialValues = defaultInitialValues,
  formikRef,
  onSubmit
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const validationSchema = useValidationSchema();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => onSubmit(values)
  });

  useEffect(() => (formikRef.current = formik), []);

  const fields = useFields(formik);

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={3}>
        {fields.map(({ breakpoints, ...field }) => (
          <Grid className={classes.gridItem} item {...breakpoints}>
            <Field {...field} />
          </Grid>
        ))}
      </Grid>

      <div className={classes.buttonContainer}>
        <Button
          onClick={formik.submitForm}
          color="primary"
          className={classes.submitButton}
        >
          {intl.formatMessage(messages.accept)}
        </Button>
      </div>
    </div>
  );
};
