import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { messages } from './FranchisesMessages';
import { useStyles } from './FranchisesStyles';

import { FranchiseCard } from './components/FranchiseCard';

import { useDrawer } from 'components/Drawer';
import { AddFranchisesDrawer } from './components/AddFranchisesDrawer';
import { useLoader } from '@octopy/react-loader';
import { downloadFile } from 'utils/files';
import {
  Container,
  Typography,
  Button,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/InsertDriveFile';
import SearchIcon from '@material-ui/icons/Search';

import { useFranchises } from './hooks/useFranchises';

import { useDebouncer } from 'hooks';

import { filterArray } from './helpers';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';
import { NoData } from 'components/NoData';

/** Dummy de Franquicias
 * import { FranchisesMook } from './mook'; */

export const Franchises = () => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const { handleShowLoader } = useLoader();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const { handleOpenDrawer } = useDrawer();
  const {
    user: { role }
  } = useAuth();

  const [allFranchises, setAllFranchises] = useState();
  const [searchFranchises, setSearchFranchises] = useState('');

  const debouncedValue = useDebouncer(searchFranchises, 500);

  const {
    franchises,
    handleGetListFranchises,
    handleCreateFranchise,
    handleExportFranchise
  } = useFranchises();

  useEffect(() => {
    handleGetListFranchises();
  }, []);

  useEffect(() => {
    if (franchises) {
      if (debouncedValue !== '') {
        const result = filterArray(franchises, debouncedValue);

        setAllFranchises(result);
      } else {
        setAllFranchises(franchises);
      }
    }
  }, [franchises, debouncedValue]);

  const saveNewFranchises = async (values) => {
    const { director, franchisesName, medical } = values;

    return await handleCreateFranchise({
      name: franchisesName,
      directorId: director,
      medicalId: medical
    });
  };

  const handleExport = async () => {
    handleShowLoader(true);
    const franchiseResponse = await handleExportFranchise();
    const format = {
      ...franchiseResponse,
      name: intl.formatMessage(messages.franchiseExport),
      date: new Date()
    };

    downloadFile(format);
    handleShowLoader(false);
  };

  const handleOpenAddFranchises = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.newFranchises),
      caption: intl.formatMessage(messages.completeFranchisesDrawer),
      body: (
        <AddFranchisesDrawer
          initialValues={{ franchisesName: '', director: '', medical: '' }}
          edit={false}
          onSubmit={saveNewFranchises}
        />
      )
    });
  };

  return (
    <Container>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography
            component={xs ? 'div' : 'span'}
            color="primary"
            variant="h5"
            className={classes.title}
          >
            <FormattedMessage {...messages.franchisesBusinessUnit} />
          </Typography>
        </div>
        {role === roles.admin && (
          <Button
            color="primary"
            className={classes.button}
            endIcon={<AddCircleIcon />}
            onClick={handleOpenAddFranchises}
          >
            <FormattedMessage {...messages.newFranchises} />
          </Button>
        )}
      </div>
      <div className={classes.searchContainer}>
        <TextField
          id="searchFranchises"
          className={classes.searchTextField}
          placeholder={intl.formatMessage(messages.franchisesSearch)}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => setSearchFranchises(e.target.value)}
        />
        {role === roles.admin && (
          <Button
            onClick={() => handleExport()}
            className={classes.button}
            endIcon={<ExportIcon />}
          >
            <FormattedMessage {...messages.franciseseExport} />
          </Button>
        )}
      </div>

      <div className={classes.cardsContainer}>
        {allFranchises ? (
          allFranchises.length ? (
            allFranchises.map((franchise) => (
              <FranchiseCard franchise={franchise} />
            ))
          ) : (
            <NoData pl={2} pt={5} />
          )
        ) : (
          <NoData pl={2} pt={5} loading />
        )}
      </div>
    </Container>
  );
};
