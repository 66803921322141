import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1)
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(4)
  },
  containerForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.typography.pxToRem(850),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  boxButtonFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1)
  },
  button: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  boxAvatar: {
    width: '350px',
    [theme.breakpoints.down('sm')]: {
      width: '200px'
    }
  },
  userName: {
    paddingLeft: theme.spacing(1)
  },
  cancelButton: {
    marginLeft: theme.spacing(2)
  },
  boxButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export { useStyles };
