import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    marginBottom: theme.spacing(1)
  }
}));

export { useStyles };
