import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.ConfigurationForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  min0Error: {
    id: 'components.ConfigurationForm.min0Error',
    defaultMessage: 'Debe ser mayor o igual a 0'
  },
  min1Error: {
    id: 'components.ConfigurationForm.min1Error',
    defaultMessage: 'Debe ser mayor a 0'
  },
  anticipationDaysLabel: {
    id: 'components.ConfigurationForm.anticipationDaysLabel',
    defaultMessage: 'Días de anticipación para crear un evento (días hábiles)'
  },
  anticipationDaysPlaceholder: {
    id: 'components.ConfigurationForm.anticipationDaysPlaceholder',
    defaultMessage: '10'
  },
  facilityRentalCostLabel: {
    id: 'components.ConfigurationForm.facilityRentalCostLabel',
    defaultMessage: 'Costo de renta de instalaciones'
  },
  facilityRentalCostPlaceholder: {
    id: 'components.ConfigurationForm.facilityRentalCostPlaceholder',
    defaultMessage: '1200'
  },
  minimumNumberOfGuestsForNovoEventAndNovoSupportLabel: {
    id:
      'components.ConfigurationForm.minimumNumberOfGuestsForNovoEventAndNovoSupportLabel',
    defaultMessage: 'Número mínimo de invitados para novo evento y novo apoyos'
  },
  minimumNumberOfGuestsForNovoEventAndNovoSupportPlaceholder: {
    id:
      'components.ConfigurationForm.minimumNumberOfGuestsForNovoEventAndNovoSupportPlaceholder',
    defaultMessage: '4'
  },
  maximumNumberOfGuestsForFaceToFaceAndPharmacyActivitiesLabel: {
    id:
      'components.ConfigurationForm.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivitiesLabel',
    defaultMessage:
      'Número máximo de invitados para actividades de farmacia y face to face'
  },
  maximumNumberOfGuestsForFaceToFaceAndPharmacyActivitiesPlaceholder: {
    id:
      'components.ConfigurationForm.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivitiesPlaceholder',
    defaultMessage: '10'
  },
  materialsCostLabel: {
    id: 'components.ConfigurationForm.materialsCostLabel',
    defaultMessage: 'Costo de materiales por evento'
  },
  materialsCostPlaceholder: {
    id: 'components.ConfigurationForm.materialsCostPlaceholder',
    defaultMessage: '3000'
  },
  maximumFoodCostPerPersonLabel: {
    id: 'components.ConfigurationForm.maximumFoodCostPerPersonLabel',
    defaultMessage: 'Costo máximo de alimentos por persona'
  },
  maximumFoodCostPerPersonPlaceholder: {
    id: 'components.ConfigurationForm.maximumFoodCostPerPersonPlaceholder',
    defaultMessage: '120'
  },
  tipPercentLabel: {
    id: 'components.ConfigurationForm.tipPercentLabel',
    defaultMessage: 'Porcentaje de propina'
  },
  tipPercentPlaceholder: {
    id: 'components.ConfigurationForm.tipPercentPlaceholder',
    defaultMessage: '12'
  },
  days: {
    id: 'components.ConfigurationForm.days',
    defaultMessage: 'Días'
  },
  guests: {
    id: 'components.ConfigurationForm.guests',
    defaultMessage: 'Invitados'
  },
  accept: {
    id: 'components.ConfigurationForm.accept',
    defaultMessage: 'Aceptar'
  },
  firstCloseNotification: {
    id: 'components.ConfigurationForm.firstNotification',
    defaultMessage: '1ª notificación para cierre de evento pendiente'
  },
  secondCloseNotification: {
    id: 'components.ConfigurationForm.secondNotification',
    defaultMessage: '2ª notificación para cierre de solicitud pendiente'
  },
  thirdCloseNotification: {
    id: 'components.ConfigurationForm.thirdNotice',
    defaultMessage: '3ª notificación para cierre de evento pendiente'
  },
  firstCloseNotificationPlaceholder: {
    id: 'components.ConfigurationForm.firstNotificationPlaceholder',
    defaultMessage: '10'
  },
  secondCloseNotificationPlaceholder: {
    id: 'components.ConfigurationForm.secondNotificationPlaceholder',
    defaultMessage: '20'
  },
  thirdCloseNotificationplaceholder: {
    id: 'components.ConfigurationForm.thirdNoticePlaceholder',
    defaultMessage: '30'
  }
});
