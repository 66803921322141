import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useFields } from './hooks/useFields';
import { renderTextField } from '@octopy/react-form';
import { useIntl } from 'react-intl';
import { messages } from './ObjectiveSectionMessages';
import { useStyles } from './ObjectiveSectionStyles';

export const ObjectiveSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFields({ formik });

  const inputProps = {
    fullWidth: true
  };

  const fieldsMapper = (field, index) => (
    <Grid
      className={classes.gridItem}
      key={`objective-section-field-${index}`}
      item
      {...field.breakpoints}
    >
      {['number', 'text', 'textarea'].includes(field.type) &&
        renderTextField({ index, formik, field, inputProps })}
    </Grid>
  );

  return (
    <>
      <Box pb={2}>
        <Typography variant="subtitle" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {fields.map(fieldsMapper)}
      </Grid>
    </>
  );
};
