import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Container, Button, Box } from '@material-ui/core';
import { useStyles } from './EditEmployeeStyles';
import { EditEmployeeActions } from './EditEmployeeActions';
import { EmployeeForm } from 'components/Employees/EmployeeForm';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import { useEmployees } from '../hooks/useContext';
import { messages } from './EditEmployeeMessages';
import { mapEditEmployee } from '../helpers';

const EditEmployee = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { employeeId } = useParams();
  const formikRef = useRef();
  const { handleGetEmployee, handleUpdateEmployee } = useEmployees();

  const handleSubmit = async (values) =>
    await handleUpdateEmployee(values, employeeId);

  useEffect(() => {
    (async () => {
      const employee = await handleGetEmployee(employeeId);

      if (employee) {
        formikRef.current.setValues(mapEditEmployee(employee));
      }
    })();
  }, []);

  return (
    <div className={classes.container}>
      <Container>
        <EditEmployeeActions />
        <EmployeeForm handleSubmit={handleSubmit} formikRef={formikRef} />
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={() => formikRef.current.submitForm()}
          >
            {intl.formatMessage(messages.editButton)}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

EditEmployee.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { EditEmployee };
