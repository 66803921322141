import React from 'react';

import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { useStyles } from './SpeakerAddDrawerStyles';

import {
  renderTextField,
  renderRadioGroup,
  renderAutocomplete
} from '@octopy/react-form';

import { useForm } from './hooks/useForm';

import { Grid, Button } from '@material-ui/core';

import { messages } from './SpeakerAddDrawerMessages';

import { FilePicker } from 'components/FilePicker';

const SpeakerAddDrawer = ({ initialValues, onSubmit, edit }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { fields, formik } = useForm({ initialValues, onSubmit, edit });

  const fieldMapper = (field) => (
    <Grid key={`speaker-Fields-${field.name}`} item {...field.breakpoints}>
      {field.type === 'radioGroup' && renderRadioGroup({ formik, field })}

      {field.type === 'select' &&
        renderAutocomplete({
          formik,
          field,
          inputProps: {}
        })}

      {field.type === 'text' &&
        renderTextField({
          formik,
          field,
          inputProps: {}
        })}

      {field.type === 'application/pdf' && <FilePicker {...field} />}
    </Grid>
  );

  return (
    <div>
      <Grid container spacing={2}>
        {fields.map(fieldMapper)}
      </Grid>

      <Button
        onClick={formik.handleSubmit}
        className={classes.submitButton}
        color="primary"
      >
        {edit
          ? intl.formatMessage(messages.update)
          : intl.formatMessage(messages.save)}
      </Button>
    </div>
  );
};

SpeakerAddDrawer.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  edit: PropTypes.bool
};

export { SpeakerAddDrawer };
