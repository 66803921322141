export const getBlobUrl = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  } catch {
    return url;
  }
};

export const downloadFile = async ({ url, name }) => {
  const blobUrl = await getBlobUrl(url);

  const a = document.createElement('a');

  a.href = blobUrl;

  if (name) {
    a.download = name;
  }

  a.click();
};
