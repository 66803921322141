import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useEditEventRequest = (setType) => {
  const { eventId } = useParams();
  const { handleGetEventById } = useEventRequestDetails();
  const [eventRequest, setEventRequest] = useState();

  useEffect(() => {
    (async () => {
      if (eventId) {
        const eventRequest = await handleGetEventById(eventId);

        if (eventRequest) {
          setEventRequest(eventRequest);
          setType(eventRequest.event_type);
        }
      }
    })();
  }, [eventId]);

  return eventRequest;
};
