import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  accept: {
    id: 'components.ImportHcpsForm.accept',
    defaultMessage: 'Aceptar'
  },
  requiredError: {
    id: 'components.ImportHcpsForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  fileLabel: {
    id: 'components.ImportHcpsForm.fileLabel',
    defaultMessage: 'Seleccionar archivo'
  },
  filePlaceholder: {
    id: 'components.ImportHcpsForm.filePlaceholder',
    defaultMessage: 'Ingrese nombre del empleado'
  },
  mustBeExcelError: {
    id: 'components.ImportHcpsForm.mustBeExcelError',
    defaultMessage: 'El archivo debe ser de tipo Excel'
  },
  anErrorHasOccured: {
    id: 'components.ImportHcpsForm.anErrorHasOccured',
    defaultMessage: 'Ha ocurrido un error'
  },
  fileUploadError: {
    id: 'components.ImportHcpsForm.fileUploadError',
    defaultMessage: 'Ha ocurrido un error al intentar subir el archivo'
  }
});
