import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './SplashScreenStyles';
import { Images } from 'assets';

const SplashScreen = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [index, setIndex] = useState(0);

  const classes = useStyles(index);

  useEffect(() => {
    if (index < 6) {
      const timeout = setTimeout(() => setIndex((index) => index + 1), 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [index]);

  return (
    <div className={classes.wrapper}>
      <img
        src={Images.splashBackground}
        alt=""
        className={classes.backgroundImage}
      />

      <div className={classes.container}>
        <div className={classes.appLogoContainer}>
          <img src={Images.whiteIconLogo} alt="" />

          <span className={classes.bar} />

          <div>
            <Typography className={classes.appName} variant="h4">
              Novo Eventos y Novo Apoyos
            </Typography>
            <Typography className={classes.appCaption} variant="h6">
              PLATAFORMA DE GESTIÓN
            </Typography>
          </div>
        </div>
        <div className={classes.welcomeContainer}>
          <Typography variant={xs ? 'h3' : 'h2'} className={classes.welcome}>
            ¡Bienvenido!
          </Typography>
        </div>
      </div>
    </div>
  );
};

SplashScreen.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { SplashScreen };
