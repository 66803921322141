import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    boxShadow: theme.customShadows.z3,
    padding: theme.spacing(2),
    borderRadius: 10,
    height: '255px'
  },
  boxSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40px'
  },
  authorizationDate: {
    fontWeight: 600,
    color: theme.palette.common.black
  },
  titleOcupation: {
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    letterSpacing: 4,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  informationLabel: {
    color: theme.palette.grey[600],
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  containerInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  boxFirm: {
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    height: '110px',
    marginBottom: theme.spacing(1)
  },
  imageSign: {
    width: '100%',
    height: '100%'
  },
  clickable: {
    cursor: 'pointer'
  }
}));

export { useStyles };
