import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Typography } from '@material-ui/core';
import { messages } from './ConfigurationMessages';
import { useStyles } from './ConfigurationStyles';
import { ConfigurationForm } from './ConfigurationForm';
import { useConfiguration } from './hooks/useConfiguration';
import { useRef } from 'react';

export const Configuration = () => {
  const classes = useStyles();
  const formikRef = useRef();
  const {
    configuration,
    handleGetConfiguration,
    handleUpdateConfiguration
  } = useConfiguration();

  useEffect(() => {
    (async () => {
      if (configuration) {
        formikRef.current.setValues(configuration);
      }
    })();
  }, [configuration]);

  const handleSetValuesConfiguation = (values) => {
    const configValues = {
      min_assistant_novo_event:
        values.minimumNumberOfGuestsForNovoEventAndNovoSupport,
      max_assistant_face_to_face:
        values.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities,
      max_expense_food: values.maximumFoodCostPerPerson,
      first_close_reminder: values.firstCloseNotification,
      second_close_reminder: values.secondCloseNotification,
      third_close_reminder: values.thirdCloseNotification,
      advance_days_to_create_event: values.anticipationDays,
      rent_cost: values.facilityRentalCost,
      material_cost: values.materialsCost
    };

    handleUpdateConfiguration(configValues);
  };

  return (
    <Container className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography color="primary" variant="h5" className={classes.title}>
            <FormattedMessage {...messages.title} />
          </Typography>
        </div>
      </div>

      <ConfigurationForm
        formikRef={formikRef}
        onSubmit={handleSetValuesConfiguation}
      />
    </Container>
  );
};
