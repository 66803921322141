import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapper: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  textField: {
    marginBottom: theme.spacing(3),
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white
    }
  },
  submitButton: {
    marginBottom: theme.spacing(5)
  },
  forgotPasswordButton: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  forgotPasswordModalImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    width: 213,
    height: 216
  }
}));

export { useStyles };
