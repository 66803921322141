import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  actionEdit: {
    id: 'components.EmployeesTable.actionEdit',
    defaultMessage: 'Editar'
  },
  actionDelete: {
    id: 'components.EmployeesTable.actionDelete',
    defaultMessage: 'Eliminar'
  },
  employeeName: {
    id: 'components.EmployeesTable.employeeName',
    defaultMessage: 'NOMBRE DEL EMPLEADO'
  },
  email: {
    id: 'components.EmployeesTable.email',
    defaultMessage: 'CORREO ELECTRÓNICO'
  },
  city: {
    id: 'components.EmployeesTable.city',
    defaultMessage: 'CIUDAD'
  },
  franchise: {
    id: 'components.EmployeesTable.franchise',
    defaultMessage: 'FRANQUICIA'
  },
  role: {
    id: 'components.EmployeesTable.role',
    defaultMessage: 'ROL'
  },
  enabled: {
    id: 'components.EmployeesTable.enabled',
    defaultMessage: 'HABILITADO'
  },
  action: {
    id: 'components.EmployeesTable.action',
    defaultMessage: 'ACCIÓN'
  },
  filter: {
    id: 'components.EmployeesTable.filter',
    defaultMessage: 'filtrar tabla'
  },
  deleteMessage: {
    id: 'components.EmployeesTable.deleteMessage',
    defaultMessage: '¿Seguro que deseas eliminar estos elementos?'
  },
  deleteElementMessage: {
    id: 'components.EmployeesTable.deleteElementMessage',
    defaultMessage: '¿Seguro que deseas eliminar este elemento?'
  },
  deleteSubtitleElementMessage: {
    id: 'components.EmployeesTable.deleteSubtitleElementMessage',
    defaultMessage: 'Si borras este elemento no podrás recuperarlo'
  },
  deleteButton: {
    id: 'components.EmployeesTable.deleteButton',
    defaultMessage: 'eliminar'
  },
  cancelButton: {
    id: 'components.EmployeesTable.cancelButton',
    defaultMessage: 'cancelar'
  },
  businessUnitLabel: {
    id: 'components.EmployeesTable.businessUnitLabel',
    defaultMessage: 'Unidad de negocio'
  },
  roleLabel: {
    id: 'components.EmployeesTable.roleLabel',
    defaultMessage: 'Rol'
  },
  cityLabel: {
    id: 'components.EmployeesTable.cityLabel',
    defaultMessage: 'Ciudad'
  },
  filterButton: {
    id: 'components.EmployeesTable.filterButton',
    defaultMessage: 'Filtrar'
  },
  cancel: {
    id: 'components.EmployeesTable.cancel',
    defaultMessage: 'Cancelar'
  },
  searchLabel: {
    id: 'components.EmployeesTable.searchLabel',
    defaultMessage: 'Buscar por nombre'
  },
  searchPlaceholder: {
    id: 'components.EmployeesTable.searchPlaceholder',
    defaultMessage: 'Ej. Eduardo Castillo'
  }
});
