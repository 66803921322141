import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useFields } from './hooks/useFields';
import { renderAutocomplete } from '@octopy/react-form';
import { useIntl } from 'react-intl';
import { messages } from './DataSectionMessages';
import { useStyles } from './DataSectionStyles';
import { FilePicker } from 'components/FilePicker';

export const DataSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFields({ formik });

  const inputProps = {
    fullWidth: true
  };

  const fieldsMapper = (field, index) => (
    <Grid
      className={classes.gridItem}
      key={`data-section-field-${index}`}
      item
      {...field.breakpoints}
    >
      {field.type === 'select' &&
        renderAutocomplete({ index, formik, field, inputProps })}

      {field.type === 'application/pdf' && (
        <div className={classes.filePicker}>
          <Typography
            variant="subtitle2"
            className={field.disabled && classes.disabledText}
          >
            {field.label}
          </Typography>
          <FilePicker {...field} />
        </div>
      )}
    </Grid>
  );

  return (
    <>
      <Box pb={2}>
        <Typography variant="subtitle" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {fields.map(fieldsMapper)}
      </Grid>
    </>
  );
};
