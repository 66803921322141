import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleImageSection: {
    id: 'components.ImageProfileSection.titleImageSection',
    defaultMessage: 'IMAGEN DEL USUARIO'
  },
  descriptionImage: {
    id: 'components.ImageProfileSection.descriptionImage',
    defaultMessage:
      'Selecciona una foto desde tu ordenador, de lo contrario se mostrará una imagen preestablecida.'
  },
  selectImageLabel: {
    id: 'components.ImageProfileSection.selectImageLabel',
    defaultMessage: 'Seleccionar'
  }
});
