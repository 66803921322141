import { useApi } from 'hooks';

export const useApproversActions = (handleUpdateFranchise) => {
  const [addOrRemoveApprover] = useApi({
    endpoint: '/franchise/associate-approver',
    method: 'put'
  });

  const handleAddOrRemoveApprover = async (associate, franchise, approver) => {
    try {
      const body = { associate, franchise, approver };

      const {
        headerResponse: { status }
        //payload
      } = await addOrRemoveApprover({ body });

      if (status === 200) {
        handleUpdateFranchise(franchise);
      }
    } catch (error) {}
  };

  return { handleAddOrRemoveApprover };
};
