import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(4)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize
    }
  },
  subtitleBody: {
    marginTop: theme.spacing(3)
  },
  applyAndRevier: {
    marginTop: theme.spacing(5)
  },
  applyAndRevierBody: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  cardsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexWrap: 'wrap'
  }
}));

export { useStyles };
