/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useValidationSchema } from './hooks/useValidationSchema';
import { useInitialValues } from './hooks/useInitialValues';
import { useFormik } from 'formik';
import { Card } from '../Card';
import { RequirementsSection } from '../sections/RequirementsSection';
import { PlaceSection } from '../sections/PlaceSection';
import { FoodSection } from '../sections/FoodSection';
import { MaterialsSection } from '../sections/MaterialsSection';
import { SpeakersSection } from '../sections/SpeakersSection';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import { Button } from '@material-ui/core';
import { useDateAndTimeUnifier } from '../NovoEventForm/hooks/useDateAndTimeUnifier';
import { messages } from '../EventRequestFormMessages';
import { mapFormValues, mapEventRequest } from './helpers';
import { useEventRequests } from 'components/EventRequests/hooks/useEventRequests';

export const NovoSupportForm = ({ formikRef, eventRequest }) => {
  const { eventId } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal, handleCloseModal } = useModal();
  const {
    handleCreateEventSupportRequest,
    handleUpdateNovoEventSupportRequest
  } = useEventRequests();
  const [numberOfGuests, setNumberOfGuests] = useState();

  const validationSchema = useValidationSchema({ numberOfGuests });
  const initialValues = useInitialValues();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        handleShowLoader(true);

        const mappedValues = await mapFormValues(values);

        let done;

        if (eventId) {
          done = await handleUpdateNovoEventSupportRequest(
            eventId,
            mappedValues
          );
        } else {
          done = await handleCreateEventSupportRequest(mappedValues);
        }

        if (done) {
          history.push('/eventRequests');
        }
      } catch (error) {
        handleOpenModal({
          configProps: {
            maxWidth: 'xs'
          },
          title: intl.formatMessage(messages.anErrorHasOccured),
          body: intl.formatMessage(messages.fileUploadError),
          actionButtons: (
            <Button onClick={handleCloseModal}>
              {intl.formatMessage(messages.accept)}
            </Button>
          )
        });
      } finally {
        handleShowLoader(false);
      }
    }
  });

  useEffect(() => {
    setNumberOfGuests(formik.values.requirements.numberOfGuests);
  }, [formik.values.requirements.numberOfGuests]);

  useDateAndTimeUnifier(formik);

  useEffect(() => {
    if (eventRequest) {
      formik.setValues(mapEventRequest(eventRequest));
    }
  }, [eventRequest]);

  useEffect(() => {
    formikRef.current = formik;
  }, []);

  return (
    <div>
      <Card>
        <RequirementsSection formik={formik} />
      </Card>
      <Card mt={3}>
        <SpeakersSection formik={formik} />
      </Card>
      <Card mt={3}>
        <PlaceSection formik={formik} />
      </Card>
      <Card mt={3}>
        <FoodSection formik={formik} />
      </Card>
      <Card mt={3}>
        <MaterialsSection formik={formik} />
      </Card>
    </div>
  );
};
