import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  container: ({ touchable }) => ({
    padding: theme.spacing(2),
    marginLeft: theme.spacing(3),
    display: 'flex',
    boxShadow: theme.customShadows.z1,
    width: 344,
    maxWidth: '100%',
    poasition: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    marginBottom: theme.spacing(3),
    cursor: touchable ? 'pointer' : null
  }),
  removeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  avatarContainer: {
    marginRight: theme.spacing(2)
  },
  avatar: ({ color }) => ({
    height: 85,
    width: 85,
    border: `1px solid ${theme.palette.primary[600]}`,
    backgroundColor: theme.palette[color].main
  }),
  infoContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& > div': {
      width: '100%',
      height: '50%'
    }
  },
  name: {
    fontWeight: 'bold',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.removable': {
      marginRight: theme.spacing(2.5)
    }
  },
  roleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  role: {
    fontWeight: 'bold',
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontSize: 13,
    lineHeight: 1.25
  },
  approver: {
    lineHeight: 1.25,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 13,
    color: hexToRgba(theme.palette.text.primary, 0.2)
  }
}));

export { useStyles };
