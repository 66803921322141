import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: { paddingBottom: theme.spacing(2) },
  backButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  },
  title: {
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    letterSpacing: 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize
    }
  }
}));

export { useStyles };
