import { FranchisesContext } from './FranchisesContext';

import { useApi } from 'hooks';

import { mapFranchises } from './helpers';
import { useState } from 'react';

export const FranchisesProvider = ({ children }) => {
  const [franchises, setFranchises] = useState();

  const [getListFranchises] = useApi({
    endpoint: '/franchise/list',
    method: 'get'
  });
  const [exportFranchise] = useApi({
    endpoint: '/franchise/export',
    method: 'get'
  });
  const [createFranchise] = useApi({
    endpoint: '/franchise/create',
    method: 'post'
  });
  const [updateFranchise] = useApi({
    endpoint: '/franchise/update',
    method: 'put'
  });
  const [deleteFranchise] = useApi({
    endpoint: '/franchise/delete',
    method: 'put'
  });
  const [disableOrEnableFranchise] = useApi({
    endpoint: 'franchise/activate',
    method: 'put'
  });

  const handleCreateFranchise = async ({ name, directorId, medicalId }) => {
    try {
      const body = {
        name,
        director_id: directorId,
        medical_id: medicalId
      };

      const {
        headerResponse: { status }
      } = await createFranchise({ body });

      if (status === 200) {
        handleGetListFranchises();

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetListFranchises = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getListFranchises();

      if (status === 200) {
        const franchises = mapFranchises(payload);

        setFranchises(franchises);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteFranchise = async (franchiseId) => {
    try {
      const {
        headerResponse: { status }
      } = await deleteFranchise({ urlParams: franchiseId });

      if (status === 200) {
        handleGetListFranchises();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportFranchise = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await exportFranchise();

      if (status === 200) {
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisableOrEnableFranchise = async (franchiseId, active) => {
    try {
      const body = { active: active };
      const {
        headerResponse: { status }
      } = await disableOrEnableFranchise({ body, urlParams: franchiseId });

      if (status === 200) {
        handleGetListFranchises();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateFranchise = async ({
    name,
    directorId,
    medicalId,
    franchiseId
  }) => {
    try {
      const body = {
        name,
        director_id: directorId,
        medical_id: medicalId
      };
      const {
        headerResponse: { status }
      } = await updateFranchise({ body, urlParams: franchiseId });

      if (status === 200) {
        handleGetListFranchises();

        return true;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const contextValue = {
    franchises,
    handleGetListFranchises,
    handleDeleteFranchise,
    handleDisableOrEnableFranchise,
    handleCreateFranchise,
    handleUpdateFranchise,
    handleExportFranchise
  };

  return (
    <FranchisesContext.Provider value={contextValue}>
      {children}
    </FranchisesContext.Provider>
  );
};
