import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../MyAccountFormMessages';

export const useForm = () => {
  const intl = useIntl();

  const initialValues = {
    identifier: '',
    name: '',
    email: '',
    rfc: '',
    city: '',
    image: null
  };

  const validationSchema = yup.object({
    // id: yup.string().required(intl.formatMessage(messages.requiredError)),
    name: yup.string().required(intl.formatMessage(messages.requiredError)),
    email: yup
      .string()
      .email(intl.formatMessage(messages.emailError))
      .required(intl.formatMessage(messages.requiredError)),
    rfc: yup.string().required(intl.formatMessage(messages.requiredError)),
    city: yup.string().required(intl.formatMessage(messages.requiredError)),
    image: yup
      .object()
      .shape({
        url: yup.string()
      })
      .nullable()
      .required(intl.formatMessage(messages.requiredError))
  });

  return { initialValues, validationSchema };
};
