import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.EmailForm.title',
    defaultMessage: 'Agregar email'
  },
  formInstructions: {
    id:
      'components.EventRequestForm.sections.HcpsSection.AddHcpForm.EmailForm.formInstructions',
    defaultMessage:
      'El HCP aún no tiene un email asociado, es necesario que lo agregues antes de utilizarlo en una solicitud.'
  }
});
