import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    ...theme.typography.subtitle1,
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    letterSpacing: 4
  },
  loader: {
    marginRight: theme.spacing(2),
    marginTop: -4
  }
}));

export { useStyles };
