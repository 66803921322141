import { responseMapper } from 'utils/responseMapper';

const editTemplate = {
  city: 'city',
  email: 'email',
  rfc: 'rfc',
  identifier: 'identifier',
  name: 'name'
};

export const mapEditEmployee = (employeeData) => {
  const mappedEmployee = responseMapper({
    data: employeeData,
    template: editTemplate
  });

  if (employeeData.avatar) {
    mappedEmployee.image = {
      url: employeeData.avatar
    };
  }

  return mappedEmployee;
};
