import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { messages } from './RequiredApprovalsSectionMessages';
import { useStyles } from './RequiredApprovalsSectionStyles';
import { useRef } from 'react';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { useReviewerApprovers } from 'components/EventRequestDetails/byUserRole/ReviewerEventRequestDetails/hooks/useReviewerApprovers';
import { ApprovalCard } from './ApprovalCard';
import { useApprovers } from 'components/EventRequestDetails/byUserRole/ApproverEventRequestDetails/hooks/useApprovers';

const RequiredApprovalsSection = () => {
  const intl = useIntl();
  const classes = useStyles();
  const padRef = useRef({});

  const { userRole, user, allApprovers } = useEventRequestDetails();
  const approvs = useApprovers();

  const reviewerApprovers = useReviewerApprovers();

  if (userRole !== 'reviewer' && !allApprovers?.length) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      {userRole === 'reviewer' && (
        <Grid container spacing={2}>
          {reviewerApprovers.manager && (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <ApprovalCard
                item={reviewerApprovers.manager}
                padRef={padRef}
                role={userRole}
                ocupation={reviewerApprovers.manager.title}
                user={user}
              />
            </Grid>
          )}
          {reviewerApprovers.approvers.map((item) => (
            <Grid key={`approval-${item.id}`} item lg={3} md={4} sm={6} xs={12}>
              <ApprovalCard
                item={item}
                padRef={padRef}
                role={userRole}
                ocupation={item.title}
                user={user}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {userRole === 'approver' && (
        <Grid container spacing={2}>
          {allApprovers.map((item) => (
            <Grid key={`approval-${item.id}`} item lg={3} md={4} sm={6} xs={12}>
              <ApprovalCard
                item={item}
                padRef={padRef}
                role={userRole}
                ocupation={item.title}
                user={user}
                approve={approvs.handleAuthorizedSignature}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {userRole === 'observer' && (
        <Grid container spacing={2}>
          {allApprovers.map((item) => (
            <Grid key={`approval-${item.id}`} item lg={3} md={4} sm={6} xs={12}>
              <ApprovalCard
                item={item}
                padRef={padRef}
                role={userRole}
                ocupation={item.title}
                user={user}
                approve={null}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export { RequiredApprovalsSection };
