import { defineMessages } from 'react-intl';
import { eventTypes } from './helpers';

export const messages = defineMessages({
  newRequest: {
    id: 'components.EventRequestForm.newRequest',
    defaultMessage: 'Nueva solicitud'
  },
  editRequest: {
    id: 'components.EventRequestForm.editRequest',
    defaultMessage: 'Editar solicitud'
  },
  typeLabel: {
    id: 'components.EventRequestForm.typeLabel',
    defaultMessage: 'Tipo de evento'
  },
  typePlaceholder: {
    id: 'components.EventRequestForm.typePlaceholder',
    defaultMessage: 'Selecciona un tipo de evento'
  },
  enterRequest: {
    id: 'components.EventRequestForm.enterRequest',
    defaultMessage: 'Ingresar solicitud'
  },
  saveAsDraft: {
    id: 'components.EventRequestForm.saveAsDraft',
    defaultMessage: 'Guardar como borrador'
  },
  requiredError: {
    id: 'components.EventRequestForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  noMoreThanNumberError: {
    id: 'components.EventRequestForm.noMoreThanNumberError',
    defaultMessage: 'No pueden ser más de {number}'
  },
  anticipationDaysError: {
    id: 'components.EventRequestForm.anticipationDaysError',
    defaultMessage: 'Deben haber al menos {days} días de anticipación'
  },
  lessThanMoneyError: {
    id: 'components.EventRequestForm.lessThanMoneyError',
    defaultMessage: 'No puede pasar de $ {money}'
  },
  minimumOneSpeakerError: {
    id: 'components.EventRequestForm.minimumOneSpeakerError',
    defaultMessage: 'Al menos un speaker es requerido'
  },
  minimumOneHcpError: {
    id: 'components.EventRequestForm.minimumOneHcpError',
    defaultMessage: 'Al menos un HCP es requerido'
  },
  greaterThanError: {
    id: 'components.EventRequestForm.greaterThanError',
    defaultMessage: 'Debe ser mayor a {number}'
  },
  mustBePdfError: {
    id: 'components.AddFranchisesDrawer.mustBePdfError',
    defaultMessage: 'El archivo debe ser de tipo PDF'
  },
  anErrorHasOccured: {
    id: 'components.NovoEventForm.anErrorHasOccured',
    defaultMessage: 'Ha ocurrido un error'
  },
  fileUploadError: {
    id: 'components.NovoEventForm.fileUploadError',
    defaultMessage: 'Ha ocurrido un error al intentar subir el archivo'
  },
  accept: {
    id: 'components.NovoEventForm.accept',
    defaultMessage: 'Aceptar'
  },
  invalidEmailError: {
    id: 'components.NovoEventForm.invalidEmailError',
    defaultMessage: 'El formato de email no es válido.'
  },
  [eventTypes.novoEvent]: {
    id: `components.EventRequestForm.${eventTypes.novoEvent}`,
    defaultMessage: 'Novo evento'
  },
  [eventTypes.novoSupport]: {
    id: `components.EventRequestForm.${eventTypes.novoSupport}`,
    defaultMessage: 'Novo apoyo'
  },
  [eventTypes.pharmacyActivities]: {
    id: `components.EventRequestForm.${eventTypes.pharmacyActivities}`,
    defaultMessage: 'Actividades de farmacias'
  },
  [eventTypes.faceToFace]: {
    id: `components.EventRequestForm.${eventTypes.faceToFace}`,
    defaultMessage: 'Face to face'
  }
});
