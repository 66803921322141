import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  returnRequest: {
    id:
      'components.EventRequestDetails.byUserRole.ReviewerEventRequestDetails.returnRequest',
    defaultMessage: 'Devolver solicitud'
  },
  approveRequest: {
    id:
      'components.EventRequestDetails.byUserRole.ReviewerEventRequestDetails.approveRequest',
    defaultMessage: 'Aceptar solicitud'
  },
  accept: {
    id:
      'components.EventRequestDetails.byUserRole.ReviewerEventRequestDetails.accept',
    defaultMessage: 'Aceptar'
  },
  cancel: {
    id:
      'components.EventRequestDetails.byUserRole.ReviewerEventRequestDetails.cancel',
    defaultMessage: 'Cancelar'
  }
});
