import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestForm.sections.HcpsSection.title',
    defaultMessage: 'HCPS'
  },
  addHcp: {
    id: 'components.EventRequestForm.sections.HcpsSection.addHcp',
    defaultMessage: 'Agregar hcp'
  },
  addHcpFormTitle: {
    id: 'components.EventRequestForm.sections.HcpsSection.addHcpFormTitle',
    defaultMessage: 'Agregar hcp'
  },
  addHcpFormCaption: {
    id: 'components.EventRequestForm.sections.HcpsSection.addHcpFormCaption',
    defaultMessage:
      'Completa los siguientes campos para agregar un HCP a la solicitud face to face.'
  }
});
