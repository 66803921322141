import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: `${theme.zIndex.drawer} !important`
  },
  container: ({ color }) => ({
    backgroundColor:
      color === 'default'
        ? theme.palette.background.paper
        : theme.palette[color].main,
    width: 478,
    maxWidth: '100%',
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(7),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%'
    }
  }),
  title: ({ color }) => ({
    textTransform: 'uppercase',
    letterSpacing: '6px',
    marginTop: theme.spacing(8),
    fontWeight: 'bold',
    color:
      color === 'default'
        ? theme.palette.primary.main
        : theme.palette[color].contrastText
  }),
  caption: ({ color }) => ({
    marginTop: theme.spacing(1),
    color:
      color === 'default'
        ? theme.palette.text.primary
        : theme.palette[color].contrastText
  }),
  headerContainer: {
    marginBottom: theme.spacing(3.5),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: ({ color }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    color:
      color === 'default'
        ? theme.palette.color.primary
        : theme.palette[color].contrastText
  })
}));

export { useStyles };
