import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  anErrorHasOccured: {
    id: 'components.FaceToFaceForm.anErrorHasOccured',
    defaultMessage: 'Ha ocurrido un error'
  },
  accept: {
    id: 'components.FaceToFaceForm.accept',
    defaultMessage: 'Aceptar'
  }
});
