import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestForm.sections.RequirementsSection.title',
    defaultMessage: 'Requisitos'
  },
  dateLabel: {
    id: 'components.EventRequestForm.sections.RequirementsSection.dateLabel',
    defaultMessage: 'Fecha solicitada'
  },
  datePlaceholder: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.datePlaceholder',
    defaultMessage: 'Ingrese la fecha solicitada'
  },
  timeLabel: {
    id: 'components.EventRequestForm.sections.RequirementsSection.timeLabel',
    defaultMessage: 'Hora del evento'
  },
  timePlaceholder: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.timePlaceholder',
    defaultMessage: 'Ingrese la hora del evento'
  },
  scheduleIdLabel: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.scheduleIdLabel',
    defaultMessage: 'Agenda'
  },
  scheduleIdPlaceholder: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.scheduleIdPlaceholder',
    defaultMessage: 'Seleccione la agenda deseada'
  },
  numberOfGuestsLabel: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.numberOfGuestsLabel',
    defaultMessage: 'Número de invitados'
  },
  numberOfGuestsPlaceholder: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.numberOfGuestsPlaceholder',
    defaultMessage: 'Ingrese el número de invitados'
  },
  supportTypeLabel: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.supportTypeLabel',
    defaultMessage: 'Tipo de apoyo'
  },
  supportTypePlaceholder: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.supportTypePlaceholder',
    defaultMessage: 'Seleccione el tipo de apoyo'
  },
  contractLabel: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.contractLabel',
    defaultMessage: 'Adjuntar Contrato: '
  },
  contractButtonLabel: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.contractButtonLabel',
    defaultMessage: 'Seleccionar archivo PDF'
  },
  pharmacyNameLabel: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.pharmacyLabel',
    defaultMessage: 'Nombre de la farmacia'
  },
  pharmacyNamePlaceholder: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.pharmacyPlaceholder',
    defaultMessage: 'Ingrese el nombre de la farmacia'
  },
  placeNameLabel: {
    id: 'components.EventRequestForm.sections.RequirementsSection.placeLabel',
    defaultMessage: 'Nombre del lugar'
  },
  placeNamePlaceholder: {
    id:
      'components.EventRequestForm.sections.RequirementsSection.placePlaceholder',
    defaultMessage: 'Ingrese el nombre del lugar'
  }
});
