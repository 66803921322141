import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  removeModalTitle: {
    id: 'components.DistrictCard.removeModal.title',
    defaultMessage: 'Eliminar distrito'
  },
  removeModalBody1: {
    id: 'components.DistrictCard.removeModal.body1',
    defaultMessage: '¿Estas seguro de querer eliminar al distrito '
  },
  removeModalBody2: {
    id: 'components.DistrictCard.removeModal.body2',
    defaultMessage: '?'
  },
  disableModalTitle: {
    id: 'components.DistrictCard.disableModal.title',
    defaultMessage: 'Deshabilitar distrito'
  },
  disableModalBody1: {
    id: 'components.DistrictCard.disableModal.body1',
    defaultMessage: '¿Estás seguro de querer deshabilitar el distrito'
  },
  disableModalBody2: {
    id: 'components.DistrictCard.disableModal.body2',
    defaultMessage:
      '? Una vez deshabilitado este distrito no podrá realizar solicitudes de ningún tipo.'
  },
  enableModalTitle: {
    id: 'components.DistrictCard.enableModal.title',
    defaultMessage: 'Habilitar distrito'
  },
  enableModalBody1: {
    id: 'components.DistrictCard.enableModal.body1',
    defaultMessage: '¿Estas seguro de querer habilitar el distrito'
  },
  enableModalBody2: {
    id: 'components.DistrictCard.enableModal.body2',
    defaultMessage:
      '? Una vez habilitado este distrito podrá realizar solicitudes de todo tipo.'
  },
  accept: {
    id: 'components.DistrictCard.removeModal.accept',
    defaultMessage: 'Aceptar'
  },
  cancel: {
    id: 'components.DistrictCard.removeModal.cancel',
    defaultMessage: 'Cancelar'
  },
  manager: {
    id: 'components.DistrictCard.manager',
    defaultMessage: 'Gerente'
  },
  representatives: {
    id: 'components.DistrictCard.representatives',
    defaultMessage: 'representantes'
  },
  editDistrictDrawerTitle: {
    id: 'components.Franchise.editDistrictDrawerTitle',
    defaultMessage: 'Datos del distrito'
  }
});
