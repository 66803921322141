import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../ImportHcpsFormMessages';
import { useHcps } from 'components/Hcps/hooks/useHcps';
import { useLoader } from '@octopy/react-loader';
import { uploadFilePromise } from 'utils/aws';
import { useModal } from '@octopy/react-modal';
import { Button } from '@material-ui/core';
import { useDrawer } from 'components/Drawer';

export const useForm = () => {
  const intl = useIntl();
  const { handleImportList } = useHcps();
  const { handleShowLoader } = useLoader();
  const { handleCloseModal, handleOpenModal } = useModal();
  const { handleCloseDrawer } = useDrawer();

  const initialValues = {
    document: {
      url: null,
      file: null
    }
  };

  const validationSchema = yup.object({
    document: yup
      .object({
        file: yup
          .mixed()
          .nullable()
          .required(intl.formatMessage(messages.requiredError))
          .test(
            'fileType',
            intl.formatMessage(messages.mustBeExcelError),
            (file) =>
              !file?.type ||
              file?.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          )
      })
      .nullable()
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (_values) => {
      try {
        handleShowLoader(true);

        const value = {
          url: !!_values.document.file
            ? await uploadFilePromise(_values.document.file)
            : _values.document.url
        };

        const status = await handleImportList(value);

        if (status === 200) {
          handleCloseDrawer();
        }
      } catch (error) {
        console.log(error);

        handleOpenModal({
          configProps: {
            maxWidth: 'xs'
          },
          title: intl.formatMessage(messages.anErrorHasOccured),
          body: intl.formatMessage(messages.fileUploadError),
          actionButtons: (
            <Button onClick={handleCloseModal}>
              {intl.formatMessage(messages.accept)}
            </Button>
          )
        });
      } finally {
        handleShowLoader(false);
      }
    }
  });

  const fields = {
    file: {
      type: 'filePicker',
      name: 'document',
      buttonLabel: intl.formatMessage(messages.fileLabel),
      onChange: (value) => formik.setFieldValue('document', value),
      value: formik.values.document,
      error: formik.touched.document?.file && formik.errors.document?.file
    }
  };

  return { formik, fields };
};
