export const mapEventRequest = (eventRequest) => {
  const mappedEventRequest = {
    draft: false,
    requirements: {
      pharmacyName: eventRequest.place_name,
      date: eventRequest.event_date ? new Date(eventRequest.event_date) : null,
      time: eventRequest.event_date ? new Date(eventRequest.event_date) : null
    },
    address: {
      cityId: eventRequest.city,
      colonyId: eventRequest.neighborhood,
      externalNumber: eventRequest.external_number,
      internalNumber: eventRequest.internal_number,
      zipCode: eventRequest.zip_code
    },
    objective: eventRequest.activity_goal
  };

  return mappedEventRequest;
};

export const mapFormValues = async (values) => {
  const requirementsMappedValues = {
    event_date: values.requirements.date || undefined,
    place_name: values.requirements.pharmacyName || undefined
  };

  const addressMappedValues = {
    city: values.address.cityId || undefined,
    neighborhood: values.address.colonyId || undefined,
    external_number: `${values.address.externalNumber}` || undefined,
    internal_number: `${values.address.internalNumber}` || undefined,
    zip_code: values.address.zipCode || undefined
  };

  const mappedValues = {
    is_entered: !values.draft,
    ...requirementsMappedValues,
    ...addressMappedValues,
    activity_goal: values.objective || undefined
  };

  return mappedValues;
};
