import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  box: {
    boxShadow: theme.customShadows.z3,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      padding: theme.spacing(2)
    },
    padding: theme.spacing(3)
  },
  boxButton: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2)
    }
  }
}));

export { useStyles };
