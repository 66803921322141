import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box } from '@material-ui/core';
import { messages } from './ImageProfileSectionMessages';
import { useStyles } from './ImageProfileSectionStyles';
import { useFields } from './hooks/useFields';
import { renderImagePicker } from '@octopy/react-form';

const ImageProfileSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();

  const fields = useFields();

  return (
    <Box>
      <Typography variant="h6" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Typography className={classes.description}>
        {intl.formatMessage(messages.imageDescription)}
      </Typography>
      <Grid container spacing={1}>
        {fields.map((field, index) => (
          <Grid
            key={`profile-image-section-field-${index}`}
            item
            {...field.breakpoints}
          >
            {field.type === 'image' &&
              renderImagePicker({ index, formik, field })}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

ImageProfileSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ImageProfileSection };
