/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Paper,
  IconButton,
  Tooltip
} from '@material-ui/core';

import {
  PictureAsPdf as PdfIcon,
  VideoLibrary as VideoIcon,
  Image as ImageIcon,
  Description as DocumentIcon,
  Audiotrack as AudioIcon,
  Close as CloseIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon
} from '@material-ui/icons';

// import { messages } from './FilePreviewMessages';
import { useStyles } from './FilePreviewStyles';

const acceptedTypes = {
  image: ['.jpeg', '.jpg', '.png', '.raw'],
  video: ['.mp4', '.avi', '.mov'],
  audio: ['.mp3', '.m4a', '.wav', '.mpeg'],
  application: [
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.txt',
    '.ppt',
    '.pptx',
    '.odp'
  ]
};

const getPreview = (file) => {
  const result = {
    useNative: false,
    icon: null,
    preview: null
  };

  const fileType = file.type.split('/');
  const type = fileType[0]; // returns image video application
  const extension = fileType[1]; // returns .png .pdf

  switch (type) {
    case 'image':
      if (extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
        result.useNative = true;
        result.preview = URL.createObjectURL(file);
      } else {
        result.icon = ImageIcon;
      }

      break;
    case 'application':
      result.icon = extension === 'pdf' ? PdfIcon : DocumentIcon;

      break;
    case 'audio':
      result.icon = AudioIcon;

      break;

    case 'video':
      result.icon = VideoIcon;

      break;

    default:
      break;
  }

  return result;
};

const FilePreviewComponent = ({
  id,
  file,
  customName,
  type,
  onDelete,
  onChangeName,
  uploaded
}) => {
  const intl = useIntl();
  const { useNative, icon: PreviewIcon, preview } = getPreview(file);
  const classes = useStyles({ backgroundImage: preview });
  const windowWidth = window.innerWidth;

  const handleDelete = () => {
    onDelete && onDelete(type, id, file.name);
  };

  const handleChangeName = (value) => {
    onChangeName && onChangeName(id, value);
  };

  return (
    <Grid item xs={windowWidth < 400 ? 12 : 6} sm={4} md={4} lg={3}>
      <Paper className={classes.card}>
        {!uploaded && (
          <div className={`delete-button ${classes.deleteButton}`}>
            <IconButton onClick={handleDelete}>
              <Paper elevation={1} className={classes.deleteIcon}>
                <CancelIcon className="icon" fontSize="large" />
              </Paper>
            </IconButton>
          </div>
        )}

        <Box w={1} height={200} display="flex" justifyContent="center">
          {useNative ? (
            <div className={classes.img}></div>
          ) : (
            <Box
              w={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <PreviewIcon
                color="secondary"
                fontSize="large"
                className={classes.previewIcon}
              />
            </Box>
          )}
        </Box>

        <Box mt={1}>
          {type === 'media' && (
            <>
              <Typography component="p" className={classes.fileName}>
                {file.name}
              </Typography>
              <Typography component="p" className={classes.fileName}>
                {id}
              </Typography>
            </>
          )}

          {type === 'document' && (
            <TextField
              variant="outlined"
              size="small"
              label="Name"
              placeholder="Name"
              value={customName}
              onChange={(e) => handleChangeName(e.target.value)}
              disabled={uploaded}
            />
          )}
        </Box>

        <Paper className={classes.uploadStatusIcon}>
          <Tooltip
            title={
              uploaded
                ? 'El archivo se subió correctamente'
                : 'El archivo aun no se ha subido'
            }
            placement="right-start"
            arrow
          >
            <CheckCircleIcon
              className={uploaded ? classes.success : undefined}
              fontSize="small"
              color="disabled"
            />
          </Tooltip>
        </Paper>
      </Paper>
    </Grid>
  );
};

FilePreviewComponent.propTypes = {
  id: PropTypes.string,
  file: PropTypes.object,
  type: PropTypes.oneOf(['media', 'document']),
  onDelete: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  customName: PropTypes.string,
  uploaded: PropTypes.bool
};

const checkRender = (prevProps, nextProps) => {
  if (
    (prevProps.type === 'document' &&
      prevProps.customName !== nextProps.customName) ||
    prevProps.uploaded !== nextProps.uploaded
  ) {
    return false; // re-render
  }

  return true;
};

export const FilePreview = React.memo(FilePreviewComponent, checkRender);
