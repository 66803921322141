import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: {
    id: 'components.ScheduleCard.cancel',
    defaultMessage: 'Cancelar'
  },
  approved: {
    id: 'components.ScheduleCard.approved',
    defaultMessage: 'Aprobada'
  },
  disapproved: {
    id: 'components.ScheduleCard.disapproved',
    defaultMessage: 'Sin aprobación'
  },
  deleteScheduleTitle: {
    id: 'components.ScheduleCard.deleteScheduleTitle',
    defaultMessage: 'Eliminar agenda'
  },
  deleteScheduleQuestion: {
    id: 'components.ScheduleCard.deleteScheduleQuestion',
    defaultMessage: '¿Estas seguro de querer eliminar esta agenda?'
  },
  deleteSchedule: {
    id: 'components.ScheduleCard.deleteSchedule',
    defaultMessage: 'Eliminar'
  },
  editScheduleCard: {
    id: 'components.ScheduleCard.editSchedule',
    defaultMessage: 'Editar agenda'
  },
  editScheduleCarSubTitle: {
    id: 'components.ScheduleCard.editScheduleCarSubTitle',
    defaultMessage: 'Completa los siguientes campos para editar agenda'
  },
  approveScheduleModalTitle: {
    id: 'components.ScheduleCard.approveScheduleModalTitle',
    defaultMessage: 'Aprobar agenda'
  },
  approveScheduleModalBody: {
    id: 'components.ScheduleCard.approveScheduleModalBody',
    defaultMessage: '¿Estas seguro de querer aprobar esta agenda?'
  },
  approve: {
    id: 'components.ScheduleCard.approve',
    defaultMessage: 'Aprobar'
  }
});
