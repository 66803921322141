import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleCard: {
    id: 'components.RequirementsSection.titleCard',
    defaultMessage: 'REQUISITOS'
  },
  pharmacyName: {
    id: 'components.RequirementsSection.pharmacyName',
    defaultMessage: 'Nombre de la farmacia:'
  },
  nameLocation: {
    id: 'components.RequirementsSection.nameLocation',
    defaultMessage: 'Nombre del lugar:'
  },
  dateRequested: {
    id: 'components.RequirementsSection.dateRequested',
    defaultMessage: 'Fecha solicitada:'
  },
  hourEvent: {
    id: 'components.RequirementsSection.hourEvent',
    defaultMessage: 'Hora del evento:'
  }
});
