import { useApi } from 'hooks';
import { useState } from 'react';
import { EventRequestsContext } from './EventRequestsContext';
import { mapEventRequests } from './helper';
import { useCreateActions } from './hooks/useCreateActions';
import { useUpdateActions } from './hooks/useUpdateActions';

export const EventRequestsProvider = ({ children }) => {
  const [eventRequests, setEventRequests] = useState();
  const [pendingEventRequests, setPendingEventRequests] = useState();
  const [selectedEventCard, setSelectedEventCard] = useState({});

  const [getEventRequests] = useApi({
    endpoint: '/request/list',
    method: 'get'
  });
  const [getPendingEventRequests] = useApi({
    endpoint: '/request/pending',
    method: 'get'
  });

  const handleGetEventRequests = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getEventRequests();

      if (status === 200) {
        setEventRequests(mapEventRequests(payload));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPendingEventRequests = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getPendingEventRequests();

      if (status === 200) {
        setPendingEventRequests(mapEventRequests(payload));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clear = () => {
    setEventRequests(undefined);
    setPendingEventRequests(undefined);
  };

  const handleSelectEvent = (event) => {
    setSelectedEventCard(event);
  };

  const createActions = useCreateActions();
  const updateActions = useUpdateActions();

  const contextValue = {
    eventRequests,
    pendingEventRequests,
    selectedEventCard,
    handleGetEventRequests,
    handleGetPendingEventRequests,
    clear,
    handleSelectEvent,
    ...createActions,
    ...updateActions
  };

  return (
    <EventRequestsContext.Provider value={contextValue}>
      {children}
    </EventRequestsContext.Provider>
  );
};
