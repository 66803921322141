import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Typography,
  Box,
  Switch,
  IconButton,
  Button,
  Tooltip
} from '@material-ui/core';
import { messages } from './ApprovalCardMessages';
import { useStyles } from './ApprovalCardStyles';
import CloseIcon from '@material-ui/icons/Close';
import { useModal } from '@octopy/react-modal';
import { SignModal } from 'components/EventRequestDetails/SignModal';
import { useReviewerApprovers } from 'components/EventRequestDetails/byUserRole/ReviewerEventRequestDetails/hooks/useReviewerApprovers';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const ApprovalCard = ({ item, padRef, role, ocupation, user, approve }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal, handleCloseModal } = useModal();
  const reviewerApprovers = useReviewerApprovers();
  const { userCanApprove } = useEventRequestDetails();

  const handleSubmitSign = (id) => {
    if (padRef.current.toData().length !== 0 && approve !== null) {
      approve(id, padRef.current.getTrimmedCanvas().toDataURL('image/png'));

      handleCloseModal();
    }
  };

  const signApproval = () => {
    handleOpenModal({
      title: (
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6" className={classes.titleModal}>
              {intl.formatMessage(messages.approveRequest)}
            </Typography>
          </Box>
        </Box>
      ),
      body: <SignModal padRef={padRef} />,
      actionButtons: (
        <Button color="primary" onClick={() => handleSubmitSign(item.id)}>
          {intl.formatMessage(messages.aceptButton)}
        </Button>
      )
    });
  };

  const signable = item.id === user.employeeId && userCanApprove;

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxSwitch}>
        <Typography
          color="secondary"
          variant="subtitle2"
          className={classes.titleOcupation}
        >
          {item.title}
        </Typography>

        {ocupation !== 'Gerente'
          ? role === 'reviewer' && (
              <Switch
                value={item.enabled}
                onChange={() => reviewerApprovers.switchApprover(item.id)}
              />
            )
          : null}
      </Box>

      {
        <Tooltip
          placement="right"
          title={signable ? intl.formatMessage(messages.approveRequest) : ''}
        >
          <Box
            className={classes.boxFirm}
            style={{ cursor: signable ? 'pointer' : 'not-allowed' }}
            onClick={signable ? signApproval : undefined}
          >
            {role !== 'reviewer'
              ? !!item.url && (
                  <img
                    className={classes.imageSign}
                    src={item.url}
                    alt="sign"
                  />
                )
              : null}
          </Box>
        </Tooltip>
      }
      <Box display="flex">
        {item.identifier && (
          <Typography className={classes.name} variant="subtitle1">
            {`${item.identifier} - `}
          </Typography>
        )}
        <Typography className={classes.name} variant="subtitle1">
          {item.name}
        </Typography>
      </Box>

      {!!item.date && (
        <Box>
          <Typography className={classes.authorizationDate} variant="caption">
            {intl.formatMessage(messages.authorizationDate)}
          </Typography>
          <Typography variant="caption">{item.date}</Typography>
        </Box>
      )}
    </Box>
  );
};

ApprovalCard.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ApprovalCard };
