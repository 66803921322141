import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.AddApproverForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  franchiseIdLabel: {
    id: 'components.AddApproverForm.franchiseIdLabel',
    defaultMessage: 'Franquicia / Unidad de negocio'
  },
  franchiseIdPlaceholder: {
    id: 'components.AddApproverForm.franchiseIdPlaceholder',
    defaultMessage: 'Ingrese nombre de la franquicia'
  },
  approverIdLabel: {
    id: 'components.AddApproverForm.approverIdLabel',
    defaultMessage: 'Nombre del empleado'
  },
  approverIdPlaceholder: {
    id: 'components.AddApproverForm.approverIdPlaceholder',
    defaultMessage: 'Ingrese nombre del empleado'
  },
  approver: {
    id: 'components.AddApproverForm.approver',
    defaultMessage: 'Aprobador'
  },
  accept: {
    id: 'components.AddApproverForm.accept',
    defaultMessage: 'Aceptar'
  }
});
