import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.SpeakerAddDrawer.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  speaker: {
    id: 'components.SpeakerAddDrawer.speaker',
    defaultMessage: 'Speaker'
  },
  save: {
    id: 'components.SpeakerAddDrawer.save',
    defaultMessage: 'Guardar'
  },
  update: {
    id: 'components.SpeakerAddDrawer.update',
    defaultMessage: 'Actualizar'
  },
  addByInternal: {
    id: 'components.SpeakerAddDrawer.addByOneKeyIdLabel',
    defaultMessage: 'Interno'
  },
  addByExternal: {
    id: 'components.SpeakerAddDrawer.addByNameLabel',
    defaultMessage: 'Externo'
  },
  employee: {
    id: 'components.SpeakerAddDrawer.employee',
    defaultMessage: 'Empleado'
  },
  contractNumber: {
    id: 'components.SpeakerAddDrawer.contractNumber',
    defaultMessage: 'Número de contrato'
  },
  supplierNumber: {
    id: 'components.SpeakerAddDrawer.supplierNumber',
    defaultMessage: 'Número de proveedor'
  },
  email: {
    id: 'components.SpeakerAddDrawer.email',
    defaultMessage: 'Correo electrónico'
  },
  rfc: {
    id: 'components.SpeakerAddDrawer.rfc',
    defaultMessage: 'RFC'
  },
  cedula: {
    id: 'components.SpeakerAddDrawer.cedula',
    defaultMessage: 'Cédula profesional'
  },
  city: {
    id: 'components.SpeakerAddDrawer.city',
    defaultMessage: 'Ciudad de origen'
  },
  fiscalAddress: {
    id: 'components.SpeakerAddDrawer.fiscalAddress',
    defaultMessage: 'Dirección fiscal'
  },
  hcpTier: {
    id: 'components.SpeakerAddDrawer.hcpTier',
    defaultMessage: 'HCP Tier'
  },
  speciality: {
    id: 'components.SpeakerAddDrawer.speciality',
    defaultMessage: 'Especialidad'
  },
  curriculum: {
    id: 'components.SpeakerAddDrawer.curriculum ',
    defaultMessage: 'Currículum (PDF)'
  },
  rfcError: {
    id: 'components.SpeakerAddDrawer.rfcError',
    defaultMessage: 'Ingresa un RFC valido'
  },
  emailError: {
    id: 'components.SpeakerAddDrawer.emailError',
    defaultMessage: 'Ingresa un correo electrónico valido'
  },
  anErrorHasOccured: {
    id: 'components.SpeakerAddDrawer.anErrorHasOccured',
    defaultMessage: 'Ha ocurrido un error'
  },
  fileUploadError: {
    id: 'components.SpeakerAddDrawer.fileUploadError',
    defaultMessage: 'Ha ocurrido un error al intentar subir el archivo'
  },
  accept: {
    id: 'components.SpeakerAddDrawer.accept',
    defaultMessage: 'Aceptar'
  },
  numberError: {
    id: 'components.SpeakerAddDrawer.numberError',
    defaultMessage: 'Ingresa número valido'
  },
  mustBePdfError: {
    id: 'components.SpeakerAddDrawer.mustBePdfError',
    defaultMessage: 'El archivo debe ser de tipo PDF'
  }
});
