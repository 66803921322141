import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box, IconButton } from '@material-ui/core';
import { messages } from './NewEmployeeActionsMessages';
import { useStyles } from './NewEmployeeActionsStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

const NewEmployeeActions = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box display="flex" alignItems="center" className={classes.container}>
      <IconButton
        edge="start"
        onClick={() => history.push('/employees')}
        className={classes.backButton}
      >
        <ArrowBackIcon color="secondary" />
      </IconButton>
      <Typography variant="h5" color="primary" className={classes.title}>
        {intl.formatMessage(messages.newEmployee)}
      </Typography>
    </Box>
  );
};

NewEmployeeActions.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { NewEmployeeActions };
