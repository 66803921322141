import React from 'react';

import { useIntl } from 'react-intl';

import { renderAutocomplete, renderDatePicker } from '@octopy/react-form';

import { Button } from '@material-ui/core';

import { messages } from './EventSearchMessages';
import { useStyles } from './EventSearchStyles';

import { useForm } from './hooks/useForm';
import { useFields } from './hooks/useFields';

export const EventSearch = ({ onSubmit }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { formik } = useForm({ onSubmit });
  const { datesFields, searchFields } = useFields(formik);

  return (
    <div className={classes.container}>
      {!!datesFields.length && (
        <div className={classes.actionsDatePickers}>
          {datesFields?.map((field) =>
            renderDatePicker({
              formik,
              field,
              inputProps: {}
            })
          )}
        </div>
      )}

      <div className={classes.container}>
        {searchFields?.map((field) =>
          renderAutocomplete({
            formik,
            field,
            inputProps: {}
          })
        )}
      </div>

      <Button
        onClick={formik.handleSubmit}
        className={classes.buttonEventSearch}
        size="large"
        color="primary"
      >
        {intl.formatMessage(messages.eventSearch)}
      </Button>
    </div>
  );
};
