export const mapHcpslist = (list) => {
  const mappedList = list.map((hcp) => ({
    oneKeyId: hcp.onekey_id,
    name: hcp.name,
    license: hcp.licence_number,
    specialty: hcp.speciality1,
    therapys: hcp.therapy,
    email: hcp.email ? hcp.email : ''
  }));

  return mappedList;
};
