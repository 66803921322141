import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid, CardActionArea } from '@material-ui/core';
import { messages } from './AttachmentsSectionMessages';
import { useStyles } from './AttachmentsSectionStyles';
import { usePdfViewer } from 'components/PdfViewer/hooks/usePdfViewer';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const AttachmentsSection = () => {
  const intl = useIntl();
  const classes = useStyles();
  const pdfViewer = usePdfViewer();

  const {
    eventRequest: { documents }
  } = useEventRequestDetails();

  const documentsWithName = documents.map((item) => {
    if (item.type === 'food') {
      return {
        ...item,
        name: intl.formatMessage(messages.foodFileName)
      };
    }

    if (item.type === 'contract') {
      return {
        ...item,
        name: intl.formatMessage(messages.contractFileName)
      };
    }

    if (item.type === 'material') {
      return {
        ...item,
        name: intl.formatMessage(messages.materialFileName)
      };
    }

    if (item.type === 'place') {
      return {
        ...item,
        name: intl.formatMessage(messages.placeFileName)
      };
    }

    if (item.type === 'speaker') {
      return {
        ...item,
        name: `${intl.formatMessage(messages.speakerFileName)} ${
          item.speakerName
        }`
      };
    }
  });

  if (!documentsWithName?.length) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Grid container spacing={5}>
        {documentsWithName.map((item) => (
          <Grid item md={4} sm={6} xs={12}>
            <CardActionArea onClick={() => pdfViewer.handleOpen(item)}>
              <Box className={classes.boxPdf}>
                <PdfIcon className={classes.icon} />
                <Typography className={classes.namePdf}>{item.name}</Typography>
              </Box>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

AttachmentsSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { AttachmentsSection };
