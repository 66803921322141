/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { messages } from './ForgotPasswordModalContentMessages';
import { renderTextField } from '@octopy/react-form';
import { useForm } from './hooks/useForm';

const ForgotPasswordModalContent = ({ email, formikRef }) => {
  const intl = useIntl();
  const { formik, fields } = useForm({ initialValues: { email } });

  useEffect(() => {
    formikRef.current = formik;
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="body2">
        {intl.formatMessage(messages.description)}
      </Typography>
      {renderTextField({ formik, field: fields.email })}
      <input type="submit" hidden />
    </form>
  );
};

export { ForgotPasswordModalContent };
