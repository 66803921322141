import PropTypes from 'prop-types';
import { useStyles } from './EmployeesTableStyles';
import { Table } from '@octopy/react-table';
import React, { useState, useEffect } from 'react';
import { Avatar, Box, Typography, Switch } from '@material-ui/core';
import { useTable } from './hooks';
import { useEmployees } from '../hooks/useContext';

const EmployeesTable = () => {
  const classes = useStyles();
  const table = useTable();
  const [updatedList, setUpdatedList] = useState([]);
  const { employeesList, handleGetList, handleActiveEmployee } = useEmployees();

  useEffect(() => {
    handleGetList();
  }, []);

  useEffect(() => {
    getList(employeesList);
  }, [employeesList]);

  const handleChange = (event, id) => {
    handleActiveEmployee(event.target.checked, id);
  };

  const getList = async (employees) => {
    const updateList = employeesList.map((user) => ({
      ...user,
      city: user.city?.nombre,
      name: (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          className={classes.boxAvatar}
        >
          {user.image === null ? (
            <Avatar>{`${user.name[0]}${
              user.name[user.name.search(' ') + 1]
            }`}</Avatar>
          ) : (
            <Avatar
              alt={user.name}
              src={user.image}
              className={classes.avatar}
            />
          )}

          <Typography className={classes.userName}>{user.name}</Typography>
        </Box>
      ),
      enabled: (
        <Box>
          <Switch
            checked={user.enabled}
            onChange={(e) => handleChange(e, user.id)}
            name="checked"
          />
        </Box>
      )
    }));

    setUpdatedList(updateList);
  };

  return (
    <>
      <div className={classes.container}>
        <Table
          data={updatedList}
          columns={table.tableHead}
          actions={table.actions}
          configProps={{
            selectedOptions: {
              checkboxHidden: true
            }
          }}
        />
      </div>
    </>
  );
};

EmployeesTable.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { EmployeesTable };
