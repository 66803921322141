import React, { useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography
} from '@material-ui/core';
import { messages } from './AssociateSpeakerFormMessages';
import { useStyles } from './AssociateSpeakerFormStyles';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import { useForm } from './hooks/useForm';
import { renderRadioGroup } from '@octopy/react-form';
import { Card } from 'components/EventRequestForm/Card';
import { DataSection } from './sections/DataSection';
import { LogisticsSection } from './sections/LogisticsSection';
import { useCatalogues } from 'components/Catalogues';

export const AssociateSpeakerForm = ({
  match: {
    params: { eventId: eventRequestId }
  }
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { formik, typeField } = useForm();
  const { handleGetCatalogueRequest } = useCatalogues();

  useEffect(() => {
    handleGetCatalogueRequest();
  }, []);

  if (!location.state) {
    return <Redirect to="/eventRequests" />;
  }

  return (
    <Container className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <IconButton
            edge="start"
            color="secondary"
            className={classes.backButton}
            onClick={() =>
              history.replace(
                `/eventRequests/${eventRequestId}`,
                location.state
              )
            }
          >
            <BackIcon />
          </IconButton>
          <Typography color="primary" variant="h5" className={classes.title}>
            <Typography color="primary" variant="h5" className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
          </Typography>
        </div>
      </div>

      <Box className={classes.typeRadioGroupContainer}>
        <Typography className={classes.typeLabel} variant="subtitle2">
          {intl.formatMessage(messages.typeLabel)}
        </Typography>
        {renderRadioGroup({ formik, field: typeField })}
      </Box>

      <Card mt={3}>
        <DataSection formik={formik} />
      </Card>
      <Card mt={3}>
        <LogisticsSection formik={formik} />
      </Card>

      <Button
        onClick={formik.submitForm}
        className={classes.submitButton}
        color="primary"
      >
        {intl.formatMessage(messages.submit)}
      </Button>
    </Container>
  );
};
