import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { messages } from './EventLocationSectionMessages';
import { useStyles } from './EventLocationSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const EventLocationSection = () => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    eventRequest: { location }
  } = useEventRequestDetails();

  let count = 0;

  for (let prop of [
    'city',
    'colony',
    'externNumber',
    'internNumber',
    'postalCode',
    'typeLocation',
    'nameLocation',
    'facilitiesCost'
  ]) {
    count += +!!location[prop];
  }

  if (!count) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Grid container spacing={3}>
        {location.city && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.city)}
            </Typography>
            <Typography>{location.city}</Typography>
          </Grid>
        )}
        {location.colony && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.colony)}
            </Typography>
            <Typography>{location.colony}</Typography>
          </Grid>
        )}
        {location.externNumber && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.externNumber)}
            </Typography>
            <Typography>{location.externNumber}</Typography>
          </Grid>
        )}
        {location.internNumber && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.internNumber)}
            </Typography>
            <Typography>{location.internNumber}</Typography>
          </Grid>
        )}

        {location.postalCode && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.postalCode)}
            </Typography>
            <Typography>{location.postalCode}</Typography>
          </Grid>
        )}
        {location.typeLocation && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.typeLocation)}
            </Typography>
            <Typography>{location.typeLocation}</Typography>
          </Grid>
        )}

        {location.nameLocation && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.nameLocation)}
            </Typography>
            <Typography>{location.nameLocation}</Typography>
          </Grid>
        )}
        {location.facilitiesCost && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.cost)}
            </Typography>
            <Typography>{intl.formatMessage(messages.costState)}</Typography>
          </Grid>
        )}
        {location.facilitiesCost && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.cost)}
            </Typography>
            <Typography>{location.facilitiesCost}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

EventLocationSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { EventLocationSection };
