import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: 4,
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    paddingBottom: theme.spacing(1)
  },
  subtitle: {
    fontWeight: 500
  }
}));

export { useStyles };
