/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Box, Grid, Button } from '@material-ui/core';
import { messages } from './EmployeeFormMessages';
import { useStyles } from './EmployeeFormStyles';
import { useForm } from './hooks/useForm';
import { DataSection } from './DataSection';
import { ImageProfileSection } from './ImageProfileSection';
import { useFormik } from 'formik';
import { mapCreateEmployee } from 'components/Employees/helpers';
import { uploadFilePromise } from 'utils/aws';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import { useHistory } from 'react-router-dom';

const EmployeeForm = ({ formikRef, handleSubmit }) => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const { initialValues, validationSchema } = useForm();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal, handleCloseModal } = useModal();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (_values) => {
      try {
        handleShowLoader(true);

        const values = {
          ..._values,
          image: {
            url:
              _values.image === null
                ? null
                : !!_values.image.file
                ? await uploadFilePromise(_values.image.file)
                : _values.image.url
          }
        };

        const done = await handleSubmit(mapCreateEmployee(values));

        if (done) {
          history.push('/employees');
        }
      } catch (error) {
        console.log(error);

        handleOpenModal({
          configProps: {
            maxWidth: 'xs'
          },
          title: intl.formatMessage(messages.anErrorHasOccured),
          body: intl.formatMessage(messages.fileUploadError),
          actionButtons: (
            <Button onClick={handleCloseModal}>
              {intl.formatMessage(messages.accept)}
            </Button>
          )
        });
      } finally {
        handleShowLoader(false);
      }
    }
  });

  useEffect(() => (formikRef.current = formik), []);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item md={6} xs={12}>
        <Box className={classes.formDataContainer}>
          <DataSection formik={formik} />
        </Box>
      </Grid>

      <Grid item md={6} xs={12}>
        <Box className={classes.formImageContainer}>
          <ImageProfileSection formik={formik} />
        </Box>
      </Grid>
    </Grid>
  );
};

EmployeeForm.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { EmployeeForm };
