import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.AttachmentsSection.title',
    defaultMessage: 'DOCUMENTOS ADJUNTADOS'
  },
  foodFileName: {
    id: 'components.AttachmentsSection.foodFileName',
    defaultMessage: 'Cotización de la comida'
  },
  materialFileName: {
    id: 'components.AttachmentsSection.materialFileName',
    defaultMessage: 'Cotización de los materiales'
  },
  placeFileName: {
    id: 'components.AttachmentsSection.placeFileName',
    defaultMessage: 'Cotización del lugar'
  },
  speakerFileName: {
    id: 'components.AttachmentsSection.speakerFileName',
    defaultMessage: 'Cotización de'
  },
  contractFileName: {
    id: 'components.AttachmentsSection.contractFileName',
    defaultMessage: 'Contrato'
  }
});
