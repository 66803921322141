import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button } from '@material-ui/core';
import { messages } from './ApproverEventRequestDetailsMessages';
import { useStyles } from './ApproverEventRequestDetailsStyles';
import { ApproversContext } from './ApproverEventRequestContext';
import { NovoEvent } from 'components/EventRequestDetails/byEventType/NovoEvent';
import { NovoSupport } from 'components/EventRequestDetails/byEventType/NovoSupport';
import { PharmacyActivity } from 'components/EventRequestDetails/byEventType/PharmacyActivity';
import { FaceToFace } from 'components/EventRequestDetails/byEventType/FaceToFace';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { eventTypes } from 'components/EventRequestForm/helpers';
import { useModal } from '@octopy/react-modal';
import { CommentForm } from 'components/EventRequestDetails/CommentForm';
import { useHistory, useParams } from 'react-router-dom';
import { uploadFilePromise } from 'utils/aws';
import { base64ToFile } from 'utils/base64';
import { useLoader } from '@octopy/react-loader';

const ApproverEventRequestDetails = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { eventId } = useParams();
  const commentFormikRef = useRef();
  const history = useHistory();
  const { eventRequest, allApprovers } = useEventRequestDetails();
  const {
    approverActions: {
      handleReturnEventRequest,
      handleApproveEventRequest,
      handleRejectEventRequest
    }
  } = useEventRequestDetails();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleShowLoader } = useLoader();

  const [approverList, setApproverList] = useState(allApprovers);

  const uploadImage = async (imgBase64) => {
    try {
      handleShowLoader(true);
      const file = await base64ToFile(imgBase64);
      const signatureUrl = await uploadFilePromise(file);

      if (signatureUrl.indexOf('aws') === -1) {
        throw intl.formatMessage(messages.imgUploadSignError);
      }

      return signatureUrl;
    } catch (error) {
      handleOpenModal({
        title: error,
        configProps: {
          maxWidth: 'xs'
        }
      });
    } finally {
      handleShowLoader(false);
    }
  };

  const handleAuthorizedSignature = async (approverId, signatureBase64) => {
    try {
      const signatureUrl = await uploadImage(signatureBase64);

      if (signatureUrl.indexOf('aws') !== -1) {
        const done = await handleApproveEventRequest(eventId, signatureUrl);

        if (done) {
          history.push('/eventRequests');
        }

        // if (done.success) {
        //   setApproverList((approverList) =>
        //     approverList.map((approver) =>
        //       approver._id === approverId
        //         ? { ...approver, url: done.signatureUrl }
        //         : approver
        //     )
        //   );
        // }
      }
    } catch (error) {}
  };

  const approversContextValue = { approverList, handleAuthorizedSignature };

  const getFormMessage = (type) =>
    type === 'return' ? messages.returnRequest : messages.rejectRequest;

  const handleCommentFormFunction = async (eventId, comment, type) => {
    let success = false;

    if (type === 'return') {
      success = await handleReturnEventRequest(eventId, comment);
    } else if (type === 'reject') {
      success = await handleRejectEventRequest(eventId, comment);
    }

    if (success) {
      history.push('/eventRequests');
    }
  };

  const openCommentModal = (type = '') => {
    handleOpenModal({
      title: intl.formatMessage(getFormMessage(type)),
      body: (
        <CommentForm
          type={type}
          onSubmit={({ comment }) =>
            handleCommentFormFunction(eventId, comment, type)
          }
          formikRef={commentFormikRef}
        />
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              commentFormikRef.current.submitForm();
            }}
          >
            {intl.formatMessage(getFormMessage(type))}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.btnCancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  return (
    <ApproversContext.Provider value={approversContextValue}>
      {eventRequest.type === eventTypes.novoSupport && <NovoSupport />}
      {eventRequest.type === eventTypes.novoEvent && <NovoEvent />}
      {eventRequest.type === eventTypes.pharmacyActivities && (
        <PharmacyActivity />
      )}
      {eventRequest.type === eventTypes.faceToFace && <FaceToFace />}

      <Box pt={4} display="flex" justifyContent="flex-end">
        <Button color="primary" onClick={() => openCommentModal('return')}>
          {intl.formatMessage(messages.returnRequest)}
        </Button>
        <Button
          color="primary"
          className={classes.leftSpace}
          onClick={() => openCommentModal('reject')}
        >
          {intl.formatMessage(messages.rejectRequest)}
        </Button>
      </Box>
    </ApproversContext.Provider>
  );
};

export { ApproverEventRequestDetails };
