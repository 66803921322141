import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: { paddingBottom: theme.spacing(2) },
  title: {
    letterSpacing: 6,
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground
  },
  backButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  }
}));

export { useStyles };
