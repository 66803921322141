import React, { useState, useMemo } from 'react';
import { AuthContext } from './AuthContext';
import { getItem, removeItem, setItem } from 'utils/persistentStorage';
import { useApi } from 'hooks';
import { mapLoginResponsePayload } from './helpers';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getItem('session')?.user);

  const [_login] = useApi({
    endpoint: '/user/signin',
    method: 'post'
  });

  const [_fakeSignin] = useApi({
    endpoint: '/user/fake-signin',
    method: 'post'
  });

  const [_logout] = useApi({
    endpoint: '/user/signout',
    method: 'post'
  });

  const [_changePassword] = useApi({
    endpoint: '/user/change-password',
    method: 'put'
  });

  const [_updateMyAccount] = useApi({
    endpoint: '/employee/update',
    method: 'put'
  });

  const login = async (values) => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await _login({ body: values });

      if (status === 200) {
        const { user, token } = mapLoginResponsePayload(payload);

        const session = {
          token,
          user
        };

        setItem('session', session);
        setUser(session.user);
      }
    } catch {}
  };

  const logout = async () => {
    try {
      await _logout();
    } catch {
    } finally {
      removeItem('session');
      removeItem('adminSession');
      setUser(null);
    }
  };

  const fakeSignin = async (values) => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await _fakeSignin({ body: values });

      if (status === 200) {
        const { user, token } = mapLoginResponsePayload(payload);

        const session = {
          token,
          user
        };

        const adminSession = getItem('session');

        setItem('adminSession', adminSession);

        setItem('session', session);
        setUser(session.user);

        return true;
      }
    } catch {}
  };

  const returnToAdmin = () => {
    try {
      const adminSession = getItem('adminSession');

      setItem('session', adminSession);
      removeItem('adminSession');
      setUser(adminSession.user);
    } catch (error) {}
  };

  const changePassword = async (values) => {
    try {
      const {
        headerResponse: { status }
      } = await _changePassword({ body: values });

      if (status === 200) {
        return true;
      }
    } catch {}
  };

  const updateMyAccount = async (id, values) => {
    try {
      const {
        headerResponse: { status }
      } = await _updateMyAccount({ body: values, urlParams: id });

      if (status === 200) {
        return true;
      }
    } catch {}
  };

  const contextValue = useMemo(
    () => ({
      user,
      login,
      logout,
      fakeSignin,
      returnToAdmin,
      changePassword,
      updateMyAccount
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
