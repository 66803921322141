import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Card,
  IconButton,
  Switch,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/EditRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import { messages } from './DistrictCardMessages';
import { useStyles } from './DistrictCardStyles';
import { useModal } from '@octopy/react-modal';
import { useDrawer } from 'components/Drawer';
import { DistrictForm } from '../DistrictForm';
import { useParams } from 'react-router-dom';
import { useFranchise } from 'components/Franchise/hooks/useFranchise';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';

export const DistrictCard = ({ district }) => {
  const { franchiseId } = useParams();
  const {
    user: { role }
  } = useAuth();
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleOpenDrawer } = useDrawer();
  const {
    handleEditDistrict,
    handleEnableDistrict,
    handleRemoveDistrict
  } = useFranchise();

  const handleOpenEnableDistrictModal = (event) => {
    event.stopPropagation();

    handleOpenModal({
      title: intl.formatMessage(messages.enableModalTitle),
      body: (
        <>
          {intl.formatMessage(messages.enableModalBody1)}{' '}
          <span className={classes.modalImportantText}>{district.name}</span>
          {intl.formatMessage(messages.enableModalBody2)}
        </>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleEnableDistrict(franchiseId, {
                districtId: district.id,
                active: true
              });
            }}
          >
            {intl.formatMessage(messages.accept)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleOpenDisableDistrictModal = (event) => {
    event.stopPropagation();

    handleOpenModal({
      title: intl.formatMessage(messages.disableModalTitle),
      body: (
        <>
          {intl.formatMessage(messages.disableModalBody1)}{' '}
          <span className={classes.modalImportantText}>{district.name}</span>
          {intl.formatMessage(messages.disableModalBody2)}
        </>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleEnableDistrict(franchiseId, {
                districtId: district.id,
                active: false
              });
            }}
          >
            {intl.formatMessage(messages.accept)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleOpenRemoveDistrictModal = (event) => {
    event.stopPropagation();

    handleOpenModal({
      title: intl.formatMessage(messages.removeModalTitle),
      body: (
        <>
          {intl.formatMessage(messages.removeModalBody1)}{' '}
          <span className={classes.modalImportantText}>{district.name}</span>
          {intl.formatMessage(messages.removeModalBody2)}
        </>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleRemoveDistrict(franchiseId, district.id);
            }}
          >
            {intl.formatMessage(messages.accept)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleOpenEditForm = (event) => {
    event.stopPropagation();

    console.log('district', district);

    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.editDistrictDrawerTitle),
      body: (
        <DistrictForm
          onSubmit={async (values) =>
            await handleEditDistrict(franchiseId, values)
          }
          initialValues={{
            id: district.id,
            managerId: district.manager.id,
            name: district.name
          }}
          manager={{
            label: district.manager.name,
            value: district.manager.id
          }}
        />
      )
    });
  };

  return (
    <Card
      onClick={() => history.push(`/franchises/districts/${district.id}`)}
      className={classes.container}
    >
      <div className={classes.header}>
        <Typography
          component="div"
          className={classes.name}
          variant="caption"
          color="secondary"
        >
          {district.name}
        </Typography>

        {role === roles.admin && (
          <Switch
            onClick={
              district.active
                ? handleOpenDisableDistrictModal
                : handleOpenEnableDistrictModal
            }
            checked={district.active}
            edge="end"
            className={classes.activeSwitch}
            color="default"
          />
        )}
      </div>
      <div className={classes.body}>
        <Typography className={classes.managerName} variant="body2">
          {intl.formatMessage(messages.manager)}: {district.manager.name}
        </Typography>
      </div>
      <div className={classes.footer}>
        <Typography className={classes.representativeQuantity} variant="body2">
          {district.representativeQuantity}{' '}
          {intl.formatMessage(messages.representatives)}
        </Typography>
        {role === roles.admin && (
          <div>
            <IconButton size="small" onClick={handleOpenEditForm}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleOpenRemoveDistrictModal}
              size="small"
              edge="end"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    </Card>
  );
};
