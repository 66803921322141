import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    boxShadow: theme.customShadows.z3,
    padding: theme.spacing(3),
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    letterSpacing: 6,
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase'
  },
  comment: {
    marginTop: theme.spacing(1)
  }
}));

export { useStyles };
