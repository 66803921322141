import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import AddIcon from '@material-ui/icons/AddCircle';
import { messages } from './FranchiseMessages';
import { useStyles } from './FranchiseStyles';
import { useDrawer } from 'components/Drawer';
import { useFranchise } from './hooks/useFranchise';
import { AddApproverForm } from './AddApproverForm';
import { Approvers } from './Approvers';
import { Districts } from './Districts';
import { Schedules } from './Schedules';
import { useParams } from 'react-router-dom';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';

const Franchise = () => {
  const { franchiseId } = useParams();
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const { handleOpenDrawer } = useDrawer();
  const {
    franchise,
    handleGetFranchise,
    clear,
    handleAddOrRemoveApprover
  } = useFranchise();
  const {
    user: { role, medical, ...user }
  } = useAuth();

  const [innerRole, setInnerRole] = useState();

  useEffect(() => {
    if (franchise?.director?.id === user.employeeId) {
      setInnerRole(roles.director);
    } else if (franchise?.medical?.id === user.employeeId) {
      setInnerRole(roles.medical);
    } else {
      setInnerRole(role);
    }
  }, [franchise]);

  useEffect(() => {
    handleGetFranchise(franchiseId);

    return () => clear();
  }, []);

  const handleSubmitApproverForm = ({ approverId }) =>
    handleAddOrRemoveApprover(true, franchiseId, approverId);

  const handleOpenAddApproverForm = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.addApproverFormTitle),
      caption: intl.formatMessage(messages.addApproverFormCaption),
      body: <AddApproverForm onSubmit={handleSubmitApproverForm} />
    });
  };

  return (
    <Container>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          {(innerRole === roles.admin || medical) && (
            <IconButton
              edge="start"
              color="secondary"
              className={classes.backButton}
              onClick={() => history.push('/franchises')}
            >
              <BackIcon />
            </IconButton>
          )}
          <Typography color="primary" variant="h5" className={classes.title}>
            {intl.formatMessage(messages.franchise)}
            {!xs && <> - </>}
            {franchise?.name ? (
              <Typography
                component={xs ? 'div' : 'span'}
                color="secondary"
                variant="h5"
                className={classes.title}
              >
                {franchise.name}
              </Typography>
            ) : (
              <Box mt={0.5} ml={1}>
                <CircularProgress color="secondary" size={20} />
              </Box>
            )}
          </Typography>
        </div>
        {[roles.admin].includes(innerRole) && (
          <Button
            onClick={handleOpenAddApproverForm}
            endIcon={<AddIcon />}
            color="primary"
            className={classes.associateApproverButton}
          >
            {intl.formatMessage(messages.associateApprover)}
          </Button>
        )}
      </div>

      {[roles.admin, roles.director].includes(innerRole) && (
        <>
          <Approvers />

          <div className={classes.spacer} />
        </>
      )}

      {[roles.admin, roles.director].includes(innerRole) && (
        <>
          <Districts />

          <div className={classes.spacer} />
        </>
      )}

      <Schedules medical={innerRole === roles.medical} />
    </Container>
  );
};

export { Franchise };
