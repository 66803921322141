import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1
  },
  link: {
    display: 'flex',
    color: 'inherit',
    textDecoration: 'none'
  },
  bar: {
    height: '55%',
    borderRadius: theme.shape.borderRadius,
    width: 4,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  appNameContainer: {
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1)
    },
    '& > *': {
      color: theme.palette.primary.contrastText
    }
  },
  avatarWrapper: {
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1)
    },
    borderRadius: '50%'
  },
  avatar: {
    [theme.breakpoints.up('lg')]: {
      width: 50,
      height: 50
    }
  },
  avatarBadge: {
    border: `3px solid ${theme.palette.common.white}`,
    width: 15,
    height: 15,
    borderRadius: '50%',
    transform: 'scale(1)',
    [theme.breakpoints.down('md')]: {
      border: `2px solid ${theme.palette.common.white}`,
      width: 11,
      height: 11
    }
  },
  iconAdmin: {
    color: '#fff'
  }
}));

export { useStyles };
