import { EmployeesContext } from './EmployeesContext';
import { useApi } from 'hooks';
import { useState } from 'react';
import { mapEmployeesList } from './helpers';

export const EmployeesProvider = ({ children }) => {
  const [employeesList, setEmployeesList] = useState([]);

  const [createEmployee] = useApi({
    endpoint: 'employee/create',
    method: 'post'
  });
  const [updateEmployee] = useApi({
    endpoint: 'employee/update',
    method: 'put'
  });
  const [deleteEmployee] = useApi({
    endpoint: 'employee/delete',
    method: 'put'
  });
  const [enabledEmployee] = useApi({
    endpoint: 'employee/activate',
    method: 'put'
  });

  const [getEmployee] = useApi({ endpoint: 'employee/get', method: 'get' });
  const [exportList] = useApi({ endpoint: 'employee/export', method: 'get' });
  const [getList] = useApi({ endpoint: 'employee/list', method: 'get' });

  const handleGetList = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getList();

      if (status === 200) {
        setEmployeesList(mapEmployeesList(payload));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateEmployee = async (values) => {
    try {
      const {
        headerResponse: { status }
      } = await createEmployee({ body: values });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateEmployee = async (values, id) => {
    try {
      const {
        headerResponse: { status }
      } = await updateEmployee({ body: values, urlParams: id });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteEmployee = async (value, id) => {
    try {
      const body = { deleted: !value };

      const {
        headerResponse: { status }
      } = await deleteEmployee({ body, urlParams: id });

      if (status === 200) {
        handleGetList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleActiveEmployee = async (values, id) => {
    try {
      const body = { enabled: values };
      const {
        headerResponse: { status }
      } = await enabledEmployee({ body, urlParams: id });

      if (status === 200) {
        handleGetList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEmployee = async (id) => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getEmployee({ urlParams: id });

      if (status === 200) {
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportEmployee = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await exportList();

      if (status === 200) {
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contextValue = {
    handleGetList,
    employeesList,
    handleActiveEmployee,
    handleCreateEmployee,
    handleDeleteEmployee,
    handleExportEmployee,
    handleGetEmployee,
    handleUpdateEmployee
  };

  return (
    <EmployeesContext.Provider value={contextValue}>
      {children}
    </EmployeesContext.Provider>
  );
};
