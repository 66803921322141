import { useIntl } from 'react-intl';
import { messages } from '../ObjectiveSectionMessages';

export const useFields = () => {
  const intl = useIntl();

  return [
    {
      type: 'textarea',
      name: 'objective',
      label: intl.formatMessage(messages.objectiveLabel),
      placeholder: intl.formatMessage(messages.objectivePlaceholder),
      breakpoints: {
        xs: 12
      }
    }
  ];
};
