export const mapEventRequest = (eventRequest) => {
  const mappedEventRequest = {
    draft: false,
    requirements: {
      date: eventRequest.event_date ? new Date(eventRequest.event_date) : null,
      time: eventRequest.event_date ? new Date(eventRequest.event_date) : null,
      placeName: eventRequest.place_name
    },
    hcps: eventRequest.hcps.map((hcp) => ({
      id: hcp.hcps_id,
      name: hcp.name,
      oneKeyId: hcp.one_key_id,
      addBy: !!hcp.one_key_id ? 'one-key-id' : 'name'
    })),
    objective: eventRequest.activity_goal
  };

  return mappedEventRequest;
};

export const mapFormValues = async (values) => {
  const data = {
    event_date: values.requirements.date || undefined,
    place_name: values.requirements.placeName || undefined,
    hcps: values.hcps.map((hcp) => hcp.id),
    activity_goal: values.objective || undefined,
    is_entered: !values.draft
  };

  return data;
};
