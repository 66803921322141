import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useFields } from './hooks/useFields';
import {
  renderAutocomplete,
  renderDatePicker,
  renderTextField,
  renderTimePicker
} from '@octopy/react-form';
import { useIntl } from 'react-intl';
import { messages } from './RequirementsSectionMessages';
import { useStyles } from './RequirementsSectionStyles';
import { FilePicker } from 'components/FilePicker';

const RequirementsSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFields({ formik });

  const extraInputProps = {
    fullWidth: true
  };

  const fieldsMapper = ({ inputProps, ...field }, index) => (
    <Grid
      key={`requirements-section-field-${index}`}
      item
      {...field.breakpoints}
      className={classes.gridItem}
    >
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}
      {['number', 'text'].includes(field.type) &&
        renderTextField({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}
      {field.type === 'datePicker' &&
        renderDatePicker({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}
      {field.type === 'timePicker' &&
        renderTimePicker({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}

      {field.type === 'application/pdf' && (
        <div className={classes.filePicker}>
          <Typography variant="subtitle2">{field.label}</Typography>
          <FilePicker {...field} />
        </div>
      )}
    </Grid>
  );

  return (
    <>
      <Box pb={2}>
        <Typography variant="subtitle" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {fields.map(fieldsMapper)}
      </Grid>
    </>
  );
};

export { RequirementsSection };
