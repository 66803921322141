import isEmpty from 'lodash/isEmpty';

/**
 * Funcion que devuleve en formato long la fecha
 * @param {string} date Thu May 19 2022 14:28:03 GMT-0500 (hora de verano central)
 * @returns 19 de mayo del 2022
 */
export const formatDateLong = (date) => {
  const dateIn = new Date(date);

  return dateIn.toLocaleDateString('es-Mx', {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });
};

/**
 * Funtion que saca el promedio de participantes que aprovaron
 * @param {object}} event
 * @returns int
 */
export const countApprovers = (event) => {
  let participanTotal = 0,
    participantTrue = 0;

  if (!isEmpty(event.franchise.director)) {
    participanTotal = participanTotal + 1;

    if (event.franchise.director.approved === true) {
      participantTrue = participantTrue + 1;
    }
  }

  if (!isEmpty(event.franchise.medical)) {
    participanTotal = participanTotal + 1;

    if (event.franchise.medical.approved === true) {
      participantTrue = participantTrue + 1;
    }
  }

  if (!isEmpty(event.district.manager)) {
    participanTotal = participanTotal + 1;

    if (event.district.manager.approved === true) {
      participantTrue = participantTrue + 1;
    }
  }

  for (let index = 0; index < event.approvers.length; index++) {
    if (event.approvers[index].approved) {
      participantTrue = participantTrue + 1;
    }
  }

  participanTotal = participanTotal + event.approvers.length;

  return (participantTrue * 100) / participanTotal;
};

/**
 * Funcion que valida cuales eventos estan en revision y/o en aprobación
 * @param {array} events
 * @returns { eventsReview, eventsApproved }
 */
export const eventsToPassAndApprovedEvents = (events) => {
  let eventsReview = [],
    eventsApproved = [];

  try {
    for (let index = 0; index < events.length; index++) {
      if (events[index].status === 'en revision') {
        eventsReview = eventsReview.concat(events[index]);
      }

      if (events[index].status !== 'en revision') {
        eventsApproved = eventsApproved.concat(events[index]);
      }
    }
  } catch {}

  return { eventsReview, eventsApproved };
};

export const mapEventRequests = (eventRequests) => {
  const mappedEventRequests = eventRequests.map((eventRequest) => {
    const mappedEventRequest = {
      id: eventRequest._id,
      name: eventRequest.request_agenda_name,
      type: eventRequest.request_type,
      date: eventRequest.request_event_date,
      createdAt: eventRequest.createdAt,
      status: eventRequest.request_status,
      updatedAt: eventRequest.updatedAt,
      objective: eventRequest.activity_goal,
      representative: {
        id: eventRequest.employee_id,
        name: eventRequest.employee_name
      },
      franchise: {
        id: eventRequest.franchise_id,
        name: eventRequest.franchise_name
      },
      district: {
        id: eventRequest.district_id,
        name: eventRequest.district_name
      },
      approvers: [],
      hasExternalSpeaker: eventRequest.request_has_external_speakers,
      history: eventRequest.request_status_history.map((movement) => ({
        name: movement.status,
        date: new Date(movement.updatedAt),
        employee: movement.employee_name
      }))
    };

    for (let approver of eventRequest.approvers) {
      if (approver._id === eventRequest.manager_id) {
        mappedEventRequest.district.manager = {
          id: approver._id,
          name: approver.name,
          approved: approver.approved
        };
      } else if (approver._id === eventRequest.director_id) {
        mappedEventRequest.franchise.director = {
          id: approver._id,
          name: approver.name,
          approved: approver.approved
        };
      } else if (approver.medical) {
        mappedEventRequest.franchise.medical = {
          id: approver._id,
          name: approver.name,
          approved: approver.approved
        };
      } else {
        mappedEventRequest.approvers.push({
          id: approver._id,
          name: approver.name,
          approved: approver.approved,
          franchise: {
            name: approver.franchise_name
          }
        });
      }
    }

    return mappedEventRequest;
  });

  return mappedEventRequests;
};
