import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dashboard: {
    id: 'components.SideMenu.dashboard',
    defaultMessage: 'Dashboard'
  },
  eventRequests: {
    id: 'components.SideMenu.eventRequests',
    defaultMessage: 'Solicitudes de eventos'
  },
  formatRepository: {
    id: 'components.SideMenu.formatRepository',
    defaultMessage: 'Repositorio de formatos'
  },
  franchises: {
    id: 'components.SideMenu.franchises',
    defaultMessage: 'Franquicias'
  },
  franchise: {
    id: 'components.SideMenu.franchise',
    defaultMessage: 'Franquicia'
  },
  district: {
    id: 'components.SideMenu.district',
    defaultMessage: 'Distrito'
  },
  management: {
    id: 'components.SideMenu.management',
    defaultMessage: 'GESTIÓN'
  },
  hcps: {
    id: 'components.SideMenu.hcps',
    defaultMessage: 'HCPS'
  },
  employees: {
    id: 'components.SideMenu.employees',
    defaultMessage: 'Empleados'
  },
  configuration: {
    id: 'components.SideMenu.configuration',
    defaultMessage: 'Configuración'
  },
  profile: {
    id: 'components.SideMenu.profile',
    defaultMessage: 'Perfil'
  },
  table: {
    id: 'components.SideMenu.table',
    defaultMessage: 'Tabla'
  },
  add: {
    id: 'components.SideMenu.add',
    defaultMessage: 'Agregar'
  },
  edit: {
    id: 'components.SideMenu.edit',
    defaultMessage: 'Editar'
  },
  speakers: {
    id: 'components.SideMenu.speakers',
    defaultMessage: 'Speakers'
  }
});
