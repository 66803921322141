import { useDrawer } from 'components/Drawer';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../AddApproverFormMessages';
import { useCatalogues } from 'components/Catalogues';

export const useForm = ({ onSubmit }) => {
  const { handleCloseDrawer } = useDrawer();
  const intl = useIntl();
  const { handleGetFranchiseEmployee, employeesFranchises } = useCatalogues();

  useEffect(() => {
    handleGetFranchiseEmployee();
  }, []);

  const initialValues = {
    franchiseId: '',
    approverId: ''
  };

  const validationSchema = yup.object({
    franchiseId: yup
      .string()
      .required(intl.formatMessage(messages.requiredError)),
    approverId: yup
      .string()
      .required(intl.formatMessage(messages.requiredError))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
      handleCloseDrawer();
    }
  });

  const searchApprovers = () => {
    let wantedApprovers = [];

    if (formik.values.franchiseId !== '') {
      wantedApprovers = employeesFranchises.filter(
        (employeesFranchise) =>
          formik.values.franchiseId === employeesFranchise.value
      );
    }

    return wantedApprovers.length > 0 ? wantedApprovers[0].approvers : [];
  };

  const fields = {
    franchiseId: {
      type: 'select',
      name: 'franchiseId',
      label: intl.formatMessage(messages.franchiseIdLabel),
      placeholder: intl.formatMessage(messages.franchiseIdPlaceholder),
      options: employeesFranchises,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value
    },
    approverId: {
      type: 'select',
      name: 'approverId',
      label: intl.formatMessage(messages.approverIdLabel),
      placeholder: intl.formatMessage(messages.approverIdPlaceholder),
      options: formik.values.franchiseId !== '' ? searchApprovers() : [],
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      disabled: formik.values.franchiseId === ''
    }
  };

  return { formik, fields };
};
