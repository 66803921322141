import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { useStyles } from './HcpsTableStyles';
import { Table } from '@octopy/react-table';
import { useTable } from './hooks';
import { useHcps } from '../hooks/useHcps';

const HcpsTable = () => {
  const classes = useStyles();
  const table = useTable();
  const [hcpsListUpdate, setHcpsListUpdate] = useState([]);
  const { filter, handleGetList, hcpsList, pagination } = useHcps();

  useEffect(() => {
    handleGetList();
  }, [pagination.currentPage, pagination.rowsPerPage, filter.name]);

  useEffect(() => {
    handleList(!!hcpsList.list ? hcpsList.list : []);
  }, [hcpsList]);

  const handleList = (list) => {
    const listWithAreas = list.map((hcp) => ({
      ...hcp,
      therapys: (
        <Box className={classes.boxAreas}>
          <Typography className={classes.areasText}>
            {hcp.therapys.map((area, index) =>
              hcp.therapys.length - 1 === index ? `${area} ` : `${area}, `
            )}
          </Typography>
        </Box>
      )
    }));

    setHcpsListUpdate(listWithAreas);
  };

  return (
    <>
      <Table
        data={hcpsListUpdate}
        columns={table.tableHead}
        pagination={pagination}
        configProps={{ selectedOptions: { checkboxHidden: true } }}
      />
    </>
  );
};

HcpsTable.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { HcpsTable };
