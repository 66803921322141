/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { messages } from './CommentFormMessages';
import { useStyles } from './CommentFormStyles';
import { renderTextField } from '@octopy/react-form';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useModal } from '@octopy/react-modal';

const CommentForm = ({ type = 'return', formikRef, onSubmit }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleCloseModal } = useModal();

  const initialValues = {
    comment: ''
  };

  const validationSchema = yup.object({
    comment: yup
      .string()
      .required(intl.formatMessage(messages.requiredCommentError))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      onSubmit(values);

      handleCloseModal();
    }
  });

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik]);

  const field = {
    type: 'textarea',
    name: 'comment',
    label: intl.formatMessage(messages[`${type}CommentLabel`]),
    placeholder: intl.formatMessage(messages[`${type}CommentPlaceholder`])
  };

  return (
    <Box py={1} className={classes.container}>
      {renderTextField({ field, formik, inputProps: { multilineRows: 4 } })}
    </Box>
  );
};

CommentForm.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { CommentForm };
