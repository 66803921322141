import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useIntl, FormattedMessage } from 'react-intl';

import { Container, Typography, Button } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import { messages } from './SpeakersMessages';

import { SpeakerTable } from './SpeakerTable';
import { SpeakerAddDrawer } from './SpeakerAddDrawer';
import { SepakerSearch } from './SepakerSearch';

import { useDrawer } from 'components/Drawer';
import { useSpeakers } from './hooks/useSpeakers';
import { useStyles } from './SpeakersStyles';
import { useLoader } from '@octopy/react-loader';

import { filterSearchSpeakers } from './SepakerSearch/helpers';

import { downloadFile } from 'utils/files';

import cloneDeep from 'lodash/cloneDeep';

/**
 * import { speakers } from './mook';
 */

const Speakers = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const { handleShowLoader } = useLoader();
  const {
    speakerListService,
    handleGetListSpeakers,
    handleCreateSpeakers,
    handleExportSepakers
  } = useSpeakers();

  const [speakersList, setSpeakerList] = useState([]);
  const [valuesExport, setValuesExport] = useState({});

  useEffect(() => {
    handleGetListSpeakers();
  }, []);

  useEffect(() => {
    setSpeakerList(speakerListService);
  }, [speakerListService]);

  const addSpeaker = (values) => handleCreateSpeakers(values);

  const handleAddSpeaker = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.title),
      body: <SpeakerAddDrawer onSubmit={addSpeaker} edit={false} />
    });
  };

  const sepakerSearch = (values) => {
    const speakerListServiceClone = cloneDeep(speakerListService);
    const newFilterSpeakers = filterSearchSpeakers(
      values,
      speakerListServiceClone
    );

    setValuesExport(values);

    setSpeakerList(newFilterSpeakers);

    if (
      values.speakerType === '' &&
      values.speakerName === '' &&
      !values.speakerFranchises
    ) {
      setSpeakerList(speakerListService);
      setValuesExport({});
    }
  };

  const handleExport = async () => {
    let speakerType = '',
      speaker = '',
      speakerFranchise = '';

    if (speakersList.length !== 0) {
      speaker = valuesExport.speakerName;
      speakerType = valuesExport.speakerType;
      speakerFranchise = valuesExport.speakerFranchises;
    }

    handleShowLoader(true);
    const speakerExportResponse = await handleExportSepakers(
      speakerType,
      speaker,
      speakerFranchise
    );
    const format = {
      ...speakerExportResponse,
      name: intl.formatMessage(messages.speakersExport),
      date: new Date()
    };

    downloadFile(format);
    handleShowLoader(false);
  };

  return (
    <>
      <Container>
        <div className={classes.header}>
          <Typography
            component={'div'}
            color="primary"
            variant="h5"
            className={classes.title}
          >
            <FormattedMessage {...messages.title} />
          </Typography>
          <Button
            color="primary"
            className={classes.button}
            endIcon={<AddCircleIcon />}
            onClick={handleAddSpeaker}
          >
            <FormattedMessage {...messages.newSpeaker} />
          </Button>
        </div>

        <SepakerSearch
          setValuesExport={setValuesExport}
          onSubmit={sepakerSearch}
          onExport={handleExport}
        />
      </Container>

      <SpeakerTable speakers={speakersList} />
    </>
  );
};

Speakers.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Speakers };
