import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  ButtonBase,
  Collapse,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useTheme
} from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { Images } from 'assets';
import { ProfilePreview } from './ProfilePreview';
import { MiniMenuSwitch } from './MiniMenuSwitch';
import { useSideMenu } from '.';
import { useStyles } from './SideMenuStyles';
import { useLists } from './helpers';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';

const SideMenu = () => {
  const route = useRouteMatch();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { visible, setVisible, mini, expanded, setExpanded } = useSideMenu();
  const classes = useStyles({ expanded });
  const { user } = useAuth();

  const lists = useLists();

  const subitemsVisibilityStates = {
    users: useState(false)
  };

  useEffect(() => setVisible(false), [route]);

  const getSubitemsList = (subitems, visible) => (
    <Collapse in={visible} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {subitems.map((item) => (
          <ListItem
            button
            component={item.to ? Link : null}
            to={item.to}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <span
                className={`${classes.point} ${
                  item.to === route.path ? 'active' : ''
                }`}
              />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primaryTypographyProps={
                item.to === route.path
                  ? {
                      className: classes.boldText,
                      variant: 'body2',
                      color: 'textSecondary'
                    }
                  : undefined
              }
            >
              {formatMessage(item.message)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  const getList = (list) => (
    <List
      subheader={
        list.title ? (
          <ListSubheader
            disableSticky
            className={classes.listSubHeader}
            component="div"
          >
            {formatMessage(list.title)}
          </ListSubheader>
        ) : null
      }
    >
      {list.items.map((item, index) => {
        const [subitemsVisible, setSubitemsVisible] = subitemsVisibilityStates[
          item.name
        ] || [false, () => null];

        return (
          <>
            <ListItem
              className={`${classes.listItem} ${
                route.path === item.path ? 'active' : ''
              }`}
              button
              component={!item.subitems && item.path ? Link : null}
              to={item.path}
              key={item.message + index}
              onClick={() => {
                setSubitemsVisible((visible) => !visible);
                item.action && item.action();
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <item.icon />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primaryTypographyProps={
                  item.to === route.path
                    ? {
                        className: classes.boldText,
                        variant: 'body2',
                        color: 'textSecondary'
                      }
                    : undefined
                }
              >
                {formatMessage(item.message)}
              </ListItemText>
              {!!item.subitems && (
                <ExpandIcon
                  className={`${classes.expandIcon} ${
                    subitemsVisible ? 'inverted' : ''
                  }`}
                />
              )}
            </ListItem>
            {!!item.subitems && getSubitemsList(item.subitems, subitemsVisible)}
          </>
        );
      })}
    </List>
  );

  const drawer = (
    <Box pr={0.5}>
      <MiniMenuSwitch />
      <ButtonBase
        disableRipple
        component={Link}
        to="/"
        className={classes.logoContainer}
      >
        <img className={classes.iconLogo} src={Images.iconLogo} alt="logo" />
        {/* <div className={classes.nameLogo} /> */}
      </ButtonBase>
      {user.role !== roles.admin && <ProfilePreview />}
      {lists.map((list) => getList(list))}
    </Box>
  );

  return (
    <>
      <Hidden mdUp implementation="css">
        <Drawer
          transitionDuration={theme.transitions.duration.standard}
          variant="temporary"
          anchor="left"
          open={visible}
          onClose={() => setVisible(false)}
          className={classes.drawer}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          open
          onMouseOver={mini ? () => setExpanded(true) : null}
          onMouseLeave={mini ? () => setExpanded(false) : null}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export { SideMenu };
