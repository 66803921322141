import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  businessUnitLabel: {
    id: 'components.TableFilters.businessUnitLabel',
    defaultMessage: 'Unidad de negocio'
  },
  roleLabel: {
    id: 'components.TableFilters.roleLabel',
    defaultMessage: 'Rol'
  },
  cityLabel: {
    id: 'components.TableFilters.cityLabel',
    defaultMessage: 'Ciudad'
  },
  filterButton: {
    id: 'components.TableFilters.filterButton',
    defaultMessage: 'Filtrar'
  },
  cancel: {
    id: 'components.TableFilters.cancel',
    defaultMessage: 'Cancelar'
  },
  searchLabel: {
    id: 'components.TableFilters.searchLabel',
    defaultMessage: 'Buscar por nombre'
  },
  searchPlaceholder: {
    id: 'components.TableFilters.searchPlaceholder',
    defaultMessage: 'Ej. Eduardo Castillo'
  }
});
