import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, ButtonBase, Typography } from '@material-ui/core';
import { useStyles } from './ProfilePreviewStyles';
import { useSideMenu } from '..';
import { Link } from 'react-router-dom';
import { useAuth } from 'components/Auth';

const ProfilePreview = () => {
  const { expanded } = useSideMenu();
  const classes = useStyles({ expanded });
  const { user } = useAuth();

  return (
    <ButtonBase component={Link} className={classes.container} to="/myAccount">
      {!!user.image ? (
        <Avatar
          src={user.image}
          variant="circular"
          className={classes.avatar}
          alt={user.name}
        />
      ) : (
        <Avatar className={classes.avatar}>{`${user.name[0]}${
          user.name[user.name.search(' ') + 1]
        }`}</Avatar>
      )}
      <Box pl={1.5} className={classes.textContainer}>
        <Typography className={classes.name} variant="subtitle2">
          {user.name}
        </Typography>
        <Typography component="Box" variant="body2" color="textSecondary">
          {user.role}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

ProfilePreview.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ProfilePreview };
