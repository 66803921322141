import { useFormik } from 'formik';

import * as yup from 'yup';

import { useIntl } from 'react-intl';

import { messages } from '../SepakerSearchMessages';

import { useStyles } from '../SepakerSearchStyles';

import { useCatalogues } from 'components/Catalogues';
import { useEffect, useState } from 'react';

const _initialValues = {
  speakerName: '',
  speakerType: '',
  speakerFranchises: ''
};

export const useForm = ({ initialValues = _initialValues, onSubmit }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [franchisesOptios, setFranchisesOptions] = useState([]);

  const { franchises, handleGetFranchises } = useCatalogues();

  useEffect(() => {
    handleGetFranchises();
  }, []);

  useEffect(() => {
    setFranchisesOptions(franchises);
  }, [franchises]);

  const validationSchema = yup.object({
    speakerName: yup.string(),
    speakerType: yup.string(),
    speakerFranchises: yup.string()
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => onSubmit(values)
  });

  const fields = {
    speakerName: {
      type: 'text',
      name: 'speakerName',
      label: intl.formatMessage(messages.speakerName),
      placeholder: intl.formatMessage(messages.speakerName),
      className: classes.searchItems
    },
    speakerType: {
      type: 'select',
      name: 'speakerType',
      label: intl.formatMessage(messages.speakerType),
      placeholder: intl.formatMessage(messages.speakerType),
      options: [
        { value: 'interno', label: intl.formatMessage(messages.internal) },
        { value: 'externo', label: intl.formatMessage(messages.external) }
      ],
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      className: classes.searchItems
    },
    speakerFranchises: {
      type: 'select',
      name: 'speakerFranchises',
      label: intl.formatMessage(messages.speakerFranchises),
      placeholder: intl.formatMessage(messages.speakerFranchises),
      options: franchisesOptios,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      className: classes.searchItems
    }
  };

  return { formik, fields };
};
