import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ConfigurationContext } from './ConfigurationContext';
import { useApi } from 'hooks';
import { mapperConfiguration } from './helpers';
import { useAuth } from 'components/Auth';

const ConfigurationProvider = (props) => {
  const { children } = props;
  const { user } = useAuth();
  const [configuration, setConfiguration] = useState({});

  const [getConfiguration] = useApi({
    endpoint: '/config/get',
    method: 'get'
  });
  const [updateConfiguration] = useApi({
    endpoint: '/config/set',
    method: 'put'
  });

  const handleGetConfiguration = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getConfiguration();

      if (status === 200) {
        const mappedConfiguration = mapperConfiguration(payload || {});

        setConfiguration(mappedConfiguration);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateConfiguration = async (body) => {
    try {
      const {
        headerResponse: { status }
      } = await updateConfiguration({ body });

      if (status === 200) {
        handleGetConfiguration();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      configuration,
      handleGetConfiguration,
      handleUpdateConfiguration
    }),
    [configuration]
  );

  useEffect(() => {
    if (user) {
      handleGetConfiguration();
    }
  }, [user]);

  return (
    <ConfigurationContext.Provider value={contextValue}>
      {children}
    </ConfigurationContext.Provider>
  );
};

ConfigurationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ConfigurationProvider };
