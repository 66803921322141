import { BaseLayout, FullScreenLayout } from 'layouts';
import { Home } from 'views/Home';
import { Error404 } from 'views/Error404';
import { Employees } from 'components/Employees';
import { Franchise } from 'components/Franchise';
import { Franchises } from 'components/Franchises';
import { District } from 'components/District';
import { FormatRepository } from 'components/FormatRepository';
import { EventRequestDetails } from 'components/EventRequestDetails';
import { NewEmployee } from 'components/Employees/NewEmployee';
import { MyAccount } from 'components/MyAccount';
import { EditEmployee } from 'components/Employees/EditEmployee';
import { Login } from 'components/Login';
import { Images } from 'assets';
import { RestorePassword } from 'components/RestorePassword';
import { EventRequests } from 'components/EventRequests';
import { EventRequestForm } from 'components/EventRequestForm';
import { CloseEventRequest } from 'components/CloseEventRequest';
import { AssociateSpeakerForm } from 'components/EventRequestForm/sections/SpeakersSection/AssociateSpeakerForm';
import { Hcps } from 'components/Hcps';
import { roles } from 'components/Auth/helpers';
import { Speakers } from 'components/Speakers';
import { Configuration } from 'components/Configuration';
import { useAuth } from 'components/Auth';

const home = {
  main: {
    path: '/',
    layout: BaseLayout,
    component: Home,
    exact: true,
    titleMessage: 'appName',
    private: false
  }
};

const myAccount = {
  main: {
    path: '/myAccount',
    layout: BaseLayout,
    component: MyAccount,
    exact: true,
    titleMessage: 'myAccount',
    private: false
  }
};

const employees = {
  main: {
    path: '/employees',
    layout: BaseLayout,
    component: Employees,
    exact: true,
    titleMessage: 'employees',
    private: true
  },
  new: {
    path: '/employees/new',
    layout: BaseLayout,
    component: NewEmployee,
    exact: true,
    titleMessage: 'newEmployee',
    private: true
  },
  edit: {
    path: '/employees/edit/:employeeId',
    layout: BaseLayout,
    component: EditEmployee,
    exact: true,
    titleMessage: 'newEmployee',
    private: true
  }
};

const hcps = {
  main: {
    path: '/hcps',
    layout: BaseLayout,
    component: Hcps,
    exact: true,
    titleMessage: 'hcps',
    private: false
  }
};

const eventRequests = {
  main: {
    path: '/eventRequests',
    layout: BaseLayout,
    component: EventRequests,
    exact: true,
    titleMessage: 'eventRequests',
    private: false
  },
  detailed: {
    path: '/eventRequests/:eventId/details',
    layout: BaseLayout,
    component: EventRequestDetails,
    exact: true,
    titleMessage: 'eventRequestDetails',
    private: false
  },
  new: {
    path: '/eventRequests/new',
    layout: BaseLayout,
    component: EventRequestForm,
    exact: true,
    titleMessage: 'newEventRequest'
  },
  edit: {
    path: '/eventRequests/:eventId',
    layout: BaseLayout,
    component: EventRequestForm,
    exact: true,
    titleMessage: 'newEventRequest'
  },
  speaker: {
    path: '/eventRequests/:eventId/speaker',
    layout: BaseLayout,
    component: AssociateSpeakerForm,
    exact: true,
    titleMessage: 'newEventRequest'
  },
  close: {
    path: '/eventRequests/:eventId/close',
    layout: BaseLayout,
    component: CloseEventRequest,
    exact: true,
    titleMessage: 'closeEventRequest'
  }
};

const franchises = {
  main: {
    path: '/franchises',
    layout: BaseLayout,
    component: Franchises,
    exact: true,
    titleMessage: 'franchises',
    private: false
  },
  detailed: {
    path: '/franchises/:franchiseId',
    layout: BaseLayout,
    component: Franchise,
    exact: true,
    titleMessage: 'franchise',
    private: false
  }
};

const districts = {
  detailed: {
    path: '/franchises/districts/:districtId',
    layout: BaseLayout,
    component: District,
    exact: true,
    titleMessage: 'district',
    private: false
  }
};

const formatRepository = {
  main: {
    path: '/formatRepository',
    layout: BaseLayout,
    component: FormatRepository,
    exact: true,
    titleMessage: 'formatRepository',
    private: false
  }
};

const auth = {
  login: {
    path: '/login',
    layout: (props) => (
      <FullScreenLayout
        withSplashScreen
        backgroundImage={Images.loginBackground}
        {...props}
      />
    ),
    component: Login,
    exact: true,
    titleMessage: 'login'
  },
  recoveryPassword: {
    path: '/recoveryPassword',
    layout: (props) => (
      <FullScreenLayout
        backgroundImage={Images.restorePasswordBackground}
        {...props}
      />
    ),
    component: RestorePassword,
    exact: true,
    titleMessage: 'recoveryPassword'
  }
};

const configuration = {
  main: {
    path: '/configuration',
    layout: BaseLayout,
    component: Configuration,
    exact: true,
    titleMessage: 'configuration'
  }
};

const notFound = {
  main: {
    layout: FullScreenLayout,
    component: Error404,
    titleMessage: '404'
  }
};

const speakers = {
  main: {
    path: '/speakers',
    layout: BaseLayout,
    component: Speakers,
    exact: true,
    titleMessage: 'speakers',
    private: false
  }
};

export const useRoutes = () => {
  const { user } = useAuth();

  const medical = user?.medical;

  return {
    [roles.admin]: [
      ...Object.values(home),
      ...Object.values(employees),
      ...Object.values(hcps),
      eventRequests.main,
      eventRequests.detailed,
      ...Object.values(eventRequests),
      ...Object.values(franchises),
      ...Object.values(districts),
      ...Object.values(formatRepository),
      ...Object.values(speakers),
      ...Object.values(configuration),
      ...Object.values(notFound)
    ],
    [roles.representative]: [
      ...Object.values(home),
      ...Object.values(myAccount),
      ...Object.values(eventRequests),
      ...(medical ? Object.values(franchises) : [franchises.detailed]),
      formatRepository.main,
      ...Object.values(notFound)
    ],
    [roles.manager]: [
      ...Object.values(home),
      ...Object.values(myAccount),
      eventRequests.main,
      eventRequests.detailed,
      ...(medical ? Object.values(franchises) : []),
      districts.detailed,
      ...Object.values(notFound)
    ],
    [roles.director]: [
      ...Object.values(home),
      ...Object.values(myAccount),
      ...(medical ? Object.values(franchises) : [franchises.detailed]),
      eventRequests.main,
      eventRequests.detailed,
      districts.detailed,
      ...Object.values(notFound)
    ],
    // [roles.medical]: [
    //   ...Object.values(home),
    //   ...Object.values(myAccount),
    //   eventRequests.main,
    //   eventRequests.detailed,
    //   franchises.main,
    //   franchises.detailed,
    //   ...Object.values(notFound)
    // ],
    [roles.reviewer]: [
      ...Object.values(home),
      ...Object.values(myAccount),
      eventRequests.main,
      eventRequests.detailed,
      ...Object.values(notFound)
    ],
    public: [...Object.values(auth), ...Object.values(notFound)]
  };
};
