import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  oneKeyLabel: {
    id: 'components.HcpsFiltersForm.oneKeyLabel',
    defaultMessage: 'one key id'
  },
  nameLabel: {
    id: 'components.HcpsFiltersForm.nameLabel',
    defaultMessage: 'Nombre'
  },
  specialtyLabel: {
    id: 'components.HcpsFiltersForm.specialtyLabel',
    defaultMessage: 'Especialidad'
  },
  specialtyPlaceholder: {
    id: 'components.HcpsFiltersForm.specialtyPlaceholder',
    defaultMessage: 'Medico'
  },
  treatmentLabel: {
    id: 'components.HcpsFiltersForm.treatmentLabel',
    defaultMessage: 'Tratamiento'
  },
  treatmentPlaceholder: {
    id: 'components.HcpsFiltersForm.treatmentPlaceholder',
    defaultMessage: 'Diabetes'
  },
  filterButton: {
    id: 'components.HcpsFiltersForm.filterButton',
    defaultMessage: 'Filtrar'
  }
});
