import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../EmployeeFormMessages';

export const useForm = () => {
  const intl = useIntl();
  const initialValues = {
    identifier: '',
    name: '',
    email: '',
    rfc: '',
    city: '',
    image: null
  };

  const validationSchema = yup.object({
    identifier: yup
      .string()
      .trim()
      // .matches(/((?=.*[A-Z]){1})+/, 'Is not in correct format')
      .required(intl.formatMessage(messages.requiredError)),
    name: yup.string().required(intl.formatMessage(messages.requiredError)),
    email: yup
      .string()
      .email(intl.formatMessage(messages.emailError))
      .required(intl.formatMessage(messages.requiredError)),
    rfc: yup.string().required(intl.formatMessage(messages.requiredError)),
    city: yup.string().required(intl.formatMessage(messages.requiredError)),
    image: yup
      .object()
      .shape({
        url: yup.string(),
        file: yup.mixed()
      })
      .nullable()
  });

  return { initialValues, validationSchema };
};
