import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { messages } from './EventRequestFormMessages';
import { useStyles } from './EventRequestFormStyles';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import { useHistory } from 'react-router-dom';
import { renderAutocomplete } from '@octopy/react-form';
import { useTypeField } from './hooks/useTypeField';
import { eventTypes } from './helpers';
import { NovoEventForm } from './NovoEventForm';
import { EventTypeContext } from './EventTypeContext';
import { NovoSupportForm } from './NovoSupportForm';
import { PharmacyActivityForm } from './PharmacyActivityForm';
import { FaceToFaceForm } from './FaceToFaceForm';
import { useCatalogues } from 'components/Catalogues';
import { useEditEventRequest } from './hooks/useEditEventRequest';
import { useConfiguration } from 'components/Configuration/hooks/useConfiguration';

const EventRequestForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const formikRef = useRef();
  const { handleGetCatalogueRequest } = useCatalogues();
  const { configuration, handleGetConfiguration } = useConfiguration();

  useEffect(() => {
    handleGetCatalogueRequest();
    handleGetConfiguration();
  }, []);

  /* useEffect(() => {
    console.log('configuration', configuration);

    const {
      minimumNumberOfGuestsForNovoEventAndNovoSupport
    } = configuration;

    setMinimumNumberOf(minimumNumberOfGuestsForNovoEventAndNovoSupport);
  }, []); */

  const { setType, type, field: typeField } = useTypeField();

  const eventRequest = useEditEventRequest(setType);

  return (
    <EventTypeContext.Provider value={type}>
      <Container className={classes.container}>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <IconButton
              edge="start"
              color="secondary"
              className={classes.backButton}
              onClick={() => history.push('/eventRequests')}
            >
              <BackIcon />
            </IconButton>
            <Typography color="primary" variant="h5" className={classes.title}>
              {!!eventRequest
                ? intl.formatMessage(messages.editRequest)
                : intl.formatMessage(messages.newRequest)}
              {!xs && <> - </>}
              <Typography
                component={xs ? 'div' : 'span'}
                color="secondary"
                variant="h5"
                className={classes.title}
              >
                {type && intl.formatMessage(messages[type])}
              </Typography>
            </Typography>
          </div>

          {renderAutocomplete(typeField)}
        </div>

        {type === eventTypes.novoEvent && (
          <NovoEventForm formikRef={formikRef} eventRequest={eventRequest} />
        )}

        {type === eventTypes.novoSupport && (
          <NovoSupportForm formikRef={formikRef} eventRequest={eventRequest} />
        )}

        {type === eventTypes.pharmacyActivities && (
          <PharmacyActivityForm
            formikRef={formikRef}
            eventRequest={eventRequest}
          />
        )}

        {type === eventTypes.faceToFace && (
          <FaceToFaceForm formikRef={formikRef} eventRequest={eventRequest} />
        )}

        <Box className={classes.submitButtonsContainer}>
          <Button
            onClick={async () => {
              await formikRef.current.setFieldValue('draft', true);
              await formikRef.current.submitForm();
            }}
            className={classes.submitButton}
          >
            {intl.formatMessage(messages.saveAsDraft)}
          </Button>
          <Button
            onClick={async () => {
              await formikRef.current.setFieldValue('draft', false);
              await formikRef.current.submitForm();
            }}
            className={classes.submitButton}
            color="primary"
          >
            {intl.formatMessage(messages.enterRequest)}
          </Button>
        </Box>
      </Container>
    </EventTypeContext.Provider>
  );
};

export { EventRequestForm };
