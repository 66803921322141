import { useApi } from 'hooks';
import { useState } from 'react';
import { FranchiseContext } from './FranchiseContext';
import { mapFranchise } from './helpers';
import { useDistrictsActions } from './hooks/useDistrictsActions';
import { useSchedulesActions } from './hooks/useSchedulesActions';
import { useApproversActions } from './hooks/useApproversActions';

export const FranchiseProvider = ({ children }) => {
  const [franchise, setFranchise] = useState();
  const [approvers, setApprovers] = useState();
  const [districts, setDistricts] = useState();
  const [schedules, setSchedules] = useState();

  const [getFranchise] = useApi({ endpoint: '/franchise/get', method: 'get' });
  const [exportDistricts] = useApi({
    endpoint: 'district/export',
    method: 'get'
  });

  const handleGetFranchise = async (franchiseId) => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getFranchise({ urlParams: franchiseId });

      if (status === 200) {
        const { approvers, districts, schedules, ...franchise } = mapFranchise(
          payload
        );

        setFranchise(franchise);
        setApprovers(approvers);
        setDistricts(districts);
        setSchedules(schedules);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportDistricts = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await exportDistricts();

      if (status === 200) {
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const schedulesActions = useSchedulesActions(handleGetFranchise);
  const districtsActions = useDistrictsActions(handleGetFranchise);
  const approversActions = useApproversActions(handleGetFranchise);

  const clear = () => {
    setFranchise(undefined);
    setApprovers(undefined);
    setDistricts(undefined);
    setSchedules(undefined);
  };

  const contextValue = {
    clear,
    franchise,
    approvers,
    districts,
    schedules,
    handleGetFranchise,
    handleExportDistricts,
    ...schedulesActions,
    ...districtsActions,
    ...approversActions
  };

  return (
    <FranchiseContext.Provider value={contextValue}>
      {children}
    </FranchiseContext.Provider>
  );
};
