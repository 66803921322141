import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { useStyles } from './FieldStyles';

const Field = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.label} variant="body1" color="primary">
        {label}
      </Typography>
      <TextField {...props} size="small" />
    </div>
  );
};

export { Field };
