import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box } from '@material-ui/core';
import { messages } from './ImageProfileSectionMessages';
import { useStyles } from './ImageProfileSectionStyles';
import { renderImagePicker } from '@octopy/react-form';
import { useFields } from './hooks/useFields';

const ImageProfileSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFields();

  return (
    <>
      <Box pb={2}>
        <Typography variant="h6" color="primary" className={classes.title}>
          {intl.formatMessage(messages.titleImageSection)}
        </Typography>
        <Typography>{intl.formatMessage(messages.descriptionImage)}</Typography>
      </Box>

      {fields.map((field, index) => (
        <Grid
          key={`profile-image-section-field-${index}`}
          item
          {...field.breakpoints}
        >
          {field.type === 'image' &&
            renderImagePicker({ index, formik, field })}
        </Grid>
      ))}
    </>
  );
};

ImageProfileSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ImageProfileSection };
