import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  speakerName: {
    id: 'components.SepakerSearch.speakerName',
    defaultMessage: 'Nombre'
  },
  speakerType: {
    id: 'components.SepakerSearch.speakerType',
    defaultMessage: 'Tipo'
  },
  speakerFranchises: {
    id: 'components.SepakerSearch.speakerFranchises',
    defaultMessage: 'Franquicia'
  },
  search: {
    id: 'components.SepakerSearch.search',
    defaultMessage: 'Buscar'
  },
  internal: {
    id: 'components.SepakerSearch.internal',
    defaultMessage: 'Interno'
  },
  external: {
    id: 'components.SepakerSearch.external',
    defaultMessage: 'Externo'
  },
  exportDoc: {
    id: 'components.SepakerSearch.exportDoc',
    defaultMessage: 'Exportar'
  }
});
