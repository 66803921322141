import { uploadFilePromise } from 'utils/aws';

export const mapSpeakers = async (speakers) => {
  const mappedSpeakers = await Promise.all(
    speakers.map(async (speaker) => {
      const dataMappedValues = {
        speaker_id: speaker.data.speaker.id,
        talk_type: speaker.data.typeOfTalk,
        speaker_reason:
          speaker.type === 'externo'
            ? speaker.data.reasonForUsingExternalSpeaker
            : undefined,
        confirmation_file:
          speaker.data.confirmation.file instanceof File
            ? await uploadFilePromise(speaker.data.confirmation.file)
            : speaker.data.confirmation.url || undefined
      };

      const logisticsMappedValues =
        speaker.type === 'externo' && speaker.logisticsEnabled
          ? {
              type_of_logistic: speaker.logistics.type || undefined,
              transport_cost: speaker.logistics.transportationCost || undefined,
              hotel_cost: speaker.logistics.hotelCost || undefined,
              quote_file:
                speaker.logistics.quote.file instanceof File
                  ? await uploadFilePromise(speaker.logistics.quote.file)
                  : speaker.logistics.quote.url || undefined
            }
          : undefined;

      const mappedSpeaker = {
        ...dataMappedValues,
        ...logisticsMappedValues
      };

      return mappedSpeaker;
    })
  );

  return mappedSpeakers;
};
