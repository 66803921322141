import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Button, Box, Divider } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/HomeRounded';
import { messages } from './AccountPopoverMessages';
import { useStyles } from './AccountPopoverStyles';
import { Popover } from 'components/Popover';
import { Link } from 'react-router-dom';
import { useAuth } from 'components/Auth';

export const AccountPopover = ({ visible, anchor, handleClose }) => {
  const { user, logout } = useAuth();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Popover anchorEl={anchor} open={visible} onClose={handleClose}>
      <Box p={2.5}>
        <Typography variant="subtitle1">{user.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {user.email}
        </Typography>
      </Box>

      <Divider />

      <Box p={2.5}>
        <Link
          startIcon={<HomeIcon />}
          component={Button}
          variant="text"
          color="primary"
          fullWidth
          className={classes.homeButton}
          to="/"
        >
          <span className="text">{intl.formatMessage(messages.home)}</span>
        </Link>

        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={logout}
        >
          {intl.formatMessage(messages.logout)}
        </Button>
      </Box>
    </Popover>
  );
};
