import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  idLabel: {
    id: 'components.UserDataSection.idLabel',
    defaultMessage: 'Identificador'
  },
  nameLabel: {
    id: 'components.UserDataSection.nameLabel',
    defaultMessage: 'Nombre'
  },
  emailLabel: {
    id: 'components.UserDataSection.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  rfcLabel: {
    id: 'components.UserDataSection.rfcLabel',
    defaultMessage: 'RFC'
  },
  cityLabel: {
    id: 'components.UserDataSection.cityLabel',
    defaultMessage: 'Ciudad'
  },
  userData: {
    id: 'components.UserDataSection.userData',
    defaultMessage: 'DATOS DEL USUARIO'
  }
});
