import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(3)
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      flexWrap: 'wrap'
    }
  },
  title: {
    letterSpacing: 4,
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    flexGrow: 1
  },
  exportButton: {
    marginLeft: theme.spacing(2)
  }
}));

export { useStyles };
