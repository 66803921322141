import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    boxShadow: theme.customShadows.z3,
    padding: theme.spacing(3),
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    letterSpacing: 6,
    paddingBottom: theme.spacing(2)
  },
  rolDescription: {
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    letterSpacing: 6,
    wordWrap: 'break-word'
  },
  informationLabel: {
    color: theme.palette.grey[600],
    paddingRight: theme.spacing(1)
  },
  containerInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  gridContainers: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export { useStyles };
