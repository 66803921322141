import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestForm.sections.ObjectiveSection.title',
    defaultMessage: 'Objetivo de la actividad'
  },
  objectiveLabel: {
    id: 'components.EventRequestForm.sections.AddressSection.objectiveLabel',
    defaultMessage: 'Objetivo de la actividad'
  },
  objectivePlaceholder: {
    id:
      'components.EventRequestForm.sections.AddressSection.objectivePlaceholder',
    defaultMessage: 'Describe el objetivo de esta actividad de farmacia'
  }
});
