import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { messages } from './DataSectionMessages';
import { useStyles } from './DataSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

export const DataSection = () => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    eventRequest: { data }
  } = useEventRequestDetails();

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Grid container spacing={3}>
        {data.date && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.dateEvent)}
            </Typography>
            <Typography>{data.date}</Typography>
          </Grid>
        )}
        {data.hour && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.hourEvent)}
            </Typography>
            <Typography>{data.hour}</Typography>
          </Grid>
        )}
        {data.schedule && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.scheduleEvent)}
            </Typography>
            <Typography>{data.schedule}</Typography>
          </Grid>
        )}
        <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
          <Typography className={classes.informationLabel}>
            {intl.formatMessage(messages.guests)}
          </Typography>
          <Typography>{data.numberGuests || 0}</Typography>
        </Grid>
        {data.typeSupport && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.typeSupport)}
            </Typography>
            <Typography>{data.typeSupport}</Typography>
          </Grid>
        )}
        {data.nameSchedule && (
          <Grid item lg={3} sm={6} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.nameSchedule)}
            </Typography>
            <Typography>{data.nameSchedule}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
