import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@octopy/react-loader';
import { Modal } from '@octopy/react-modal';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Header } from 'components/Header';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { useSideMenu, SideMenu } from 'components/SideMenu';
import { useStyles } from './BaseLayoutStyles';
import './BaseLayout.scss';
import { Drawer } from 'components/Drawer';
import { PdfViewer } from 'components/PdfViewer';
import { Footer } from 'components/Footer';

function BaseLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { mini } = useSideMenu();

  return (
    <ErrorBoundary>
      <Loader />
      <Modal />
      <Header />
      <Drawer />
      <PdfViewer />

      <div className="base-layout">
        <SideMenu />
        <div className={classes.appBarSpacer} />
        <div
          style={{
            [theme.direction === 'ltr' ? 'marginLeft' : 'marginRight']: mdUp
              ? theme.mixins.getSideMenu(mini).width
              : 0,
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.standard
            })
          }}
        >
          {children}
          <Footer />
        </div>
      </div>
    </ErrorBoundary>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export { BaseLayout };
