import _ from 'lodash';
import { useEffect, useState } from 'react';
import { EventRequestDetailsContext } from './EventRequestDetailsContext';
import {
  determinateIfUserCanApprove,
  getAllApprovers,
  getUserRole
} from './helpers';
import { useAuth } from 'components/Auth';
import { useApi } from 'hooks';
import { useReviewerActions } from './hooks/useReviewerActions';
import { useApproverActions } from './hooks/useApproverActions';

export const EventRequestDetailsProvider = ({ children }) => {
  const { user } = useAuth();
  const [eventRequest, setEventRequest] = useState();
  const [userRole, setUserRole] = useState();
  const [allApprovers, setAllApprovers] = useState();
  const [userCanApprove, setUserCanApprove] = useState();
  const [getByIdrequest] = useApi({
    endpoint: 'request/get',
    method: 'get'
  });

  const clear = () => {
    setUserRole(undefined);
    setEventRequest(undefined);
    setAllApprovers(undefined);
    setUserCanApprove(undefined);
  };

  useEffect(() => {
    if (eventRequest) {
      setUserRole(getUserRole(eventRequest, user));
    }
  }, [eventRequest]);

  useEffect(() => {
    if (
      eventRequest &&
      userRole &&
      !['observer', 'reviewer'].includes(userRole)
    ) {
      setUserCanApprove(determinateIfUserCanApprove(eventRequest, user));
    }
  }, [eventRequest, userRole]);

  const handleGetEventById = async (id) => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getByIdrequest({ urlParams: id });

      if (status === 200) {
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEventRequest = (eventRequest) => {
    setEventRequest({
      ...eventRequest
    });

    setAllApprovers(getAllApprovers(eventRequest));
  };

  const reviewerActions = useReviewerActions();
  const approverActions = useApproverActions();

  const contextValue = {
    eventRequest,
    userRole,
    user,
    allApprovers,
    userCanApprove,
    handleGetEventRequest,
    handleGetEventById,
    reviewerActions,
    approverActions,
    clear
  };

  return (
    <EventRequestDetailsContext.Provider value={contextValue}>
      {children}
    </EventRequestDetailsContext.Provider>
  );
};
