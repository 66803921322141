import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    // marginLeft: theme.spacing(3),
    // marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(2),
    boxShadow: theme.customShadows.z2,
    // width: 281,
    // maxWidth: '100%',
    flexDirection: 'column',
    position: 'relative',
    // [theme.breakpoints.down('xs')]: {
    //   width: '100%'
    // },
    cursor: 'pointer',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: hexToRgba(theme.palette.primary.main, 0.05)
    }
  },
  type: {
    fontSize: 18
  },
  status: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    width: 132,
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    '&.aprobada': {
      background: hexToRgba(theme.palette.success.main),
      color: hexToRgba(theme.palette.common.white)
    },
    '&.revisión': {
      background: hexToRgba(theme.palette.secondary.light),
      color: hexToRgba(theme.palette.common.white)
    },
    '&.aprobación': {
      background: hexToRgba(theme.palette.warning.dark),
      color: hexToRgba(theme.palette.common.white)
    },
    '&.devuelta': {
      background: hexToRgba(theme.palette.error.light),
      color: hexToRgba(theme.palette.common.white)
    },
    '&.rechazada': {
      background: hexToRgba(theme.palette.error.dark),
      color: hexToRgba(theme.palette.common.white)
    },
    '&.ingresada': {
      background: hexToRgba(theme.palette.info.main),
      color: hexToRgba(theme.palette.common.white)
    },
    '&.creada': {
      background: hexToRgba(theme.palette.common.black),
      color: hexToRgba(theme.palette.common.white)
    },
    '&.cerrada': {
      background: hexToRgba(theme.palette.common.black, 0.1),
      color: hexToRgba(theme.palette.common.black)
    },
    '&.archivada': {
      background: hexToRgba(theme.palette.common.black, 0.5),
      color: hexToRgba(theme.palette.common.white)
    }
  },
  titles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2)
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& button': {
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1)
    },
    '& button:last-child': {
      marginLeft: theme.spacing(1)
    }
  },
  titlePrincipal: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    height: 45,
    lineHeight: 1.5
  },
  fontWeDark: {
    fontWeight: `${theme.typography.fontWeightBold} !important`
  },
  opaci: {
    opacity: 0.5
  },
  cardFooter: {
    height: theme.spacing(4),
    marginTop: theme.spacing(2)
  },
  marginFooter: {
    marginTop: theme.spacing(1),
    opacity: 0.5
  },
  bodyParticipants: {
    height: 150
  },
  MuiDrawerPaper: {
    backgroundColor: 'red'
  },
  id: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export { useStyles };
