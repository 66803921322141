import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { messages } from './AddHcpFormMessages';
import { useStyles } from './AddHcpFormStyles';
import { useForm } from './hooks/useForm';
import { renderTextField, renderRadioGroup } from '@octopy/react-form';

export const AddHcpForm = ({ initialValues, onSubmit }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { fields, formik } = useForm({ initialValues, onSubmit });

  const fieldMapper = (field) => (
    <Grid key={`hcps-section-field-${field.name}`} item {...field.breakpoints}>
      {field.type === 'radioGroup' && renderRadioGroup({ formik, field })}

      {field.type === 'text' &&
        renderTextField({
          formik,
          field,
          inputProps: {}
        })}
    </Grid>
  );

  return (
    <div>
      <Grid container spacing={2}>
        {fields.map(fieldMapper)}
      </Grid>

      <Button
        onClick={formik.handleSubmit}
        className={classes.submitButton}
        color="primary"
      >
        {intl.formatMessage(messages.accept)}
      </Button>
    </div>
  );
};
