import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { messages } from './FoodSectionMessages';
import { useStyles } from './FoodSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const FoodSection = () => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    eventRequest: { food }
  } = useEventRequestDetails();

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Grid container spacing={2}>
        {typeof food.totalCost === 'number' && (
          <Grid item md={4} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.totalCost)}
            </Typography>
            <Typography>{food.totalCost}</Typography>
          </Grid>
        )}
        {typeof food.perPerson === 'number' && (
          <Grid item md={4} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.costPerPerson)}
            </Typography>
            <Typography>{food.perPerson}</Typography>
          </Grid>
        )}
        {food.tip && (
          <Grid item md={4} xs={12} className={classes.containerInfo}>
            <Typography className={classes.informationLabel}>
              {intl.formatMessage(messages.tip)}
            </Typography>
            <Typography>{food.tip}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

FoodSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { FoodSection };
