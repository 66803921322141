import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useModal } from '@octopy/react-modal';
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { messages } from './ChangePasswordSectionMessages';
import { useStyles } from './ChangePasswordSectionStyles';
import { renderTextField, usePasswordInputs } from '@octopy/react-form';
import { useForm } from './hooks/useForm';
import { useFormik } from 'formik';

const ChangePasswordSection = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { initialValues, validationSchema, handleSubmit, fields } = useForm();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (item, { resetForm }) => {
      const success = await handleSubmit(item);

      if (success) {
        resetForm();
        handleOpenModal({
          title: intl.formatMessage(messages.passwordChangeSuccess),
          actionButtons: (
            <Button onClick={handleCloseModal}>
              {intl.formatMessage(messages.btnAccept)}
            </Button>
          ),
          configProps: {
            maxWidth: 'xs'
          }
        });
      }
    }
  });

  const passwordInputs = usePasswordInputs({ fields, formik });

  const fieldsMapper = (field, index) => (
    <Grid key={`password-section-field-${index}`} item {...field.breakpoints}>
      {field.type === 'password' &&
        renderTextField({ index, formik, field, passwordInputs })}
    </Grid>
  );

  return (
    <>
      <Box pb={2}>
        <Typography color="primary" variant="h6" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {fields.map(fieldsMapper)}
      </Grid>
      <Box pt={2} display="flex" justifyContent="flex-end">
        <Button color="primary" onClick={() => formik.handleSubmit()}>
          {intl.formatMessage(messages.changeButton)}
        </Button>
      </Box>
    </>
  );
};

ChangePasswordSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ChangePasswordSection };
