import { responseMapper } from 'utils/responseMapper';

export const franchiseTemplate = {
  id: '_id',
  name: 'name',
  director: {
    imageUrl: 'director.avatar',
    name: 'director.full_name',
    identifier: 'director.identifier',
    id: 'director.director_id'
  },
  medical: {
    imageUrl: 'medical.avatar',
    name: 'medical.full_name',
    identifier: 'medical.identifier',
    id: 'medical.medical_id'
  },
  approvers: [
    'approvers',
    {
      imageUrl: 'avatar',
      name: 'full_name',
      franchise: {
        name: 'franchise_name'
      },
      approverId: 'approver_id'
    }
  ],
  districts: [
    'districts',
    {
      id: '_id',
      name: 'name',
      manager: {
        id: 'manager_id',
        identifier: 'manager_identifier',
        name: 'manager_name'
      },
      active: 'enabled',
      representativeQuantity: 'employee_count'
    }
  ],
  schedules: [
    'agendas',
    {
      id: '_id',
      name: 'name',
      franchise: {
        name: 'franchise_name'
      },
      approved: 'approved',
      url: 'file_url'
    }
  ]
};

export const mapFranchise = (franchise) => {
  const mappedFranchise = responseMapper({
    data: franchise,
    template: franchiseTemplate
  });

  return mappedFranchise;
};
