import { useDrawer } from 'components/Drawer';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../LoginAsFormMessages';
import { useEmployees } from '../../hooks/useContext';
import { useAuth } from 'components/Auth/hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { usePasswordInputs } from '@octopy/react-form';

export const useForm = () => {
  const intl = useIntl();
  const auth = useAuth();
  const history = useHistory();
  const { handleCloseDrawer } = useDrawer();
  const { employeesList } = useEmployees();
  const initialValues = {
    employee: ''
  };
  const validationSchema = yup.object({
    employee: yup.string().required(intl.formatMessage(messages.requiredError)),
    adminPassword: yup
      .string()
      .required(intl.formatMessage(messages.requiredError))
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const success = await auth.fakeSignin({
        employee_id: values.employee,
        password: values.adminPassword
      });

      if (success) {
        handleCloseDrawer();
        history.push('/');
      }
    }
  });

  const fields = {
    employee: {
      type: 'select',
      name: 'employee',
      label: intl.formatMessage(messages.employeeNameLabel),
      options: employeesList.map((employee) => ({
        name: employee.id,
        label: employee.name
      })),
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      breakpoints: { xs: 12 }
    },
    adminPassword: {
      type: 'password',
      name: 'adminPassword',
      autoComplete: false,
      label: intl.formatMessage(messages.adminPassword),
      placeholder: intl.formatMessage(messages.adminPassword)
    }
  };

  const passwordInputs = usePasswordInputs({
    fields: Object.values(fields),
    formik
  });

  return { formik, fields, passwordInputs };
};
