import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/InsertDriveFile';
import AddIcon from '@material-ui/icons/AddCircle';
import { messages } from './DistrictsMessages';
import { useStyles } from './DistrictsStyles';
import { DistrictCard } from './DistrictCard';
import { useDrawer } from 'components/Drawer';
import { DistrictForm } from './DistrictForm';
import { useFranchise } from '../hooks/useFranchise';
import { useParams } from 'react-router-dom';
import { NoData } from 'components/NoData';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';
import { useDistrictsActions } from '../hooks/useDistrictsActions';
import { useLoader } from '@octopy/react-loader';
import { downloadFile } from 'utils/files';

const Districts = () => {
  const { franchiseId } = useParams();
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const { districts, handleAddDistrict } = useFranchise();
  const { handleDistrictExport } = useDistrictsActions();
  const { handleShowLoader } = useLoader();
  const {
    user: { role }
  } = useAuth();

  const handleOpenAddDistrictForm = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.addDistrictDrawerTitle),
      caption: intl.formatMessage(messages.addDistrictDrawerCaption),
      body: (
        <DistrictForm
          onSubmit={async (values) =>
            await handleAddDistrict(franchiseId, values)
          }
        />
      )
    });
  };

  const handleExport = async () => {
    handleShowLoader(true);
    const DistrictExportResponse = await handleDistrictExport();
    const format = {
      ...DistrictExportResponse,
      name: intl.formatMessage(messages.districtExport),
      date: new Date()
    };

    downloadFile(format);
    handleShowLoader(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="subtitile1" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
        <div className={classes.actionButtons}>
          {role === roles.admin && (
            <>
              <Button endIcon={<ExportIcon />} onClick={handleExport}>
                {intl.formatMessage(messages.export)}
              </Button>
              <Button
                onClick={handleOpenAddDistrictForm}
                color="primary"
                endIcon={<AddIcon />}
              >
                {intl.formatMessage(messages.newDistrict)}
              </Button>
            </>
          )}
        </div>
      </div>

      <div className={classes.cardsContainer}>
        {districts ? (
          districts.length ? (
            districts.map((district) => (
              <DistrictCard key={district.id} district={district} />
            ))
          ) : (
            <NoData pl={2} pt={2} pb={4} />
          )
        ) : (
          <NoData pl={2} pt={2} pb={4} loading />
        )}
      </div>
    </div>
  );
};

export { Districts };
