import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Card, Typography, LinearProgress } from '@material-ui/core';

import { messages } from './EventRequestCardMessages';
import { useStyles } from './EventRequestCardStyles';

import { formatDateLong, countApprovers } from '../../helper';

import { messageStatus, messageStatusFooter } from './helper';

import { useActions } from './hooks/useActions';

import { useDrawer } from 'components/Drawer';
import { EventMovements } from '../EventMovements';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'components/Auth/hooks/useAuth';
import { roles } from 'components/Auth/helpers';
import { useEventRequests } from 'components/EventRequests/hooks/useEventRequests';

const EventRequestCard = ({ event }) => {
  const {
    user: { role, medical }
  } = useAuth();
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const { handleSelectEvent } = useEventRequests();

  const totalApprovers = countApprovers(event);

  const dateTrans = formatDateLong(event.date);
  const createdAtTrans = formatDateLong(event.createdAt);
  const updatedAtTrans = formatDateLong(event.updatedAt);

  const closeEvent = () => {
    console.log('close here event');
  };

  /**
   * Hook para abrir el menú en la card
   */
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  /**
   * Funcion para abrir el drawer y mostrar movimientos
   */
  const OpenMovementsDrawer = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right',
        color: 'primary'
      },
      closeButton: true,
      title: event.name,
      caption: (
        <Typography variant="subtitle1" color="secondary">
          {intl.formatMessage(messages.movementsDrawer)}
        </Typography>
      ),
      body: <EventMovements movementsHistory={event.history} />
    });

    handleClose();
  };

  const actionButtons = useActions({
    anchorEl,
    handleOpenMenu,
    handleClose,
    OpenMovementsDrawer,
    closeEvent,
    status: event.status,
    eventDate: event.createdAt,
    eventId: event.id,
    hasExternalSpeaker: event.hasExternalSpeaker
  });

  const mapParticipantsMain = (particpantMain, message, franchise = true) => {
    let validateMain = true;

    if (!franchise) {
      validateMain = particpantMain.approved;
    }

    const htmlMapParticipantsMain = (
      <div className={validateMain ? '' : classes.opaci}>
        <Typography
          id={`${particpantMain.name}${particpantMain.id}`}
          component="div"
          color="primary"
          variant="caption"
        >
          {message}
          <Fragment> {':'} </Fragment>
          <Typography
            id={`${particpantMain.name}${particpantMain.id}-sub`}
            component="span"
            color="primary"
            variant="caption"
          >
            {particpantMain.name}
          </Typography>
        </Typography>
      </div>
    );

    return htmlMapParticipantsMain;
  };

  const mapApprovers = () => {
    let approversHtml = [];

    if (event.approvers.length !== 0) {
      approversHtml = event.approvers?.map((approver) => (
        <div className={approver.approved ? '' : classes.opaci}>
          <Typography
            id={`${approver.name}${approver._id}`}
            component="div"
            color="primary"
            variant="caption"
          >
            {approver.franchise.name}
            <Fragment> {':'} </Fragment>
            <Typography
              id={`${approver.name}${approver._id}-sub`}
              component="span"
              color="primary"
              variant="caption"
            >
              {approver?.name}
            </Typography>
          </Typography>
        </div>
      ));
    }

    return approversHtml;
  };

  return (
    <Card
      onClick={() => {
        history.push(`/eventRequests/${event.id}/details`);
        handleSelectEvent(event);
      }}
      className={classes.container}
      key={`${dateTrans}-${event.name}`}
    >
      <div className={classes.titles}>
        <Typography
          component="div"
          variant="body2"
          className={`${classes.status} ${event.status}`}
        >
          {intl.formatMessage(messageStatus[event.status])}
        </Typography>
        <div className={classes.actionButtons}>{actionButtons}</div>
      </div>
      <Typography
        component="div"
        variant="subtitile1"
        color="primary"
        className={classes.titlePrincipal}
      >
        {event.name || event.objective || 'Borrador'}
      </Typography>
      <Typography
        component="div"
        color="secondary"
        variant="subtitile1"
        className={classes.fontWeDark}
      >
        <span className={classes.type}>{event.type}</span>
        <Typography
          component="span"
          color="primary"
          variant="caption"
          className={classes.opaci}
        >
          <Fragment> {'-'} </Fragment>
          {createdAtTrans}
        </Typography>
      </Typography>
      <div>
        <Typography
          component="div"
          color="primary"
          variant="subtitile1"
          className={classes.fontWeDark}
        >
          {intl.formatMessage(messages.eventDate)}
          <Fragment className={classes.fontWeDark}> {':'} </Fragment>
          <Typography
            component="span"
            color="secondary"
            variant="caption"
            className={classes.fontWeDark}
          >
            {dateTrans}
          </Typography>
        </Typography>

        <Typography color="secondary" variant="body2" className={classes.id}>
          <b>ID:</b>{' '}
          {event.id
            ?.split('')
            .reverse()
            .slice(0, 5)
            .reverse()
            .join('')
            .toUpperCase()}
        </Typography>
      </div>
      <div className={classes.bodyParticipants}>
        {([roles.admin, roles.reviewer].includes(role) || medical) &&
          event.franchise.id &&
          mapParticipantsMain(
            event.franchise,
            intl.formatMessage(messages.franchise),
            true
          )}

        {![roles.manager, roles.representative].includes(role) &&
          event.district.id &&
          mapParticipantsMain(
            event.district,
            intl.formatMessage(messages.district),
            true
          )}

        {role !== roles.representative &&
          event.representative?.id &&
          mapParticipantsMain(
            event.representative,
            intl.formatMessage(messages.representative),
            true
          )}

        {event.district?.manager?.id &&
          mapParticipantsMain(
            event.district.manager,
            intl.formatMessage(messages.manager),
            false
          )}

        {event.franchise?.director?.id &&
          mapParticipantsMain(
            event.franchise.director,
            intl.formatMessage(messages.director),
            false
          )}

        {event.franchise?.medical?.id &&
          mapParticipantsMain(
            event.franchise.medical,
            intl.formatMessage(messages.medical),
            false
          )}

        {mapApprovers()}
      </div>
      <div className={classes.cardFooter}>
        <LinearProgress
          color="secondary"
          valueBuffer={totalApprovers}
          variant="determinate"
          value={totalApprovers}
        />
        <div className={classes.marginFooter}>
          <Typography component="div" color="primary" variant="caption">
            {`${intl.formatMessage(
              messageStatusFooter[event.status]
            )} ${updatedAtTrans}`}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

EventRequestCard.propTypes = {
  event: PropTypes.object
};

export { EventRequestCard };
