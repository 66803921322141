import { useMemo } from 'react';

import { SpeakersContext } from './SpeakersContext';

import { useApi } from 'hooks';

import { mapSpeakerList } from './helpers';
import { useState } from 'react';

export const SpeakersProvider = ({ children }) => {
  const [speakerListService, setSpeakerList] = useState();

  const [getListSpeakers] = useApi({
    endpoint: '/speaker/list',
    method: 'get'
  });
  const [createSpeakers] = useApi({
    endpoint: '/speaker/create',
    method: 'post'
  });
  const [updateSpeakers] = useApi({
    endpoint: '/speaker/update',
    method: 'put'
  });
  const [deleteSpeakers] = useApi({
    endpoint: '/speaker/delete',
    method: 'delete'
  });
  const [exportSpeaker] = useApi({
    endpoint: '/speaker/export',
    method: 'get'
  });

  const handleGetListSpeakers = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getListSpeakers();

      if (status === 200) {
        const speakersMap = mapSpeakerList(payload);

        setSpeakerList(speakersMap);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateSpeakers = async (speaker) => {
    try {
      const {
        headerResponse: { status }
      } = await createSpeakers({ body: speaker });

      if (status === 200) {
        handleGetListSpeakers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateSpeakers = async (speaker, speakerId) => {
    try {
      const {
        headerResponse: { status }
      } = await updateSpeakers({ body: speaker, urlParams: speakerId });

      if (status === 200) {
        handleGetListSpeakers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSpeakers = async (speakerId) => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await deleteSpeakers({ urlParams: speakerId });

      if (status === 200) {
        handleGetListSpeakers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportSepakers = async (speakerType, speaker, franchise) => {
    try {
      const queryString = {
        type: speakerType ? speakerType : '',
        employee_name: speaker ? speaker : '',
        franchise: franchise ? franchise : ''
      };
      const {
        headerResponse: { status },
        payload
      } = await exportSpeaker({ queryString });

      if (status === 200) {
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      speakerListService,
      handleGetListSpeakers,
      handleCreateSpeakers,
      handleUpdateSpeakers,
      handleDeleteSpeakers,
      handleExportSepakers
    }),
    [speakerListService]
  );

  return (
    <SpeakersContext.Provider value={contextValue}>
      {children}
    </SpeakersContext.Provider>
  );
};
