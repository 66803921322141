import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { messages } from './RolSectionMessages';
import { useStyles } from './RolSectionStyles';
import { getItem } from 'utils/persistentStorage';
import { get } from 'lodash';

const RolSection = () => {
  const intl = useIntl();
  const classes = useStyles();
  const session = getItem('session');

  return (
    <>
      <Typography variant="h6" color="primary" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Typography className={classes.subtitle}>
        {intl.formatMessage(messages.subtitleRol)}
      </Typography>
      <Typography>{get(session, 'user.title', '')}</Typography>
    </>
  );
};

RolSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { RolSection };
