import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.title',
    defaultMessage: 'Datos del speaker'
  },
  speakerIdLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.speakerIdLabel',
    defaultMessage: 'Speaker'
  },
  speakerIdPlaceholder: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.speakerIdPlaceholder',
    defaultMessage: 'Seleccione el speaker'
  },
  typeOfTalkLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.typeOfTalkLabel',
    defaultMessage: 'Tipo de plática'
  },
  typeOfTalkPlaceholder: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.typeOfTalkPlaceholder',
    defaultMessage: 'Seleccione el tipo de plática'
  },
  reasonForUsingExternalSpeakerLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.reasonForUsingExternalSpeakerLabel',
    defaultMessage: 'Razón de usar speaker externo'
  },
  reasonForUsingExternalSpeakerPlaceholder: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.reasonForUsingExternalSpeakerPlaceholder',
    defaultMessage: 'Seleccione la razón'
  },
  confirmationLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.confirmationLabel',
    defaultMessage: 'Adjuntar Confirmación: '
  },
  confirmationButtonLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.DataSection.confirmationButtonLabel',
    defaultMessage: 'Seleccionar archivo PDF'
  }
});
