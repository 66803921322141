import * as config from './config';

const LIGHT = 'light';
const DARK = 'dark';

export const availableSkins = { LIGHT, DARK };

export const skins = {
  [LIGHT]: {
    ...config.common,
    ...config.light
  },
  [DARK]: {
    ...config.common,
    ...config.dark
  }
};
