export const metas = {
  '/': [
    {
      name: 'description',
      content: 'Home component'
    }
  ],
  '/login': [
    {
      name: 'description',
      content: 'Login component'
    }
  ],
  '/recoveryPassword': [
    {
      name: 'description',
      content: 'RestorePassword component'
    }
  ],
  '/employees': [
    {
      name: 'description',
      content: 'Empleados'
    }
  ],
  '/franchises': [
    {
      name: 'description',
      content: 'Franquicias'
    }
  ],
  '/franchises/:id': [
    {
      name: 'description',
      content: 'Franquicia'
    }
  ],
  '/formatRepository': [
    {
      name: 'description',
      content: 'Repositorio de formatos'
    }
  ],
  '/eventRequests': [
    {
      name: 'eventRequests',
      content: 'Solicitudes de eventos'
    }
  ],
  '/eventRequests/new': [
    {
      name: 'description',
      content: 'EventRequestForm component'
    }
  ],
  '/speakers': [
    {
      name: 'description',
      content: 'speakers'
    }
  ]
};
