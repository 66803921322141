import { responseMapper } from 'utils/responseMapper';

const configurationTemplate = {
  anticipationDays: 'advance_days_to_create_event',
  facilityRentalCost: 'rent_cost',
  materialsCost: 'material_cost',
  minimumNumberOfGuestsForNovoEventAndNovoSupport: 'min_assistant_novo_event',
  maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities:
    'max_assistant_face_to_face',
  maximumFoodCostPerPerson: 'max_expense_food',
  firstCloseNotification: 'first_close_reminder',
  secondCloseNotification: 'second_close_reminder',
  thirdCloseNotification: 'third_close_reminder'
};

export const mapperConfiguration = (configuration) => {
  const mappedConfiguration = responseMapper({
    data: configuration,
    template: configurationTemplate
  });

  return mappedConfiguration;
};
