import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { useStyles } from './HcpsStyles';
import { HcpsActions } from './HcpsActions';
import { HcpsTable } from './HcpsTable';
import { HcpsFiltersForm } from './HcpsFiltersForm';

const Hcps = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container>
        <HcpsActions />
        <HcpsFiltersForm />
      </Container>

      <HcpsTable />
    </div>
  );
};

Hcps.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Hcps };
