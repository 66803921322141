import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2.5)
    }
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export { useStyles };
