import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  CircularProgress
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import AddIcon from '@material-ui/icons/AddCircle';
import { useModal } from '@octopy/react-modal';
import { useHistory } from 'react-router-dom';
import { messages } from './DistrictMessages';
import { useStyles } from './DistrictStyles';
import { EmployeeCard } from 'components/EmployeeCard';
import { AddRepresentativeForm } from './AddRepresentativeForm';
import { useDrawer } from 'components/Drawer';
import { useDistrict } from './hooks/useDistrict';
import { useRouteMatch } from 'react-router-dom';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';
import { NoData } from 'components/NoData';

const District = () => {
  const {
    params: { districtId }
  } = useRouteMatch();
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const { handleOpenDrawer } = useDrawer();
  const { handleOpenModal, handleCloseModal } = useModal();
  const {
    district,
    manager,
    representatives,
    handleGetDistrict,
    handleAddRepresentative,
    handleRemoveRepresentative
  } = useDistrict();
  const {
    user: { role }
  } = useAuth();

  useEffect(() => {
    handleGetDistrict(districtId);
  }, []);

  const handleOpenAddRepresentativeForm = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.addRepresentativeFormTitle),
      caption: intl.formatMessage(messages.addRepresentativeFormCaption),
      body: (
        <AddRepresentativeForm
          onSubmit={async (values) =>
            await handleAddRepresentative(districtId, values)
          }
        />
      )
    });
  };

  const handleOpenRemoveRepresentativeModal = ({ name, id }) => {
    handleOpenModal({
      title: intl.formatMessage(messages.removeRepresentativeModalTitle),
      body: (
        <>
          {intl.formatMessage(messages.removeRepresentativeModalBody1)}{' '}
          <span className={classes.removeRepresentativeModalImportantText}>
            {name}
          </span>{' '}
          {intl.formatMessage(messages.removeRepresentativeModalBody2)}
        </>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleRemoveRepresentative(districtId, id);
            }}
          >
            {intl.formatMessage(messages.accept)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const employeesLength = +!!manager?.name + representatives?.length;

  return (
    <Container>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          {[roles.admin, roles.director].includes(role) && (
            <IconButton
              edge="start"
              color="secondary"
              className={classes.backButton}
              onClick={() =>
                history.push(`/franchises/${district.franchise.id}`)
              }
            >
              <BackIcon />
            </IconButton>
          )}
          <Typography color="primary" variant="h5" className={classes.title}>
            {district?.franchise?.name || (
              <CircularProgress color="primary" size={20} />
            )}
            {!xs && <> - </>}
            <Typography
              component={xs ? 'div' : 'span'}
              color="secondary"
              variant="h5"
              className={classes.title}
            >
              {district?.name || (
                <CircularProgress color="secondary" size={20} />
              )}
            </Typography>
          </Typography>
        </div>
        {role === roles.admin && (
          <Button
            onClick={handleOpenAddRepresentativeForm}
            endIcon={<AddIcon />}
            color="primary"
            className={classes.newRepresentativeButton}
          >
            {intl.formatMessage(messages.newRepresentative)}
          </Button>
        )}
      </div>

      <Box py={1}>
        <Typography variant="subtitile1" className={classes.subtitle}>
          {intl.formatMessage(messages.subtitle)}
        </Typography>

        <div className={classes.cardsContainer}>
          {representatives ? (
            employeesLength ? (
              <>
                <EmployeeCard
                  name={manager.name}
                  imageUrl={manager.imageUrl}
                  identifier={manager.identifier}
                  role={intl.formatMessage(messages.manager)}
                  color="primary"
                />

                {representatives?.map((representative) => (
                  <EmployeeCard
                    name={representative.name}
                    imageUrl={representative.imageUrl}
                    identifier={representative.identifier}
                    role={intl.formatMessage(messages.saleRepresentative)}
                    onRemoveClick={
                      role === roles.admin
                        ? () =>
                            handleOpenRemoveRepresentativeModal(representative)
                        : undefined
                    }
                  />
                ))}
              </>
            ) : (
              <NoData pl={2} pt={2} pb={4} />
            )
          ) : (
            <NoData pl={2} pt={2} pb={4} loading />
          )}
        </div>
      </Box>
    </Container>
  );
};

export { District };
