import React from 'react';
import { Grid } from '@material-ui/core';
import { Dashboard } from 'components/Dashboard';
import './Home.scss';

function Home() {
  return (
    <Grid className="home">
      <Dashboard />
    </Grid>
  );
}

export { Home };
