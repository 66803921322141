import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  home: {
    id: 'components.AccountPopover.home',
    defaultMessage: 'Inicio'
  },
  logout: {
    id: 'components.AccountPopover.logout',
    defaultMessage: 'Cerrar sesión'
  }
});
