import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.AddFormatForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  nameLabel: {
    id: 'components.AddFormatForm.nameLabel',
    defaultMessage: 'Nombre'
  },
  namePlaceholder: {
    id: 'components.AddFormatForm.namePlaceholder',
    defaultMessage: 'Ingrese el nombre del formato'
  },
  fileLabel: {
    id: 'components.AddFormatForm.fileLabel',
    defaultMessage: 'Seleccionar archivo'
  },
  filePlaceholder: {
    id: 'components.AddFormatForm.filePlaceholder',
    defaultMessage: 'Ingrese nombre del empleado'
  },
  document: {
    id: 'components.AddFormatForm.document',
    defaultMessage: 'Documento'
  },
  anErrorHasOccured: {
    id: 'components.AddFormatForm.anErrorHasOccured',
    defaultMessage: 'Ha ocurrido un error'
  },
  fileUploadError: {
    id: 'components.AddFormatForm.fileUploadError',
    defaultMessage: 'Ha ocurrido un error al intentar subir el archivo'
  },
  accept: {
    id: 'components.AddFormatForm.accept',
    defaultMessage: 'Aceptar'
  }
});
