import { roles } from 'components/Auth/helpers';

import { messages } from '../EventSearchMessages';
import { useStyles } from '../EventSearchStyles';

import { useIntl } from 'react-intl';

import DateIcon from '@material-ui/icons/TodayRounded';
import { useAuth } from 'components/Auth';
import { useCatalogues } from 'components/Catalogues';
import { useEffect, useState } from 'react';
import { eventStatus, eventTypes, speakerType } from '../helpers';

export const useFields = (formik) => {
  const {
    user: { role, medical }
  } = useAuth();

  const {
    franchises,
    districts,
    handleGetFranchises,
    handleGetDistricts
  } = useCatalogues();

  const [filteredDistricts, setFilteredDistricts] = useState([]);

  useEffect(() => {
    handleGetDistricts();
    handleGetFranchises();
  }, []);

  useEffect(() => {
    formik.setFieldValue('district', null);

    if (formik.values.businessUnit) {
      setFilteredDistricts(
        districts.filter(
          (district) => district.franchiseId === formik.values.businessUnit
        )
      );
    }
  }, [formik.values.businessUnit]);

  const intl = useIntl();
  const classes = useStyles();

  const fields = {
    startDate: {
      type: 'datePicker',
      name: 'startDate',
      label: intl.formatMessage(messages.startDateLabel),
      placeholder: intl.formatMessage(messages.startDatePlaceholder),
      variant: 'inline',
      format: 'dd/MM/yyyy',
      disableToolbar: true,
      InputProps: {
        endAdornment: <DateIcon />
      },
      className: classes.datePicker
    },
    endDate: {
      type: 'datePicker',
      name: 'endDate',
      label: intl.formatMessage(messages.endDateLabel),
      placeholder: intl.formatMessage(messages.endDatePlaceholder),
      variant: 'inline',
      format: 'dd/MM/yyyy',
      disableToolbar: true,
      InputProps: {
        endAdornment: <DateIcon />
      },
      className: classes.datePicker
    },
    eventType: {
      type: 'select',
      name: 'eventType',
      label: intl.formatMessage(messages.eventType),
      placeholder: intl.formatMessage(messages.eventType),
      options: eventTypes,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value,
      className: classes.searchItems
    },
    eventStatus: {
      type: 'select',
      name: 'eventStatus',
      label: intl.formatMessage(messages.eventStatus),
      placeholder: intl.formatMessage(messages.eventStatus),
      options: eventStatus,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value,
      className: classes.searchItems
    },
    speaker: {
      type: 'select',
      name: 'speaker',
      label: intl.formatMessage(messages.speaker),
      placeholder: intl.formatMessage(messages.speaker),
      options: speakerType,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value,
      className: classes.searchItems
    },
    businessUnit: {
      type: 'select',
      name: 'businessUnit',
      label: intl.formatMessage(messages.businessUnit),
      placeholder: intl.formatMessage(messages.businessUnit),
      options: franchises,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value,
      className: classes.searchItems
    },
    district: {
      type: 'select',
      name: 'district',
      label: intl.formatMessage(messages.district),
      placeholder: intl.formatMessage(messages.district),
      options: filteredDistricts,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value,
      disabled: !formik.values.businessUnit,
      className: classes.searchItems
    }
  };

  const fieldsDatesByRole = {
    [roles.admin]: [fields.startDate, fields.endDate],
    // [roles.medical]: [fields.startDate, fields.endDate],
    [roles.representative]: medical ? [fields.startDate, fields.endDate] : [],
    [roles.manager]: medical ? [fields.startDate, fields.endDate] : [],
    [roles.reviewer]: medical ? [fields.startDate, fields.endDate] : [],
    [roles.director]: medical ? [fields.startDate, fields.endDate] : []
  };

  const fieldsByRole = {
    [roles.admin]: [
      fields.eventType,
      fields.eventStatus,
      fields.businessUnit,
      fields.district
    ],
    [roles.representative]: [
      fields.eventType,
      fields.eventStatus,
      ...(medical ? [fields.businessUnit] : []),
      fields.speaker
    ],
    [roles.manager]: [
      fields.eventType,
      fields.eventStatus,
      ...(medical ? [fields.businessUnit] : [])
    ],
    // [roles.medical]: [
    //   fields.eventType,
    //   fields.eventStatus,
    //   fields.businessUnit
    // ],
    [roles.reviewer]: [
      fields.eventType,
      fields.eventStatus,
      fields.businessUnit,
      fields.district
    ],
    [roles.director]: medical
      ? [
          fields.eventType,
          fields.eventStatus,
          fields.district,
          fields.businessUnit
        ]
      : [fields.eventType, fields.eventStatus, fields.district]
  };

  return {
    datesFields: fieldsDatesByRole[role],
    searchFields: fieldsByRole[role]
  };
};
