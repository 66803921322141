import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  employeeText: {
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  }
}));

export { useStyles };
