import { useDrawer } from 'components/Drawer';
import { useLoader } from '@octopy/react-loader';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../ScheduleDrawerMessages';
import { uploadFilePromise } from 'utils/aws';
import { Button } from '@material-ui/core';
import { useModal } from '@octopy/react-modal';

const defaultInitialValues = {
  name: '',
  document: {
    url: null,
    file: null
  }
};

export const useForm = ({ initialValues = defaultInitialValues, onSubmit }) => {
  const { handleCloseDrawer } = useDrawer();
  const { handleShowLoader } = useLoader();
  const intl = useIntl();
  const { handleOpenModal, handleCloseModal } = useModal();

  const validationSchema = yup.object({
    name: yup.string().required(intl.formatMessage(messages.requiredError)),
    document: yup
      .object({
        url: yup.string().nullable(),
        file: yup
          .mixed()
          .nullable()
          .required(intl.formatMessage(messages.requiredError))
          .test(
            'fileType',
            intl.formatMessage(messages.mustBePdfError),
            (file) => !file?.type || file?.type === 'application/pdf'
          )
      })
      .required(intl.formatMessage(messages.requiredError))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (_values) => {
      try {
        handleShowLoader(true);

        const values = {
          ..._values,
          document: {
            url:
              _values.document.file instanceof File
                ? await uploadFilePromise(_values.document.file)
                : _values.document.url
          }
        };

        const done = await onSubmit(values);

        if (done) {
          handleCloseDrawer();
        }
      } catch (error) {
        console.log(error);
        handleOpenModal({
          configProps: {
            maxWidth: 'xs'
          },
          title: intl.formatMessage(messages.anErrorHasOccured),
          body: intl.formatMessage(messages.fileUploadError),
          actionButtons: (
            <Button onClick={handleCloseModal}>
              {intl.formatMessage(messages.accept)}
            </Button>
          )
        });
      } finally {
        handleShowLoader(false);
      }
    }
  });

  const fields = {
    name: {
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.documentSchedule),
      placeholder: intl.formatMessage(messages.documentSchedule)
    },
    document: {
      name: 'document',
      buttonLabel: intl.formatMessage(messages.documentButtonSchedule),
      onChange: (value) => formik.setFieldValue('document', value),
      value: formik.values.document,
      error: formik.touched.document?.file && formik.errors.document?.file,
      type: 'application/pdf'
    }
  };

  return { formik, fields };
};
