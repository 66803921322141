import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  emailTextField: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '90%'
    }
  }
}));

export { useStyles };
