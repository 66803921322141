import { useCatalogues } from 'components/Catalogues';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../UserDataSectionMessages';

export const useFields = () => {
  const intl = useIntl();
  const [cities, setCities] = useState([]);
  const { handleGetCitiesCatalogue } = useCatalogues();
  const inputProps = { fullWidth: true };

  useEffect(() => {
    (async () => {
      const catalogueResponse = await handleGetCitiesCatalogue();

      if (catalogueResponse) {
        setCities(catalogueResponse);
      }
    })();
  }, []);

  const fields = [
    {
      type: 'text',
      name: 'identifier',
      label: intl.formatMessage(messages.idLabel),
      placeholder: '1221222',
      breakpoints: { xs: 12 },
      disabled: true,
      inputProps
    },
    {
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: 'Ej. Roberto Martinez',
      breakpoints: { xs: 12 },
      inputProps,
      value: 'name'
    },

    {
      type: 'text',
      name: 'email',
      label: intl.formatMessage(messages.emailLabel),
      placeholder: 'Ej. admin@dominio.com',
      breakpoints: { xs: 12 },
      inputProps
    },
    {
      type: 'text',
      name: 'rfc',
      label: intl.formatMessage(messages.rfcLabel),
      placeholder: 'Ej. 1221222',
      breakpoints: { xs: 12 },
      inputProps
    },
    {
      type: 'select',
      name: 'city',
      label: intl.formatMessage(messages.cityLabel),
      placeholder: intl.formatMessage(messages.cityLabel),
      breakpoints: { xs: 12 },
      options: cities,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value
    },
    inputProps
  ];

  return { fields };
};
