import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box } from '@material-ui/core';
import { messages } from './ObjectiveSectionMessages';
import { useStyles } from './ObjectiveSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const ObjectiveSection = () => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    eventRequest: { objective }
  } = useEventRequestDetails();

  if (!objective) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.titleCard)}
      </Typography>
      <Box className={classes.containerInfo}>
        <Typography className={classes.informationLabel}>
          {intl.formatMessage(messages.objectiveDescription)}
        </Typography>
        <Typography>{objective}</Typography>
      </Box>
    </Box>
  );
};

ObjectiveSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ObjectiveSection };
