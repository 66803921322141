import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useFields } from './hooks/useFields';
import {
  renderAutocomplete,
  renderSwitch,
  renderTextField
} from '@octopy/react-form';
import { useIntl } from 'react-intl';
import { messages } from './FoodSectionMessages';
import { useStyles } from './FoodSectionStyles';
import { FilePicker } from 'components/FilePicker';

export const FoodSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFields({ formik });

  const extraInputProps = {
    fullWidth: true
  };

  const fieldsMapper = ({ inputProps, ...field }, index) => (
    <Grid
      className={classes.gridItem}
      key={`food-section-field-${index}`}
      item
      {...field.breakpoints}
    >
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}
      {['number', 'text'].includes(field.type) &&
        renderTextField({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}

      {field.type === 'application/pdf' && (
        <div className={classes.filePicker}>
          <Typography
            variant="subtitle2"
            className={field.disabled && classes.disabledText}
          >
            {field.label}
          </Typography>
          <FilePicker {...field} />
        </div>
      )}
    </Grid>
  );

  return (
    <>
      <Box pb={2} className={classes.header}>
        {renderSwitch({
          formik,
          field: { type: 'switch', name: 'foodEnabled' }
        })}
        <Typography
          variant="subtitle"
          className={`${classes.title} ${
            !formik.values.foodEnabled && classes.disabledText
          }`}
        >
          {intl.formatMessage(messages.title)}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {fields.map(fieldsMapper)}
      </Grid>
    </>
  );
};
