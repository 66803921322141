import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../AddressSectionMessages';
import { useCatalogues } from 'components/Catalogues';
import { useStyles } from '../AddressSectionStyles';

export const useFields = () => {
  const intl = useIntl();
  const { handleGetCitiesCatalogue } = useCatalogues();
  const [citiesCatalogue, setCitiesCatalogue] = useState([]);

  useEffect(() => {
    (async () => {
      const catalogueResponse = await handleGetCitiesCatalogue();

      if (catalogueResponse) {
        setCitiesCatalogue(catalogueResponse);
      }
    })();
  }, []);

  const commonBreakpoints = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3
  };

  return [
    {
      type: 'select',
      name: 'address.cityId',
      label: intl.formatMessage(messages.cityLabel),
      placeholder: intl.formatMessage(messages.cityPlaceholder),
      options: citiesCatalogue,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: commonBreakpoints
    },
    {
      type: 'text',
      name: 'address.colonyId',
      label: intl.formatMessage(messages.colonyLabel),
      placeholder: intl.formatMessage(messages.colonyPlaceholder),
      breakpoints: commonBreakpoints
    },
    {
      type: 'number',
      name: 'address.externalNumber',
      label: intl.formatMessage(messages.externalNumberLabel),
      placeholder: intl.formatMessage(messages.externalNumberPlaceholder),
      breakpoints: commonBreakpoints
    },
    {
      type: 'number',
      name: 'address.internalNumber',
      label: intl.formatMessage(messages.internalNumberLabel),
      placeholder: intl.formatMessage(messages.internalNumberPlaceholder),
      breakpoints: commonBreakpoints
    },
    {
      type: 'number',
      name: 'address.zipCode',
      label: intl.formatMessage(messages.zipCodeLabel),
      placeholder: intl.formatMessage(messages.zipCodePlaceholder),
      breakpoints: commonBreakpoints
    }
  ];
};
