import DashboardIcon from '@material-ui/icons/DashboardRounded';
import EventRequestsIcon from '@material-ui/icons/EventRounded';
import FormatRepositoryIcon from '@material-ui/icons/AttachFileRounded';
import FranchisesIcon from '@material-ui/icons/GroupWorkRounded';
import EmployeesIcon from '@material-ui/icons/PeopleAltRounded';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { messages } from './SideMenuMessages';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';
import SettingsIcon from '@material-ui/icons/Settings';
import SpeakersIcon from '@material-ui/icons/RecordVoiceOver';

export const useLists = () => {
  const {
    user: { role, franchiseId, districtId, medical }
  } = useAuth();

  const routes = {
    home: {
      name: 'home',
      path: '/',
      message: messages.dashboard,
      icon: DashboardIcon
    },
    eventRequests: {
      name: 'eventRequests',
      path: '/eventRequests',
      message: messages.eventRequests,
      icon: EventRequestsIcon
    },
    formatRepository: {
      name: 'formatRepository',
      path: '/formatRepository',
      message: messages.formatRepository,
      icon: FormatRepositoryIcon
    },
    franchises: {
      name: 'franchises',
      path: '/franchises',
      message: messages.franchises,
      icon: FranchisesIcon
    },
    franchise: {
      name: 'franchise',
      path: `/franchises/${franchiseId}`,
      message: messages.franchise,
      icon: FranchisesIcon
    },
    district: {
      name: 'district',
      path: `/franchises/districts/${districtId}`,
      message: messages.district,
      icon: FranchisesIcon
    }
  };

  return [
    {
      items:
        role === roles.admin
          ? [
              routes.home,
              routes.eventRequests,
              routes.formatRepository,
              routes.franchises
            ]
          : role === roles.representative
          ? [
              routes.home,
              routes.eventRequests,
              routes.formatRepository,
              medical ? routes.franchises : routes.franchise
            ]
          : role === roles.manager
          ? [
              routes.home,
              routes.eventRequests,
              medical ? routes.franchises : routes.district
            ]
          : role === roles.director
          ? [
              routes.home,
              routes.eventRequests,
              medical ? routes.franchises : routes.franchise
            ]
          : // : role === roles.medical
          // ? [routes.home, routes.eventRequests, routes.franchises]
          role === roles.reviewer
          ? [routes.home, routes.eventRequests]
          : []
    },
    ...(role === roles.admin
      ? [
          {
            title: messages.management,
            items: [
              {
                name: 'employees',
                message: messages.employees,
                icon: EmployeesIcon,
                path: '/employees'
              },
              {
                name: 'hcps',
                message: messages.hcps,
                icon: EmojiPeopleIcon,
                path: '/hcps'
              },
              {
                name: 'speakers',
                path: '/speakers',
                message: messages.speakers,
                icon: SpeakersIcon
              },
              {
                name: 'configuration',
                message: messages.configuration,
                icon: SettingsIcon,
                path: '/configuration'
              }
            ]
          }
        ]
      : [])
  ];
};
