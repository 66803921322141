import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  closeRequest: {
    id: 'components.ObserverEventRequestDetails.closeRequest',
    defaultMessage: 'Cerrar solicitud'
  },
  eventDateHasntPassed: {
    id: 'components.ObserverEventRequestDetails.eventDateHasntPassed',
    defaultMessage: 'La fecha del evento aún no ha pasado'
  },
  finishEventRequest: {
    id: 'components.ObserverEventRequestDetails.finishEventRequest',
    defaultMessage: 'Dar fin a la solicitud de evento'
  }
});
