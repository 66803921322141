import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import { messages } from './ApproversMessages';
import { useModal } from '@octopy/react-modal';
import { useStyles } from './ApproversStyles';
import { EmployeeCard } from '../../EmployeeCard';
import { useFranchise } from 'components/Franchise/hooks/useFranchise';
import { useParams } from 'react-router-dom';
import { NoData } from 'components/NoData';

const Approvers = () => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    approvers,
    franchise: { medical, director } = {},
    handleAddOrRemoveApprover
  } = useFranchise();

  const { franchiseId } = useParams();

  const { handleOpenModal, handleCloseModal } = useModal();

  const handleSubmitApproverForm = (approverId) => {
    handleAddOrRemoveApprover(false, franchiseId, approverId);
    handleCloseModal();
  };

  const handleOpenRemoveApproverModal = (approver) => {
    handleOpenModal({
      title: intl.formatMessage(messages.removeApproverModalTitle),
      body: (
        <>
          {intl.formatMessage(messages.removeApproverModalBody1)}{' '}
          <span className={classes.removeApproverModalImportantText}>
            {approver.name}
          </span>{' '}
          {intl.formatMessage(messages.removeApproverModalBody2)}
        </>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => handleSubmitApproverForm(approver.approverId)}
          >
            {intl.formatMessage(messages.accept)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const approversLength =
    +!!director?.name + +!!medical?.name + approvers?.length;

  return (
    <div className={classes.container}>
      <Typography variant="subtitile1" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>

      <div className={classes.cardsContainer}>
        {approvers ? (
          approversLength ? (
            <>
              {!!director.name && (
                <EmployeeCard
                  name={director.name}
                  imageUrl={director.imageUrl}
                  identifier={director.identifier}
                  role={intl.formatMessage(messages.director)}
                />
              )}

              {!!medical.name && (
                <EmployeeCard
                  name={medical.name}
                  imageUrl={medical.imageUrl}
                  identifier={medical.identifier}
                  role={intl.formatMessage(messages.medical)}
                />
              )}

              {approvers?.map((approver) => (
                <EmployeeCard
                  key={approver.id}
                  name={approver.name}
                  imageUrl={approver.imageUrl}
                  role={approver.franchise?.name}
                  subRole={intl.formatMessage(messages.associatedApprover)}
                  onRemoveClick={() => handleOpenRemoveApproverModal(approver)}
                />
              ))}
            </>
          ) : (
            <NoData pl={2} pt={2} pb={4} />
          )
        ) : (
          <NoData pl={2} pt={2} pb={4} loading />
        )}
      </div>
    </div>
  );
};

Approvers.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Approvers };
