import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleCard: {
    id: 'components.PetitionerSection.titleCard',
    defaultMessage: 'SOLICITANTE'
  },
  id: {
    id: 'components.PetitionerSection.id',
    defaultMessage: 'Identificador:'
  },
  name: {
    id: 'components.PetitionerSection.name',
    defaultMessage: 'Nombre:'
  },
  email: {
    id: 'components.PetitionerSection.email',
    defaultMessage: 'Correo:'
  },
  franchise: {
    id: 'components.PetitionerSection.franchise',
    defaultMessage: 'Franquicia:'
  },
  district: {
    id: 'components.PetitionerSection.district',
    defaultMessage: 'Distrito:'
  }
});
