const filterByType = (speakers, speakerType) =>
  speakers.filter((speaker) => speaker.type.includes(speakerType));

const removeAccents = (str) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const filterByName = (speakers, speakerName) =>
  speakers.filter((speaker) => {
    let newSpeaker = removeAccents(speaker.name.toLowerCase());
    let newValue = removeAccents(speakerName.toLowerCase());

    return newSpeaker.includes(newValue);
  });

export const filterSearchSpeakers = (values, speakers) => {
  let filterSpeakers = [];

  if (values.speakerType !== '') {
    filterSpeakers = filterByType(speakers, values.speakerType);

    if (values.speakerName !== '') {
      filterSpeakers = filterByName(filterSpeakers, values.speakerName);
    }
  } else if (values.speakerName !== '') {
    filterSpeakers = filterByName(speakers, values.speakerName);
  }

  return filterSpeakers;
};
