import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    backgroundColor: hexToRgba(theme.palette.primary.main, 0.075),
    flexGrow: 1
  }
}));

export { useStyles };
