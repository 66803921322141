/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { HttpErrorAlert, useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { instance as mainInstance, cataloguesInstance } from 'providers/api';
import { toQueryString } from 'utils/http';
import { useAuth } from 'components/Auth/hooks/useAuth';
import { Button, Typography } from '@material-ui/core';

const useApi = ({ endpoint, method }) => {
  const [responseData, setResponseData] = useState(null);
  const [responseCode, setResponseCode] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const auth = useAuth();

  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleShowLoader } = useLoader();

  useEffect(() => {
    if (_.includes(endpoint, 'signout')) return;

    const isAuthFlow = _.includes(endpoint, 'signin');

    if (_.get(error, 'code', 0) === 401 && !isAuthFlow) {
      auth?.logout();

      handleOpenModal({
        configProps: {
          maxWidth: 'xs'
        },
        title: 'Tu sesión ha caducado',
        body: 'Vuelve a iniciar sesión.',
        actionButtons: <Button onClick={handleCloseModal}>Aceptar</Button>
      });
    } else if (error || (responseCode && responseCode >= 400)) {
      const customMessage = _.get(responseData, 'headerResponse.message', null);

      handleOpenModal({
        configProps: {
          maxWidth: 'xs'
        },
        title: 'Ha ocurrido un error',
        body: error.message || customMessage,
        actionButtons: <Button onClick={handleCloseModal}>Aceptar</Button>
      });
    }
  }, [error, responseCode]);

  const handleFetch = async ({
    body = {},
    urlParams = null,
    queryString = null,
    ignoreValidation = false
  } = {}) => {
    let url = `${endpoint}${urlParams ? `/${urlParams}` : ''}`;

    if (_.isObject(queryString)) {
      url = `${url}?${toQueryString(queryString)}`;
    }

    try {
      setLoading(true);
      handleShowLoader(true);
      setError(null);

      let instance;

      if (
        url.includes('catalogue') ||
        url.includes('config') ||
        url.includes('format') ||
        url.includes('agenda')
      ) {
        instance = cataloguesInstance;
      } else {
        instance = mainInstance;
      }

      const response = await instance[method](url, body);

      if (!ignoreValidation) {
        const headerResponse = _.get(response, ['data', 'headerResponse']);
        const hasError = _.get(headerResponse, 'status', 0) !== 200;

        const status = _.get(headerResponse, 'status', 666);
        const message = _.get(headerResponse, 'message', '');

        if (hasError) {
          setError({
            code: status,
            name: message,
            message: message
          });
        } else {
          setResponseData(response.data);
          setStatus(status);
          setResponseCode(status);
        }
      }

      return response.data;
    } catch (error) {
      console.log(error);

      setLoading(false);
      handleShowLoader(false);

      setError({
        code: _.get(error, 'response.status'),
        name: _.get(error, 'response.statusText'),
        message: _.get(error, 'response.data.headerResponse.message', null)
      });

      return _.get(error, 'response.data');
    } finally {
      setLoading(false);
      handleShowLoader(false);
    }
  };

  return [handleFetch, responseData, loading, status];
};

export { useApi };
