import { messages } from './EventRequestCardMessages';

export const messageStatus = {
  aprobada: messages.approved,
  'en revisión': messages.review,
  'en aprobación': messages.onAprobation,
  devuelta: messages.back,
  rechazada: messages.rejected,
  ingresada: messages.entered,
  archivada: messages.archived,
  creada: messages.created,
  cerrada: messages.close
};

export const messageStatusFooter = {
  aprobada: messages.approvedFooter,
  'en revisión': messages.reviewFooter,
  'en aprobación': messages.onAprobationFooter,
  devuelta: messages.backFooter,
  rechazada: messages.rejectedFooter,
  ingresada: messages.enteredFooter,
  archivada: messages.archivedFooter,
  creada: messages.createdFooter,
  cerrada: messages.closeFooter
};
