import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, Typography } from '@material-ui/core';
import { messages } from './SpeakersSectionMessages';
import { useStyles } from './SpeakersSectionStyles';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeCard } from 'components/EmployeeCard';
import { renderSwitch } from '@octopy/react-form';
import { useEventType } from 'components/EventRequestForm/hooks/useEventType';

const SpeakersSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { eventId } = useParams();

  const disabled = !formik.values.speakersEnabled;

  const eventType = useEventType();

  return (
    <>
      <Box className={classes.header}>
        <div>
          {renderSwitch({
            formik,
            field: {
              type: 'switch',
              name: 'speakersEnabled'
            }
          })}
          <Typography
            variant="subtitle"
            className={`${classes.title} ${disabled && classes.disabledText}`}
          >
            {intl.formatMessage(messages.title)}
          </Typography>
        </div>

        <Button
          className={classes.addSpeakerButton}
          color="primary"
          disabled={disabled}
          onClick={() =>
            history.replace(`/eventRequests/${eventId || 'new'}/speaker`, {
              values: formik.values,
              eventType
            })
          }
        >
          {intl.formatMessage(messages.addSpeaker)}
        </Button>
      </Box>

      {formik.touched.speakers && !!formik.errors.speakers && (
        <Typography variant="body2" color="error">
          {typeof formik.errors.speakers === 'string'
            ? formik.errors.speakers
            : 'Hay un error en los datos'}
        </Typography>
      )}

      {!!formik.values.speakers.length && (
        <Box
          className={`${classes.cardsContainer} ${
            disabled && classes.disabledCards
          }`}
        >
          {formik.values.speakers.map((speaker) => (
            <EmployeeCard
              key={speaker.data.speaker.id}
              {...speaker.data.speaker}
              role={intl.formatMessage(messages[speaker.type])}
              onRemoveClick={() =>
                formik.setFieldValue('speakers', [
                  ...formik.values.speakers.filter(
                    (currentSpeaker) =>
                      currentSpeaker.data.speaker.id !== speaker.data.speaker.id
                  )
                ])
              }
            />
          ))}
        </Box>
      )}
    </>
  );
};

export { SpeakersSection };
