import { responseMapper } from 'utils/responseMapper';

const dashboardTemplate = {
  runningRequests: [
    'running_request',
    {
      value: 'count',
      label: '_id'
    }
  ],
  completedRequest: [
    'completed_request',
    {
      value: 'count',
      label: '_id'
    }
  ],
  requestsPerFranchise: [
    'request_per_franchise',
    {
      value: 'count',
      label: '_id'
    }
  ],
  requestsPerStatus: [
    'request_per_status',
    {
      value: 'count',
      label: '_id'
    }
  ]
};

export const mapDashboard = (dashboard) => {
  const mappedDashboard = responseMapper({
    template: dashboardTemplate,
    data: dashboard
  });

  return mappedDashboard;
};
