import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.AddFranchisesDrawer.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  franchisesName: {
    id: 'components.AddFranchisesDrawer.franchisesName',
    defaultMessage: 'Nombre de la franquicia'
  },
  franchisePlaceholder: {
    id: 'components.AddFranchisesDrawer.franchisePlaceholder',
    defaultMessage: 'Ingrese nombre de la franquicia'
  },
  addDirector: {
    id: 'components.AddFranchisesDrawer.addDirector',
    defaultMessage: 'Director'
  },
  addMedical: {
    id: 'components.AddFranchisesDrawer.addMedical',
    defaultMessage: 'Médico'
  },
  create: {
    id: 'components.AddFranchisesDrawer.create',
    defaultMessage: 'Crear'
  },
  save: {
    id: 'components.AddFranchisesDrawer.save',
    defaultMessage: 'Guardar'
  },
  notSameDirectorAsMedicalError: {
    id: 'components.AddFranchisesDrawer.notSameDirectorAsMedicalError',
    defaultMessage: 'El director no puede ser medical a la vez'
  },
  notSameMedicalAsDirectorError: {
    id: 'components.AddFranchisesDrawer.notSameMedicalAsDirectorError',
    defaultMessage: 'El medical no puede ser director a la vez'
  }
});
