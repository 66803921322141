import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Container, Typography } from '@material-ui/core';
import { messages } from './RestorePasswordMessages';
import { useStyles } from './RestorePasswordStyles';
import { renderTextField } from '@octopy/react-form';
import { useForm } from './hooks/useForm';
import { Images } from 'assets';

export const RestorePassword = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { formik, fields, passwordInputs } = useForm();

  return (
    <Container className={classes.container}>
      <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
        <img src={Images.logoWithName} alt="" className={classes.logo} />

        <Typography variant="caption" className={classes.description}>
          {intl.formatMessage(messages.description)}
        </Typography>

        {renderTextField({
          formik,
          field: fields.password,
          passwordInputs
        })}
        {renderTextField({
          formik,
          field: fields.passwordConfirmation,
          passwordInputs
        })}

        <Button
          onClick={formik.handleSubmit}
          size="large"
          className={classes.submitButton}
          color="primary"
        >
          {intl.formatMessage(messages.restorePassword)}
        </Button>
        <input type="submit" hidden />
      </form>
    </Container>
  );
};
