import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../DataSectionMessages';
import { useLocation } from 'react-router-dom';
import { useCatalogues } from 'components/Catalogues';
import { useSpeakers } from 'components/Speakers/hooks/useSpeakers';

const exampleExternalSpeakers = [
  { id: '21lkdj12idj1l2d12', name: 'Fabio Bermudez' },
  { id: 'skcj092jc47hvalsk', name: 'Isaac Molina' },
  { id: '75939j93j9f93j39s', name: 'Enmanuel Espinoza' }
];

const exampleInternalSpeaker = [
  {
    id: 'salckjalcw2',
    identifier: 'CMSQ',
    name: 'Fabio Molina',
    imageUrl: ''
  },
  {
    id: 's1lckjalcwl',
    identifier: 'CMSQ',
    name: 'Fabio Bermudez',
    imageUrl: ''
  },
  {
    id: 'salck3alcwl',
    identifier: 'CMSQ',
    name: 'Fabio Isaac Enmanuel Bermudez Molina',
    imageUrl: ''
  },
  {
    id: 'salck4alcwl',
    identifier: 'CMSQ',
    name: 'Isaac Bermudez',
    imageUrl: ''
  },
  {
    id: 'salckj5lcwl',
    identifier: 'CMSQ',
    name: 'Enmanuel Bermudez Molina',
    imageUrl: ''
  },
  {
    id: 'salck6alcwl',
    identifier: 'CMSQ',
    name: 'Fabio Enmanuel Bermudez',
    imageUrl: ''
  }
];

export const useFields = ({ formik }) => {
  const intl = useIntl();
  const location = useLocation();
  const [speakerOptions, setSpeakerOptions] = useState([]);

  const {
    talkType: talkTypeOptions,
    speakerReason: speakerReasonOptions
  } = useCatalogues();

  const { speakerListService: speakers, handleGetListSpeakers } = useSpeakers();

  useEffect(() => {
    handleGetListSpeakers();
  }, []);

  useEffect(() => {
    const takenSpeakerIds = location.state.values.speakers.map(
      (speaker) => speaker.data.speaker.id
    );

    const externalSpeakers = (speakers || []).filter(
      (speaker) => speaker.type === 'externo'
    );

    const internalSpeakers = (speakers || []).filter(
      (speaker) => speaker.type === 'interno'
    );

    if (formik.values.type === 'externo') {
      setSpeakerOptions(
        externalSpeakers.filter(
          (speaker) => !takenSpeakerIds.includes(speaker.id)
        )
      );
    } else {
      setSpeakerOptions(
        internalSpeakers.filter(
          (speaker) => !takenSpeakerIds.includes(speaker.id)
        )
      );
    }

    formik.setFieldValue('data.speaker', null);
  }, [speakers, formik.values.type]);

  const commonBreakpoints = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3
  };

  return [
    {
      type: 'select',
      name: 'data.speaker',
      label: intl.formatMessage(messages.speakerIdLabel),
      placeholder: intl.formatMessage(messages.speakerIdPlaceholder),
      options: speakerOptions,
      getOptionLabel: (option) =>
        option.identifier
          ? `${option.identifier} - ${option.name}`
          : option.name,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option,
      breakpoints: commonBreakpoints
    },
    {
      type: 'select',
      name: 'data.typeOfTalk',
      label: intl.formatMessage(messages.typeOfTalkLabel),
      placeholder: intl.formatMessage(messages.typeOfTalkPlaceholder),
      options: talkTypeOptions,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: commonBreakpoints
    },
    {
      type: 'select',
      name: 'data.reasonForUsingExternalSpeaker',
      label: intl.formatMessage(messages.reasonForUsingExternalSpeakerLabel),
      placeholder: intl.formatMessage(
        messages.reasonForUsingExternalSpeakerPlaceholder
      ),
      options: speakerReasonOptions,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: commonBreakpoints,
      disabled: formik.values.type === 'interno'
    },
    {
      type: 'application/pdf',
      name: 'data.confirmation',
      buttonLabel: intl.formatMessage(messages.confirmationButtonLabel),
      label: intl.formatMessage(messages.confirmationLabel),
      value: formik.values.data.confirmation,
      onChange: (value) => formik.setFieldValue('data.confirmation', value),
      error:
        formik.touched.data?.confirmation?.file &&
        formik.errors.data?.confirmation?.file,
      breakpoints: {
        xs: 12
      },
      id: 'data.confirmation.filePicker'
    }
  ];
};
