import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  borderDashed: {
    // background:
    //   'linear-gradient(to right, #000 33%, #fff 0%) top/10px 1px repeat-x, linear-gradient(#000 33%, #fff 0%) right/1px 10px repeat-y, linear-gradient(to right, #000 33%, #fff 0%) bottom/10px 1px repeat-x, linear-gradient(#000 33%, #fff 0%) left/1px 10px repeat-y',
    // width: '100px',
    // height: '100px'
  },
  dropZone: {
    width: '100%',
    height: '180px',
    border: '1px dashed #c9c9c9',
    borderRadius: theme.spacing(1),
    backgroundColor: '#fafbfc',
    padding: theme.spacing(1)
  },
  uploadIcon: {
    fontSize: '42px'
  },
  subtitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1)
  }
}));

export { useStyles };
