import { useApi } from 'hooks';

export const useUpdateActions = () => {
  const [updateNovoEventRequest] = useApi({
    endpoint: '/request/update-novo-event',
    method: 'put'
  });

  const [updateNovoEventSupportRequest] = useApi({
    endpoint: '/request/update-novo-support',
    method: 'put'
  });

  const [updateNovoEventFaceToFaceRequest] = useApi({
    endpoint: '/request/update-face-to-face',
    method: 'put'
  });

  const [updateNovoEventPharmacyRequest] = useApi({
    endpoint: '/request/update-pharmacy-activities',
    method: 'put'
  });

  const handleUpdateNovoEventRequest = async (id, values) => {
    try {
      const body = values;

      const {
        headerResponse: { status }
      } = await updateNovoEventRequest({ urlParams: id, body });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateNovoEventSupportRequest = async (id, values) => {
    try {
      const body = values;

      const {
        headerResponse: { status }
      } = await updateNovoEventSupportRequest({ urlParams: id, body });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateNovoEventFaceToFaceRequest = async (id, values) => {
    try {
      const body = values;

      const {
        headerResponse: { status }
      } = await updateNovoEventFaceToFaceRequest({ urlParams: id, body });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateNovoEventPharmacyRequest = async (id, values) => {
    try {
      const body = values;

      const {
        headerResponse: { status }
      } = await updateNovoEventPharmacyRequest({ urlParams: id, body });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleUpdateNovoEventRequest,
    handleUpdateNovoEventSupportRequest,
    handleUpdateNovoEventFaceToFaceRequest,
    handleUpdateNovoEventPharmacyRequest
  };
};
