import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Speakers.title',
    defaultMessage: 'Speakers'
  },
  newSpeaker: {
    id: 'components.Speakers.newSpeaker',
    defaultMessage: 'Nuevo speaker'
  },
  speakersExport: {
    id: 'components.Speakers.speakersExport',
    defaultMessage: 'Exportar'
  }
});
