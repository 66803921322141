export const useInitialValues = () => ({
  draft: false,
  requirements: {
    date: null,
    time: null,
    placeName: ''
  },
  hcps: [],
  objective: ''
});
