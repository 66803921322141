import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentContainer: ({ error }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: error ? theme.spacing(1) : 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(0.5)
    }
  }),
  button: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(1)
    }
  },
  name: ({ disabled }) => ({
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: disabled ? theme.palette.text.disabled : undefined,
    pointerEvents: disabled ? 'none' : undefined,
    '&.clickable': {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  }),
  fileInput: {
    display: 'none'
  },
  error: {
    marginLeft: 14,
    color: theme.palette.error.main
  }
}));

export { useStyles };
