import { useIntl } from 'react-intl';
import { messages } from '../RequirementsSectionMessages';
import DateIcon from '@material-ui/icons/TodayRounded';
import { useEventType } from 'components/EventRequestForm/hooks/useEventType';
import { eventTypes } from 'components/EventRequestForm/helpers';
import { useCatalogues } from 'components/Catalogues';
import { useEffect } from 'react';
import { InputAdornment } from '@material-ui/core';
import { useConfiguration } from 'components/Configuration/hooks/useConfiguration';

export const useFields = ({ formik }) => {
  const intl = useIntl();
  const eventType = useEventType();
  const { schedules, handleGetSchedules } = useCatalogues();
  const { configuration } = useConfiguration();

  useEffect(() => {
    handleGetSchedules();
  }, []);

  const commonBreakpoints = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3
  };

  const novoEventFields = [
    {
      type: 'datePicker',
      name: 'requirements.date',
      label: intl.formatMessage(messages.dateLabel),
      placeholder: intl.formatMessage(messages.datePlaceholder),
      variant: 'inline',
      format: 'dd/MM/yyyy',
      disableToolbar: true,
      minDate: new Date(
        new Date().setDate(
          new Date().getDate() + ((configuration.anticipationDays || 0) + 1)
        )
      ),
      InputProps: {
        endAdornment: <DateIcon />
      },
      breakpoints: commonBreakpoints
    },
    {
      type: 'timePicker',
      name: 'requirements.time',
      label: intl.formatMessage(messages.timeLabel),
      placeholder: intl.formatMessage(messages.timePlaceholder),
      variant: 'inline',
      breakpoints: commonBreakpoints
    },
    {
      type: 'select',
      name: 'requirements.scheduleId',
      label: intl.formatMessage(messages.scheduleIdLabel),
      placeholder: intl.formatMessage(messages.scheduleIdPlaceholder),
      options: schedules,
      getOptionLabel: (option) => option.name,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.id,
      breakpoints: commonBreakpoints
    },
    {
      type: 'number',
      name: 'requirements.numberOfGuests',
      label: intl.formatMessage(messages.numberOfGuestsLabel),
      placeholder: intl.formatMessage(messages.numberOfGuestsPlaceholder),
      breakpoints: commonBreakpoints,
      inputProps: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">invitados</InputAdornment>
          )
        }
      }
    }
  ];

  const novoSupportFields = [
    ...novoEventFields,
    {
      type: 'select',
      name: 'requirements.supportType',
      label: intl.formatMessage(messages.supportTypeLabel),
      placeholder: intl.formatMessage(messages.supportTypePlaceholder),
      options: [
        { value: 'workshop-talk', label: 'Taller o plática' },
        { value: 'projects-sponsorships', label: 'Proyectos o patrocinios' },
        {
          value: 'cofee-service',
          label: 'Servicio de Café - Sin Platica Científica de NN'
        }
      ],
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: commonBreakpoints
    },
    {
      type: 'application/pdf',
      name: 'requirements.contract',
      buttonLabel: intl.formatMessage(messages.contractButtonLabel),
      label: intl.formatMessage(messages.contractLabel),
      value: formik.values.requirements.contract,
      onChange: (value) => formik.setFieldValue('requirements.contract', value),
      error:
        formik.touched.requirements?.contract?.file &&
        formik.errors.requirements?.contract?.file,
      breakpoints: {
        xs: 12,
        lg: 9
      },
      id: 'requirements.contract.filePicker'
    }
  ];

  const pharmacyActivitiesFields = [
    {
      type: 'text',
      name: 'requirements.pharmacyName',
      label: intl.formatMessage(messages.pharmacyNameLabel),
      placeholder: intl.formatMessage(messages.pharmacyNamePlaceholder),
      breakpoints: commonBreakpoints
    },
    {
      type: 'datePicker',
      name: 'requirements.date',
      label: intl.formatMessage(messages.dateLabel),
      placeholder: intl.formatMessage(messages.datePlaceholder),
      variant: 'inline',
      format: 'dd/MM/yyyy',
      disableToolbar: true,
      minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      InputProps: {
        endAdornment: <DateIcon />
      },
      breakpoints: commonBreakpoints
    },
    {
      type: 'timePicker',
      name: 'requirements.time',
      label: intl.formatMessage(messages.timeLabel),
      placeholder: intl.formatMessage(messages.timePlaceholder),
      variant: 'inline',
      breakpoints: commonBreakpoints
    }
  ];

  const faceToFaceFields = [
    {
      type: 'datePicker',
      name: 'requirements.date',
      label: intl.formatMessage(messages.dateLabel),
      placeholder: intl.formatMessage(messages.datePlaceholder),
      variant: 'inline',
      format: 'dd/MM/yyyy',
      disableToolbar: true,
      minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      InputProps: {
        endAdornment: <DateIcon />
      },
      breakpoints: commonBreakpoints
    },
    {
      type: 'timePicker',
      name: 'requirements.time',
      label: intl.formatMessage(messages.timeLabel),
      placeholder: intl.formatMessage(messages.timePlaceholder),
      variant: 'inline',
      breakpoints: commonBreakpoints
    },
    {
      type: 'text',
      name: 'requirements.placeName',
      label: intl.formatMessage(messages.placeNameLabel),
      placeholder: intl.formatMessage(messages.placeNamePlaceholder),
      breakpoints: commonBreakpoints
    }
  ];

  const fieldsByEventType = {
    [eventTypes.novoEvent]: novoEventFields,
    [eventTypes.novoSupport]: novoSupportFields,
    [eventTypes.pharmacyActivities]: pharmacyActivitiesFields,
    [eventTypes.faceToFace]: faceToFaceFields
  };

  return fieldsByEventType[eventType];
};
