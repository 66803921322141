import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  franchisesBusinessUnit: {
    id: 'components.Franchises.franchisesBusinessUnit',
    defaultMessage: 'Franquicias / Unidad de negocio'
  },
  newFranchises: {
    id: 'components.Franchises.newFranchises',
    defaultMessage: 'Nueva franquicia'
  },
  franchiseExport: {
    id: 'components.Franchises.franchiseExport',
    defaultMessage: 'Franquicias'
  },
  franchisesSearch: {
    id: 'components.Franchises.franchisesSearch',
    defaultMessage: 'Buscar'
  },
  franciseseExport: {
    id: 'components.Franchises.franciseseExport',
    defaultMessage: 'Exportar'
  },
  completeFranchisesDrawer: {
    id: 'components.Franchises.completeFranchisesDrawer',
    defaultMessage:
      'Completa los siguientes campos para crear una nueva unidad de negocio.'
  }
});
