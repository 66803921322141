import React from 'react';
import { RequirementsSection } from 'components/EventRequestDetails/sections/RequirementsSection';
import { HcpsSection } from 'components/EventRequestDetails/sections/HcpsSection';
import { ObjectiveSection } from 'components/EventRequestDetails/sections/ObjectiveSection';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { CommentsSection } from 'components/EventRequestDetails/sections/CommentsSection';
import { RequiredApprovalsSection } from 'components/EventRequestDetails/sections/RequiredApprovalsSection';

const FaceToFace = () => {
  const { eventRequest } = useEventRequestDetails();

  return (
    <>
      <RequirementsSection />
      <HcpsSection />
      <ObjectiveSection />
      <RequiredApprovalsSection />
      {!!eventRequest.comments?.length && <CommentsSection />}
    </>
  );
};

export { FaceToFace };
