import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { messages } from '../RestorePasswordMessages';
import { usePasswordInputs } from '@octopy/react-form';
import { useStyles } from '../RestorePasswordStyles';
import { useModal } from '@octopy/react-modal';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const useForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleCloseModal, handleOpenModal } = useModal();
  const history = useHistory();

  const initialValues = {
    password: '',
    passwordConfirmation: ''
  };

  const validationSchema = yup.object({
    password: yup.string().required(intl.formatMessage(messages.requiredError)),
    passwordConfirmation: yup
      .string()
      .required(intl.formatMessage(messages.requiredError))
      .oneOf(
        [yup.ref('password'), null],
        intl.formatMessage(messages.passwordNoMatchesError)
      )
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);

      handleOpenModal({
        title: intl.formatMessage(messages.restorePassword),
        body: intl.formatMessage(messages.successfullyRestored),
        actionButtons: (
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              history.push('/login');
            }}
          >
            {intl.formatMessage(messages.accept)}
          </Button>
        ),
        configProps: {
          maxWidth: 'xs'
        }
      });
    }
  });

  const fields = {
    password: {
      type: 'password',
      name: 'password',
      label: intl.formatMessage(messages.passwordLabel),
      placeholder: intl.formatMessage(messages.passwordPlaceholder),
      className: classes.textField,
      variant: 'filled'
    },
    passwordConfirmation: {
      type: 'password',
      name: 'passwordConfirmation',
      label: intl.formatMessage(messages.passwordConfirmationLabel),
      placeholder: intl.formatMessage(messages.passwordConfirmationPlaceholder),
      className: classes.textField,
      variant: 'filled'
    }
  };

  const passwordInputs = usePasswordInputs({
    fields: Object.values(fields),
    formik
  });

  return { fields, formik, passwordInputs };
};
