import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { messages } from '../LoginMessages';
import { usePasswordInputs } from '@octopy/react-form';
import { useStyles } from '../LoginStyles';
import { useAuth } from 'components/Auth/hooks/useAuth';

export const useForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const auth = useAuth();

  const initialValues = {
    email: '',
    password: ''
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(intl.formatMessage(messages.emailError))
      .required(intl.formatMessage(messages.requiredError)),
    password: yup.string().required(intl.formatMessage(messages.requiredError))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      auth.login({
        ...values,
        email: values.email.trim().toLowerCase()
      });
    }
  });

  const fields = {
    email: {
      type: 'text',
      name: 'email',
      label: intl.formatMessage(messages.emailLabel),
      placeholder: intl.formatMessage(messages.emailPlaceholder),
      className: classes.textField,
      variant: 'filled'
    },
    password: {
      type: 'password',
      name: 'password',
      label: intl.formatMessage(messages.passwordLabel),
      placeholder: intl.formatMessage(messages.passwordPlaceholder),
      className: classes.textField,
      variant: 'filled'
    }
  };

  const passwordInputs = usePasswordInputs({
    fields: Object.values(fields),
    formik
  });

  return { fields, formik, passwordInputs };
};
