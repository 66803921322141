import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Button } from '@material-ui/core';
import { messages } from './ImportHcpsFormMessages';
import { useStyles } from './ImportHcpsFormStyles';
import { FilePicker } from 'components/FilePicker';
import { useForm } from './hooks/useForm';

const ImportHcpsForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { formik, fields } = useForm();

  return (
    <>
      <Box pb={3}>
        <FilePicker {...fields.file} />
      </Box>

      <Button
        onClick={formik.handleSubmit}
        className={classes.submitButton}
        color="primary"
      >
        {intl.formatMessage(messages.accept)}
      </Button>
    </>
  );
};

export { ImportHcpsForm };
