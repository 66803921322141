import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestForm.sections.FoodSection.title',
    defaultMessage: 'Alimentos'
  },
  totalCostLabel: {
    id: 'components.EventRequestForm.sections.FoodSection.totalCostLabel',
    defaultMessage: 'Costo total'
  },
  totalCostPlaceholder: {
    id: 'components.EventRequestForm.sections.FoodSection.totalCostPlaceholder',
    defaultMessage: 'Ingrese el costo total'
  },
  costPerPersonLabel: {
    id: 'components.EventRequestForm.sections.FoodSection.costPerPersonLabel',
    defaultMessage: 'Costo por persona'
  },
  costPerPersonPlaceholder: {
    id:
      'components.EventRequestForm.sections.FoodSection.costPerPersonPlaceholder',
    defaultMessage: 'Ingrese el costo por persona'
  },
  tipLabel: {
    id: 'components.EventRequestForm.sections.FoodSection.tipLabel',
    defaultMessage: 'Propina'
  },
  tipPlaceholder: {
    id: 'components.EventRequestForm.sections.FoodSection.tipPlaceholder',
    defaultMessage: 'Seleccione la propina'
  },
  quoteLabel: {
    id: 'components.EventRequestForm.sections.FoodSection.quoteLabel',
    defaultMessage: 'Adjuntar Cotización: '
  },
  quoteButtonLabel: {
    id: 'components.EventRequestForm.sections.FoodSection.quoteButtonLabel',
    defaultMessage: 'Seleccionar archivo PDF'
  }
});
