import React from 'react';
import { Box } from '@material-ui/core';
import { RequirementsSection } from 'components/EventRequestDetails/sections/RequirementsSection';
import { EventLocationSection } from 'components/EventRequestDetails/sections/EventLocationSection';
import { ObjectiveSection } from 'components/EventRequestDetails/sections/ObjectiveSection';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { CommentsSection } from 'components/EventRequestDetails/sections/CommentsSection';
import { RequiredApprovalsSection } from 'components/EventRequestDetails/sections/RequiredApprovalsSection';

const PharmacyActivity = () => {
  const { eventRequest } = useEventRequestDetails();

  return (
    <Box>
      <RequirementsSection />
      <EventLocationSection />
      <ObjectiveSection />
      <RequiredApprovalsSection />
      {!!eventRequest.comments?.length && <CommentsSection />}
    </Box>
  );
};

export { PharmacyActivity };
