import { useApi } from 'hooks';

export const useCreateActions = () => {
  const [createNovoEventRequest] = useApi({
    endpoint: '/request/create-novo-event',
    method: 'post'
  });

  const [createEventSupportRequest] = useApi({
    endpoint: '/request/create-novo-support',
    method: 'post'
  });

  const [createEventFaceToFaceRequest] = useApi({
    endpoint: '/request/create-face-to-face',
    method: 'post'
  });

  const [createEventPharmacyRequest] = useApi({
    endpoint: '/request/create-pharmacy-activities',
    method: 'post'
  });

  const handleCreateNovoEventRequest = async (values) => {
    try {
      const body = values;

      const {
        headerResponse: { status }
      } = await createNovoEventRequest({ body });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateEventSupportRequest = async (values) => {
    try {
      const body = values;

      const {
        headerResponse: { status }
      } = await createEventSupportRequest({ body });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateEventFaceToFaceRequest = async (values) => {
    try {
      const body = values;

      const {
        headerResponse: { status }
      } = await createEventFaceToFaceRequest({ body });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateEventPharmacyRequest = async (values) => {
    try {
      const body = values;

      const {
        headerResponse: { status }
      } = await createEventPharmacyRequest({ body });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleCreateNovoEventRequest,
    handleCreateEventSupportRequest,
    handleCreateEventFaceToFaceRequest,
    handleCreateEventPharmacyRequest
  };
};
