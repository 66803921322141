import React, { useEffect, useState } from 'react';

import { roles } from 'components/Auth/helpers';

import { useAuth } from 'components/Auth';

import { useEventRequests } from './hooks/useEventRequests';
import { useIntl } from 'react-intl';
import { useStyles } from './EventRequestsStyles';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { messages } from './EventRequestsMessages';
import { EventRequestCard } from './components/EventRequestCard';
import { EventSearch } from './components/EventSearch';
import { NoData } from 'components/NoData';
import { useHistory } from 'react-router-dom';

import { CloseEventRequest } from '../CloseEventRequest';

const EventRequests = () => {
  const {
    user: { role, medical }
  } = useAuth();
  const intl = useIntl();
  const classes = useStyles();
  const {
    eventRequests,
    pendingEventRequests,
    handleGetEventRequests,
    handleGetPendingEventRequests
  } = useEventRequests();
  const [filteredEventRequests, setFilteredEventRequests] = useState();
  const [filters, setFilters] = useState({});

  const history = useHistory();

  const { clear } = useEventRequests();

  useEffect(() => () => clear(), []);

  useEffect(() => {
    if (eventRequests) {
      setFilteredEventRequests(
        eventRequests.filter((eventRequest) => {
          const results = [];

          filters.businessUnit &&
            results.push(eventRequest.franchise.id === filters.businessUnit);
          filters.district &&
            results.push(eventRequest.district.id === filters.district);
          filters.startDate &&
            results.push(new Date(eventRequest.date) >= filters.startDate);
          filters.endDate &&
            results.push(new Date(eventRequest.date) <= filters.endDate);
          filters.eventType &&
            results.push(eventRequest.type === filters.eventType);
          filters.eventStatus &&
            results.push(eventRequest.status === filters.eventStatus);

          return !results.includes(false);
        })
      );
    }
  }, [!!eventRequests, filters]);

  const funcEventSearch = (values) => {
    setFilters(values);
  };

  useEffect(() => {
    handleGetEventRequests();
    handleGetPendingEventRequests();
  }, []);

  return (
    <Container className={classes.container}>
      <div className={classes.header}>
        <Typography color="primary" variant="h5" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
        {role === roles.representative && (
          <Button
            onClick={() => history.push('eventRequests/new')}
            className={classes.buttomNewRequest}
            size="large"
            color="primary"
            endIcon={
              <AddCircleIcon>
                {intl.formatMessage(messages.newRequest)}
              </AddCircleIcon>
            }
          >
            {intl.formatMessage(messages.newRequest)}
          </Button>
        )}
      </div>

      <div className={classes.subtitleBody}>
        <Typography
          color="primary"
          variant="subtitle1"
          className={classes.subtitle}
        >
          {intl.formatMessage(
            messages[
              !medical ? `${role}PendingRequests` : 'medicalPendingRequests'
            ]
          )}
        </Typography>
      </div>

      <div className={classes.cardsContainer}>
        <Grid container spacing={2}>
          {pendingEventRequests ? (
            pendingEventRequests.length ? (
              pendingEventRequests.map((event) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <EventRequestCard event={event} showApproved={true} />
                </Grid>
              ))
            ) : (
              <NoData pl={1} pt={1} />
            )
          ) : (
            <NoData loading pl={1} pt={1} />
          )}
        </Grid>
      </div>

      <div className={classes.applyAndRevier}>
        <Typography
          color="primary"
          variant="subtitle1"
          className={classes.subtitle}
        >
          {intl.formatMessage(
            messages[!medical ? `${role}AllRequests` : 'medicalAllRequests']
          )}
        </Typography>
        {![roles.admin, roles.manager].includes(role) && (
          <Typography
            color="default"
            variant="subtitle2"
            className={classes.applyAndRevierBody}
          >
            {intl.formatMessage(
              messages[
                !medical ? `${role}AllRequestsBody` : 'medicalAllRequestsBody'
              ]
            )}
          </Typography>
        )}
      </div>

      <EventSearch onSubmit={funcEventSearch} />

      <div className={classes.cardsContainer}>
        <Grid container spacing={2}>
          {filteredEventRequests ? (
            filteredEventRequests.length ? (
              filteredEventRequests.map((event) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <EventRequestCard event={event} />
                </Grid>
              ))
            ) : (
              <NoData pl={1} />
            )
          ) : (
            <NoData loading pl={1} />
          )}
        </Grid>
      </div>
    </Container>
  );
};

export { EventRequests };
