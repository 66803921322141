import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { messages } from '../EventRequestFormMessages';
import { useStyles } from '../EventRequestFormStyles';
import { eventTypes } from '../helpers';

export const useTypeField = () => {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const { eventId } = useParams();

  const formik = useFormik({
    initialValues: {
      type: eventId ? null : location.state?.eventType || eventTypes.novoEvent
    }
  });

  const field = {
    name: 'type',
    type: 'select',
    label: intl.formatMessage(messages.typeLabel),
    placeholder: intl.formatMessage(messages.typePlaceholder),
    disabled: !!eventId,
    options: [
      { value: eventTypes.novoEvent, label: 'Novo evento' },
      { value: eventTypes.novoSupport, label: 'Novo apoyo' },
      {
        value: eventTypes.pharmacyActivities,
        label: 'Actividades de farmacias'
      },
      { value: eventTypes.faceToFace, label: 'Face to face' }
    ],
    getOptionLabel: (option) => option.label,
    getOptionSelected: (option, value) => option === value,
    getOptionValue: (option) => option.value,
    className: classes.typeField,
    disableClearable: true
  };

  return {
    setType: (type) => formik.setFieldValue('type', type),
    type: formik.values.type,
    field: {
      formik,
      field,
      inputProps: {}
    }
  };
};
