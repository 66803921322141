import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 20,
    height: '100%'
  },
  title: {
    fontWeight: 600,
    color: theme.palette.common.secondaryBackground,
    letterSpacing: 6,
    paddingBottom: theme.spacing(2)
  },
  description: {
    textAlign: 'center',
    paddingBottom: theme.spacing(4)
  },
  boxPad: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  }
}));

export { useStyles };
