export const mapFormats = (formats) => {
  const mappedFormats = formats.map((format) => ({
    id: format._id,
    name: format.name,
    url: format.file_url,
    date: new Date(format.createdAt),
    type: format.file_url.split('.').reverse()[0]
  }));

  return mappedFormats;
};
