import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { messages } from '../../LoginMessages';
import { useStyles } from '../ForgotPasswordModalContentStyles';
import { useModal } from '@octopy/react-modal';
import { Button } from '@material-ui/core';

export const useForm = ({ initialValues }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleCloseModal, handleOpenModal } = useModal();

  const validationSchema = yup.object({
    email: yup.string().required(intl.formatMessage(messages.requiredError))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      handleCloseModal();

      handleOpenModal({
        title: intl.formatMessage(messages.forgotPasswordModalTitle),
        body: intl.formatMessage(messages.checkEmail),
        actionButtons: (
          <Button color="primary" onClick={handleCloseModal}>
            {intl.formatMessage(messages.accept)}
          </Button>
        ),
        configProps: {
          maxWidth: 'xs'
        }
      });
    }
  });

  const fields = {
    email: {
      type: 'text',
      name: 'email',
      label: intl.formatMessage(messages.emailLabel),
      placeholder: intl.formatMessage(messages.emailPlaceholder),
      className: classes.emailTextField
    }
  };

  return { fields, formik };
};
