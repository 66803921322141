import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  container: ({ schedule: { url } }) => ({
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.customShadows.z2,
    width: 281,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    cursor: !!url ? 'pointer' : undefined,
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: !!url
        ? hexToRgba(theme.palette.primary.main, 0.05)
        : undefined
    }
  }),
  status: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    width: 132,
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    '&.approved': {
      background: hexToRgba(theme.palette.success.main),
      color: hexToRgba(theme.palette.common.white)
    },
    '&.disapproved': {
      background: hexToRgba(theme.palette.primary.dark),
      color: hexToRgba(theme.palette.common.white)
    }
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  bodyEllipsis: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    height: 60
  },
  franchise: {
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginTop: theme.spacing(1)
  },
  footer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  }
}));

export { useStyles };
