import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { messages } from './HcpsFiltersFormMessages';
import { useStyles } from './HcpsFiltersFormStyles';
import { renderTextField } from '@octopy/react-form';
import { useForm } from './hooks/useForm';

const HcpsFiltersForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { formik, fields } = useForm();

  const fieldsMapper = (field, index) => (
    <Grid key={`data-section-field-${index}`} item {...field.breakpoints}>
      {field.type === 'text' &&
        renderTextField({
          index,
          formik,
          field,
          inputProps: { size: 'small' }
        })}
    </Grid>
  );

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={8} md={10}>
        <Grid container spacing={2} className={classes.containerField}>
          {fields.map(fieldsMapper)}
        </Grid>
      </Grid>

      <Grid item xs={4} md={2}>
        <Button
          color="primary"
          className={classes.button}
          fullWidth
          onClick={formik.handleSubmit}
        >
          {intl.formatMessage(messages.filterButton)}
        </Button>
      </Grid>
    </Grid>
  );
};

HcpsFiltersForm.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { HcpsFiltersForm };
