import { useEffect, useState } from 'react';

export const useAccountPopover = () => {
  const [visible, setVisible] = useState();
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    setAnchor(document.getElementById('accountPopoverButton'));
  }, []);

  const handleOpen = () => setVisible(true);
  const handleClose = () => setVisible(false);

  return {
    visible,
    anchor,
    handleOpen,
    handleClose
  };
};
