import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Approvers.title',
    defaultMessage: 'Directivo y aprovadores'
  },
  removeApproverModalTitle: {
    id: 'components.Approvers.removeApproverModal.title',
    defaultMessage: 'Remover aprobador'
  },
  removeApproverModalBody1: {
    id: 'components.Approvers.removeApproverModal.body1',
    defaultMessage: '¿Estas seguro de querer eliminar a'
  },
  removeApproverModalBody2: {
    id: 'components.Approvers.removeApproverModal.body2',
    defaultMessage: 'como aprobador asociado de esta franquicia?'
  },
  director: {
    id: 'components.Approvers.director',
    defaultMessage: 'Director'
  },
  medical: {
    id: 'components.Approvers.medical',
    defaultMessage: 'Medical'
  },
  associatedApprover: {
    id: 'components.Approvers.associatedApprover',
    defaultMessage: 'Aprobador asociado'
  },
  accept: {
    id: 'components.Approvers.removeModal.accept',
    defaultMessage: 'Aceptar'
  },
  cancel: {
    id: 'components.Approvers.removeModal.cancel',
    defaultMessage: 'Cancelar'
  }
});
