import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.MyAccountForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  emailError: {
    id: 'components.MyAccountForm.emailError',
    defaultMessage: 'Correo electrónico inválido'
  },
  userUpdated: {
    id: 'components.MyAccountForm.userUpdated',
    defaultMessage: 'Usuario actualizado!'
  },
  btnAccept: {
    id: 'components.MyAccountForm.btnAccept',
    defaultMessage: 'Aceptar'
  },
  updateData: {
    id: 'components.MyAccountForm.updateData',
    defaultMessage: 'Actualizar datos'
  }
});
