import React, { useEffect, useRef, useState } from 'react';
import { Button, Box } from '@material-ui/core';
import { useStyles } from './ReviewerEventRequestDetailsStyles';
import { messages } from './ReviewerEventRequestDetailsMessages';
import { useIntl } from 'react-intl';
import { useModal } from '@octopy/react-modal';
import { ReviewerApproversContext } from './ReviewerApproversContext';
import { NovoSupport } from 'components/EventRequestDetails/byEventType/NovoSupport';
import { NovoEvent } from 'components/EventRequestDetails/byEventType/NovoEvent';
import { PharmacyActivity } from 'components/EventRequestDetails/byEventType/PharmacyActivity';
import { FaceToFace } from 'components/EventRequestDetails/byEventType/FaceToFace';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { useEventRequests } from 'components/EventRequests/hooks/useEventRequests';
import { CommentForm } from 'components/EventRequestDetails/CommentForm';
import { useParams, useHistory } from 'react-router-dom';
import { eventTypes } from 'components/EventRequestForm/helpers';

export const ReviewerEventRequestDetails = () => {
  const history = useHistory();
  const { eventId } = useParams();
  const classes = useStyles();
  const intl = useIntl();
  const { handleOpenModal, handleCloseModal } = useModal();
  const {
    eventRequest,
    reviewerActions: {
      handleCheckEventRequest,
      handleReturnEventRequest,
      handleApproveEventRequest
    }
  } = useEventRequestDetails();
  const commentFormikRef = useRef();

  useEffect(() => {
    if (eventRequest.status === 'ingresada') {
      handleCheckEventRequest(eventId);
    }
  }, [eventId]);

  const [manager, setManager] = useState();

  const [approvers, setApprovers] = useState([]);

  useEffect(() => {
    if (eventRequest) {
      setManager(eventRequest.manager);
      setApprovers(eventRequest.approvals);
    }
  }, [eventRequest]);

  const handleApprove = async () => {
    const enabledApproverIds = approvers.reduce(
      (enabledApproverIds, approver) =>
        approver.enabled
          ? [...enabledApproverIds, approver.id]
          : enabledApproverIds,
      []
    );

    const done = await handleApproveEventRequest(eventId, enabledApproverIds);

    if (done) {
      history.push('/eventRequests');
    }
  };

  const handleOpenReturnModal = () => {
    handleOpenModal({
      title: intl.formatMessage(messages.returnRequest),
      body: (
        <CommentForm
          onSubmit={async ({ comment }) => {
            const response = await handleReturnEventRequest(eventId, comment);

            if (response) {
              history.push('/eventRequests');
            }
          }}
          formikRef={commentFormikRef}
        />
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              commentFormikRef.current.submitForm();
            }}
          >
            {intl.formatMessage(messages.accept)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const switchApprover = (approverId) =>
    setApprovers((approvers) =>
      approvers.map((approver) =>
        approver.id === approverId
          ? { ...approver, enabled: !approver.enabled }
          : approver
      )
    );

  const approversContextValue = {
    manager,
    approvers,
    switchApprover
  };

  return (
    <ReviewerApproversContext.Provider value={approversContextValue}>
      {eventRequest.type === eventTypes.novoSupport && <NovoSupport />}
      {eventRequest.type === eventTypes.novoEvent && <NovoEvent />}
      {eventRequest.type === eventTypes.pharmacyActivities && (
        <PharmacyActivity />
      )}
      {eventRequest.type === eventTypes.faceToFace && <FaceToFace />}

      <Box pt={4} display="flex" justifyContent="flex-end">
        <Button color="primary" onClick={() => handleApprove(eventRequest.id)}>
          {intl.formatMessage(messages.approveRequest)}
        </Button>
        <Button
          color="primary"
          onClick={handleOpenReturnModal}
          className={classes.returnButton}
        >
          {intl.formatMessage(messages.returnRequest)}
        </Button>
      </Box>
    </ReviewerApproversContext.Provider>
  );
};
