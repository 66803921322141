import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestForm.sections.SpeakersSection.title',
    defaultMessage: 'Speakers'
  },
  addSpeaker: {
    id: 'components.EventRequestForm.sections.SpeakersSection.addSpeaker',
    defaultMessage: 'Asociar speaker'
  },
  externo: {
    id: 'components.EventRequestForm.sections.SpeakersSection.external',
    defaultMessage: 'Speaker externo'
  },
  interno: {
    id: 'components.EventRequestForm.sections.SpeakersSection.internal',
    defaultMessage: 'Speaker interno'
  }
});
