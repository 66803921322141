import { useEffect, useState } from 'react';

import { useDrawer } from 'components/Drawer';
import { useFormik } from 'formik';

import { useIntl } from 'react-intl';

import { messages } from '../SpeakerAddDrawerMessages';

import { useValidationSchema } from './useValidationSchema';

import { useCatalogues } from 'components/Catalogues';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';

import { uploadFilePromise } from 'utils/aws';

import { Button } from '@material-ui/core';

const _initialValues = {
  addBy: 'internal',
  employee: '',
  speaker: '',
  contractNumber: '',
  supplierNumber: '',
  email: '',
  rfc: '',
  cedula: '',
  city: '',
  fiscalAddress: '',
  hcpTier: '',
  speciality: '',
  curriculum: {
    url: null,
    file: null
  }
};

export const useForm = ({ initialValues = _initialValues, onSubmit, edit }) => {
  const { handleCloseDrawer } = useDrawer();
  const { handleShowLoader } = useLoader();
  const { handleCloseModal, handleOpenModal } = useModal();

  const intl = useIntl();
  const {
    employeeL,
    handleGetEmployeesList,
    handleGetCitiesCatalogue
  } = useCatalogues();

  const [employeeForm, setEmployeeForm] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    handleGetEmployeesList();
  }, []);

  useEffect(() => {
    (async () => {
      const catalogueResponse = await handleGetCitiesCatalogue();

      if (catalogueResponse) {
        setCities(catalogueResponse);
      }
    })();
  }, []);

  useEffect(() => {
    setEmployeeForm(employeeL);
  }, [employeeL]);

  const validationSchema = useValidationSchema();

  const updateFile = async (file) => {
    try {
      handleShowLoader(true);

      const documentFile = {
        url: await uploadFilePromise(file)
      };

      return documentFile;
    } catch (error) {
      handleOpenModal({
        configProps: {
          maxWidth: 'xs'
        },
        title: intl.formatMessage(messages.anErrorHasOccured),
        body: intl.formatMessage(messages.fileUploadError),
        actionButtons: (
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.accept)}
          </Button>
        )
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (_values) => {
      let documentFile = {};

      if (!edit) {
        documentFile = await updateFile(_values?.curriculum.file);
      }

      if (edit) {
        if (_values.curriculum.file.lastModified) {
          documentFile = await updateFile(_values?.curriculum.file);
        } else {
          documentFile = _values.curriculum.url;
        }
      }

      let speakerCreate = {
        rfc: _values.rfc,
        contract_number: parseInt(_values.contractNumber),
        cedula: _values.cedula,
        city: _values.city,
        fiscal_address: _values.fiscalAddress,
        hcp_tier: _values.hcpTier,
        speciality: _values.speciality,
        curriculum: documentFile.url,
        supplier_number: parseInt(_values.supplierNumber)
      };

      if (_values.addBy === 'internal') {
        speakerCreate = {
          speaker_type: 'interno',
          speaker: _values.employee,
          ...speakerCreate
        };
      }

      if (_values.addBy === 'external' && !edit) {
        speakerCreate = {
          speaker_type: 'externo',
          speaker: _values.speaker,
          email: _values.email,
          ...speakerCreate
        };
      }

      if (_values.addBy === 'external' && edit) {
        speakerCreate = {
          speaker_name: _values.speaker,
          email: _values.email,
          ...speakerCreate
        };
      }

      onSubmit(speakerCreate);
      handleCloseDrawer();
    }
  });

  const byInternal = [
    {
      type: 'select',
      name: 'employee',
      label: intl.formatMessage(messages.employee),
      placeholder: intl.formatMessage(messages.employee),
      options: employeeForm,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: { xs: 12 }
    }
  ];

  const byExternal = [
    {
      type: 'text',
      name: 'speaker',
      label: intl.formatMessage(messages.speaker),
      placeholder: intl.formatMessage(messages.speaker),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'rfc',
      label: intl.formatMessage(messages.rfc),
      placeholder: intl.formatMessage(messages.rfc),
      breakpoints: { xs: 12 }
    },
    {
      type: 'select',
      name: 'city',
      label: intl.formatMessage(messages.city),
      placeholder: intl.formatMessage(messages.city),
      breakpoints: { xs: 12 },
      options: cities,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value
    },
    {
      type: 'text',
      name: 'email',
      label: intl.formatMessage(messages.email),
      placeholder: intl.formatMessage(messages.email),
      breakpoints: { xs: 12 }
    }
  ];

  const byGeneral = [
    {
      type: 'text',
      name: 'contractNumber',
      label: intl.formatMessage(messages.contractNumber),
      placeholder: intl.formatMessage(messages.contractNumber),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'supplierNumber',
      label: intl.formatMessage(messages.supplierNumber),
      placeholder: intl.formatMessage(messages.supplierNumber),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'cedula',
      label: intl.formatMessage(messages.cedula),
      placeholder: intl.formatMessage(messages.cedula),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'fiscalAddress',
      label: intl.formatMessage(messages.fiscalAddress),
      placeholder: intl.formatMessage(messages.fiscalAddress),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'hcpTier',
      label: intl.formatMessage(messages.hcpTier),
      placeholder: intl.formatMessage(messages.hcpTier),
      breakpoints: { xs: 12 }
    },
    {
      type: 'text',
      name: 'speciality',
      label: intl.formatMessage(messages.speciality),
      placeholder: intl.formatMessage(messages.speciality),
      breakpoints: { xs: 12 }
    },
    {
      type: 'application/pdf',
      name: 'curriculum',
      buttonLabel: intl.formatMessage(messages.curriculum),
      onChange: (value) => formik.setFieldValue('curriculum', value),
      value: formik.values.curriculum,
      error: formik.touched.curriculum?.file && formik.errors.curriculum?.file
    }
  ];

  const editFields = [
    {
      name: 'external',
      label: intl.formatMessage(messages.addByExternal)
    }
  ];

  const notEditFields = [
    {
      name: 'internal',
      label: intl.formatMessage(messages.addByInternal)
    },
    {
      name: 'external',
      label: intl.formatMessage(messages.addByExternal)
    }
  ];

  const fields = [
    {
      type: 'radioGroup',
      name: 'addBy',
      row: true,
      options: edit ? editFields : notEditFields,
      breakpoints: { xs: 12 }
    },
    ...(formik.values.addBy === 'internal'
      ? [...byInternal, ...byGeneral]
      : [...byExternal, ...byGeneral])
  ];

  return { formik, fields };
};
