import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.ChangePasswordSection.title',
    defaultMessage: 'CAMBIAR CONTRASEÑA'
  },
  requiredMessage: {
    id: 'components.ChangePasswordSection.requiredMessage',
    defaultMessage: 'Este campo es requerido'
  },
  notMatchPassword: {
    id: 'components.ChangePasswordSection.notMatchPassword',
    defaultMessage: 'Las contraseñas no coinciden'
  },
  formatPassword: {
    id: 'components.ChangePasswordSection.formatPassword',
    defaultMessage:
      'Las contraseña debe tener una longuitud minima de ocho carateres, una letra mayuscula y un caracter'
  },

  currentPasswordLabel: {
    id: 'components.ChangePasswordSection.currentPasswordLabel',
    defaultMessage: 'Contraseña actual'
  },
  newPasswordLabel: {
    id: 'components.ChangePasswordSection.newPasswordLabel',
    defaultMessage: 'Nueva contraseña'
  },
  confirmPasswordLabel: {
    id: 'components.ChangePasswordSection.confirmPasswordLabel',
    defaultMessage: 'Confirmar contraseña'
  },
  changeButton: {
    id: 'components.ChangePasswordSection.changeButton',
    defaultMessage: 'Cambiar'
  },
  btnAccept: {
    id: 'components.ChangePasswordSection.btnAccept',
    defaultMessage: 'Aceptar'
  },
  passwordChangeSuccess: {
    id: 'components.ChangePasswordSection.passwordChangeSuccess',
    defaultMessage: 'Contraseña actualizada!'
  }
});
