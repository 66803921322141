import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useFields } from './hooks/useFields';
import { renderSwitch, renderTextField } from '@octopy/react-form';
import { useIntl } from 'react-intl';
import { messages } from './MaterialsSectionMessages';
import { useStyles } from './MaterialsSectionStyles';
import { FilePicker } from 'components/FilePicker';

export const MaterialsSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFields({ formik });

  const extraInputProps = {
    fullWidth: true
  };

  const fieldsMapper = ({ inputProps, ...field }, index) => (
    <Grid
      className={classes.gridItem}
      key={`materials-section-field-${index}`}
      item
      {...field.breakpoints}
    >
      {field.type === 'number' &&
        renderTextField({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}

      {field.type === 'application/pdf' && (
        <div className={classes.filePicker}>
          <Typography
            variant="subtitle2"
            className={field.disabled && classes.disabledText}
          >
            {field.label}
          </Typography>
          <FilePicker {...field} />
        </div>
      )}
    </Grid>
  );

  return (
    <>
      <Box pb={2} className={classes.header}>
        {renderSwitch({
          formik,
          field: { type: 'switch', name: 'materialsEnabled' }
        })}
        <Typography
          variant="subtitle"
          className={`${classes.title} ${
            !formik.values.materialsEnabled && classes.disabledText
          }`}
        >
          {intl.formatMessage(messages.title)}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {fields.map(fieldsMapper)}
      </Grid>
    </>
  );
};
