import { useIntl } from 'react-intl';
import { messages } from '../ImageProfileSectionMessages';
import { Avatar } from '@material-ui/core';

export const useFields = () => {
  const intl = useIntl();

  return [
    {
      type: 'image',
      name: 'image',
      breakpoints: { xs: 12 },
      icon: Avatar,
      label: intl.formatMessage(messages.selectImageLabel),
      multiple: false,
      color: 'primary.main'
    }
  ];
};
