import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.EmployeeForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  emailError: {
    id: 'components.EmployeeForm.emailError',
    defaultMessage: 'Correo electrónico inválido'
  },
  create: {
    id: 'components.EmployeeForm.create',
    defaultMessage: 'Crear empleado'
  },
  anErrorHasOccured: {
    id: 'components.EmployeeForm.anErrorHasOccured',
    defaultMessage: 'Ha ocurrido un error'
  },
  fileUploadError: {
    id: 'components.EmployeeForm.fileUploadError',
    defaultMessage: 'Ha ocurrido un error al intentar subir el archivo'
  },
  accept: {
    id: 'components.EmployeeForm.accept',
    defaultMessage: 'aceptar'
  }
});
