import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useFields } from './hooks/useFields';
import {
  renderAutocomplete,
  renderTextField,
  renderSwitch
} from '@octopy/react-form';
import { useIntl } from 'react-intl';
import { messages } from './PlaceSectionMessages';
import { useStyles } from './PlaceSectionStyles';
import { FilePicker } from 'components/FilePicker';

export const PlaceSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFields({ formik });

  const extraInputProps = {
    fullWidth: true
  };

  const fieldsMapper = ({ inputProps, ...field }, index) => (
    <Grid
      className={classes.gridItem}
      key={`place-section-field-${index}`}
      item
      {...field.breakpoints}
    >
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}
      {['number', 'text'].includes(field.type) &&
        renderTextField({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}
      {field.type === 'switch' &&
        renderSwitch({
          index,
          formik,
          field,
          inputProps: { ...extraInputProps, ...inputProps }
        })}

      {field.type === 'application/pdf' && (
        <div className={classes.filePicker}>
          <Typography variant="subtitle2">{field.label}</Typography>
          <FilePicker {...field} />
        </div>
      )}
    </Grid>
  );

  return (
    <>
      <Box pb={2}>
        <Typography variant="subtitle" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {fields.map(fieldsMapper)}
      </Grid>
    </>
  );
};
