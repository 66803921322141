import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventLocationSection.title',
    defaultMessage: 'LUGAR DEL EVENTO'
  },
  state: {
    id: 'components.EventLocationSection.state',
    defaultMessage: 'Estado:'
  },
  city: {
    id: 'components.EventLocationSection.City',
    defaultMessage: 'Ciudad:'
  },
  typeLocation: {
    id: 'components.EventLocationSection.typeLocation',
    defaultMessage: 'Tipo de lugar:'
  },
  nameLocation: {
    id: 'components.EventLocationSection.nameLocation',
    defaultMessage: 'Nombre del lugar:'
  },
  cost: {
    id: 'components.EventLocationSection.cost',
    defaultMessage: 'Costo de las instalaciones'
  },
  colony: {
    id: 'components.EventLocationSection.colony',
    defaultMessage: 'Colonia:'
  },
  externNumber: {
    id: 'components.EventLocationSection.externNumber',
    defaultMessage: 'Número externo::'
  },
  internNumber: {
    id: 'components.EventLocationSection.internNumber',
    defaultMessage: 'Número interior:'
  },
  postalCode: {
    id: 'components.EventLocationSection.postalCode',
    defaultMessage: 'Código postal:'
  },
  costState: {
    id: 'components.EventLocationSection.costState',
    defaultMessage: 'Sí'
  }
});
