import { useFormik } from 'formik';

import * as yup from 'yup';

import { useIntl } from 'react-intl';

import { messages } from '../EventSearchMessages';

export const useForm = ({ onSubmit }) => {
  const intl = useIntl();

  const initialValues = {
    eventType: null,
    eventStatus: null,
    businessUnit: null,
    district: null,
    speaker: null,
    startDate: null,
    endDate: null
  };

  const validationSchema = yup.object({
    eventType: yup.string().nullable(),
    /* .required(intl.formatMessage(messages.requiredError)), */
    eventStatus: yup.string().nullable(),
    /* .required(intl.formatMessage(messages.requiredError)), */
    businessUnit: yup.string().nullable(),
    /* .required(intl.formatMessage(messages.requiredError)),*/
    district: yup.string().nullable(),
    /* .required(intl.formatMessage(messages.requiredError)), */
    speaker: yup.string().nullable(),
    /* .required(intl.formatMessage(messages.requiredError)) */
    startDate: yup.date().nullable(),
    //.required(intl.formatMessage(messages.requiredError)),
    endDate: yup
      .date()
      .nullable()
      .min(
        yup.ref('startDate'),
        intl.formatMessage(messages.laterThanStartDateErrorMessage)
      )
    //.required(intl.formatMessage(messages.requiredError))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => onSubmit(values)
  });

  return { formik };
};
