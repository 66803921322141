import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loading: {
    id: 'components.local.NoData.loading',
    defaultMessage: 'Cargando {dataName}'
  },
  noData: {
    id: 'components.local.NoData.noData',
    defaultMessage: 'No hay {dataName} para mostrar'
  },
  data: {
    id: 'components.local.NoData.data',
    defaultMessage: 'datos'
  }
});
