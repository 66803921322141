import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  employeeData: {
    id: 'components.DataSection.employeeData',
    defaultMessage: 'DATOS DEL EMPLEADO'
  },
  idLabel: {
    id: 'components.DataSection.idLabel',
    defaultMessage: 'Identificador'
  },
  nameLabel: {
    id: 'components.DataSection.nameLabel',
    defaultMessage: 'Nombre'
  },
  emailLabel: {
    id: 'components.DataSection.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  cityLabel: {
    id: 'components.DataSection.cityLabel',
    defaultMessage: 'Ciudad'
  },
  cityPlaceHolder: {
    id: 'components.DataSection.cityPlaceHolder',
    defaultMessage: 'Ciudad'
  },
  emailError: {
    id: 'components.DataSection.emailError',
    defaultMessage: 'Correo electrónico inválido'
  },
  requiredError: {
    id: 'components.DataSection.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  imageDescription: {
    id: 'components.DataSection.imageDescription',
    defaultMessage:
      'Se mostrarán las iniciales del Nombre y Apellido del usuario como imagen preestablecida:'
  }
});
