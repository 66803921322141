import React, { useEffect, useState } from 'react';
import { upperFirst, values } from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { Event as EventIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { PieChart, BarChart } from '@octopy/react-charts';
import { useApi } from 'hooks';
import { useAuth } from 'components/Auth';
import { Images } from 'assets';
import { pieColors } from './utils';
import { messages } from './DashboardMessages';
import { useStyles } from './DashboardStyles';
import { mapDashboard } from './helpers';
import { eventTypes } from 'components/EventRequestForm/helpers';

const Dashboard = () => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles({ backgroundImage: Images.bannerDashboard });
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const { user } = useAuth();
  const [data, setData] = useState(null);

  const [getDashboard] = useApi({
    endpoint: `/request/dashboard`,
    method: 'get'
  });

  const getData = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getDashboard();

      if (status === 200) {
        setData(mapDashboard(payload));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.bannerContainer}>
        <Container>
          <Typography variant="h3" className={classes.welcomeTitle}>
            {intl.formatMessage(messages.welcome, { name: 'Dashboard' })}
          </Typography>

          <Typography variant="h5" className={classes.userNameSubtitle}>
            {user.name}
          </Typography>

          <Typography variant="body1">
            {intl.formatMessage(messages.roleIndicator, {
              role: upperFirst(user.role)
            })}
          </Typography>
        </Container>
      </div>

      <div className={classes.summaryContainer}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Card className={classes.summaryGroup}>
                <Typography
                  className={classes.summaryGroupTitle}
                  variant="body1"
                  color="primary"
                >
                  {intl.formatMessage(messages.requestsOnCurrentWeek)}
                </Typography>

                <Grid container>
                  <Grid item xs={6} sm={3}>
                    {/* SummaryMetric */}
                    <Box py={2} display="flex">
                      <Box p={1}>
                        <EventIcon
                          color="secondary"
                          className={classes.summaryIcon}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.summaryName}
                        >
                          Novo Eventos
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.summaryValue}
                        >
                          {data?.runningRequests?.find(
                            ({ label }) => label === eventTypes.novoEvent
                          )?.value || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    {/* SummaryMetric */}
                    <Box py={2} display="flex">
                      <Box p={1}>
                        <EventIcon
                          color="secondary"
                          className={classes.summaryIcon}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.summaryName}
                        >
                          Novo Apoyos
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.summaryValue}
                        >
                          {data?.runningRequests?.find(
                            ({ label }) => label === eventTypes.novoSupport
                          )?.value || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    {/* SummaryMetric */}
                    <Box py={2} display="flex">
                      <Box p={1}>
                        <EventIcon
                          color="secondary"
                          className={classes.summaryIcon}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.summaryName}
                        >
                          Actividades de farmacias
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.summaryValue}
                        >
                          {data?.runningRequests?.find(
                            ({ label }) =>
                              label === eventTypes.pharmacyActivities
                          )?.value || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    {/* SummaryMetric */}
                    <Box py={2} display="flex">
                      <Box p={1}>
                        <EventIcon
                          color="secondary"
                          className={classes.summaryIcon}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.summaryName}
                        >
                          Face to face
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.summaryValue}
                        >
                          {data?.runningRequests?.find(
                            ({ label }) => label === eventTypes.faceToFace
                          )?.value || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              <Card className={classes.summaryGroup}>
                <Typography
                  className={classes.summaryGroupTitle}
                  variant="body1"
                  color="primary"
                >
                  {intl.formatMessage(messages.requestsFinishedOnPastMonth)}
                </Typography>

                <Grid container>
                  <Grid item xs={6} sm={3}>
                    {/* SummaryMetric */}
                    <Box py={2} display="flex">
                      <Box p={1}>
                        <EventIcon
                          color="secondary"
                          className={classes.summaryIcon}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.summaryName}
                        >
                          Novo Eventos
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.summaryValue}
                        >
                          {data?.completedRequest?.find(
                            ({ label }) => label === eventTypes.novoEvent
                          )?.value || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    {/* SummaryMetric */}
                    <Box py={2} display="flex">
                      <Box p={1}>
                        <EventIcon
                          color="secondary"
                          className={classes.summaryIcon}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.summaryName}
                        >
                          Novo Apoyos
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.summaryValue}
                        >
                          {data?.completedRequest?.find(
                            ({ label }) => label === eventTypes.novoSupport
                          )?.value || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    {/* SummaryMetric */}
                    <Box py={2} display="flex">
                      <Box p={1}>
                        <EventIcon
                          color="secondary"
                          className={classes.summaryIcon}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.summaryName}
                        >
                          Actividades de farmacias
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.summaryValue}
                        >
                          {data?.completedRequest?.find(
                            ({ label }) =>
                              label === eventTypes.pharmacyActivities
                          )?.value || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    {/* SummaryMetric */}
                    <Box py={2} display="flex">
                      <Box p={1}>
                        <EventIcon
                          color="secondary"
                          className={classes.summaryIcon}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.summaryName}
                        >
                          Face to face
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.summaryValue}
                        >
                          {data?.completedRequest?.find(
                            ({ label }) => label === eventTypes.faceToFace
                          )?.value || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.chartGroupContainer}>
            <Grid item xs={12} md={6}>
              <Card className={classes.cardChart}>
                <Typography
                  className={classes.summaryGroupTitle}
                  variant="body1"
                  color="primary"
                >
                  Solicitudes por estatus
                </Typography>

                <div className={classes.chart}>
                  <PieChart
                    type="pie"
                    colors={pieColors}
                    values={data?.requestsPerStatus}
                    options={{ legend: { position: 'left' } }}
                  />
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card className={classes.cardChart}>
                <Typography
                  className={classes.summaryGroupTitle}
                  variant="body1"
                  color="primary"
                >
                  Solicitudes por franquicia
                </Typography>

                <div className={classes.chart}>
                  <PieChart
                    type="pie"
                    colors={pieColors}
                    values={data?.requestsPerFranchise}
                    options={{ legend: { position: 'left' } }}
                  />
                  {/* <BarChart
                  showTotal
                  values={[
                    // This values are only for demo
                    { label: 'GLP1 - Diabetes', value: 100 },
                    { label: 'GLP1 - Obesidad', value: 150 },
                    { label: 'Gobierno', value: 200 },
                    { label: 'Hemofilia', value: 500 },
                    { label: 'Hormona', value: 400 },
                    { label: 'Insulinas', value: 300 }
                  ]}
                  maxValuesInSight={5}
                  color={'#0099D7'}
                  barWidth={xs ? 10 : 50}
                  yTitle="Cantidad de Solicitudes"
                /> */}
                  {/* <BarChart
                  showTotal
                  values={[
                    ...data?.requestsPerFranchise,
                    ...data?.requestsPerFranchise,
                    ...data?.requestsPerFranchise,
                    ...data?.requestsPerFranchise,
                    ...data?.requestsPerFranchise
                  ]}
                  maxValuesInSight={Math.min(
                    data?.requestsPerFranchise?.length || 0,
                    5
                  )}
                  color={'#0099D7'}
                  barWidth={xs ? 10 : 50}
                  yTitle="Cantidad de Solicitudes"
                /> */}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Dashboard };
