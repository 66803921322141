import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  managementSubtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5)
  },
  submitButton: {
    marginTop: theme.spacing(4)
  }
}));

export { useStyles };
