import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(4)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      width: '100%'
    }
  },
  backButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    },
    transform: 'translateY(-1px)'
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 6,
    [theme.breakpoints.down('sm')]: {
      letterSpacing: 4,
      fontSize: theme.typography.h6.fontSize
    }
  },
  typeRadioGroupContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal'
    }
  },
  typeLabel: {
    marginRight: theme.spacing(3)
  },
  submitButton: {
    marginLeft: 'auto',
    marginTop: theme.spacing(4),
    float: 'right'
  }
}));

export { useStyles };
