import { useApi } from 'hooks';

export const useReviewerActions = () => {
  const [eventRequestCheck] = useApi({
    endpoint: 'request/check',
    method: 'put'
  });
  const [approveEventRequest] = useApi({
    endpoint: 'request/approve',
    method: 'put'
  });

  const handleApproveEventRequest = async (id, approverIds) => {
    try {
      const {
        headerResponse: { status }
      } = await approveEventRequest({
        urlParams: id,
        body: {
          approve: true,
          approvers: approverIds
        }
      });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReturnEventRequest = async (id, comment) => {
    try {
      const {
        headerResponse: { status }
      } = await approveEventRequest({
        urlParams: id,
        body: {
          approve: false,
          comment
        }
      });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckEventRequest = async (eventId) => {
    try {
      const {
        headerResponse: { status }
      } = await eventRequestCheck({ urlParams: eventId });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleCheckEventRequest,
    handleReturnEventRequest,
    handleApproveEventRequest
  };
};
