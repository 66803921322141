import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  header: {
    width: '100%',
    backgroundColor: hexToRgba(theme.palette.common.black, 0.7),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  icon: {
    color: theme.palette.common.white
  },
  image: {
    maxHeight: '90%',
    maxWidth: '95%',
    borderRadius: theme.shape.borderRadius
  },
  spacer: {
    flexGrow: 1
  }
}));

export { useStyles };
