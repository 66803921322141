import { useApi } from 'hooks';

export const useDistrictsActions = (handleUpdateFranchise) => {
  const [addDistrict] = useApi({
    endpoint: '/district/create',
    method: 'post'
  });
  const [enableDistrict] = useApi({
    endpoint: '/district/activate',
    method: 'put'
  });
  const [editDistrict] = useApi({
    endpoint: '/district/update',
    method: 'put'
  });
  const [removeDistrict] = useApi({
    endpoint: '/district/delete',
    method: 'delete'
  });
  const [districtExport] = useApi({
    endpoint: '/district/export',
    method: 'get'
  });

  const handleAddDistrict = async (franchiseId, values) => {
    try {
      const body = {
        name: values.name,
        manager_id: values.managerId,
        franchise_id: franchiseId
      };

      const {
        headerResponse: { status }
      } = await addDistrict({ body });

      if (status === 200) {
        handleUpdateFranchise(franchiseId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnableDistrict = async (franchiseId, { districtId, active }) => {
    try {
      const body = {
        enabled: active
      };

      const {
        headerResponse: { status }
      } = await enableDistrict({ urlParams: districtId, body });

      if (status === 200) {
        handleUpdateFranchise(franchiseId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditDistrict = async (franchiseId, values) => {
    try {
      const body = {
        name: values.name,
        manager_id: values.managerId
      };

      const {
        headerResponse: { status }
      } = await editDistrict({ urlParams: values.id, body });

      if (status === 200) {
        handleUpdateFranchise(franchiseId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveDistrict = async (franchiseId, districtId) => {
    try {
      const {
        headerResponse: { status }
      } = await removeDistrict({ urlParams: districtId });

      if (status === 200) {
        handleUpdateFranchise(franchiseId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDistrictExport = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await districtExport();

      if (status === 200) {
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleAddDistrict,
    handleRemoveDistrict,
    handleEditDistrict,
    handleEnableDistrict,
    handleDistrictExport
  };
};
