import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.HcpsActions.title',
    defaultMessage: 'HCPS'
  },
  importButton: {
    id: 'components.HcpsActions.importButton',
    defaultMessage: 'Importar'
  },
  exportButton: {
    id: 'components.HcpsActions.exportButton',
    defaultMessage: 'Exportar'
  },
  titleDrawer: {
    id: 'components.HcpsActions.titleDrawer',
    defaultMessage: 'ACTUALIZAR HCPS'
  },
  captionDrawer: {
    id: 'components.HcpsActions.captionDrawer',
    defaultMessage:
      'Selecciona un archivo Excel para actualizar la lista de HCPS'
  }
});
