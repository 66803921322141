import { responseMapper } from 'utils/responseMapper';

export const roles = {
  admin: 'administrador',
  medical: 'medical',
  representative: 'representante',
  reviewer: 'revisor',
  manager: 'gerente',
  director: 'director'
};

const loginResponsePayloadTemplate = {
  user: {
    userId: 'user.user_id',
    identifier: 'user.identifier',
    name: 'user.name',
    title: 'user.title',
    email: 'user.email',
    rfc: 'user.rfc',
    city: 'user.city',
    role: 'user.role',
    employeeId: 'user.employee_id',
    avatarUrl: 'user.avatar',
    franchiseId: 'user.franchise_id',
    districtId: 'user.district_id'
  },
  token: 'token'
};

export const mapLoginResponsePayload = (payload) => {
  const mappedPayload = responseMapper({
    template: loginResponsePayloadTemplate,
    data: payload
  });

  mappedPayload.user.medical = mappedPayload.user.title.includes('Medical');

  // mappedPayload.user.role = mappedPayload.user.title.includes('Medical')
  //   ? roles.medical
  //   : mappedPayload.user.role;

  return mappedPayload;
};
