import { useApi } from 'hooks';
import { useState } from 'react';
import { HcpsContext } from './HcpsContext';
import { mapHcpslist } from './helpers';

export const HcpsProvider = ({ children }) => {
  const [hcpsList, setHcpsList] = useState([]);
  const [filter, setFilter] = useState({ name: '' });
  const [pagination, setPagination] = useState({
    totalPages: 0,
    currentPage: 1,
    totalItems: 0,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 25, 30, 50, 100]
  });

  const onPageChange = (_, newPage) =>
    setPagination({
      ...pagination,
      currentPage: newPage
    });

  const onRowsPerPageChange = (event) =>
    setPagination({
      ...pagination,
      currentPage: 1,
      rowsPerPage: +event.target.value
    });

  const [getListHcps] = useApi({ endpoint: 'guest/list', method: 'get' });
  const [exportHcps] = useApi({ endpoint: 'guest/export', method: 'get' });
  const [importHcps] = useApi({ endpoint: 'guest/import', method: 'post' });

  const handleGetList = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await getListHcps({
        queryString: {
          name: filter.name,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.currentPage
        }
      });

      if (status === 200) {
        setPagination({
          ...pagination,
          currentPage: payload.currentPage,
          totalPages: payload.pages,
          rowsPerPage: payload.rowsPerPage,
          totalItems: payload.totalItems
        });
        const mappedList = mapHcpslist(payload.list);

        setHcpsList({ ...payload, list: mappedList });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportList = async () => {
    try {
      const {
        headerResponse: { status },
        payload
      } = await exportHcps();

      if (status === 200) {
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImportList = async (value) => {
    try {
      const {
        headerResponse: { status }
      } = await importHcps({ body: value });

      if (status === 200) {
        handleGetList();

        return status;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contextValues = {
    hcpsList,
    filter,
    setFilter,
    handleGetList,
    handleExportList,
    handleImportList,
    pagination: { ...pagination, onPageChange, onRowsPerPageChange }
  };

  return (
    <HcpsContext.Provider value={contextValues}>
      {children}
    </HcpsContext.Provider>
  );
};
