import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  appName: {
    id: 'routes.Navigation.appName',
    defaultMessage: 'Dashboard'
  },
  employees: {
    id: 'routes.Navigation.employees',
    defaultMessage: 'Empleados'
  },
  newEmployee: {
    id: 'routes.Navigation.newEmployee',
    defaultMessage: 'Nuevo empleado'
  },
  myAccount: {
    id: 'routes.Navigation.myAccount',
    defaultMessage: 'Mi cuenta'
  },
  franchise: {
    id: 'routes.Navigation.franchise',
    defaultMessage: 'Franquicia'
  },
  hcps: {
    id: 'routes.Navigation.hcps',
    defaultMessage: 'Hcps'
  },
  eventRequestDetails: {
    id: 'routes.Navigation.eventRequestDetails',
    defaultMessage: 'Solicitud de evento'
  },
  franchises: {
    id: 'routes.Navigation.franchises',
    defaultMessage: 'Franquicias'
  },
  district: {
    id: 'routes.Navigation.district',
    defaultMessage: 'Distrito'
  },
  formatRepository: {
    id: 'routes.Navigation.formatRepository',
    defaultMessage: 'Repositorio de formatos'
  },
  eventRequests: {
    id: 'routes.Navigation.eventRequests',
    defaultMessage: 'Solicitudes de eventos'
  },
  login: {
    id: 'routes.Navigation.login',
    defaultMessage: 'Iniciar sesión'
  },
  recoveryPassword: {
    id: 'routes.Navigation.recoveryPassword',
    defaultMessage: 'Restablecer contraseña'
  },
  newEventRequest: {
    id: 'routes.Navigation.newEventRequest',
    defaultMessage: 'Nueva solicitud de evento'
  },
  closeEventRequest: {
    id: 'routes.Navigation.closeEventRequest',
    defaultMessage: 'Cerrar solicitud de evento'
  },
  speakers: {
    id: 'routes.Navigation.speakers',
    defaultMessage: 'Speakers'
  },
  configuration: {
    id: 'routes.Navigation.configuration',
    defaultMessage: 'Configuración'
  },
  404: {
    id: 'routes.Navigation.404',
    defaultMessage: 'Pagina no encontrada'
  }
});
