import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  eventType: {
    id: 'components.EventSearch.eventType',
    defaultMessage: 'Tipo de eventos'
  },
  eventStatus: {
    id: 'components.EventSearch.eventStatus',
    defaultMessage: 'Estatus'
  },
  speaker: {
    id: 'components.EventSearch.speaker',
    defaultMessage: 'Speaker'
  },
  businessUnit: {
    id: 'components.EventSearch.businessUnit',
    defaultMessage: 'Unidad de negocio'
  },
  district: {
    id: 'components.EventSearch.district',
    defaultMessage: 'Distrito'
  },
  eventSearch: {
    id: 'components.EventSearch.eventSearch',
    defaultMessage: 'Buscar'
  },
  requiredError: {
    id: 'components.EventSearch.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  /**
   * mensajes de fechas */
  laterThanStartDateErrorMessage: {
    id: 'components.EventsByDates.laterThanStartDateErrorMessage',
    defaultMessage: 'Debe ser mayor que la de inicio'
  },
  startDateLabel: {
    id: 'components.EventsByDates.startDateLabel',
    defaultMessage: 'Desde'
  },
  startDatePlaceholder: {
    id: 'components.EventsByDates.startDatePlaceholder',
    defaultMessage: 'dd/mm/yyyy'
  },
  endDateLabel: {
    id: 'components.EventsByDates.endDateLabel',
    defaultMessage: 'Hasta'
  },
  endDatePlaceholder: {
    id: 'components.EventsByDates.endDatePlaceholder',
    defaultMessage: 'dd/mm/yyyy'
  },
  searchButton: {
    id: 'components.EventsByDates.searchButton',
    defaultMessage: 'Buscar'
  }
});
