import logo from './svg/logo.svg';
import jsIcon from './svg/javascript-icon.svg';
import reactIcon from './svg/react-js-icon.svg';
import scssIcon from './svg/sass-icon.svg';
import svgIcon from './svg/sun-icon.svg';
import folderIcon from './svg/file-manager.svg';
import folderOpenIcon from './svg/folder-open-icon.svg';
import imageIcon from './svg/image-icon.svg';
import bracketsIcon from './svg/brackets-icon.svg';
import iconLogo from './svg/novo.svg';
import whiteIconLogo from './svg/novowhite.svg';
import nameLogo from './svg/name-logo.svg';
import fileExport from './svg/icon-file-export.svg';
import loginBackground from './png/bg_login.png';
import restorePasswordBackground from './png/bg_recoverpass.png';
import logoWithName from './svg/logo_with_name.svg';
import forgotPassword from './png/forgot_password.png';
import bannerDashboard from './png/banner-dashboard.png';
import splashBackground from './png/bg_splash.png';

const Images = {
  logo,
  jsIcon,
  reactIcon,
  scssIcon,
  svgIcon,
  folderIcon,
  folderOpenIcon,
  imageIcon,
  bracketsIcon,
  iconLogo,
  nameLogo,
  fileExport,
  loginBackground,
  logoWithName,
  forgotPassword,
  restorePasswordBackground,
  bannerDashboard,
  whiteIconLogo,
  splashBackground
};

export { Images };
