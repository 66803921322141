/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useValidationSchema } from './hooks/useValidationSchema';
import { useInitialValues } from './hooks/useInitialValues';
import { useFormik } from 'formik';
import { Card } from '../Card';
import { RequirementsSection } from '../sections/RequirementsSection';
import { AddressSection } from '../sections/AddressSection';
import { ObjectiveSection } from '../sections/ObjectiveSection';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import { Button } from '@material-ui/core';
import { messages } from '../EventRequestFormMessages';
import { useDateAndTimeUnifier } from '../NovoEventForm/hooks/useDateAndTimeUnifier';
import { mapFormValues, mapEventRequest } from './helpers';
import { useEventRequests } from 'components/EventRequests/hooks/useEventRequests';

export const PharmacyActivityForm = ({ formikRef, eventRequest }) => {
  const intl = useIntl();
  const history = useHistory();
  const { eventId } = useParams();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal, handleCloseModal } = useModal();
  const {
    handleCreateEventPharmacyRequest,
    handleUpdateNovoEventPharmacyRequest
  } = useEventRequests();

  const validationSchema = useValidationSchema();
  const initialValues = useInitialValues();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        handleShowLoader(true);

        const mappedValues = await mapFormValues(values);

        let done;

        if (eventId) {
          done = await handleUpdateNovoEventPharmacyRequest(
            eventId,
            mappedValues
          );
        } else {
          done = await handleCreateEventPharmacyRequest(mappedValues);
        }

        if (done) {
          history.push('/eventRequests');
        }
      } catch (error) {
        handleOpenModal({
          configProps: {
            maxWidth: 'xs'
          },
          title: intl.formatMessage(messages.anErrorHasOccured),
          body: intl.formatMessage(messages.fileUploadError),
          actionButtons: (
            <Button onClick={handleCloseModal}>
              {intl.formatMessage(messages.accept)}
            </Button>
          )
        });
      } finally {
        handleShowLoader(false);
      }
    }
  });

  useDateAndTimeUnifier(formik);

  useEffect(() => {
    console.log('eventRequest', eventRequest);
    if (eventRequest) {
      formik.setValues(mapEventRequest(eventRequest));
    }
  }, [eventRequest]);

  useEffect(() => {
    formikRef.current = formik;
  }, []);

  return (
    <div>
      <Card>
        <RequirementsSection formik={formik} />
      </Card>
      <Card mt={3}>
        <AddressSection formik={formik} />
      </Card>
      <Card mt={3}>
        <ObjectiveSection formik={formik} />
      </Card>
    </div>
  );
};
