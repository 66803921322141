import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.DistrictForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  managerIdLabel: {
    id: 'components.DistrictForm.managerIdLabel',
    defaultMessage: 'Gerente'
  },
  managerIdPlaceholder: {
    id: 'components.DistrictForm.managerIdPlaceholder',
    defaultMessage: 'Ingrese el nombre del gerente'
  },
  nameLabel: {
    id: 'components.DistrictForm.nameLabel',
    defaultMessage: 'Nombre del distrito'
  },
  namePlaceholder: {
    id: 'components.DistrictForm.namePlaceholder',
    defaultMessage: 'Ingrese el nombre del distrito'
  },
  management: {
    id: 'components.DistrictForm.management',
    defaultMessage: 'Gerencia'
  },
  accept: {
    id: 'components.DistrictForm.accept',
    defaultMessage: 'Aceptar'
  }
});
