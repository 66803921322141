import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { messages } from '../DataSectionMessages';
import { useCatalogues } from 'components/Catalogues';

export const useFields = () => {
  const intl = useIntl();
  const { handleGetCitiesCatalogue } = useCatalogues();
  const [citiesCatalogue, setCitiesCatalogue] = useState([]);

  useEffect(() => {
    (async () => {
      const catalogueResponse = await handleGetCitiesCatalogue();

      if (catalogueResponse) {
        setCitiesCatalogue(catalogueResponse);
      }
    })();
  }, []);

  const inputProps = { fullWidth: true };

  const fields = [
    {
      type: 'text',
      name: 'identifier',
      label: intl.formatMessage(messages.idLabel),
      placeholder: 'Ej. JAAC',
      breakpoints: { xs: 12 },
      inputProps
    },

    {
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: 'Ej. Oscar trejo',
      breakpoints: { xs: 12 },
      inputProps
    },

    {
      type: 'text',
      name: 'email',
      label: intl.formatMessage(messages.emailLabel),
      placeholder: 'Ej. correo@gmail.com',
      breakpoints: { xs: 12 },
      inputProps
    },

    {
      type: 'text',
      name: 'rfc',
      label: 'RFC',
      placeholder: 'Ej. AOCA8904152P7',
      breakpoints: { xs: 12 },
      inputProps
    },

    {
      type: 'select',
      name: 'city',
      label: intl.formatMessage(messages.cityLabel),
      placeholder: intl.formatMessage(messages.cityPlaceHolder),
      options: citiesCatalogue,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value,
      breakpoints: { xs: 12 }
    },
    inputProps
  ];

  return { fields };
};
