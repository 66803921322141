import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.MaterialSection.title',
    defaultMessage: 'MATERIALES'
  },
  totalCost: {
    id: 'components.MaterialSection.totalCost',
    defaultMessage: 'Costo total:'
  }
});
