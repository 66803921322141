import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  description: {
    id: 'components.RestorePassword.description',
    defaultMessage:
      'Escribe una nueva contraseña para poder acceder a la plataforma.'
  },
  passwordLabel: {
    id: 'components.RestorePassword.passwordLabel',
    defaultMessage: 'Contraseña'
  },
  passwordPlaceholder: {
    id: 'components.RestorePassword.passwordPlaceholder',
    defaultMessage: 'Ingresa tu contraseña'
  },
  passwordConfirmationLabel: {
    id: 'components.RestorePassword.passwordConfirmationLabel',
    defaultMessage: 'Confirmar contraseña'
  },
  passwordConfirmationPlaceholder: {
    id: 'components.RestorePassword.passwordConfirmationPlaceholder',
    defaultMessage: 'Ingresa tu contraseña nuevamente'
  },
  requiredError: {
    id: 'components.RestorePassword.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  passwordNoMatchesError: {
    id: 'components.RestorePassword.passwordNoMatchesError',
    defaultMessage: 'Las contraseñas no coinciden'
  },
  restorePassword: {
    id: 'components.RestorePassword.restorePassword',
    defaultMessage: 'Restablecer contraseña'
  },
  successfullyRestored: {
    id: 'components.RestorePassword.successfullyRestored',
    defaultMessage:
      'Su contraseña ha sido restablecida satisfactoriamente. Ya puedes iniciar sesión.'
  },
  accept: {
    id: 'components.RestorePassword.accept',
    defaultMessage: 'Aceptar'
  }
});
