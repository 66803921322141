import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, Box, Typography } from '@material-ui/core';
import { messages } from './UserDataSectionMessages';
import { useStyles } from './UserDataSectionStyles';
import { renderAutocomplete, renderTextField } from '@octopy/react-form';
import { useFields } from './hooks/useFields';

const UserDataSection = ({ formik }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { fields } = useFields();

  const fieldsMapper = (field, index) => (
    <Grid key={`data-section-field-${index}`} item {...field.breakpoints}>
      {field.type === 'text' &&
        renderTextField({ index, formik, field, inputProps: {} })}
      {field.type === 'select' &&
        renderAutocomplete({ index, formik, field, inputProps: {} })}
    </Grid>
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" color="primary" className={classes.title}>
        {intl.formatMessage(messages.userData)}
      </Typography>
      <Box pt={2}>
        <Grid container spacing={3}>
          {fields.map(fieldsMapper)}
        </Grid>
      </Box>
    </Box>
  );
};

UserDataSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { UserDataSection };
