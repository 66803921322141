import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './FooterStyles';

const Footer = ({ variant = 'light' }) => {
  const classes = useStyles({ variant });

  return (
    <div className={classes.container}>
      <Container className={classes.wrapper}>
        <Typography
          component="span"
          variant="caption"
          color="textSecondary"
          className={classes.text}
        >
          Powered by
        </Typography>

        <div className={classes.octopy} />

        <Typography
          component="span"
          variant="subtitle2"
          color="textSecondary"
          className={classes.r}
        >
          ®
        </Typography>

        <Typography
          component="span"
          variant="caption"
          color="textSecondary"
          className={classes.text}
        >
          2022 V.1.0
        </Typography>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Footer };
