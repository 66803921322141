import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.title',
    defaultMessage: 'Asociar speaker'
  },
  typeLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.typeLabel',
    defaultMessage: 'Tipo de speaker:'
  },
  typeExternalLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.typeExternalLabel',
    defaultMessage: 'Speaker externo'
  },
  typeInternalLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.typeInternalLabel',
    defaultMessage: 'Speaker interno'
  },
  submit: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.submit',
    defaultMessage: 'Asociar speaker'
  },
  requiredError: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  }
});
