import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Button, Box } from '@material-ui/core';
import { messages } from './LoginAsFormMessages';
import { useStyles } from './LoginAsFormStyles';
import { renderAutocomplete, renderTextField } from '@octopy/react-form';
import { useForm } from './hooks/useForm';

export const LoginAsForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { formik, fields, passwordInputs } = useForm();

  return (
    <>
      <Typography className={classes.employeeText}>
        {intl.formatMessage(messages.employee)}
      </Typography>

      {renderAutocomplete({
        formik,
        field: fields.employee,
        inputProps: {},
        autocomplete: 'off'
      })}
      <Box pt={2}>
        {renderTextField({
          formik,
          field: fields.adminPassword,
          passwordInputs,
          autocomplete: 'off'
        })}
      </Box>

      <Box pt={5}>
        <Button color="primary" onClick={formik.handleSubmit}>
          {intl.formatMessage(messages.loginButton)}
        </Button>
      </Box>
    </>
  );
};
