/* eslint-disable react/jsx-props-no-spreading */
import { uniqueId } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { Backup as UploadIcon } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';

import { useApi } from 'hooks';
import { uploadFilePromise } from 'utils/aws';
import { messages } from './CloseEventRequestMessages';
import { useStyles } from './CloseEventRequestStyles';
import { FilePreview } from './FilePreview';

const CloseEventRequest = () => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const { eventId } = useParams();
  const [mediaFiles, setMediaFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const totalItems = mediaFiles.length + documentFiles.length;

  const [closeEventRequest] = useApi({
    endpoint: `/request/close/${eventId}`,
    method: 'post'
  });

  const onDropMedia = useCallback(async (acceptedFiles) => {
    // Do something with the files
    // const fileUrl = await uploadFilePromise(acceptedFiles[0], 'prueba/otra/');
    // console.log('fileUrl', fileUrl);
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop: onDropMedia,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.raw'],
      'video/*': ['.mp4', '.avi', '.mov'],
      'audio/*': ['.mp3', '.m4a', '.wav'],
      'application/*': [
        '.pdf',
        '.doc',
        '.docx',
        '.xls',
        '.xlsx',
        '.txt',
        '.ppt',
        '.pptx',
        '.odp'
      ]
    }
  });

  useEffect(() => {
    const media = [];
    const documents = [];

    acceptedFiles.forEach((file) => {
      if (file.type.includes('application')) {
        documents.push({
          id: uniqueId('media_file_'),
          name: file.name,
          modifiedByUser: false,
          file,
          uploaded: false,
          url: ''
        });
      } else {
        media.push({
          id: uniqueId('document_file_'),
          uploaded: false,
          file,
          url: ''
        });
      }
    });

    setMediaFiles(media);
    setDocumentFiles(documents);
  }, [acceptedFiles]);

  const handleDelete = (type, id) => {
    const updater = type === 'media' ? setMediaFiles : setDocumentFiles;

    updater((prev) => {
      const updatedList = prev.filter((file) => file.id !== id);

      return updatedList;
    });
  };

  const handleChangeName = (id, name) => {
    setDocumentFiles((prev) => {
      const updatedList = prev.map((file) => {
        if (file.id === id) {
          return { ...file, name };
        }

        return file;
      });

      return updatedList;
    });
  };

  // const fileUploader = async (fileList, type) => {
  //   setLoading(true);

  //   const path = `test-upload/`;
  //   const totalItems = fileList.length;
  //   const stateUpdater = type === 'document' ? setDocumentFiles : setMediaFiles;

  //   let i = 0;

  //   do {
  //     try {
  //       const fileUrl = await uploadFilePromise(
  //         fileList[i].file,
  //         'prueba/otra/'
  //       );

  //       stateUpdater((prev) => {
  //         const updatedList = prev.map((file) => {
  //           if (file.id === fileList[i].id) {
  //             return { ...file, url: fileUrl, uploaded: true };
  //           }

  //           return file;
  //         });

  //         return updatedList;
  //       });

  //       if (type === 'media') {
  //         setMedia((prev) => [...prev, fileUrl]);
  //       } else {
  //         setAttachments((prev) => [
  //           ...prev,
  //           { name: fileList[i].name, file: fileUrl }
  //         ]);
  //       }

  //       i = i + 1;
  //     } catch (error) {
  //       // TODO: handle upload errors
  //     }
  //   } while (i < totalItems);

  //   setLoading(false);

  //   console.log('mediaFiles-->', mediaFiles);

  //   return i === totalItems;
  // };

  const fileUploader = async (fileList, type) => {
    setLoading(true);

    const path = `closed-requests/${eventId}/`;
    const totalItems = fileList.length;
    const stateUpdater = type === 'document' ? setDocumentFiles : setMediaFiles;

    let i = 0;

    const list = [...fileList];

    do {
      try {
        const fileUrl = await uploadFilePromise(list[i].file, path);

        list[i].url = fileUrl;
        list[i].uploaded = true;

        i = i + 1;
      } catch (error) {
        // TODO: handle upload errors
      }
    } while (i < totalItems);

    setLoading(false);

    stateUpdater(list);

    return i === totalItems;
  };

  const handleUploadFiles = async () => {
    const resultDocuments = await fileUploader(documentFiles, 'document');
    const resultMedia = await fileUploader(mediaFiles, 'media');

    const payload = {
      media: mediaFiles.map((mediaFile) => mediaFile.url),
      attachments: documentFiles.map((documentFile) => ({
        name: documentFile.name,
        file: documentFile.url
      }))
    };

    try {
      const {
        headerResponse: { status }
      } = await closeEventRequest({ body: payload });

      if (status === 200) {
        history.push('/eventRequests');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.container}>
      <Box>
        <Box w={1} mb={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={handleUploadFiles}
            // className={classes.buttonDatePicker}
            // size="large"
            color="primary"
            disabled={totalItems === 0 || loading}
          >
            Subir archivos
          </Button>
        </Box>

        {loading && (
          <Box mb={2}>
            <LinearProgress />
          </Box>
        )}

        <Typography variant="subtitle1">
          {intl.formatMessage(messages.evidences)}
        </Typography>

        <div
          {...getRootProps()}
          className={`${classes.borderDashed} ${classes.dropZone}`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box textAlign="center">
                <Typography variant="body1">
                  Agrega archivos arrastrandolos directamente o con un click
                </Typography>
                <Typography variant="body2">
                  {'(jpeg, png, mp4, pdf, doc, xlsx)'}
                </Typography>

                <Box mt={2}>
                  <UploadIcon
                    color="secondary"
                    className={classes.uploadIcon}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </div>
      </Box>

      <Typography variant="subtitle1" className={classes.subtitle}>
        {'Documentos Adjuntos (Debes asignar un nombre)'}
      </Typography>

      {documentFiles.length > 0 && (
        <Grid container spacing={2}>
          {documentFiles.map(({ id, name, uploaded, file }) => (
            <FilePreview
              id={id}
              key={id}
              file={file}
              type="document"
              onDelete={handleDelete}
              onChangeName={handleChangeName}
              customName={name}
              uploaded={uploaded}
            />
          ))}
        </Grid>
      )}

      <Typography variant="subtitle1" className={classes.subtitle}>
        {'Archivos multimedia (fotografías, videos y audios)'}
      </Typography>

      {mediaFiles.length > 0 && (
        <Grid container spacing={2}>
          {mediaFiles.map(({ id, uploaded, file }) => (
            <FilePreview
              id={id}
              key={id}
              file={file}
              type="media"
              onDelete={handleDelete}
              uploaded={uploaded}
            />
          ))}
        </Grid>
      )}
    </div>
  );
};

CloseEventRequest.propTypes = {};

export { CloseEventRequest };
