import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box, Button } from '@material-ui/core';
import { messages } from './SignModalMessages';
import { useStyles } from './SignModalStyles';
import SignaturePad from 'react-signature-canvas';

const SignModal = ({ padRef }) => {
  const intl = useIntl();
  const classes = useStyles();

  const cleanPad = () => {
    padRef.current.clear();
  };

  return (
    <>
      <Box className={classes.container}>
        <Typography className={classes.description}>
          {intl.formatMessage(messages.descriptionModal)}
        </Typography>
        <Box className={classes.boxPad}>
          <SignaturePad backgroundColor="#F6F6F6" ref={padRef} />
        </Box>
      </Box>
      <Box pt={2} display="flex" justifyContent="flex-end">
        <Button variant="text" onClick={cleanPad}>
          {intl.formatMessage(messages.cleanButton)}
        </Button>
      </Box>
    </>
  );
};

SignModal.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { SignModal };
