import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { AttachmentsSection } from 'components/EventRequestDetails/sections/AttachmentsSection';
import { PetitionerSection } from 'components/EventRequestDetails/sections/PetitionerSection';
import { DataSection } from 'components/EventRequestDetails/sections/DataSection';
import { EventLocationSection } from 'components/EventRequestDetails/sections/EventLocationSection';
import { SpeakersSection } from 'components/EventRequestDetails/sections/SpeakersSection';
import { FoodSection } from 'components/EventRequestDetails/sections/FoodSection';
import { MaterialSection } from 'components/EventRequestDetails/sections/MaterialSection';
import { RequiredApprovalsSection } from 'components/EventRequestDetails/sections/RequiredApprovalsSection';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { CommentsSection } from 'components/EventRequestDetails/sections/CommentsSection';

export const NovoSupport = () => {
  const { eventRequest } = useEventRequestDetails();

  return (
    <>
      <PetitionerSection />
      <DataSection />
      {!!eventRequest.speakers?.length && <SpeakersSection />}
      <EventLocationSection />
      <FoodSection />
      <MaterialSection />
      <AttachmentsSection />
      {(!!eventRequest.approvals?.length || !!eventRequest.manager) && (
        <RequiredApprovalsSection />
      )}
      {!!eventRequest.comments?.length && <CommentsSection />}
    </>
  );
};
