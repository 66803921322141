import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.RequiredApprovalsSection.title',
    defaultMessage: 'APROBACIONES REQUERIDAS'
  },
  titleModal: {
    id: 'components.RequiredApprovalsSection.titleModal',
    defaultMessage: 'APROBAR SOLICITUD'
  },
  authorizationDate: {
    id: 'components.RequiredApprovalsSection. authorizationDate',
    defaultMessage: 'Fecha de autorización:'
  },
  aceptButton: {
    id: 'components.RequiredApprovalsSection.aceptButton',
    defaultMessage: 'Aceptar'
  },
  manager: {
    id: 'components.RequiredApprovalsSection.manager',
    defaultMessage: 'Gerente'
  },
  director: {
    id: 'components.RequiredApprovalsSection.director',
    defaultMessage: 'Director'
  },
  marketing: {
    id: 'components.RequiredApprovalsSection.marketing',
    defaultMessage: 'Marketing'
  },
  medical: {
    id: 'components.RequiredApprovalsSection.medical',
    defaultMessage: 'Medical'
  },
  compilance: {
    id: 'components.RequiredApprovalsSection.compilance',
    defaultMessage: 'Compilance'
  }
});
