import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  descriptionModal: {
    id: 'components.SignModal.descriptionModal',
    defaultMessage:
      'Si estas seguro que la solicitud cumple con todo lo necesario para continuar con el proceso de aprobación, por favor ingresa tu firma dentro del recuadro que se muestra a continuación.'
  },
  cleanButton: {
    id: 'components.SignModal.cleanButton',
    defaultMessage: 'Limpiar'
  }
});
