export const mapSpeakerList = (speakers) =>
  speakers.map(
    (speaker) => ({
      id: speaker._id,
      name: speaker.speaker_name,
      type: speaker.speaker_type,
      franchises: speaker.franchises_name,
      cedula: speaker.cedula,
      cityId: speaker.city_id,
      cityName: speaker.city_name,
      contractNumber: speaker.contract_number,
      curriculum: {
        url: speaker.curriculum,
        file: {
          name: 'Curriculum',
          type: 'application/pdf'
        }
      },
      email: speaker.email,
      fiscalAddress: speaker.fiscal_address,
      hcpTier: speaker.hcp_tier,
      rfc: speaker.rfc,
      speciality: speaker.speciality,
      supplierNumber: speaker.supplier_number
    }),
    []
  );
