import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  imageLabel: {
    id: 'components.ImageProfileSection.imageLabel',
    defaultMessage: 'Seleccionar'
  },
  title: {
    id: 'components.ImageProfileSection.title',
    defaultMessage: 'IMAGEN DEL EMPLEADO'
  },
  imageDescription: {
    id: 'components.ImageProfileSection.imageDescription',
    defaultMessage:
      'Se mostrarán las iniciales del Nombre y Apellido del usuario como imagen preestablecida:'
  }
});
