import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    letterSpacing: 4,
    fontWeight: 'bold',
    lineHeight: 1,
    marginBottom: theme.spacing(2)
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column'
  },
  switch: {
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  filePicker: {
    [theme.breakpoints.up('md')]: {
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'flex',
      alignItems: 'center',
      '& > .MuiTypography-subtitle2': {
        marginRight: theme.spacing(2),
        whiteSpace: 'nowrap'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& > .MuiTypography-subtitle2': {
        marginBottom: theme.spacing(1)
      }
    }
  },
  disabledText: {
    color: theme.palette.text.disabled
  }
}));

export { useStyles };
