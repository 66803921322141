import { useEffect } from 'react';

export const useDateAndTimeUnifier = (formik) => {
  useEffect(() => {
    const { date, time } = formik.values.requirements;

    if (date) {
      formik.setFieldValue(
        'requirements.date',
        new Date(
          `${date.toDateString()} ${(time || new Date('0')).toTimeString()}`
        )
      );
    }
  }, [formik.values.requirements.time]);
};
