import { useState } from 'react';
import { useLoader } from '@octopy/react-loader';
import { getBlobUrl } from 'utils/files';

export const useImageViewer = () => {
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState(null);
  const [name, setName] = useState(null);
  const { handleShowLoader } = useLoader();

  const handleOpen = async ({ url, name }) => {
    handleShowLoader(true);

    const blobUrl = await getBlobUrl(url);

    handleShowLoader(false);

    setUrl(blobUrl);
    setName(name);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return {
    visible,
    url,
    name,
    handleOpen,
    handleClose
  };
};
