export const useInitialValues = () => ({
  draft: false,
  requirements: {
    pharmacyName: '',
    date: null,
    time: null
  },
  address: {
    cityId: null,
    colonyId: null,
    externalNumber: null,
    internalNumber: null,
    zipCode: null
  },
  objective: ''
});
