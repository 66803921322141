import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.SpeakersSection.title',
    defaultMessage: 'SPEAKERS'
  },
  speakerExtern: {
    id: 'components.SpeakersSection.speakerExtern',
    defaultMessage: 'SPEAKER EXTERNO:'
  },
  speakerIntern: {
    id: 'components.SpeakersSection.speakerIntern',
    defaultMessage: 'SPEAKER INTERNO:'
  },
  extern: {
    id: 'components.SpeakersSection.extern',
    defaultMessage: 'EXTERNOS'
  },
  intern: {
    id: 'components.SpeakersSection.intern',
    defaultMessage: 'INTERNOS'
  }
});
