import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Switch,
  Typography,
  IconButton,
  Button
} from '@material-ui/core';

import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import { AddFranchisesDrawer } from '../AddFranchisesDrawer/AddFranchisesDrawer';

import { useDrawer } from 'components/Drawer';
import { useModal } from '@octopy/react-modal';

import { useIntl } from 'react-intl';

import { useStyles } from './FranchiseCardStyles';

import { useHistory } from 'react-router-dom';

import clsx from 'clsx';

import { messages } from './FranchiseCardMessages';
import { useFranchises } from 'components/Franchises/hooks/useFranchises';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';

export const FranchiseCard = ({ franchise }) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    user: { role, ...user }
  } = useAuth();

  const medical = user.employeeId === franchise?.medical?.value;

  const { handleOpenDrawer } = useDrawer();
  const { handleOpenModal, handleCloseModal } = useModal();

  const history = useHistory();

  const {
    handleUpdateFranchise,
    handleDeleteFranchise,
    handleDisableOrEnableFranchise
  } = useFranchises();

  const handlUpdateFranchises = (event) => {
    event.stopPropagation();

    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.editFranchisesTitle),
      body: (
        <AddFranchisesDrawer
          onSubmit={async (values) =>
            await handleUpdateFranchise({
              name: values.franchisesName,
              directorId: values.director,
              medicalId: values.medical,
              franchiseId: franchise.id
            })
          }
          initialValues={{
            franchisesName: franchise.franquicia,
            director: franchise.director.value,
            medical: franchise.medical.value
          }}
          edit={true}
          director={franchise.director}
        />
      )
    });
  };

  const handleOpenDeleteFranchisesModal = (event) => {
    event.stopPropagation();

    handleOpenModal({
      title: <>{intl.formatMessage(messages.removeFranchisesId)}</>,
      body: (
        <Fragment>
          {intl.formatMessage(messages.removeFranchisesQuestion)}
        </Fragment>
      ),
      actionButtons: (
        <>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleDeleteFranchise(franchise.id);
            }}
          >
            {intl.formatMessage(messages.delete)}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleOpenModalEnableOrDisable = (event) => {
    event.stopPropagation();

    let ObjectEnableOrDisableFranchise = {};

    if (franchise.active) {
      ObjectEnableOrDisableFranchise = {
        title: intl.formatMessage(messages.disableFranchisesTitle),
        question: intl.formatMessage(messages.disableFranchisesQuestion),
        body: intl.formatMessage(messages.disableFranchisesBody),
        button: intl.formatMessage(messages.disableFranchisesButton)
      };
    } else {
      ObjectEnableOrDisableFranchise = {
        title: intl.formatMessage(messages.enabledFranchisesTitle),
        question: intl.formatMessage(messages.enabledFranchisesQuestion),
        body: intl.formatMessage(messages.enabledFranchisesBody),
        button: intl.formatMessage(messages.enabledFranchisesButton)
      };
    }

    handleOpenModal({
      title: ObjectEnableOrDisableFranchise.title,
      body: (
        <Fragment>
          {ObjectEnableOrDisableFranchise.question}
          {ObjectEnableOrDisableFranchise.body}
        </Fragment>
      ),
      actionButtons: (
        <Fragment>
          <Button
            color="primary"
            onClick={() => {
              handleCloseModal();
              handleDisableOrEnableFranchise(franchise.id, !franchise.active);
            }}
          >
            {ObjectEnableOrDisableFranchise.button}
          </Button>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </Fragment>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  return (
    <Card
      key={`${franchise.id}-${franchise.franquicia}`}
      className={classes.card}
      onClick={() =>
        history.push(`${history.location.pathname}/${franchise.id}`)
      }
    >
      <CardHeader
        classes={{
          title: classes.cardHeaderTitle,
          content: classes.cardHeaderContent,
          root: classes.cardHeader
        }}
        action={
          role === roles.admin && (
            <Switch
              checked={franchise.active}
              name="agendas"
              onClick={handleOpenModalEnableOrDisable}
            />
          )
        }
        title={`${franchise.agendas} Agendas`}
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography variant="subtitle1" className={classes.franquiciaCss}>
          {franchise.franquicia}
        </Typography>
        <Typography variant="body2" className={classes.directorCss}>
          {franchise.director.label}
        </Typography>
      </CardContent>
      <CardActions>
        <Typography
          className={clsx(classes.empleadosCss, classes.empleadosActions)}
        >
          {medical
            ? franchise.numberOfSchedulesPendingApproval
            : franchise.empleados}{' '}
          {intl.formatMessage(
            messages[medical ? 'schedulesPendingApproval' : 'employees']
          )}
        </Typography>
        {role === roles.admin && (
          <>
            <IconButton
              id={franchise.id}
              className={classes.btnFranchises}
              aria-label="editFranchises"
              onClick={handlUpdateFranchises}
            >
              <CreateIcon />
            </IconButton>
            <IconButton
              className={classes.btnFranchises}
              aria-label="handleOpenDeleteFranchisesModal"
              onClick={handleOpenDeleteFranchisesModal}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </CardActions>
    </Card>
  );
};

FranchiseCard.propTypes = {
  franchises: PropTypes.array
};
