import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: theme.customShadows.z2,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      padding: theme.spacing(2)
    }
  }
}));

export { useStyles };
