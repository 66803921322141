export const mapEditScheduleFormInitialValues = ({ id, name, url }) => ({
  id,
  name,
  document: {
    url,
    file: {
      name,
      type: 'application/pdf'
    }
  }
});
