import React from 'react';

import { useIntl } from 'react-intl';

import { Typography, Button } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircle';

import { messages } from './SchedulesMessages';
import { useStyles } from './SchedulesStyles';

import { ScheduleCard } from './ScheduleCard';
import { ScheduleDrawer } from './ScheduleDrawer';

import { useDrawer } from 'components/Drawer';
import { useFranchise } from '../hooks/useFranchise';
import { useParams } from 'react-router-dom';
import { useAuth } from 'components/Auth';
import { roles } from 'components/Auth/helpers';
import { NoData } from 'components/NoData';

const Schedules = ({ medical }) => {
  const { franchiseId } = useParams();
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const { schedules, handleAddSchedule } = useFranchise();
  const {
    user: { role }
  } = useAuth();

  const handleOpenNewScheduleForm = (event) => {
    event.stopPropagation();

    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      title: intl.formatMessage(messages.newScheduleTitle),
      caption: intl.formatMessage(messages.scheduleDrawerSubTitle),
      body: (
        <ScheduleDrawer
          onSubmit={async (values) =>
            await handleAddSchedule(franchiseId, values)
          }
        />
      )
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="subtitile1" className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
        <div className={classes.actionButtons}>
          {role === roles.admin && (
            <Button
              onClick={handleOpenNewScheduleForm}
              color="primary"
              endIcon={<AddIcon />}
            >
              {intl.formatMessage(messages.newSchedule)}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.cardsContainer}>
        {schedules ? (
          schedules.length ? (
            schedules?.map((schedule) => (
              <ScheduleCard
                key={`schedule-${schedule.id}`}
                schedule={schedule}
                medical={medical}
              />
            ))
          ) : (
            <NoData pl={2} pt={2} pb={4} />
          )
        ) : (
          <NoData pl={2} pt={2} pb={4} loading />
        )}
      </div>
    </div>
  );
};

export { Schedules };
