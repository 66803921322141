import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  title: {
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2)
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cardsContainer: {
    marginLeft: -theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(2)
  }
}));

export { useStyles };
