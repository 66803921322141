import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  franchise: {
    id: 'components.Franchise.franchise',
    defaultMessage: 'Franquicia'
  },
  associateApprover: {
    id: 'components.Franchise.associateApprover',
    defaultMessage: 'Asociar aprobador'
  },
  addApproverFormTitle: {
    id: 'components.Franchise.addApproverFormTitle',
    defaultMessage: 'Asociar aprobador'
  },
  addApproverFormCaption: {
    id: 'components.Franchise.addApproverFormCaption',
    defaultMessage:
      'Completa los siguientes campos para asociar un aprobador perteneciente a otra franquicia.'
  }
});
