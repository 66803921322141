import React from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Button, Tooltip } from '@material-ui/core';
import { messages } from './ObserverEventRequestDetailsMessages';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { NovoSupport } from 'components/EventRequestDetails/byEventType/NovoSupport';
import { NovoEvent } from 'components/EventRequestDetails/byEventType/NovoEvent';
import { PharmacyActivity } from 'components/EventRequestDetails/byEventType/PharmacyActivity';
import { FaceToFace } from 'components/EventRequestDetails/byEventType/FaceToFace';
import { eventTypes } from 'components/EventRequestForm/helpers';
import { useAuth } from 'components/Auth';

const ObserverEventRequestDetails = () => {
  const intl = useIntl();
  const { eventId } = useParams();
  const history = useHistory();
  const { user } = useAuth();
  const { eventRequest } = useEventRequestDetails();

  return (
    <>
      {eventRequest.type === eventTypes.novoSupport && <NovoSupport />}
      {eventRequest.type === eventTypes.novoEvent && <NovoEvent />}
      {eventRequest.type === eventTypes.pharmacyActivities && (
        <PharmacyActivity />
      )}
      {eventRequest.type === eventTypes.faceToFace && <FaceToFace />}

      {eventRequest.status === 'aprobada' &&
        user.employeeId === eventRequest.representative.id && (
          <Box pt={4} display="flex" justifyContent="flex-end">
            <Tooltip
              placement="left"
              title={
                eventRequest.date > new Date()
                  ? intl.formatMessage(messages.eventDateHasntPassed)
                  : intl.formatMessage(messages.finishEventRequest)
              }
            >
              <div>
                <Button
                  disabled={eventRequest.date > new Date()}
                  color="primary"
                  onClick={() =>
                    history.push(`/eventRequests/${eventId}/close`)
                  }
                >
                  {intl.formatMessage(messages.closeRequest)}
                </Button>
              </div>
            </Tooltip>
          </Box>
        )}
    </>
  );
};

export { ObserverEventRequestDetails };
