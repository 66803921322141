import { useIntl } from 'react-intl';

import * as yup from 'yup';

import { messages } from '../SpeakerAddDrawerMessages';

export const useValidationSchema = () => {
  const intl = useIntl();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const rfcErrorMessage = intl.formatMessage(messages.rfcError);
  const emailErrorMessage = intl.formatMessage(messages.emailError);
  const numberErroMessage = intl.formatMessage(messages.numberError);
  const mustBePdfErrorMessage = intl.formatMessage(messages.mustBePdfError);

  const validationSchema = yup.object({
    addBy: yup.string().oneOf(['internal', 'external']).required(),
    employee: yup
      .string()
      .nullable()
      .when('addBy', {
        is: 'internal',
        then: yup.string().required(requiredErrorMessage),
        otherwise: yup.string()
      }),
    speaker: yup.string().when('addBy', {
      is: 'external',
      then: yup.string().required(requiredErrorMessage).trim(),
      otherwise: yup.string()
    }),
    email: yup.string().when('addBy', {
      is: 'external',
      then: yup
        .string()
        .email(emailErrorMessage)
        .required(requiredErrorMessage),
      otherwise: yup.string()
    }),
    contractNumber: yup
      .number()
      .required(requiredErrorMessage)
      .positive(numberErroMessage)
      .integer()
      .typeError(numberErroMessage),
    supplierNumber: yup
      .number()
      .required(requiredErrorMessage)
      .positive(numberErroMessage)
      .integer()
      .typeError(numberErroMessage),
    rfc: yup.string().when('addBy', {
      is: 'external',
      then: yup
        .string()
        .matches(
          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
          {
            message: rfcErrorMessage,
            excludeEmptyString: true
          }
        )
        .required(requiredErrorMessage),
      otherwise: yup.string()
    }),
    cedula: yup.string().required(requiredErrorMessage),
    city: yup.string().when('addBy', {
      is: 'external',
      then: yup.string().required(requiredErrorMessage),
      otherwise: yup.string()
    }),
    fiscalAddress: yup.string().required(requiredErrorMessage),
    hcpTier: yup.string().required(requiredErrorMessage),
    speciality: yup.string().required(requiredErrorMessage),
    curriculum: yup.object({
      file: yup
        .mixed()
        .nullable()
        .required(requiredErrorMessage)
        .test(
          'fileType',
          mustBePdfErrorMessage,
          (file) => !file?.type || file?.type === 'application/pdf'
        )
    })
  });

  return validationSchema;
};
