import { roles } from 'components/Auth/helpers';
import { eventTypes } from 'components/EventRequestForm/helpers';
import { formatTime } from 'utils/dates';

export const getUserRole = (eventRequest, user) => {
  if (
    ![eventTypes.faceToFace, eventTypes.pharmacyActivities].includes(
      eventRequest.type
    )
  ) {
    if (['ingresada', 'en revisión'].includes(eventRequest.status)) {
      if (user.role === roles.reviewer) {
        return 'reviewer';
      } else {
        return 'observer';
      }
    }
  }

  const approvers = getAllApprovers(eventRequest);

  const userRole = approvers.find((approver) => approver.id === user.employeeId)
    ? 'approver'
    : 'observer';

  return userRole;
};

export const getAllApprovers = (eventRequest) => {
  const approvers = [];

  eventRequest.manager &&
    approvers.push({ ...eventRequest.manager, role: 'manager' });

  !!eventRequest.director &&
    approvers.push({ ...eventRequest.director, role: 'director' });

  !!eventRequest.medical &&
    approvers.push({ ...eventRequest.medical, role: 'medical' });

  if (eventRequest.approvals) {
    for (let approver of eventRequest.approvals) {
      approvers.push({ ...approver, role: 'approver' });
    }
  }

  return approvers;
};

export const determinateIfUserCanApprove = (eventRequest, user) => {
  const allApprovers = getAllApprovers(eventRequest);

  const userIndex = allApprovers.findIndex(
    (approver) => approver.id === user.employeeId
  );

  if (userIndex === 0) {
    return !allApprovers[userIndex].approved;
  }

  return (
    allApprovers[userIndex - 1].approved && !allApprovers[userIndex].approved
  );
};

export const changeStatus = (list, userId) => {
  const userIndex = list.findIndex((approver) => approver.id === userId);

  if (userIndex === 0) {
    return !list[userIndex].approved;
  }

  return list[userIndex - 1].approved && !list[userIndex].approved;
};

export const objectEventRequest = (event, user) => {
  const isReviewer =
    ![eventTypes.pharmacyActivities, eventTypes.faceToFace].includes(
      event.type
    ) &&
    ['ingresada', 'en revisión'].includes(event.status) &&
    user.role === roles.reviewer;

  let mappedEventRequest;

  if (event.event_type === eventTypes.novoEvent) {
    const mappedFiles = event.speakers.map((item) => ({
      name: 'speaker file',
      url: item.quote_file,
      type: 'speaker',
      speakerName: item.speaker_id?.speaker_name
    }));

    if (event.place_file) {
      mappedFiles.push({
        url: event.place_file,
        type: 'place'
      });
    }

    if (event.food_file) {
      mappedFiles.push({
        url: event.food_file,
        type: 'food'
      });
    }

    if (event.material_file) {
      mappedFiles.push({
        url: event.material_file,
        type: 'material'
      });
    }

    mappedEventRequest = {
      status: event.status,
      type: event.event_type,
      petitioner: {
        id: event.employee_identifier,
        name: event.employee_name,
        email: event.employee_email,
        franchise: event.franchise_name,
        district: event.district_name
      },
      data: {
        date: new Date(event.event_date).toLocaleDateString({
          region: 'es-mx'
        }),
        hour: formatTime(new Date(event.event_date)),
        schedule: event.agenda_name,
        numberGuests: event.total_guest
      },
      speakers: (event.speakers || []).map((speaker) => ({
        rol: speaker.speaker_id?.speaker_type,
        id: speaker.speaker_id?._id,
        name: speaker.speaker_id?.speaker_name
      })),
      location: {
        city: event?.city?.nombre,
        typeLocation: event.place_type?.name,
        nameLocation: event.place_name,
        cost: 'Sí',
        facilitiesCost: event.place_cost
      },
      food: {
        totalCost: event.food_cost,
        perPerson: event.food_per_person_cost,
        tip: event.food_tip?.name
      },
      materials: {
        totalCost: event.material_cost
      },
      documents: mappedFiles,
      approvals: []
    };

    if (isReviewer) {
      mappedEventRequest.manager = {
        name: event.manager_name,
        id: event.manager_id,
        title: 'Gerente'
      };

      mappedEventRequest.medical = {
        name: event.medical_name,
        id: event.medical_id,
        title: 'Medical'
      };
      mappedEventRequest.director = {
        name: event.director_name,
        id: event.director_id,
        title: 'Director'
      };
      const mappedFranchiseApprovers = [
        {
          name: event.director_name,
          id: event.director_id,
          title: 'Director'
        },
        {
          name: event.medical_name,
          id: event.medical_id,
          title: 'Medical'
        },
        ...event.franchise_approvers.map((approver) => ({
          name: approver.name,
          title: approver.franchise_name,
          id: approver._id
        }))
      ];

      mappedEventRequest.approvals = mappedFranchiseApprovers;
    } else {
      for (let approver of event.approvers) {
        if (approver._id === event.manager_id) {
          mappedEventRequest.manager = {
            title: 'Gerente',
            name: approver.name,
            approved: approver.approved,
            id: approver._id,
            url: approver.signature_url
          };
        } else if (approver._id === event.medical_id) {
          mappedEventRequest.medical = {
            title: 'Medical',
            name: approver.name,
            approved: approver.approved,
            id: approver._id,
            url: approver.signature_url
          };
        } else if (approver._id === event.director_id) {
          mappedEventRequest.director = {
            title: 'Director',
            name: approver.name,
            approved: approver.approved,
            id: approver._id,
            url: approver.signature_url
          };
        } else {
          mappedEventRequest.approvals.push({
            name: approver.name,
            title: approver.franchise_name,
            approved: approver.approved,
            id: approver._id,
            url: approver.signature_url
          });
        }
      }
    }
  }

  if (event.event_type === eventTypes.novoSupport) {
    const isReviewer =
      ![eventTypes.pharmacyActivities, eventTypes.faceToFace].includes(
        event.type
      ) &&
      ['ingresada', 'en revisión'].includes(event.status) &&
      user.role === roles.reviewer;

    const mappedFiles = event.speakers.map((item) => ({
      name: 'speaker file',
      url: item.quote_file,
      type: 'speaker',
      speakerName: item.speaker_id?.speaker_name
    }));

    if (event.contract_file) {
      mappedFiles.push({
        url: event.contract_file,
        type: 'contract'
      });
    }

    if (event.place_file) {
      mappedFiles.push({
        url: event.place_file,
        type: 'place'
      });
    }

    if (event.food_file) {
      mappedFiles.push({
        url: event.food_file,
        type: 'food'
      });
    }

    if (event.material_file) {
      mappedFiles.push({
        url: event.material_file,
        type: 'material'
      });
    }

    mappedEventRequest = {
      status: event.status,
      type: event.event_type,
      petitioner: {
        id: event.employee_identifier,
        name: event.employee_name,
        email: event.employee_email,
        franchise: event.franchise_name,
        district: event.district_name
      },
      data: {
        date: new Date(event.event_date).toLocaleDateString({
          region: 'es-mx'
        }),
        hour: formatTime(new Date(event.event_date)),
        schedule: event.agenda_name,
        numberGuests: event.total_guest,
        typeSupport: event.support_type,
        nameSchedule: event.agenda_name
      },
      speakers: (event.speakers || []).map((speaker) => ({
        rol: speaker.speaker_id?.speaker_type,
        id: speaker.speaker_id?._id,
        name: speaker.speaker_id?.speaker_name
      })),

      location: {
        city: event?.city?.nombre,
        typeLocation: event.place_type?.name,
        nameLocation: event.place_name,
        cost: 'Sí',
        facilitiesCost: event.place_cost
      },
      food: {
        totalCost: event.food_cost,
        perPerson: event.food_per_person_cost,
        tip: event.food_tip?.name
      },
      materials: {
        totalCost: event.material_cost
      },
      documents: mappedFiles,
      approvals: []
    };

    if (isReviewer) {
      mappedEventRequest.manager = {
        name: event.manager_name,
        id: event.manager_id,
        title: 'Gerente'
      };

      mappedEventRequest.medical = {
        name: event.medical_name,
        id: event.medical_id,
        title: 'Medical'
      };
      mappedEventRequest.director = {
        name: event.director_name,
        id: event.director_id,
        title: 'Director'
      };
      const mappedFranchiseApprovers = [
        {
          name: event.director_name,
          id: event.director_id,
          title: 'Director'
        },
        {
          name: event.medical_name,
          id: event.medical_id,
          title: 'Medical'
        },
        ...event.franchise_approvers.map((approver) => ({
          name: approver.name,
          title: approver.franchise_name,
          id: approver._id
        }))
      ];

      mappedEventRequest.approvals = mappedFranchiseApprovers;
    } else {
      for (let approver of event.approvers) {
        if (approver._id === event.manager_id) {
          console.log('approver', approver);

          mappedEventRequest.manager = {
            title: 'Gerente',
            name: approver.name,
            approved: approver.approved,
            id: approver._id,
            url: approver.signature_url
          };
        } else if (approver._id === event.medical_id) {
          mappedEventRequest.medical = {
            title: 'Medical',
            name: approver.name,
            approved: approver.approved,
            id: approver._id,
            url: approver.signature_url
          };
        } else if (approver._id === event.director_id) {
          mappedEventRequest.director = {
            title: 'Director',
            name: approver.name,
            approved: approver.approved,
            id: approver._id,
            url: approver.signature_url
          };
        } else {
          mappedEventRequest.approvals.push({
            name: approver.name,
            title: approver.franchise_name,
            approved: approver.approved,
            id: approver._id,
            url: approver.signature_url
          });
        }
      }
    }
  }

  if (event.event_type === eventTypes.pharmacyActivities) {
    mappedEventRequest = {
      type: event.event_type,
      status: event.status,
      requirements: {
        name: event.place_name,
        date: new Date(event.event_date).toLocaleDateString({
          region: 'es-mx'
        }),
        hourEvent: formatTime(new Date(event.event_date))
      },
      location: {
        city: event?.city?.nombre,
        colony: event.neighborhood,
        externNumber: event.external_number,
        internNumber: event.internal_number,
        postalCode: event.zip_code
      },

      objective: event.activity_goal,
      approvals: []
    };
    for (let approver of event.approvers) {
      if (approver._id === event.manager_id) {
        mappedEventRequest.manager = {
          title: 'Gerente',
          name: approver.name,
          id: approver._id,
          url: approver.signature_url
        };
      }
    }
  }

  if (event.event_type === eventTypes.faceToFace) {
    mappedEventRequest = {
      type: event.event_type,
      status: event.status,

      requirements: {
        name: event.place_name,
        date: new Date(event.event_date).toLocaleDateString({
          region: 'es-mx'
        }),
        hourEvent: formatTime(new Date(event.event_date))
      },
      hcpsData: event.hcps.map((item) => ({
        name: item.name,
        oneKeyId: item.onekey_id ? item.onekey_id : ''
      })),
      objective: event.activity_goal,
      approvals: []
    };

    for (let approver of event.approvers) {
      if (approver._id === event.manager_id) {
        mappedEventRequest.manager = {
          title: 'Gerente',
          name: approver.name,
          id: approver._id,
          url: approver.signature_url
        };
      }
    }
  }

  mappedEventRequest.comments = event.status_history?.reduce(
    (comments, record) =>
      record.comment
        ? [
            ...comments,
            {
              date: new Date(record.updatedAt),
              text: record.comment,
              from: record.employee_name
            }
          ]
        : comments,
    []
  );

  mappedEventRequest.id = event._id;

  mappedEventRequest.representative = {
    id: event.employee_id,
    identifier: event.employee_identifier,
    name: event.employee_name
  };

  if (event.event_date) {
    mappedEventRequest.date = new Date(event.event_date);
  }

  return mappedEventRequest;
};

export const payload = {
  type: 'novoEvent',
  eventName: 'Novo Evento',
  status: 'en revision',
  petitioner: {
    id: 'RARZ',
    name: 'Ramíro Alcocer Rodríguez',
    email: 'rarz@novonordisk.com',
    franchise: 'Insulinas',
    district: '100'
  },
  data: {
    date: '15 mayo 2022',
    hour: '10:24 pm',
    schedule: 'solicitar agenda',
    numberGuests: '245'
    // typeSupport: 'Proyectos y patrocinadores',
    // nameSchedule: 'Conferencia Insulinas 2022'
  },
  speakers: [
    {
      name: 'Rosa estrella',
      rol: 'extern'
    },
    {
      name: 'Ricardo García',
      rol: 'extern'
    },
    {
      name: 'Christian Castillo Herrera',
      rol: 'extern'
    },
    {
      name: 'Rosa estrella',
      rol: 'extern'
    },
    {
      name: 'Ricardo García',
      rol: 'extern'
    },
    {
      name: 'Christian Castillo Herrera',
      rol: 'extern'
    },
    {
      name: 'Rosa estrella',
      rol: 'extern'
    },
    {
      name: 'Ricardo García',
      rol: 'extern'
    },
    {
      name: 'Christian Castillo Herrera',
      rol: 'extern'
    },
    {
      name: 'Areli Zapata Portillo',
      rol: 'intern'
    },
    {
      name: 'Rosa estrella',
      rol: 'intern'
    },
    {
      name: 'Rosa estrella',
      rol: 'intern'
    }
  ],
  location: {
    state: 'Tamaulipas',
    city: 'Tampico',
    typeLocation: 'Restaurante',
    nameLocation: 'El lindero',
    cost: 'Sí',
    facilitiesCost: '3500 mx'
  },
  food: {
    totalCost: '100000 mx',
    perPerson: '1500 mx',
    tip: '10%'
  },
  materials: {
    totalCost: '1000000'
  },
  documents: [
    {
      name: 'Introducción a Javascript',
      url:
        'http://190.57.147.202:90/jspui/bitstream/123456789/430/1/introduccion_javascript%20%281%29.pdf'
    },
    {
      name: 'Teoria de automatas y lenguajes formales',
      url: 'http://formella.webs.uvigo.es/doc/talf12/talf_final.pdf'
    },
    {
      name:
        'doc javascript 3 jdfjkdjks sd kjsd kjsd fjksd fkjs dfkjs fk dfkjsd jnkjnkjsandkasnjd jksn jksandjksand  sandjkansjksandjksad ',
      url:
        'http://190.57.147.202:90/jspui/bitstream/123456789/430/1/introduccion_javascript%20%281%29.pdf'
    }
  ],
  manager: {
    name: 'Gerente Christian ',
    date: '12 septiembre 2022',
    url: null,
    ocupation: 'Gerente',
    id: '234',
    identifier: 'CC',
    approvalStatus: 'approved'
  },
  director: {
    name: 'Gerente Christian ',
    date: '12 septiembre 2022',
    url: null,
    ocupation: 'Director',
    id: '214',
    identifier: 'CP',
    approvalStatus: 'approved'
  },
  medical: {
    name: 'Gerente Armando',
    date: '12 septiembre 2022',
    url: null,
    ocupation: 'Medical',
    id: '454',
    identifier: 'AC',
    approvalStatus: 'approved'
  },
  approvals: [
    {
      type: 'medical',
      ocupation: 'Marketing',
      franchise: 'Marketing',
      name: 'Gerente Fabio',
      date: '12 septiembre 2022',
      identifier: 'FC',
      url: null,
      id: '123',
      approvalStatus: 'approved'
    },
    {
      type: 'medical',
      ocupation: 'Compilance',
      franchise: 'Compilance',
      name: 'Gerente Carlos',
      date: '12 septiembre 2022',
      identifier: 'CB',
      url: null,
      id: '333',
      approvalStatus: 'none'
    }
  ]
};

// export const payload = {
//   type: 'pharmacy',
//   eventName: 'Actividades de farmacia',
//   requirements: {
//     name: 'San Marcos',
//     date: '12 de abril',
//     hourEvent: '16:00 pm'
//   },
//   location: {
//     state: 'Tamaulipas',
//     city: 'Tampico',
//     colony: 'Loma',
//     externNumber: '56',
//     internNumber: '0',
//     postalCode: '1234'
//   },
// manager: {
//   name: 'Gerente Christian ',
//   date: '12 septiembre 2022',
//   url: null,
//   id: '234',
//   approvalStatus: 'approved'
// },
//   objective: 'Visitar la farmacia'
// };

// export const payload = {
//   type: 'faceToFace',
//   eventName: 'Face to face',
//   requirements: {
//     name: 'San Marcos',
//     date: '12 de abril',
//     hourEvent: '16:00 pm'
//   },
//   hcpsData: [
//     {
//       name: 'Marco castelan Estrada',
//       oneKeyId: '1431222'
//     },
//     {
//       name: 'Antonio  Pedro Palacios García',
//       oneKeyId: '2234533'
//     },
//     {
//       name: 'Pedro Palacios García',
//       oneKeyId: '2234533'
//     }
//   ],
//   objective: 'Llega puntual al evento',
//   manager: {
//     name: 'Gerente Christian ',
//     date: '12 septiembre 2022',
//     url: null,
//     id: '234',
//     approvalStatus: 'approved'
//   }
// };
