import React from 'react';
import PropTypes from 'prop-types';

import { useIntl, FormattedMessage } from 'react-intl';

import { Typography, Button } from '@material-ui/core';

import { messages } from './ScheduleDrawerMessages';

import { useStyles } from './ScheduleDrawerStyles';
import { useForm } from './hooks/useForm';

import { renderTextField } from '@octopy/react-form';

import { FilePicker } from 'components/FilePicker';

const ScheduleDrawer = ({ initialValues, onSubmit }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { fields, formik } = useForm({ initialValues, onSubmit });

  console.log(initialValues);

  return (
    <div>
      {renderTextField({
        formik,
        field: fields.name,
        inputProps: {}
      })}

      <Typography variant="subtitle2" className={classes.approverSubtitle}>
        <FormattedMessage {...messages.documentSchedule} />
      </Typography>

      <FilePicker {...fields.document} />

      <Button
        onClick={formik.handleSubmit}
        className={classes.submitButtonPdf}
        color="primary"
      >
        {intl.formatMessage(messages.saveSchedule)}
      </Button>
    </div>
  );
};

ScheduleDrawer.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func
};

export { ScheduleDrawer };
