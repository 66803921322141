import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestForm.sections.AddressSection.title',
    defaultMessage: 'DIRECCIÓN DE LA FARMACIA'
  },
  stateLabel: {
    id: 'components.EventRequestForm.sections.AddressSection.stateLabel',
    defaultMessage: 'Estado'
  },
  statePlaceholder: {
    id: 'components.EventRequestForm.sections.AddressSection.statePlaceholder',
    defaultMessage: 'Seleccione el estado'
  },
  cityLabel: {
    id: 'components.EventRequestForm.sections.AddressSection.cityLabel',
    defaultMessage: 'Ciudad'
  },
  cityPlaceholder: {
    id: 'components.EventRequestForm.sections.AddressSection.cityPlaceholder',
    defaultMessage: 'Seleccione la ciudad'
  },
  colonyLabel: {
    id: 'components.EventRequestForm.sections.AddressSection.colonyLabel',
    defaultMessage: 'Colonia'
  },
  colonyPlaceholder: {
    id: 'components.EventRequestForm.sections.AddressSection.colonyPlaceholder',
    defaultMessage: 'Ingrese la colonia'
  },
  externalNumberLabel: {
    id:
      'components.EventRequestForm.sections.AddressSection.externalNumberLabel',
    defaultMessage: 'Número exterior'
  },
  externalNumberPlaceholder: {
    id:
      'components.EventRequestForm.sections.AddressSection.externalNumberPlaceholder',
    defaultMessage: 'Ingrese el número exterior'
  },
  internalNumberLabel: {
    id:
      'components.EventRequestForm.sections.AddressSection.internalNumberLabel',
    defaultMessage: 'Número interior'
  },
  internalNumberPlaceholder: {
    id:
      'components.EventRequestForm.sections.AddressSection.internalNumberPlaceholder',
    defaultMessage: 'Ingrese el número interior'
  },
  zipCodeLabel: {
    id: 'components.EventRequestForm.sections.AddressSection.zipCodeLabel',
    defaultMessage: 'Código postal'
  },
  zipCodePlaceholder: {
    id:
      'components.EventRequestForm.sections.AddressSection.zipCodePlaceholder',
    defaultMessage: 'Ingrese el número postal'
  }
});
