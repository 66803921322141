import React from 'react';
import { useIntl } from 'react-intl';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { messages } from './NoDataMessages';
import { useStyles } from './NoDataStyles';

const NoData = ({ loading, dataName, className, ...props }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Box {...props} className={`${classes.container} ${className}`}>
      {loading ? (
        <>
          <CircularProgress
            className={classes.loader}
            color="secondary"
            size={20}
          />
          <Typography className={classes.text}>
            {intl.formatMessage(messages.loading, {
              dataName: dataName || intl.formatMessage(messages.data)
            })}
          </Typography>
        </>
      ) : (
        <Typography className={classes.text}>
          {intl.formatMessage(messages.noData, {
            dataName: dataName || intl.formatMessage(messages.data)
          })}
        </Typography>
      )}
    </Box>
  );
};

export { NoData };
