import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { messages } from './AddRepresentativeFormMessages';
import { useStyles } from './AddRepresentativeFormStyles';
import { useForm } from './hooks/useForm';
import { renderAutocomplete } from '@octopy/react-form';

export const AddRepresentativeForm = ({ onSubmit }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { fields, formik } = useForm({ onSubmit });

  return (
    <div>
      <Typography className={classes.employeeSubtitle} variant="subtitle2">
        {intl.formatMessage(messages.employee)}
      </Typography>

      {renderAutocomplete({
        formik,
        field: fields.employeeId,
        inputProps: {}
      })}

      <Button
        onClick={formik.handleSubmit}
        className={classes.submitButton}
        color="primary"
      >
        {intl.formatMessage(messages.accept)}
      </Button>
    </div>
  );
};
