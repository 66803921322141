import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.EventRequestDetailsActions.title',
    defaultMessage: 'SOLICITUD-'
  },
  novoEvent: {
    id: 'components.EventRequestDetailsActions.novoEvent',
    defaultMessage: 'NOVO EVENTO'
  },
  novoSupport: {
    id: 'components.EventRequestDetailsActions.novoSupport',
    defaultMessage: 'NOVO APOYO'
  },
  faceToFace: {
    id: 'components.EventRequestDetailsActions.faceToFace',
    defaultMessage: 'FACE TO FACE'
  },
  pharmacy: {
    id: 'components.EventRequestDetailsActions.pharmacy',
    defaultMessage: 'ACTIVIDADES DE FARMACIA'
  }
});
