import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  editFranchisesTitle: {
    id: 'components.CardMessages.editFranchises.title',
    defaultMessage: 'Datos de la franquicia'
  },
  employees: {
    id: 'components.CardMessages.editFranchises.employees',
    defaultMessage: 'empleados'
  },
  cancel: {
    id: 'components.Franchises.cancelFranchises.cancel',
    defaultMessage: 'Cancelar'
  },
  removeFranchisesId: {
    id: 'components.Franchises.CardMessages.removeFranchises.id',
    defaultMessage: 'Eliminar franquicia'
  },
  removeFranchisesQuestion: {
    id: 'components.Franchises.CardMessages.removeFranchises.question',
    defaultMessage: '¿Estas seguro de querer eliminar esta franquicia?'
  },
  delete: {
    id: 'components.Franchises.CardMessages.delete',
    defaultMessage: 'Eliminar'
  },
  disableFranchisesTitle: {
    id: 'components.Franchises.disableFranchises.title',
    defaultMessage: 'Deshabilitar franquicia'
  },
  disableFranchisesQuestion: {
    id: 'components.Franchises.disableFranchises.question',
    defaultMessage: '¿Estas seguro de querer deshabilitar esta franquicia?. '
  },
  disableFranchisesBody: {
    id: 'components.Franchises.disableFranchises.body',
    defaultMessage:
      'Una vez deshabilitada esta unidad de negocio no podrá realizar solicitudes de ningún tipo.'
  },
  disableFranchisesButton: {
    id: 'components.Franchises.disableFranchises.button',
    defaultMessage: 'Deshabilitar'
  },
  enabledFranchisesTitle: {
    id: 'components.Franchises.enabledFranchises.title',
    defaultMessage: 'Habilitar franquicia'
  },
  enabledFranchisesQuestion: {
    id: 'components.Franchises.enabledFranchises.question',
    defaultMessage: '¿Estas seguro de querer habilitar esta franquicia?. '
  },
  enabledFranchisesBody: {
    id: 'components.Franchises.enabledFranchisesBody.body',
    defaultMessage:
      'Una vez habilitada esta unidad de negocio podrá realizar solicitudes de todo tipo.'
  },
  enabledFranchisesButton: {
    id: 'components.Franchises.enabledFranchises.button',
    defaultMessage: 'Habilitar'
  },
  schedulesPendingApproval: {
    id: 'components.Franchises.FranchiseCard.schedulesPendingApproval',
    defaultMessage: 'agendas pendientes de aprobación'
  }
});
