import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Districts.title',
    defaultMessage: 'Distritos'
  },
  export: {
    id: 'components.Districts.export',
    defaultMessage: 'Exportar'
  },
  newDistrict: {
    id: 'components.Districts.newDistrict',
    defaultMessage: 'Nuevo distrito'
  },
  addDistrictDrawerTitle: {
    id: 'components.Franchise.addDistrictDrawerTitle',
    defaultMessage: 'Nuevo distrito'
  },
  addDistrictDrawerCaption: {
    id: 'components.Franchise.addDistrictDrawerCaption',
    defaultMessage:
      'Completa los siguientes campos para crear un nuevo distrito.'
  },
  districtExport: {
    id: 'components.Districts.districtExport',
    defaultMessage: 'Exportar'
  }
});
