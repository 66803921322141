import { useConfiguration } from 'components/Configuration';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../../EventRequestFormMessages';
import { useValidationSchema as useHcpValidationSchema } from '../../sections/HcpsSection/AddHcpForm/hooks/useValidationSchema';

export const useValidationSchema = () => {
  const intl = useIntl();
  const { configuration } = useConfiguration();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const minimumOneHcpErrorMessage = intl.formatMessage(
    messages.minimumOneHcpError
  );
  const noMoreThanMaxGuestsError = intl.formatMessage(
    messages.noMoreThanNumberError,
    {
      number:
        configuration.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities
    }
  );

  const validationSchema = yup.object({
    draft: yup.boolean(),
    requirements: yup
      .object({
        date: yup.date().nullable().required(requiredErrorMessage),
        time: yup.date().nullable().required(requiredErrorMessage),
        placeName: yup.string().required(requiredErrorMessage)
      })
      .when('draft', (draft, schema) => (!draft ? schema : yup.object())),
    hcps: yup
      .array()
      .of(useHcpValidationSchema())
      .max(
        configuration.maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities,
        noMoreThanMaxGuestsError
      )
      .required(requiredErrorMessage)
      .when('draft', (draft, schema) =>
        !draft ? schema.min(1, minimumOneHcpErrorMessage) : schema
      ),
    objective: yup
      .string()
      .when('draft', (draft, schema) =>
        !draft ? schema.required(requiredErrorMessage) : schema
      )
  });

  return validationSchema;
};
