import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../../../../EventRequestFormMessages';

export const useValidationSchema = () => {
  const intl = useIntl();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const mustBePdfErrorMessage = intl.formatMessage(messages.mustBePdfError);

  const validationSchema = yup.object({
    type: yup.string().oneOf(['externo', 'interno']).required(),
    data: yup.object().when('type', {
      is: 'externo',
      then: yup.object({
        speaker: yup
          .object({
            id: yup.string().nullable().required(),
            name: yup.string().nullable().required()
          })
          .nullable()
          .required(requiredErrorMessage),
        typeOfTalk: yup.string().nullable().required(requiredErrorMessage),
        reasonForUsingExternalSpeaker: yup
          .string()
          .nullable()
          .required(requiredErrorMessage),
        confirmation: yup.object({
          url: yup.string().nullable(),
          file: yup
            .mixed()
            .nullable()
            .required(requiredErrorMessage)
            .test(
              'fileType',
              mustBePdfErrorMessage,
              (file) => !file?.type || file?.type === 'application/pdf'
            )
        })
      }),
      otherwise: yup.object({
        speaker: yup
          .object({
            id: yup.string().nullable().required(),
            identifier: yup.string().nullable(),
            name: yup.string().nullable().required(),
            imageUrl: yup.string().nullable()
          })
          .nullable()
          .required(requiredErrorMessage),
        typeOfTalk: yup.string().nullable().required(requiredErrorMessage),
        confirmation: yup.object({
          url: yup.string().nullable(),
          file: yup
            .mixed()
            .nullable()
            .required(requiredErrorMessage)
            .test(
              'fileType',
              mustBePdfErrorMessage,
              (file) => !file?.type || file?.type === 'application/pdf'
            )
        })
      })
    }),
    logisticsEnabled: yup.boolean(),
    logistics: yup
      .object()
      .when(['type', 'logisticsEnabled'], (type, logisticsEnabled) =>
        type === 'externo' && logisticsEnabled
          ? yup.object({
              type: yup.string().nullable().required(requiredErrorMessage),
              transportationCost: yup
                .number()
                .nullable()
                .min(0)
                .required(requiredErrorMessage),
              hotelCost: yup
                .number()
                .nullable()
                .min(0)
                .required(requiredErrorMessage),
              quote: yup
                .object({
                  url: yup.string().nullable(),
                  file: yup
                    .mixed()
                    .nullable()
                    .required(requiredErrorMessage)
                    .test(
                      'fileType',
                      mustBePdfErrorMessage,
                      (file) => !file?.type || file?.type === 'application/pdf'
                    )
                })
                .required(requiredErrorMessage)
            })
          : yup.object()
      )
  });

  return validationSchema;
};
