import { useConfiguration } from 'components/Configuration';
import { useValidationSchema as useSpeakerValidationSchema } from 'components/EventRequestForm/sections/SpeakersSection/AssociateSpeakerForm/hooks/useValidationSchema';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../../EventRequestFormMessages';

export const useValidationSchema = ({ numberOfGuests }) => {
  const { configuration } = useConfiguration();
  const intl = useIntl();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const mustBePdfErrorMessage = intl.formatMessage(messages.mustBePdfError);
  const greaterThanZeroErrorMessage = intl.formatMessage(
    messages.greaterThanError,
    {
      number: 0
    }
  );
  const greaterThanGuestsErrorMessage = intl.formatMessage(
    messages.greaterThanError,
    {
      number: configuration.minimumNumberOfGuestsForNovoEventAndNovoSupport
    }
  );
  const minimumOneSpeakerErrorMessage = intl.formatMessage(
    messages.minimumOneSpeakerError
  );
  const anticipationDaysError = intl.formatMessage(
    messages.anticipationDaysError,
    {
      days: configuration.anticipationDays
    }
  );
  const lessThanPlaceCostError = intl.formatMessage(
    messages.lessThanMoneyError,
    {
      money: configuration.facilityRentalCost
    }
  );
  const lessThanMaterialsCostError = intl.formatMessage(
    messages.lessThanMoneyError,
    {
      money: configuration.materialsCost
    }
  );
  const lessThanFoodCostError = intl.formatMessage(
    messages.lessThanMoneyError,
    {
      money: configuration.maximumFoodCostPerPerson
    }
  );
  const lessThanTotalFoodCostError = intl.formatMessage(
    messages.lessThanMoneyError,
    {
      money: configuration.maximumFoodCostPerPerson * (numberOfGuests || 0)
    }
  );

  const validationSchema = yup.object({
    draft: yup.boolean(),
    requirements: yup
      .object({
        date: yup
          .date()
          .min(
            new Date(
              new Date().setDate(
                new Date().getDate() +
                  ((configuration.anticipationDays || 0) + 1)
              )
            ),
            anticipationDaysError
          )
          .nullable()
          .required(requiredErrorMessage),
        time: yup.date().nullable().required(requiredErrorMessage),
        scheduleId: yup.string().nullable().required(requiredErrorMessage),
        numberOfGuests: yup
          .number()
          .nullable()
          .min(
            configuration.minimumNumberOfGuestsForNovoEventAndNovoSupport,
            greaterThanGuestsErrorMessage
          )
          .required(requiredErrorMessage),
        supportType: yup.string().nullable().required(requiredErrorMessage),
        contract: yup.object({
          url: yup.string().nullable(),
          file: yup
            .mixed()
            .nullable()
            .test(
              'fileType',
              mustBePdfErrorMessage,
              (file) => !file?.type || file?.type === 'application/pdf'
            )
        })
      })
      .when('draft', (draft, schema) =>
        !draft
          ? schema
          : yup.object({
              date: yup
                .date()
                .min(
                  new Date(
                    new Date().setDate(
                      new Date().getDate() +
                        ((configuration.anticipationDays || 0) + 1)
                    )
                  ),
                  anticipationDaysError
                )
                .nullable(),
              numberOfGuests: yup
                .number()
                .nullable()
                .min(
                  configuration.minimumNumberOfGuestsForNovoEventAndNovoSupport,
                  greaterThanGuestsErrorMessage
                )
            })
      ),
    speakersEnabled: yup.boolean(),
    speakers: yup
      .array()
      .of(useSpeakerValidationSchema())
      .when(['draft', 'speakersEnabled'], (draft, speakersEnabled, schema) =>
        !speakersEnabled || draft
          ? schema
          : schema
              .min(1, minimumOneSpeakerErrorMessage)
              .required(requiredErrorMessage)
      ),
    place: yup
      .object({
        cityId: yup.string().nullable().required(requiredErrorMessage),
        type: yup.string().nullable().required(requiredErrorMessage),
        name: yup.string().required(requiredErrorMessage),
        hasACost: yup.boolean().required(requiredErrorMessage),
        cost: yup
          .number()
          .nullable()
          .when('hasACost', {
            is: true,
            then: yup
              .number()
              .nullable()
              .min(1, greaterThanZeroErrorMessage)
              .max(
                configuration.facilityRentalCost || 1,
                lessThanPlaceCostError
              )
              .required(requiredErrorMessage),
            otherwise: yup.number().nullable()
          }),
        quote: yup.object().when('hasACost', {
          is: true,
          then: yup
            .object({
              url: yup.string().nullable(),
              file: yup
                .mixed()
                .nullable()
                .required(requiredErrorMessage)
                .test(
                  'fileType',
                  mustBePdfErrorMessage,
                  (file) => !file?.type || file?.type === 'application/pdf'
                )
            })
            .required(),
          otherwise: yup.object().nullable()
        })
      })
      .when('draft', (draft, schema) =>
        !draft
          ? schema
          : yup.object({
              cost: yup
                .number()
                .nullable()
                .when('hasACost', {
                  is: true,
                  then: yup
                    .number()
                    .nullable()
                    .min(1, greaterThanZeroErrorMessage)
                    .max(
                      configuration.facilityRentalCost || 1,
                      lessThanPlaceCostError
                    ),
                  otherwise: yup.number().nullable()
                })
            })
      ),
    foodEnabled: yup.boolean(),
    food: yup
      .object({
        costPerPerson: yup
          .number()
          .nullable()
          .min(1, greaterThanZeroErrorMessage)
          .max(
            configuration.maximumFoodCostPerPerson || 0,
            lessThanFoodCostError
          )
          .required(requiredErrorMessage),
        totalCost: yup
          .number()
          .nullable()
          .min(1, greaterThanZeroErrorMessage)
          .max(
            (configuration.maximumFoodCostPerPerson || 0) * numberOfGuests,
            lessThanTotalFoodCostError
          )
          .required(requiredErrorMessage),
        tip: yup.string().nullable().required(requiredErrorMessage),
        quote: yup
          .object({
            url: yup.string().nullable(),
            file: yup
              .mixed()
              .nullable()
              .required(requiredErrorMessage)
              .test(
                'fileType',
                mustBePdfErrorMessage,
                (file) => !file?.type || file?.type === 'application/pdf'
              )
          })
          .required()
      })
      .when(['draft', 'foodEnabled'], (draft, foodEnabled, schema) =>
        !foodEnabled
          ? yup.object()
          : draft
          ? yup.object({
              costPerPerson: yup
                .number()
                .nullable()
                .min(1, greaterThanZeroErrorMessage)
                .max(
                  configuration.maximumFoodCostPerPerson || 0,
                  lessThanFoodCostError
                ),
              totalCost: yup
                .number()
                .nullable()
                .min(1, greaterThanZeroErrorMessage)
                .max(
                  (configuration.maximumFoodCostPerPerson || 0) *
                    numberOfGuests,
                  lessThanTotalFoodCostError
                )
            })
          : schema
      ),
    materialsEnabled: yup.boolean(),
    materials: yup
      .object({
        totalCost: yup
          .number()
          .nullable()
          .min(1, greaterThanZeroErrorMessage)
          .max(configuration.materialsCost, lessThanMaterialsCostError)
          .required(requiredErrorMessage),
        quote: yup
          .object({
            url: yup.string().nullable(),
            file: yup
              .mixed()
              .nullable()
              .required(requiredErrorMessage)
              .test(
                'fileType',
                mustBePdfErrorMessage,
                (file) => !file?.type || file?.type === 'application/pdf'
              )
          })
          .required()
      })
      .when(['draft', 'materialsEnabled'], (draft, materialsEnabled, schema) =>
        !materialsEnabled
          ? yup.object()
          : draft
          ? yup.object({
              totalCost: yup
                .number()
                .nullable()
                .min(1, greaterThanZeroErrorMessage)
                .max(configuration.materialsCost, lessThanMaterialsCostError)
            })
          : schema
      )
  });

  return validationSchema;
};
