import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  emailLabel: {
    id: 'components.Login.emailLabel',
    defaultMessage: 'Usuario'
  },
  emailPlaceholder: {
    id: 'components.Login.emailPlaceholder',
    defaultMessage: 'Ingresa tu correo electrónico'
  },
  passwordLabel: {
    id: 'components.Login.passwordLabel',
    defaultMessage: 'Contraseña'
  },
  passwordPlaceholder: {
    id: 'components.Login.passwordPlaceholder',
    defaultMessage: 'Ingresa tu contraseña'
  },
  requiredError: {
    id: 'components.Login.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  emailError: {
    id: 'components.Login.emailError',
    defaultMessage: 'Ingresa un correo electrónico valido'
  },
  forgotPasswordModalTitle: {
    id: 'components.Login.forgotPasswordModalTitle',
    defaultMessage: 'RESTABLECER CONTRASEÑA'
  },
  send: {
    id: 'components.Login.send',
    defaultMessage: 'Enviar'
  },
  cancel: {
    id: 'components.Login.cancel',
    defaultMessage: 'Cancelar'
  },
  login: {
    id: 'components.Login.login',
    defaultMessage: 'Login'
  },
  forgotPassword: {
    id: 'components.Login.forgotPassword',
    defaultMessage: 'Olvidé mi contraseña'
  },
  checkEmail: {
    id: 'components.Login.checkEmail',
    defaultMessage:
      'Por favor, revisa tu correo para continuar con el proceso de restablecimiento de tu contraseña'
  },
  accept: {
    id: 'components.Login.accept',
    defaultMessage: 'Aceptar'
  }
});
