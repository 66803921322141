import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { messages } from './AddFormatFormMessages';
import { useStyles } from './AddFormatFormStyles';
import { useForm } from './hooks/useForm';
import { renderTextField } from '@octopy/react-form';
import { FilePicker } from 'components/FilePicker';

export const AddFormatForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { fields, formik } = useForm();

  return (
    <div>
      {renderTextField({
        formik,
        field: fields.name,
        inputProps: {}
      })}

      <Typography className={classes.approverSubtitle} variant="subtitle2">
        {intl.formatMessage(messages.document)}
      </Typography>

      <FilePicker {...fields.file} />

      <Button
        onClick={formik.handleSubmit}
        className={classes.submitButton}
        color="primary"
      >
        {intl.formatMessage(messages.accept)}
      </Button>
    </div>
  );
};
