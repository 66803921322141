import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Table } from '@octopy/react-table';

import { useTable } from './hooks';

import { useStyles } from './SpeakerTableStyles';
import { Button } from '@material-ui/core';
import { usePdfViewer } from 'components/PdfViewer';

const SpeakerTable = ({ speakers }) => {
  const classes = useStyles();
  const table = useTable();
  const pdfViewer = usePdfViewer();

  const [speakersList, setSpeakerList] = useState([]);

  useEffect(() => {
    getList();
  }, [speakers]);

  const mapperFranchises = (franchises) => {
    let newFranchises = '';

    franchises?.forEach((franchise) => {
      newFranchises += `${franchise.name}, `;
    });

    return newFranchises;
  };

  const getList = async () => {
    const updateList = speakers?.map((speaker) => ({
      ...speaker,
      franchises: mapperFranchises(speaker.franchises),
      curriculum: (
        <Button
          color="primary"
          disabled={!speaker.curriculum?.url}
          onClick={() =>
            pdfViewer.handleOpen({
              url: speaker.curriculum.url,
              name: `${speaker.name} - Curriculum`
            })
          }
        >
          Ver
        </Button>
      )
    }));

    setSpeakerList(updateList);
  };

  return (
    <div className={classes.container}>
      <Table
        data={speakersList}
        columns={table.tableHead}
        actions={table.actions}
        configProps={{
          selectedOptions: {
            checkboxHidden: true
          }
        }}
      />
    </div>
  );
};

SpeakerTable.propTypes = {
  speakers: PropTypes.array
};

export { SpeakerTable };
