import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  newRepresentative: {
    id: 'components.Franchise.Districts.District.newRepresentative',
    defaultMessage: 'Nuevo representante'
  },
  subtitle: {
    id: 'components.Franchise.Districts.District.subtitle',
    defaultMessage: 'Gerente y representantes de ventas'
  },
  saleRepresentative: {
    id: 'components.Franchise.Districts.District.saleRepresentative',
    defaultMessage: 'Representante de ventas'
  },
  manager: {
    id: 'components.Franchise.Districts.District.manager',
    defaultMessage: 'Gerente'
  },
  addRepresentativeFormTitle: {
    id: 'components.Franchise.addRepresentativeFormTitle',
    defaultMessage: 'Nuevo representante'
  },
  addRepresentativeFormCaption: {
    id: 'components.Franchise.addRepresentativeFormCaption',
    defaultMessage:
      'Completa los siguientes campos para añadir un empleado como representante de ventas de esta franquicia.'
  },
  removeRepresentativeModalTitle: {
    id: 'components.Representatives.removeRepresentativeModal.title',
    defaultMessage: 'Eliminar representante'
  },
  removeRepresentativeModalBody1: {
    id: 'components.Representatives.removeRepresentativeModal.body1',
    defaultMessage: '¿Estas seguro de querer eliminar a'
  },
  removeRepresentativeModalBody2: {
    id: 'components.Representatives.removeRepresentativeModal.body2',
    defaultMessage: 'como representante de ventas de esta franquicia?'
  },
  cancel: {
    id: 'components.Franchise.Districts.District.cancel',
    defaultMessage: 'Cancelar'
  },
  accept: {
    id: 'components.Franchise.Districts.District.accept',
    defaultMessage: 'Aceptar'
  }
});
