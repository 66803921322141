import { useDrawer } from 'components/Drawer';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../AddFormatFormMessages';
import { useFormatRepository } from '../../hooks/useFormatRepository';
import { uploadFilePromise } from 'utils/aws';
import { Button } from '@material-ui/core';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';

export const useForm = () => {
  const { handleCloseDrawer } = useDrawer();
  const intl = useIntl();
  const { handleCloseModal, handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const { handleAddFormat } = useFormatRepository();

  const initialValues = {
    name: '',
    document: {
      url: null,
      file: null
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required(intl.formatMessage(messages.requiredError)),
    document: yup
      .object({
        file: yup
          .mixed()
          .nullable()
          .required(intl.formatMessage(messages.requiredError))
      })
      .nullable()
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (_values) => {
      try {
        handleShowLoader(true);

        const values = {
          ..._values,
          document: {
            url: await uploadFilePromise(_values.document.file)
          }
        };

        const done = await handleAddFormat(values);

        if (done) {
          handleCloseDrawer();
        }
      } catch (error) {
        handleOpenModal({
          configProps: {
            maxWidth: 'xs'
          },
          title: intl.formatMessage(messages.anErrorHasOccured),
          body: intl.formatMessage(messages.fileUploadError),
          actionButtons: (
            <Button onClick={handleCloseModal}>
              {intl.formatMessage(messages.accept)}
            </Button>
          )
        });
      } finally {
        handleShowLoader(false);
      }
    }
  });

  const fields = {
    name: {
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: intl.formatMessage(messages.namePlaceholder)
    },
    file: {
      type: 'filePicker',
      name: 'document',
      buttonLabel: intl.formatMessage(messages.fileLabel),
      onChange: (value) => formik.setFieldValue('document', value),
      value: formik.values.document,
      error: formik.touched.document?.file && formik.errors.document?.file
    }
  };

  return { formik, fields };
};
