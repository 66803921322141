import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.title',
    defaultMessage: 'Logística'
  },
  transportationCostLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.transportationCostLabel',
    defaultMessage: 'Costo del transporte'
  },
  transportationCostPlaceholder: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.transportationCostPlaceholder',
    defaultMessage: 'Ingrese el costo del transporte'
  },
  hotelCostLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.hotelCostLabel',
    defaultMessage: 'Costo del hotel'
  },
  hotelCostPlaceholder: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.hotelCostPlaceholder',
    defaultMessage: 'Ingrese el costo del hotel'
  },
  typeLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.typeLabel',
    defaultMessage: 'Tipo de logística'
  },
  typePlaceholder: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.typePlaceholder',
    defaultMessage: 'Seleccione el tipo de logística'
  },
  quoteLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.quoteLabel',
    defaultMessage: 'Adjuntar Cotización: '
  },
  quoteButtonLabel: {
    id:
      'components.EventRequestForm.sections.SpeakersSection.AssociateSpeakerForm.sections.LogisticsSection.quoteButtonLabel',
    defaultMessage: 'Seleccionar archivo PDF'
  }
});
