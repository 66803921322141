import { InputAdornment } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { messages } from '../MaterialsSectionMessages';

export const useFields = ({ formik }) => {
  const intl = useIntl();

  return [
    {
      type: 'number',
      name: 'materials.totalCost',
      label: intl.formatMessage(messages.totalCostLabel),
      placeholder: intl.formatMessage(messages.totalCostPlaceholder),
      breakpoints: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      disabled: !formik.values.materialsEnabled,
      inputProps: {
        InputProps: {
          endAdornment: <InputAdornment position="end">$</InputAdornment>
        }
      }
    },
    {
      type: 'application/pdf',
      name: 'materials.quote',
      buttonLabel: intl.formatMessage(messages.quoteButtonLabel),
      label: intl.formatMessage(messages.quoteLabel),
      value: formik.values.materials.quote,
      onChange: (value) => formik.setFieldValue('materials.quote', value),
      error:
        formik.touched.materials?.quote?.file &&
        formik.errors.materials?.quote?.file,
      breakpoints: {
        xs: 12
      },
      disabled: !formik.values.materialsEnabled,
      id: 'materials.quote.filePicker'
    }
  ];
};
