import { hexToRgba } from '../helpers';

export const getOverrides = (theme) => {
  const IS_DARK = theme.palette.mode === 'dark';

  return {
    MuiAppBar: {
      colorDefault: {
        backdropFilter: 'blur(6px)',
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
      }
    },
    MuiToolbar: {
      regular: theme.mixins.toolbar
    },
    MuiFormControlLabel: {
      label: theme.typography.subtitle2
    },
    MuiFilledInput: {
      input: {
        '&:-webkit-autofill': {
          borderRadius: theme.shape.borderRadius
        }
      },
      root: {
        borderRadius: theme.shape.borderRadius
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: theme.palette.grey[IS_DARK ? 600 : 300]
        },
        '&:hover $notchedOutline': {
          borderColor: theme.palette.grey[IS_DARK ? 600 : 400]
        },
        '&$focused $notchedOutline': {
          borderColor: theme.palette.grey[IS_DARK ? 600 : 500]
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.action.disabledBackground
          }
        }
      }
    },
    MuiButton: {
      root: {
        fontWeight: 'normal'
      },
      contained: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: hexToRgba(theme.palette.common.black, 0.85)
        }
      },
      outlined: {
        borderColor: theme.palette.grey[300],
        fontWeight: 'bold'
      },
      containedPrimary: {
        boxShadow: IS_DARK
          ? theme.customShadows.z1
          : theme.customShadows.primary
      },
      containedSecondary: {
        boxShadow: IS_DARK
          ? theme.customShadows.z1
          : theme.customShadows.secondary
      },
      sizeLarge: {
        height: '48px'
      }
    },
    MuiCard: {
      root: {
        boxShadow: theme.customShadows.z2,
        position: 'relative',
        border: '1px solid #00000010'
      }
    },
    MuiPopover: {
      paper: {
        boxShadow: theme.customShadows.z16,
        border:
          theme.palette.mode === 'light' &&
          `solid 1px ${theme.palette.grey[100]}`,
        overflowX: 'initial',
        overflowY: 'initial'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: theme.shape.borderRadius * 2
      }
    },
    MuiBackdrop: {
      root: {
        background: `linear-gradient(75deg, ${hexToRgba(
          theme.palette.grey[900],
          0.1
        )} 0%, ${hexToRgba(theme.palette.grey[900], 0.9)} 100%)`
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: theme.palette.grey[600]
      },
      thumb: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[200]}`
      },
      switchBase: {
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1
        },
        '&.Mui-checked .MuiSwitch-thumb': {
          backgroundColor: theme.palette.secondary.main,
          border: 'none'
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: `${theme.palette.grey[300]} !important`,
          opacity: 1
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          border: `1px solid ${theme.palette.grey[200]}`,
          backgroundColor: theme.palette.common.white
        }
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: `solid 1px ${hexToRgba(
          theme.palette.grey[IS_DARK ? 100 : 700],
          0.075
        )}`,
        padding: theme.spacing
      }
    },
    MuiBreadcrumbs: {
      ol: {
        flexWrap: 'nowrap',
        overflowX: 'auto'
      }
    },
    MuiListItemText: {
      root: {
        marginBottom: 2
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 30
      }
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '2rem'
      }
    },
    MuiDialog: {
      paper: {
        padding: theme.spacing * 4,
        marginLeft: theme.spacing,
        marginRight: theme.spacing,
        [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
          padding: theme.spacing * 2
        }
      },
      paperWidthFalse: {
        [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
          maxWidth: `calc(100% - ${theme.spacing * 2}px)`
        }
      }
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        '& > .MuiTypography-h6': {
          letterSpacing: 6,
          textTransform: 'uppercase',
          fontWeight: 'bold',
          textAlign: 'center',
          color: theme.palette.primary.main
        }
      }
    },
    MuiDialogContent: {
      root: {
        ...theme.typography.body2,
        textAlign: 'center',
        padding: 0,
        paddingTop: theme.spacing * 2,
        paddingBottom: theme.spacing * 3
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        padding: 0
      },
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: theme.spacing * 2
        }
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: theme.palette.secondary.main
      }
    },
    MuiIconButton: {
      root: {
        color: theme.palette.common.black
      }
    },
    MuiContainer: {
      root: {
        [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
          paddingLeft: theme.spacing,
          paddingRight: theme.spacing
        }
      }
    }
  };
};
