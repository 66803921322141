import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { messages } from '../AssociateSpeakerFormMessages';
import { useValidationSchema } from './useValidationSchema';

export const useForm = () => {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const history = useHistory();
  const location = useLocation();
  const { eventId: eventRequestId } = useParams();

  const initialValues = {
    type: 'externo',
    data: {
      speaker: {
        id: null,
        identifier: null,
        name: null,
        imageUrl: null
      },
      typeOfTalk: null,
      reasonForUsingExternalSpeaker: null,
      confirmation: {
        url: null,
        file: null
      }
    },
    logisticsEnabled: true,
    logistics: {
      type: null,
      transportationCost: null,
      hotelCost: null,
      quote: {
        url: null,
        file: null
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const eventRequestFormValues = {
        ...location.state.values,
        speakers: [...location.state.values.speakers, values]
      };

      history.replace(`/eventRequests/${eventRequestId}`, {
        ...location.state,
        values: eventRequestFormValues
      });
    }
  });

  const typeField = {
    section: 'category',
    type: 'radioGroup',
    name: 'type',
    row: true,
    options: [
      {
        name: 'interno',
        label: intl.formatMessage(messages.typeInternalLabel)
      },
      {
        name: 'externo',
        label: intl.formatMessage(messages.typeExternalLabel)
      }
    ]
  };

  return { formik, typeField };
};
