import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    overflowX: 'clip'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(8)
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(8)
    }
  }
}));

export { useStyles };
