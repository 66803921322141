import { uploadFilePromise } from 'utils/aws';
import { mapSpeakers } from '../sections/SpeakersSection/helpers';

export const mapEventRequest = (eventRequest) => {
  const mappedEventRequest = {
    draft: false,
    requirements: {
      date: eventRequest.event_date ? new Date(eventRequest.event_date) : null,
      time: eventRequest.event_date ? new Date(eventRequest.event_date) : null,
      scheduleId: eventRequest.agenda_id,
      numberOfGuests: eventRequest.total_guest,
      supportType: eventRequest.support_type,
      contract: {
        url: eventRequest.contract_file,
        file: !!eventRequest.contract_file
          ? { name: 'Cotizacion de la logística', type: 'application/pdf' }
          : null
      }
    },
    speakersEnabled: true,
    speakers: eventRequest.speakers.map((speaker) => ({
      type: speaker.speaker_id?.speaker_type || 'externo',
      data: {
        speaker: {
          id: speaker.speaker_id?._id,
          name: speaker.speaker_id?.speaker_name
        },
        typeOfTalk: speaker.talk_type?._id,
        reasonForUsingExternalSpeaker: speaker.speaker_reason?.name,
        confirmation: {
          url: speaker.confirmation_file,
          file: !!speaker.confirmation_file
            ? { name: 'Confirmacion del speaker', type: 'application/pdf' }
            : null
        }
      },
      logistics: {
        type: speaker.type_of_logistic?._id,
        transportationCost: speaker.transport_cost,
        hotelCost: speaker.hotel_cost,
        quote: {
          url: speaker.quote_file,
          file: !!speaker.quote_file
            ? { name: 'Cotizacion de la logística', type: 'application/pdf' }
            : null
        }
      }
    })),
    place: {
      cityId: eventRequest.city,
      type: eventRequest.place_type,
      name: eventRequest.place_name,
      hasACost: true,
      cost: eventRequest.place_cost,
      quote: {
        url: eventRequest.place_file,
        file: !!eventRequest.place_file
          ? { name: 'Cotizacion del lugar', type: 'application/pdf' }
          : null
      }
    },
    foodEnabled: true,
    food: {
      costPerPerson: eventRequest.food_per_person_cost,
      totalCost: eventRequest.food_cost,
      tip: eventRequest.food_tip,
      quote: {
        url: eventRequest.food_file,
        file: !!eventRequest.food_file
          ? { name: 'Cotizacion de la comida', type: 'application/pdf' }
          : null
      }
    },
    materialsEnabled: true,
    materials: {
      totalCost: eventRequest.material_cost,
      quote: {
        url: eventRequest.material_file,
        file: !!eventRequest.material_file
          ? { name: 'Cotizacion de los materiales', type: 'application/pdf' }
          : null
      }
    }
  };

  return mappedEventRequest;
};

export const mapFormValues = async (values) => {
  const mappedSpeakers = await mapSpeakers(values.speakers);

  const requirementsMappedValues = {
    event_date: values.requirements.date || undefined,
    agenda_id: values.requirements.scheduleId || undefined,
    total_guest: values.requirements.numberOfGuests || undefined,
    support_type: values.requirements.supportType || undefined,
    contract_file:
      values.requirements.contract.file instanceof File
        ? await uploadFilePromise(values.requirements.contract.file)
        : values.requirements.contract.url || undefined
  };

  const placeMappedValues = {
    city: values.place.cityId || undefined,
    place_type: values.place.type || undefined,
    place_name: values.place.name || undefined,
    place_file:
      values.place.quote.file instanceof File
        ? await uploadFilePromise(values.place.quote.file)
        : values.place.quote.url || undefined,
    place_cost: values.place.hasACost
      ? values.place.cost || undefined
      : undefined
  };

  const foodMappedValues = values.foodEnabled
    ? {
        food_cost: values.food.totalCost || undefined,
        food_per_person_cost: values.food.costPerPerson || undefined,
        food_tip: values.food.tip || undefined,
        food_file:
          values.food.quote.file instanceof File
            ? await uploadFilePromise(values.food.quote.file)
            : values.food.quote.url || undefined
      }
    : {};

  const materialsMappedValues = values.materialsEnabled
    ? {
        material_cost: values.materials.totalCost || undefined,
        material_file:
          values.materials.quote.file instanceof File
            ? await uploadFilePromise(values.materials.quote.file)
            : values.materials.quote.url || undefined
      }
    : {};

  const mappedValues = {
    is_entered: !values.draft,
    ...requirementsMappedValues,
    ...placeMappedValues,
    ...foodMappedValues,
    ...materialsMappedValues,
    speakers: mappedSpeakers
  };

  return mappedValues;
};
