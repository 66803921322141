import { useApi } from 'hooks';

const STATUS = {
  RETURN: 'devuelta',
  REJECTED: 'rechazada',
  APPROVED: 'aprobada'
};

export const useApproverActions = () => {
  const [approveEventRequest] = useApi({
    endpoint: 'request/employee-aprove',
    method: 'put'
  });

  const handleApproveEventRequest = async (id, signatureUrl) => {
    try {
      const {
        headerResponse: { status }
      } = await approveEventRequest({
        urlParams: id,
        body: {
          status: STATUS.APPROVED,
          signature_url: signatureUrl
        }
      });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReturnEventRequest = async (id, comment) => {
    try {
      const {
        headerResponse: { status }
      } = await approveEventRequest({
        urlParams: id,
        body: {
          status: STATUS.RETURN,
          comment
        }
      });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectEventRequest = async (id, comment) => {
    try {
      const {
        headerResponse: { status }
      } = await approveEventRequest({
        urlParams: id,
        body: {
          status: STATUS.REJECTED,
          comment
        }
      });

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleReturnEventRequest,
    handleApproveEventRequest,
    handleRejectEventRequest
  };
};
