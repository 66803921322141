import { useDrawer } from 'components/Drawer';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useCatalogues } from 'components/Catalogues';

import { useIntl } from 'react-intl';

import * as yup from 'yup';

import { messages } from '../AddFranchisesDrawerMessages';

export const useForm = ({ initialValues, onSubmit, director }) => {
  const { handleCloseDrawer } = useDrawer();
  const intl = useIntl();
  const [newEmployees, setNewEmployees] = useState([]);
  const [newEmployeesWithFranchise, setNewEmployeesWithFranchise] = useState(
    []
  );

  const {
    emptyEmployees,
    employeesFranchises: employeesWithFranchise,
    handleGetEmptyEmployees,
    handleGetFranchiseEmployee
  } = useCatalogues();

  useEffect(() => {
    setNewEmployeesWithFranchise(
      employeesWithFranchise.reduce(
        (newAllEmployees, franchise) => [
          ...newAllEmployees,
          ...franchise.approvers
        ],
        []
      )
    );
  }, [employeesWithFranchise]);

  useEffect(() => {
    handleGetEmptyEmployees();
    handleGetFranchiseEmployee();
  }, []);

  useEffect(() => {
    if (director?.value) {
      setNewEmployees([...emptyEmployees, director]);
    } else {
      setNewEmployees(emptyEmployees);
    }
  }, [director, emptyEmployees]);

  const validationSchema = yup.object({
    franchisesName: yup
      .string()
      .required(intl.formatMessage(messages.requiredError)),
    director: yup
      .string()
      .default('director')
      .notOneOf(
        [yup.ref('medical')],
        intl.formatMessage(messages.notSameDirectorAsMedicalError)
      ),
    medical: yup
      .string()
      .nullable()
      .default('medical')
      .notOneOf(
        [yup.ref('director')],
        intl.formatMessage(messages.notSameMedicalAsDirectorError)
      )
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const done = await onSubmit({
        ...values,
        director: values.director || undefined,
        medical: values.medical || undefined
      });

      if (done) {
        handleCloseDrawer();
      }
    }
  });

  const fields = {
    franchisesName: {
      type: 'text',
      name: 'franchisesName',
      label: intl.formatMessage(messages.franchisesName),
      placeholder: intl.formatMessage(messages.franchisePlaceholder)
    },
    director: {
      type: 'select',
      name: 'director',
      label: intl.formatMessage(messages.addDirector),
      placeholder: intl.formatMessage(messages.addDirector),
      options: newEmployees,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value
    },
    medical: {
      type: 'select',
      name: 'medical',
      label: intl.formatMessage(messages.addMedical),
      placeholder: intl.formatMessage(messages.addMedical),
      options: newEmployeesWithFranchise,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value
    }
  };

  return { formik, fields };
};
