import { useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { messages } from '../LogisticsSectionMessages';
import { useCatalogues } from 'components/Catalogues';

export const useFields = ({ formik }) => {
  const intl = useIntl();
  const [logisticTypeOptions, setLogisticTypeOptions] = useState([]);

  const { logisticType } = useCatalogues();

  useEffect(() => {
    setLogisticTypeOptions(logisticType);
  }, [logisticType]);

  const internalSpeaker = formik.values.type === 'interno';

  const commonBreakpoints = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3
  };

  return [
    {
      type: 'select',
      name: 'logistics.type',
      label: intl.formatMessage(messages.typeLabel),
      placeholder: intl.formatMessage(messages.typePlaceholder),
      options: logisticTypeOptions,
      getOptionLabel: (option) => option.label,
      getOptionSelected: (option, value) => option === value,
      getOptionValue: (option) => option.value,
      breakpoints: commonBreakpoints,
      disabled: !formik.values.logisticsEnabled || internalSpeaker
    },
    {
      type: 'number',
      name: 'logistics.transportationCost',
      label: intl.formatMessage(messages.transportationCostLabel),
      placeholder: intl.formatMessage(messages.transportationCostPlaceholder),
      breakpoints: commonBreakpoints,
      disabled: !formik.values.logisticsEnabled || internalSpeaker,
      inputProps: {
        InputProps: {
          endAdornment: <InputAdornment position="end">$</InputAdornment>
        }
      }
    },
    {
      type: 'number',
      name: 'logistics.hotelCost',
      label: intl.formatMessage(messages.hotelCostLabel),
      placeholder: intl.formatMessage(messages.hotelCostPlaceholder),
      breakpoints: commonBreakpoints,
      disabled: !formik.values.logisticsEnabled || internalSpeaker,
      inputProps: {
        InputProps: {
          endAdornment: <InputAdornment position="end">$</InputAdornment>
        }
      }
    },
    {
      type: 'application/pdf',
      name: 'logistics.quote',
      buttonLabel: intl.formatMessage(messages.quoteButtonLabel),
      label: intl.formatMessage(messages.quoteLabel),
      value: formik.values.logistics.quote,
      onChange: (value) => formik.setFieldValue('logistics.quote', value),
      error:
        formik.touched.logistics?.quote?.file &&
        formik.errors.logistics?.quote?.file,
      breakpoints: {
        xs: 12
      },
      disabled: !formik.values.logisticsEnabled || internalSpeaker,
      id: 'logistics.quote.filePicker'
    }
  ];
};
