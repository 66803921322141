import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { messages } from '../HcpsFiltersFormMessages';
import { useHcps } from 'components/Hcps/hooks/useHcps';

export const useForm = () => {
  const intl = useIntl();
  const { setFilter } = useHcps();

  const initialValues = {
    //oneKeyId: '',
    name: ''
    //specialty: '',
    //therapyAreas: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => setFilter(values)
  });

  const fields = [
    // {
    //   type: 'text',
    //   name: 'oneKeyId',
    //   label: intl.formatMessage(messages.oneKeyLabel),
    //   placeholder: '1221222',
    //   breakpoints: { xs: 12, sm: 3 }
    // },
    {
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: 'Ej. Christian Cedillo',
      breakpoints: { xs: 12, md: 6 }
    }
    // {
    //   type: 'text',
    //   name: 'specialty',
    //   label: intl.formatMessage(messages.specialtyLabel),
    //   placeholder: 'Medico',
    //   breakpoints: { xs: 12, sm: 3 }
    // },
    // {
    //   type: 'text',
    //   name: 'therapyAreas',
    //   label: intl.formatMessage(messages.treatmentLabel),
    //   placeholder: intl.formatMessage(messages.treatmentPlaceholder),
    //   breakpoints: { xs: 12, sm: 3 }
    // }
  ];

  return { formik, fields };
};
