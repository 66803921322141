import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  header: {
    width: '100%',
    backgroundColor: hexToRgba(theme.palette.common.black, 0.7),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  icon: {
    color: theme.palette.common.white
  },
  pdfIcon: {
    color: '#EA4335',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  name: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    transform: 'translateY(1px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  pdf: {
    height: '90%',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      height: '60%'
    }
  },
  spacer: {
    flexGrow: 1
  }
}));

export { useStyles };
