import React from 'react';
import { Backdrop, Box, IconButton } from '@material-ui/core';
import { useStyles } from './ImageViewerStyles';
import { createPortal } from 'react-dom';
import BackIcon from '@material-ui/icons/ArrowBack';
import DownloadIcon from '@material-ui/icons/GetAppRounded';
import { downloadFile } from 'utils/files';

export const ImageViewer = ({ visible, handleClose, url, name }) => {
  const classes = useStyles();

  const handleDownload = () => downloadFile({ url, name });

  return createPortal(
    <Backdrop className={classes.backdrop} open={visible} onClick={handleClose}>
      <Box
        className={classes.header}
        onClick={(event) => event.stopPropagation()}
      >
        <IconButton onClick={handleClose} className={classes.icon}>
          <BackIcon />
        </IconButton>

        <div className={classes.spacer} />

        <IconButton className={classes.icon} onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
      </Box>

      <div className={classes.spacer} />

      <img src={url} className={classes.image} alt="" />

      <div className={classes.spacer} />
    </Backdrop>,
    document.getElementById('root')
  );
};
