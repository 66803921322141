import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { messages } from './HcpsSectionMessages';
import { useStyles } from './HcpsSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const HcpsSection = () => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    eventRequest: { hcpsData }
  } = useEventRequestDetails();

  if (!hcpsData?.length) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.titleCard)}
      </Typography>
      <Grid container spacing={2}>
        {hcpsData.map((item) => (
          <Grid item xs={12} className={classes.containerInfo}>
            <Typography color="secondary" className={classes.keyIdLabel}>
              {item.oneKeyId || 'NUEVO'}-
            </Typography>
            <Typography>{item.name}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

HcpsSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { HcpsSection };
