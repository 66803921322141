import { Fragment } from 'react';

import { useIntl } from 'react-intl';
import { useModal } from '@octopy/react-modal';

import { useApi } from 'hooks';
import { roles } from 'components/Auth/helpers';

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Box
} from '@material-ui/core';

import TimelineIcon from '@material-ui/icons/Timeline';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EditIcon from '@material-ui/icons/EditRounded';
import DeleteIcon from '@material-ui/icons/Delete';

import PersonIcon from '@material-ui/icons/Person';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { messages } from '../EventRequestCardMessages';
import { useAuth } from 'components/Auth';
import { useHistory } from 'react-router-dom';
import { useEventRequests } from 'components/EventRequests/hooks/useEventRequests';

export const useActions = ({
  anchorEl,
  handleOpenMenu,
  handleClose,
  OpenMovementsDrawer,
  closeEvent,
  status,
  eventDate,
  eventId,
  hasExternalSpeaker
}) => {
  const history = useHistory();
  const intl = useIntl();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleGetPendingEventRequests } = useEventRequests();

  const {
    user: { role, medical }
  } = useAuth();

  const validateIfCloseEvent = {
    aprobada: true
  };

  const [deleteEvent] = useApi({
    endpoint: `/request/delete/${eventId}`,
    method: 'delete'
  });

  const handleOpenDeleteEventModal = () => {
    handleClose();

    handleOpenModal({
      title: intl.formatMessage(messages.deleteModalTitle),
      body: <Fragment>{intl.formatMessage(messages.deleteModalBody)}</Fragment>,
      actionButtons: (
        <>
          <Button onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              try {
                const response = await deleteEvent();

                if (response.headerResponse.status === 200) {
                  handleGetPendingEventRequests();
                  handleCloseModal();
                }
              } catch (error) {
                //
              }
            }}
          >
            {intl.formatMessage(messages.delete)}
          </Button>
        </>
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const actionsButtons = {
    speakerExt: hasExternalSpeaker ? (
      <IconButton size="small" edge="end" disabled>
        <PersonIcon color="secondary" />
      </IconButton>
    ) : undefined,
    multiEvents: (showCloseEvent = false) => (
      <Box onClick={(event) => event.stopPropagation()}>
        <IconButton size="small" edge="end" onClick={handleOpenMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={OpenMovementsDrawer}>
            <ListItemIcon>
              <TimelineIcon fontSize="small" />
            </ListItemIcon>
            {intl.formatMessage(messages.movements)}
          </MenuItem>

          {showCloseEvent && (
            <MenuItem onClick={closeEvent}>
              <ListItemIcon>
                <EventBusyIcon fontSize="small" />
              </ListItemIcon>
              {intl.formatMessage(messages.closeRequest)}
            </MenuItem>
          )}
          {role === roles.representative &&
            ['creada', 'devuelta'].includes(status) && (
              <MenuItem
                onClick={() => history.push(`/eventRequests/${eventId}`)}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                {intl.formatMessage(messages.edit)}
              </MenuItem>
            )}

          {role === roles.representative &&
            ['creada', 'devuelta', 'rechazada', 'cerrada'].includes(status) && (
              <MenuItem onClick={handleOpenDeleteEventModal}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                {intl.formatMessage(messages.delete)}
              </MenuItem>
            )}
        </Menu>
      </Box>
    )
  };

  let ifApproved = false;

  switch (role) {
    case roles.representative:
      if (
        validateIfCloseEvent[status] &&
        Date.parse(eventDate) < new Date().getTime()
      ) {
        ifApproved = true;
      }

      break;
    default:
      break;
  }

  const actionsButtonsByRole = {
    [roles.admin]: [actionsButtons.speakerExt, actionsButtons.multiEvents()],
    [roles.representative]: [
      actionsButtons.speakerExt,
      actionsButtons.multiEvents(ifApproved)
    ],
    [roles.manager]: medical
      ? [actionsButtons.speakerExt, actionsButtons.multiEvents()]
      : [actionsButtons.multiEvents()],
    [roles.director]: medical
      ? [actionsButtons.speakerExt, actionsButtons.multiEvents()]
      : [],
    [roles.reviewer]: [actionsButtons.speakerExt, actionsButtons.multiEvents()]
  };

  return actionsButtonsByRole[role];
};
