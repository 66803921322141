import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@material-ui/lab';

import { messages } from './EventMovementsMessages';
import { useStyles } from './EventMovementsStyles';
import { formatDateLong } from '../../helper';
import { messageStatusTimeline } from './helper';

const EventMovements = ({ movementsHistory }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant="caption"
        color="secondary"
        className={classes.history}
      >
        {intl.formatMessage(messages.history)}
      </Typography>

      {movementsHistory.map((movementHistory) => (
        <div>
          <Timeline
            align="alternate"
            classes={{
              root: classes.MuiTimelineRoot
            }}
          >
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="subtitle2" className={classes.history}>
                  {formatDateLong(movementHistory.date)}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="secondary" />
                <TimelineConnector
                  color="secondary"
                  classes={{
                    root: classes.MuiTimelineSeparatorRoot
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="subtitle2" className={classes.history}>
                  {intl.formatMessage(
                    messageStatusTimeline[movementHistory.name] ||
                      messageStatusTimeline.undefined
                  )}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      ))}
    </div>
  );
};

EventMovements.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { EventMovements };
