import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredCommentError: {
    id: 'components.CommentForm.requiredCommentError',
    defaultMessage: 'Es necesario especificar el motivo'
  },
  returnCommentLabel: {
    id: 'components.CommentForm.returnCommentLabel',
    defaultMessage: 'Motivo de devolución de la solicitud'
  },
  returnCommentPlaceholder: {
    id: 'components.CommentForm.returnCommentPlaceholder',
    defaultMessage: 'Ingrese el motivo de devolución de la solicitud'
  },
  rejectCommentLabel: {
    id: 'components.CommentForm.rejectCommentLabel',
    defaultMessage: 'Motivo de rechazo de la solicitud'
  },
  rejectCommentPlaceholder: {
    id: 'components.CommentForm.rejectCommentPlaceholder',
    defaultMessage: 'Ingrese el motivo de rechazo de la solicitud'
  }
});
