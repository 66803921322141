import React from 'react';
import { App } from 'components/App';
import { LanguageProvider } from 'components/Language';
import { ThemeProvider } from 'components/Theme';
import { ModalProvider } from '@octopy/react-modal';
import { LoaderProvider } from '@octopy/react-loader';
import { SideMenuProvider } from 'components/SideMenu';
// import { ContextLogger } from 'components/ContextLogger';
import { config } from 'providers/config';
import { translations } from './translations';
// import appContext from './context';
import { DrawerProvider } from 'components/Drawer';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AuthProvider } from 'components/Auth';
import { DistrictProvider } from 'components/District';
import { EmployeesProvider } from 'components/Employees';
import { FranchisesProvider } from 'components/Franchises';
import { FranchiseProvider } from 'components/Franchise/FranchiseProvider';
import { EventRequestDetailsProvider } from 'components/EventRequestDetails/EventRequestDetailsProvider';
import { CataloguesProvider } from 'components/Catalogues';
import { PdfViewerProvider } from 'components/PdfViewer/PdfViewerProvider';
import { FormatRepositoryProvider } from 'components/FormatRepository/FormatRepositoryProvider';
import { HcpsProvider } from 'components/Hcps/HcpsProvider';
import { SpeakersProvider } from 'components/Speakers/SpeakersProvider';
import { EventRequestsProvider } from 'components/EventRequests/EventRequestsProvider';
import { ConfigurationProvider } from 'components/Configuration/ConfigurationProvider';

const contextConfig = { objectDiffs: true, arrayDiffs: false };

export function Root() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider defaultTheme={config.siteConfig.defaultTheme}>
        <LanguageProvider
          locale={config.siteConfig.languageCode}
          translations={translations}
        >
          <LoaderProvider>
            <ModalProvider>
              <DrawerProvider>
                <SideMenuProvider>
                  <AuthProvider>
                    <DistrictProvider>
                      <EmployeesProvider>
                        <FranchisesProvider>
                          <FranchiseProvider>
                            <EventRequestsProvider>
                              <EventRequestDetailsProvider>
                                <FormatRepositoryProvider>
                                  <CataloguesProvider>
                                    <PdfViewerProvider>
                                      <HcpsProvider>
                                        <SpeakersProvider>
                                          <ConfigurationProvider>
                                            <App />
                                            {/* <ContextLogger
                                            contexts={appContext}
                                            config={contextConfig}
                                          /> */}
                                          </ConfigurationProvider>
                                        </SpeakersProvider>
                                      </HcpsProvider>
                                    </PdfViewerProvider>
                                  </CataloguesProvider>
                                </FormatRepositoryProvider>
                              </EventRequestDetailsProvider>
                            </EventRequestsProvider>
                          </FranchiseProvider>
                        </FranchisesProvider>
                      </EmployeesProvider>
                    </DistrictProvider>
                  </AuthProvider>
                </SideMenuProvider>
              </DrawerProvider>
            </ModalProvider>
          </LoaderProvider>
        </LanguageProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
