import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  download: {
    id: 'components.FormatRepository.FormatListItem.download',
    defaultMessage: 'Descargar'
  },
  delete: {
    id: 'components.FormatRepository.FormatListItem.delete',
    defaultMessage: 'Eliminar'
  },
  cancel: {
    id: 'components.FormatRepository.FormatListItem.cancel',
    defaultMessage: 'Cancelar'
  },
  deleteModalTitle: {
    id: 'components.Approvers.deleteModal.title',
    defaultMessage: 'Eliminar formato'
  },
  deleteModalBody1: {
    id: 'components.Approvers.deleteModal.body1',
    defaultMessage: '¿Estas seguro de querer eliminar el formato'
  },
  deleteModalBody2: {
    id: 'components.Approvers.deleteModal.body2',
    defaultMessage:
      '?. Una vez eliminado, si quiere utilizarse nuevamente se tendrá que volver a cargar.'
  }
});
