import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nameLabel: {
    id: 'components.HcpsTable.nameLabel',
    defaultMessage: 'Nombre'
  },
  licenseLabel: {
    id: 'components.HcpsTable.licenseLabel',
    defaultMessage: 'Licencia'
  },
  specialtyLabel: {
    id: 'components.HcpsTable.specialtyLabel',
    defaultMessage: 'Especialidad'
  },
  therapysLabel: {
    id: 'components.HcpsTable.therapysLabel',
    defaultMessage: 'Areas de terapia'
  },
  emailLabel: {
    id: 'components.HcpsTable.emailLabel',
    defaultMessage: 'Correo'
  }
});
