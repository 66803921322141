import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(10)
  },
  rejectButton: {
    marginLeft: theme.spacing(2)
  }
}));

export { useStyles };
