import { useCatalogues } from 'components/Catalogues';
import { useDrawer } from 'components/Drawer';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../DistrictFormMessages';

const _initialValues = {
  name: '',
  managerId: ''
};

export const useForm = ({
  initialValues = _initialValues,
  onSubmit,
  manager
}) => {
  const { handleCloseDrawer } = useDrawer();
  const intl = useIntl();
  const { emptyEmployees, handleGetEmptyEmployees } = useCatalogues();
  const [newEmployes, setNewEmployes] = useState([]);

  useEffect(() => {
    handleGetEmptyEmployees();
  }, []);

  useEffect(() => {
    if (manager?.value) {
      setNewEmployes([...emptyEmployees, manager]);
    } else {
      setNewEmployes(emptyEmployees);
    }
  }, [emptyEmployees, manager]);

  const validationSchema = yup.object({
    name: yup.string().required(intl.formatMessage(messages.requiredError)),
    managerId: yup.string().required(intl.formatMessage(messages.requiredError))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const done = await onSubmit(values);

      done && handleCloseDrawer();
    }
  });

  const fields = {
    name: {
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: intl.formatMessage(messages.namePlaceholder)
    },
    managerId: {
      type: 'select',
      name: 'managerId',
      label: intl.formatMessage(messages.managerIdLabel),
      placeholder: intl.formatMessage(messages.managerIdPlaceholder),
      options: newEmployes,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      getOptionSelected: (option, value) => option === value
    }
  };

  return { formik, fields };
};
