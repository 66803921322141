import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { messages } from './CommentsSectionMessages';
import { useStyles } from './CommentsSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';
import { formatTime } from 'utils/dates';

export const CommentsSection = () => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    eventRequest: { comments }
  } = useEventRequestDetails();

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.titleCard)}
      </Typography>

      {comments.map(({ date, from, text }) => (
        <Box className={classes.comment}>
          <Typography variant="subtitle1" color="secondary">
            {from}{' '}
            {!xs && (
              <Typography
                variant="body2"
                component="span"
                color="textSecondary"
              >
                ({date.toLocaleString({ region: 'es-MX' })})
              </Typography>
            )}
          </Typography>
          {xs && (
            <Typography variant="body2" component="span" color="textSecondary">
              ({date.toLocaleString({ region: 'es-MX' })})
            </Typography>
          )}
          <Typography variant="body1">{text}</Typography>
        </Box>
      ))}
    </Box>
  );
};
