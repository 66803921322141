import { responseMapper } from 'utils/responseMapper';

const franchisesTemplate = {
  id: '_id',
  franquicia: 'name',
  agendas: 'agenda_count',
  empleados: 'employee_count',
  numberOfSchedulesPendingApproval: 'agenda_count',
  active: 'enabled',
  director: {
    label: 'director_name',
    value: 'director_id'
  },
  medical: {
    value: 'medical_id'
  }
};

export const mapFranchises = (franchises) => {
  const mappedFranchises = responseMapper({
    data: franchises,
    template: franchisesTemplate
  });

  return mappedFranchises;
};

export const filterArray = (franchises, value) => {
  const results = franchises.filter((franchise) => {
    let newFranuicia = removeAccents(franchise.franquicia.toLowerCase());
    let newValue = removeAccents(value.toLowerCase());

    return newFranuicia.includes(newValue);
  });

  return results;
};

const removeAccents = (str) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
