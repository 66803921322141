import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  history: {
    id: 'components.EventMovements.history',
    defaultMessage:
      'Historial de movimientos o cambios que ha presentado el estatus de esta solicitud'
  },
  onAprobation: {
    id: 'components.EventMovements.onAprobation',
    defaultMessage: 'Inició la aprobación'
  },
  approved: {
    id: 'components.EventMovements.approved',
    defaultMessage: 'Se aprobó la solicitud'
  },
  review: {
    id: 'components.EventMovements.review',
    defaultMessage: 'Inicio la revisión'
  },
  back: {
    id: 'components.EventMovements.back',
    defaultMessage: 'Se devolvió la solicitud'
  },
  rejected: {
    id: 'components.EventMovements.rejected',
    defaultMessage: 'Se rechazó la solicitud'
  },
  entered: {
    id: 'components.EventMovements.entered',
    defaultMessage: 'Se ingresó la solicitud'
  },
  created: {
    id: 'components.EventMovements.created',
    defaultMessage: 'Se creó la solicitud'
  },
  closed: {
    id: 'components.EventMovements.closed',
    defaultMessage: 'Se cerró la solicitud'
  },
  archived: {
    id: 'components.EventMovements.archived',
    defaultMessage: 'Se archivó la solicitud'
  },
  undefined: {
    id: 'components.EventMovements.undefined',
    defaultMessage: 'Desconocido'
  }
});
