import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  evidences: {
    id: 'components.CloseEventRequest.evidences',
    defaultMessage: 'Evidencias del Evento (fotografías, videos o documentos)'
  },
  files: {
    id: 'components.CloseEventRequest.files',
    defaultMessage: 'Documentos Adjuntos (PDF, Word, etc)'
  }
});
