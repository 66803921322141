import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F6F6F6'
  },
  bannerContainer: ({ backgroundImage }) => ({
    backgroundImage: `url('${backgroundImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '& > .nav-container': {
      minHeight: '100vh'
    },
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8)
  }),
  welcomeTitle: {
    fontWeight: 700,
    color: theme.palette.primary.main
  },
  userNameSubtitle: {
    textTransform: 'uppercase',
    letterSpacing: 4,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  summaryContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  summaryGroup: {
    padding: theme.spacing(2)
    // minHeight: 135
  },
  summaryGroupTitle: {
    textTransform: 'uppercase',
    letterSpacing: 4,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2.fontSize
    }
  },
  summaryIcon: {
    fontSize: 48
  },
  summaryName: {
    textTransform: 'uppercase',
    fontWeight: 500,
    maxWidth: '80px'
  },
  summaryValue: {
    fontWeight: 700
  },
  chartGroupContainer: {
    marginTop: theme.spacing(2)
  },
  cardChart: {
    padding: theme.spacing(2)
    // minHeight: 280,
  },
  chart: {
    padding: theme.spacing(2),
    width: '100%',
    height: '300px'
  }
}));

export { useStyles };
