import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  title: {
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  cardsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginLeft: -theme.spacing(3),
    flexWrap: 'wrap'
  },
  removeApproverModalImportantText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}));

export { useStyles };
