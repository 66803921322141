import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexFlow: 'row wrap',
    '& div': {
      marginRight: theme.spacing(1)
    }
  },
  searchItems: {
    width: 261,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      width: '100%'
    }
  },
  buttonEventSearch: {
    flexGrow: 0,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  actionsDatePickers: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    '& div': {
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 0,
      flexDirection: 'column',
      flexWrap: 'wrap'
    }
  },
  datePicker: {
    width: 210,
    height: 56,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      marginRight: 0,
      width: '100%'
    }
  },
  buttonDatePicker: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      marginRight: 0,
      width: '100%'
    }
  }
}));

export { useStyles };
