import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Grid, Box } from '@material-ui/core';
import { useModal } from '@octopy/react-modal';
import { messages } from './MyAccountFormMessages';
import { useStyles } from './MyAccountFormStyles';
import { UserDataSection } from './UserDataSection';
import { RolSection } from './RolSection';
import { ImageProfileSection } from './ImageProfileSection';
import { ChangePasswordSection } from './ChangePasswordSection';
import { useForm } from './hooks/useForm';
import { useFormik } from 'formik';
import { getItem } from 'utils/persistentStorage';
import { get } from 'lodash';
import { useAuth } from 'components/Auth';
import { useLoader } from '@octopy/react-loader';
import { uploadFilePromise } from 'utils/aws';
import { useEmployees } from 'components/Employees/hooks/useContext';
import { mapEditEmployee } from './helpers';

const MyAccountForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { initialValues, validationSchema } = useForm();
  const { updateMyAccount, user } = useAuth();
  const { handleShowLoader } = useLoader();
  const { handleGetEmployee } = useEmployees();

  useEffect(() => {
    (async () => {
      const employee = await handleGetEmployee(user.employeeId);

      if (employee) {
        formik.setValues(mapEditEmployee(employee));
      }
    })();
  }, []);

  const uploadImage = async (file) => {
    try {
      handleShowLoader(true);
      const userProfile = await uploadFilePromise(file);

      if (userProfile.indexOf('aws') === -1) {
        throw intl.formatMessage(messages.imgUploadSignError);
      }

      return userProfile;
    } catch (error) {
      handleOpenModal({
        title: error,
        configProps: {
          maxWidth: 'xs'
        }
      });
    } finally {
      handleShowLoader(false);
    }
  };

  const handleSubmit = async (values) => {
    const session = getItem('session');
    const userId = get(session, 'user.userId', null);

    const img = await uploadImage(values.image.file);

    const profileData = {
      identifier: values.identifier,
      name: values.name,
      email: values.email,
      rfc: values.rfc,
      city: values.city,
      avatar: img
    };

    if (userId) {
      const success = await updateMyAccount(userId, profileData);

      return success;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const success = await handleSubmit(values);

      if (success) {
        resetForm();
        handleOpenModal({
          title: intl.formatMessage(messages.userUpdated),
          actionButtons: (
            <Button onClick={handleCloseModal}>
              {intl.formatMessage(messages.btnAccept)}
            </Button>
          ),
          configProps: {
            maxWidth: 'xs'
          }
        });
      }
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.box}>
              <UserDataSection formik={formik} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.box}>
              <ImageProfileSection formik={formik} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.box}>
              <RolSection formik={formik} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.box}>
              <ChangePasswordSection formik={formik} />
            </Box>
            <Box className={classes.boxButton}>
              <Button onClick={() => formik.handleSubmit()} color="primary">
                {intl.formatMessage(messages.updateData)}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MyAccountForm.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { MyAccountForm };
