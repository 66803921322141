import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { messages } from './MaterialSectionMessages';
import { useStyles } from './MaterialSectionStyles';
import { useEventRequestDetails } from 'components/EventRequestDetails/hooks/useEventRequestDetails';

const MaterialSection = () => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    eventRequest: { materials }
  } = useEventRequestDetails();

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="body2" color="primary" className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Grid container>
        <Grid item xs={12} className={classes.containerInfo}>
          <Typography className={classes.informationLabel}>
            {intl.formatMessage(messages.totalCost)}
          </Typography>
          <Typography>{materials.totalCost}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

MaterialSection.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { MaterialSection };
