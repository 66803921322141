import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 1000,
    maxWidth: '100%'
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    marginLeft: theme.spacing(2)
  }
}));

export { useStyles };
