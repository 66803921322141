import { responseMapper } from 'utils/responseMapper';

export const mapEmployeesList = (list) => {
  const mappedList = list.map((item) => ({
    id: item._id,
    name: item.name,
    email: item.email,
    rfc: item.rfc,
    city: item.city,
    franchise: item.franchise_name,
    rol: item.role,
    enabled: item.enabled,
    image: item.avatar,
    deleted: item.deleted,
    identifier: item.identifier,
    title: item.title
  }));

  return mappedList;
};

const createTemplate = {
  city: 'city',
  email: 'email',
  rfc: 'rfc',
  identifier: 'identifier',
  name: 'name',
  avatar: 'image.url'
};

const editTemplate = {
  city: 'city',
  email: 'email',
  rfc: 'rfc',
  identifier: 'identifier',
  name: 'name'
};

export const mapCreateEmployee = (employeeData) => {
  const mappedCreate = responseMapper({
    data: employeeData,
    template: createTemplate
  });

  return { ...mappedCreate, avatar: mappedCreate.avatar || undefined };
};

export const mapEditEmployee = (employeeData) => {
  const mappedEmployee = responseMapper({
    data: employeeData,
    template: editTemplate
  });

  if (employeeData.avatar) {
    mappedEmployee.image = {
      url: employeeData.avatar
    };
  }

  return mappedEmployee;
};
