import React from 'react';
import { Avatar, Card, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { useStyles } from './EmployeeCardStyles';

export const EmployeeCard = ({
  name,
  imageUrl,
  identifier,
  role,
  subRole,
  onRemoveClick,
  color = 'secondary'
}) => {
  const classes = useStyles({ color });

  return (
    <Card className={classes.container}>
      {!!onRemoveClick && (
        <IconButton
          onClick={onRemoveClick}
          className={classes.removeButton}
          size="small"
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      )}

      <div className={classes.avatarContainer}>
        <Avatar
          src={imageUrl}
          alt={name}
          variant="rounded"
          className={classes.avatar}
        />
      </div>
      <div className={classes.infoContainer}>
        <div>
          <Typography
            className={`${classes.name} ${onRemoveClick && 'removable'}`}
            variant="body2"
          >
            {!!identifier && `${identifier} -`} {name}
          </Typography>
        </div>
        <div className={classes.roleContainer}>
          <Typography
            component="div"
            className={classes.role}
            variant="caption"
            color={color}
          >
            {role}
          </Typography>
          {subRole && (
            <Typography
              component="div"
              className={classes.approver}
              variant="caption"
            >
              {subRole}
            </Typography>
          )}
        </div>
      </div>
    </Card>
  );
};
