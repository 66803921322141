import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.DataSection.title',
    defaultMessage: 'DATOS DE LA SOLICITUD'
  },
  dateEvent: {
    id: 'components.DataSection.dateEvent',
    defaultMessage: 'fecha del evento:'
  },
  hourEvent: {
    id: 'components.DataSection.hourEvent',
    defaultMessage: 'Hora del evento:'
  },
  scheduleEvent: {
    id: 'components.DataSection.scheduleEvent',
    defaultMessage: 'Agenda:'
  },
  guests: {
    id: 'components.DataSection.guests',
    defaultMessage: 'Número de invitados:'
  },
  typeSupport: {
    id: 'components.DataSection.typeSupport',
    defaultMessage: 'Tipo de apoyo:'
  },
  nameSchedule: {
    id: 'components.DataSection.nameSchedule',
    defaultMessage: 'Nombre de la agenda:'
  }
});
