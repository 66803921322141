import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, IconButton, Box } from '@material-ui/core';
import { messages } from './EventRequestDetailsActionsMessages';
import { useStyles } from './EventRequestDetailsActionsStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useEventRequestDetails } from '../hooks/useEventRequestDetails';
import { useHistory, useParams } from 'react-router-dom';
import { eventTypes } from 'components/EventRequestForm/helpers';

export const EventRequestDetailsActions = () => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const { eventId } = useParams();
  const { eventRequest } = useEventRequestDetails();

  return (
    <>
      <div className={classes.container}>
        <IconButton edge="start" onClick={() => history.push('/eventRequests')}>
          <ArrowBackIcon color="secondary" />
        </IconButton>
        <Box className={classes.boxTitle}>
          <Typography variant="h5" color="primary" className={classes.title}>
            {intl.formatMessage(messages.title)}
          </Typography>
          {eventRequest.type === eventTypes.novoSupport && (
            <Typography
              variant="h5"
              color="secondary"
              className={classes.title}
            >
              {intl.formatMessage(messages.novoSupport)}
            </Typography>
          )}
          {eventRequest.type === eventTypes.novoEvent && (
            <Typography
              variant="h5"
              color="secondary"
              className={classes.title}
            >
              {intl.formatMessage(messages.novoEvent)}
            </Typography>
          )}
          {eventRequest.type === eventTypes.pharmacyActivities && (
            <Typography
              variant="h5"
              color="secondary"
              className={classes.title}
            >
              {intl.formatMessage(messages.pharmacy)}
            </Typography>
          )}
          {eventRequest.type === eventTypes.faceToFace && (
            <Typography
              variant="h5"
              color="secondary"
              className={classes.title}
            >
              {intl.formatMessage(messages.faceToFace)}
            </Typography>
          )}
        </Box>
      </div>
      <Typography variant="body1" color="secondary">
        <b>ID: </b>
        {eventId
          ?.split('')
          .reverse()
          .slice(0, 5)
          .reverse()
          .join('')
          .toUpperCase()}
      </Typography>
    </>
  );
};
