import React from 'react';
import {
  Backdrop,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useStyles } from './PdfViewerStyles';
import BackIcon from '@material-ui/icons/ArrowBack';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import DownloadIcon from '@material-ui/icons/GetAppRounded';
import PrintIcon from '@material-ui/icons/PrintRounded';
import printJS from 'print-js';
import { downloadFile } from 'utils/files';
import { usePdfViewer } from './hooks/usePdfViewer';

const PdfViewer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { visible, handleClose, url, name } = usePdfViewer();

  const handlePrint = () => printJS(url);
  const handleDownload = () => downloadFile({ url, name });

  return (
    <Backdrop className={classes.backdrop} open={visible} onClick={handleClose}>
      <Box
        className={classes.header}
        onClick={(event) => event.stopPropagation()}
      >
        <IconButton onClick={handleClose} className={classes.icon}>
          <BackIcon />
        </IconButton>

        <PdfIcon className={classes.pdfIcon} />

        <Typography variant="body2" className={classes.name}>
          {name}
        </Typography>

        <div className={classes.spacer} />

        {url?.includes('blob') && (
          <>
            <IconButton
              size={xs ? 'small' : 'medium'}
              className={classes.icon}
              onClick={handlePrint}
            >
              <PrintIcon />
            </IconButton>
            <IconButton className={classes.icon} onClick={handleDownload}>
              <DownloadIcon />
            </IconButton>
          </>
        )}
      </Box>

      <div className={classes.spacer} />

      {!!url && (
        <embed
          id="pdf-embed"
          src={`${url}#toolbar=0&amp;navpanes=0&amp;scrollbar=0`}
          className={classes.pdf}
          type="application/pdf"
        ></embed>
      )}

      <div className={classes.spacer} />
    </Backdrop>
  );
};

export { PdfViewer };
