import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../ConfigurationFormMessages';

export const useValidationSchema = () => {
  const intl = useIntl();

  const min0ErrorMessage = intl.formatMessage(messages.min0Error);
  const min1ErrorMessage = intl.formatMessage(messages.min1Error);
  const requiredErrorMessage = intl.formatMessage(messages.requiredError);

  const validationSchema = yup.object({
    anticipationDays: yup
      .number()
      .min(1, min1ErrorMessage)
      .required(requiredErrorMessage),
    facilityRentalCost: yup
      .number()
      .min(0, min0ErrorMessage)
      .required(requiredErrorMessage),
    materialsCost: yup.number().min(0).required(requiredErrorMessage),
    minimumNumberOfGuestsForNovoEventAndNovoSupport: yup
      .number()
      .min(1, min1ErrorMessage)
      .required(requiredErrorMessage),
    maximumNumberOfGuestsForFaceToFaceAndPharmacyActivities: yup
      .number()
      .min(1, min1ErrorMessage)
      .required(requiredErrorMessage),
    maximumFoodCostPerPerson: yup
      .number()
      .min(0, min0ErrorMessage)
      .required(requiredErrorMessage),
    // tipPercent: yup
    //   .number()
    //   .min(0, min0ErrorMessage)
    //   .required(requiredErrorMessage),
    firstCloseNotification: yup
      .number()
      .min(0, min0ErrorMessage)
      .required(requiredErrorMessage),
    secondCloseNotification: yup
      .number()
      .min(0, min0ErrorMessage)
      .required(requiredErrorMessage),
    thirdCloseNotification: yup
      .number()
      .min(1, min1ErrorMessage)
      .required(requiredErrorMessage)
  });

  return validationSchema;
};
