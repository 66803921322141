const pieColors = [
  '#0099D7',
  '#E0007A',
  '#066F6F',
  '#9B35A3',
  '#ECAC1B',
  '#00EDBF',
  '#EF6C26'
];

export { pieColors };
