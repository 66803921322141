import { useApi } from 'hooks';

export const useSchedulesActions = (handleUpdateFranchise) => {
  const [addSchedule] = useApi({
    endpoint: '/agenda/create',
    method: 'post'
  });
  const [editSchedule] = useApi({
    endpoint: '/agenda/update',
    method: 'put'
  });
  const [removeSchedule] = useApi({
    endpoint: '/agenda/delete',
    method: 'delete'
  });
  const [approveSchedule] = useApi({
    endpoint: '/agenda/approve',
    method: 'put'
  });

  const handleApproveSchedule = async (franchiseId, scheduleId) => {
    try {
      const {
        headerResponse: { status }
      } = await approveSchedule({ urlParams: scheduleId });

      if (status === 200) {
        handleUpdateFranchise(franchiseId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSchedule = async (franchiseId, values) => {
    try {
      const body = {
        name: values.name,
        file_url: values.document.url,
        franchise_id: franchiseId
      };

      const {
        headerResponse: { status }
      } = await addSchedule({ body });

      if (status === 200) {
        handleUpdateFranchise(franchiseId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditSchedule = async (franchiseId, values) => {
    try {
      const body = {
        name: values.name,
        file_url: values.document.url
      };

      const {
        headerResponse: { status }
      } = await editSchedule({ urlParams: values.id, body });

      if (status === 200) {
        handleUpdateFranchise(franchiseId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveSchedule = async (franchiseId, scheduleId) => {
    try {
      const {
        headerResponse: { status }
      } = await removeSchedule({ urlParams: scheduleId });

      if (status === 200) {
        handleUpdateFranchise(franchiseId);

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleAddSchedule,
    handleRemoveSchedule,
    handleEditSchedule,
    handleApproveSchedule
  };
};
