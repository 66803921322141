import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  authorizationDate: {
    id: 'components.ApprovalCard. authorizationDate',
    defaultMessage: 'Fecha de autorización:'
  },
  approveRequest: {
    id: 'components.ApprovalCard.approveRequest',
    defaultMessage: 'Aprobar solicitud'
  },
  aceptButton: {
    id: 'components.ApprovalCard.aceptButton',
    defaultMessage: 'Aceptar'
  }
});
