import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { messages } from '../../EventRequestFormMessages';

export const useValidationSchema = () => {
  const intl = useIntl();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const greaterThanZeroErrorMessage = intl.formatMessage(
    messages.greaterThanError,
    {
      number: 0
    }
  );

  const validationSchema = yup.object({
    draft: yup.boolean(),
    requirements: yup
      .object({
        pharmacyName: yup.string().required(requiredErrorMessage),
        date: yup.date().nullable().required(requiredErrorMessage),
        time: yup.date().nullable().required(requiredErrorMessage)
      })
      .when('draft', (draft, schema) => (!draft ? schema : yup.object())),
    address: yup
      .object({
        cityId: yup.string().nullable().required(requiredErrorMessage),
        colonyId: yup.string().nullable().required(requiredErrorMessage),
        externalNumber: yup
          .number()
          .nullable()
          .min(1, greaterThanZeroErrorMessage)
          .required(requiredErrorMessage),
        internalNumber: yup
          .number()
          .nullable()
          .min(1, greaterThanZeroErrorMessage)
          .required(requiredErrorMessage),
        zipCode: yup
          .number()
          .nullable()
          .min(1, greaterThanZeroErrorMessage)
          .required(requiredErrorMessage)
      })
      .when('draft', (draft, schema) => (!draft ? schema : yup.object())),
    objective: yup.string()
    // .when('draft', (draft, schema) =>
    //   !draft ? schema.required(requiredErrorMessage) : schema
    // )
  });

  return validationSchema;
};
