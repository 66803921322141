import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { useStyles } from './FullScreenLayoutStyles';
import { Modal } from '@octopy/react-modal';
import { Loader } from '@octopy/react-loader';
import { SplashScreen } from 'components/SplashScreen';
import { Footer } from 'components/Footer';

function FullScreenLayout({
  children,
  backgroundImage,
  withSplashScreen = false
}) {
  const styles = useStyles({ backgroundImage });

  return (
    <ErrorBoundary>
      <Modal />
      <Loader />
      {withSplashScreen && <SplashScreen />}
      <div className={styles.container}>
        {children}
        <Footer variant="dark" />
      </div>
    </ErrorBoundary>
  );
}

FullScreenLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export { FullScreenLayout };
